<script setup lang="ts">
import { defineProps } from "vue";
import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
// Import ChartData as a type only
import type { ChartData } from "chart.js";
import { LineChart } from "vue-chart-3";

// Register Chart.js components
ChartJS.register(
  LineController,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

// Define the props for chart data and options
const props = defineProps({
  Data: {
    type: Object as () => ChartData<"line", (number | null)[], string>, // Define ChartData type here
    required: true,
  },
  chartOptions: {
    type: Object,
    required: true,
  },
  showCenterText: {
    required: false,
  },
});
</script>

<template>
  <div class="w-[100%]">
    <line-chart
      :chart-options="props.chartOptions"
      :chart-data="props.Data"
      :chart-id="'api-usage-chart'"
      :dataset-id-key="'apiName'"
      :options="{
        hover: {
          mode: 'nearest', // Ensure hover works on the nearest point
          intersect: false, // Trigger only when directly over a point
        },
        plugins: {
          centerText: showCenterText, // You can conditionally add/remove it from options
          legend: {
            position: 'top',
            labels: {
              boxWidth: 4, // Set to 0 to hide the checkbox
              boxHeight: 4, // Optionally adjust the height if you still want a small box
              padding: 20, // Add padding if needed
              color: '#94969C',
              generateLabels: (chart:any) => {
                const originalLabels = ChartJS.defaults.plugins.legend.labels.generateLabels(chart);
                return originalLabels.map((label:any) => ({
                  ...label,
                  borderRadius: 2, // Add custom property for border-radius
                }));
              },
          },
        },
      },
      }"
    />
  </div>
</template>

<style>
#api-usage-chart {
  height: 160px;
  cursor: pointer;
}
</style>
