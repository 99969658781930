export function GetUserStatusClass(statusId: number): string {
    switch (statusId) {
        case 1:
            return 'text-[#86efac] border-[#86efac]';
        case 2:
            return 'text-[#64748b] border-[#64748b]';
        case 3:
            return 'text-[#fb923c] border-[#fb923c]';
        case 4:
            return 'text-[#ef4444] border-[#ef4444]';
        case 5:
            return 'text-[#d9f99d] border-[#d9f99d]';
        default:
            return '';
    }
}