<template>
  <section class="pop-up-create-key">
    <header class="header">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/601a5267783be676e315b70bb1f816c6b149d6dc4ee63f4ce4c0f4e1ef49ef22?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51"
        alt=""
        class="close-icon"
        @click="closeView"
      />
    </header>
    <main class="content">
      <article class="success-message">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/38df5e9dbc55aaa81e49f9adf1cbc86c3aebaf846535b310647c17e35c6fcd5e?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51"
          alt="Success icon"
          class="success-icon"
        />
        <div class="message-container">
          <h2 class="h6Bold title">Request submitted successfully</h2>
          <p class="bodyXSmall description">
            Your request has been successfully processed.
            <br />We appreciate your submission.
          </p>
        </div>
      </article>
      <button class="h7 confirm-button" @click="closeView">Got it</button>
    </main>
  </section>
</template>

<script setup lang="ts">
const emits = defineEmits(["closeView"]);

const closeView = () => {
  emits("closeView");
};
</script>

<style scoped>
.pop-up-create-key {
  border-radius: 15px;
  background-color: rgba(3, 21, 37, 1);
  display: flex;
  width: 888px;
  max-width: 95vw;
  height: 359px;
  max-height: 90vh;
  flex-direction: column;
  overflow: hidden;
}

.header {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
}

.close-icon {
  width: 32px;
  height: 32px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 16px;
  font-family: Inter, sans-serif;
  text-align: center;
}

.success-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 585px;
  width: 100%;
}

.success-icon {
  width: 49px;
  height: 49px;
  border-radius: 24px;
}

.message-container {
  margin-top: 16px;
}

.title {
  color: #fff;
}

.description {
  color: var(--Text-Tetriary, #cbd6e3);
  margin-top: 6px;
  max-width: 341px;
}

.confirm-button {
  width: 100px;
  border-radius: 6px;
  margin-top: 26px;
  min-height: 40px;
  color: var(--Button, #7ba8ef);
  padding: 2px 0;
  border: 1px solid rgba(123, 168, 239, 1);
  background: none;
  cursor: pointer;
}

@media (max-width: 991px) {
  .pop-up-create-key,
  .header,
  .content,
  .success-message,
  .message-container,
  .title {
    max-width: 100%;
  }
}
</style>
