<!-- AssistantItem.vue -->
<template>
  <div
    v-if="selectedItemData"
    :class="
      isAssistantPlayground
        ? 'border-none pl-0'
        : 'xl:w-[70%] lg:border-l xl:border-l pl-5 md:w-full sm:w-full'
    "
  >
    <div
      class="xl:flex lg:flex md:flex sm:flex-none justify-between flex-auto sm:mb-5 xl:mb-0 lg:mb-0 md:mb-0 mb-5"
    >
      <div class="flex">
        <div class="py-5 pr-5 relative">
          <img src="../../assets/profile-img.svg" alt="" />
          <div class="absolute top-12 right-1">
            <svg
              width="53"
              height="53"
              viewBox="0 0 53 53"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_d_21829_2136)">
                <rect
                  x="14"
                  y="14"
                  width="25"
                  height="25"
                  rx="12.5"
                  fill="#7BA8EF"
                  shape-rendering="crispEdges"
                />
                <g clip-path="url(#clip0_21829_2136)">
                  <path
                    d="M25.957 22.1647H23.682C22.7719 22.1647 22.3169 22.1647 21.9693 22.3418C21.6635 22.4976 21.4149 22.7462 21.2591 23.0519C21.082 23.3995 21.082 23.8546 21.082 24.7647V29.3147C21.082 30.2248 21.082 30.6798 21.2591 31.0274C21.4149 31.3332 21.6635 31.5818 21.9693 31.7376C22.3169 31.9147 22.7719 31.9147 23.682 31.9147H28.232C29.1421 31.9147 29.5972 31.9147 29.9448 31.7376C30.2505 31.5818 30.4991 31.3332 30.6549 31.0274C30.832 30.6798 30.832 30.2248 30.832 29.3147V27.0397M24.332 28.6647H25.2391C25.504 28.6647 25.6365 28.6647 25.7612 28.6347C25.8717 28.6082 25.9774 28.5644 26.0743 28.505C26.1837 28.438 26.2773 28.3444 26.4647 28.157L31.6445 22.9772C32.0933 22.5284 32.0933 21.8009 31.6445 21.3522C31.1958 20.9034 30.4683 20.9034 30.0195 21.3522L24.8397 26.532C24.6523 26.7194 24.5587 26.813 24.4917 26.9224C24.4323 27.0193 24.3885 27.125 24.3619 27.2355C24.332 27.3602 24.332 27.4927 24.332 27.7576V28.6647Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </g>
              <defs>
                <filter
                  id="filter0_d_21829_2136"
                  x="0"
                  y="0"
                  width="53"
                  height="53"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feMorphology
                    radius="4"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_21829_2136"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="5" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_21829_2136"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_21829_2136"
                    result="shape"
                  />
                </filter>
                <clipPath id="clip0_21829_2136">
                  <rect
                    width="13"
                    height="13"
                    fill="white"
                    transform="translate(20 20)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <p
          v-if="!isCreation && selectedItemData.id !== 0"
          class="mr-auto ml-3 mt-8 text-[1.5rem] font-bold"
        >
          {{ selectedItemData?.id }}
        </p>
      </div>
      <div
        class="mt-8 py-1 h-10 flex"
        v-if="!isCreation && !isAssistantPlayground"
      >
        <button
          class="btn btn-secondary"
          type="submit"
          @click="openAssistantPlayground"
        >
          Playground
        </button>
      </div>
    </div>
    <div class="flex flex-col relative mb-5">
      <label
        class="mb-2 text-[0.875rem] font-normal leading-[1.25rem] text-[#CBD6E3]"
        >name</label
      >
      <input
        type="text"
        placeholder="olivia@untitledui.com"
        v-model="assistantsDetailsToUpdate.name"
        :readonly="isDefaultAssistant"
        class="h-10 rounded-md bg-[var(--color-background-sidebar)] border border-[#333741] p-3 pr-[1.875rem] focus-visible:outline-none"
      />
      <span
        class="absolute top-[2.5rem] right-3 group"
        data-tooltip-placement="top"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_21821_8660)">
            <path
              d="M6.0587 6.0026C6.21543 5.55705 6.5248 5.18134 6.932 4.94203C7.3392 4.70271 7.81796 4.61523 8.28348 4.69508C8.749 4.77493 9.17124 5.01695 9.47542 5.37829C9.77959 5.73963 9.94607 6.19695 9.94536 6.66927C9.94536 8.0026 7.94536 8.66927 7.94536 8.66927M7.9987 11.3359H8.00536M14.6654 8.0026C14.6654 11.6845 11.6806 14.6693 7.9987 14.6693C4.3168 14.6693 1.33203 11.6845 1.33203 8.0026C1.33203 4.32071 4.3168 1.33594 7.9987 1.33594C11.6806 1.33594 14.6654 4.32071 14.6654 8.0026Z"
              stroke="#85888E"
              stroke-width="1.33333"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_21821_8660">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <Tooltip :title="assistantNameTooltipText" />
      </span>
    </div>
    <div class="flex flex-col relative mb-1 mb-5">
      <label
        class="mb-2 text-[0.875rem] font-normal leading-[1.25rem] text-[#CBD6E3]"
      >
        Default Instructions
      </label>
      <textarea
        type="text"
        placeholder="Describe how you want me to be."
        rows="8"
        v-model="assistantsDetailsToUpdate.instructions"
        :readonly="isDefaultAssistant"
        class="rounded-md bg-[var(--color-background-sidebar)] border border-[#333741] p-5 pr-[1.875rem] focus-visible:outline-none resize-none"
      >
      </textarea>
      <span class="absolute top-[2.75rem] right-3 group">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_21821_8660)">
            <path
              d="M6.0587 6.0026C6.21543 5.55705 6.5248 5.18134 6.932 4.94203C7.3392 4.70271 7.81796 4.61523 8.28348 4.69508C8.749 4.77493 9.17124 5.01695 9.47542 5.37829C9.77959 5.73963 9.94607 6.19695 9.94536 6.66927C9.94536 8.0026 7.94536 8.66927 7.94536 8.66927M7.9987 11.3359H8.00536M14.6654 8.0026C14.6654 11.6845 11.6806 14.6693 7.9987 14.6693C4.3168 14.6693 1.33203 11.6845 1.33203 8.0026C1.33203 4.32071 4.3168 1.33594 7.9987 1.33594C11.6806 1.33594 14.6654 4.32071 14.6654 8.0026Z"
              stroke="#85888E"
              stroke-width="1.33333"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_21821_8660">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <Tooltip :title="defaultInstructionsTooltipText" content="" />
      </span>
    </div>
    <div class="flex flex-col relative mb-1 mb-5">
      <label
        class="mb-2 text-[0.875rem] font-normal leading-[1.25rem] text-[#CBD6E3]"
      >
        Additional Instructions
      </label>
      <textarea
        type="text"
        placeholder="Describe how you want me to be."
        rows="8"
        class="rounded-md bg-[var(--color-background-sidebar)] border border-[#333741] p-5 pr-[1.875rem] focus-visible:outline-none resize-none"
        v-model="assistantsDetailsToUpdate.additionalInstructions"
        :readonly="isDefaultAssistant"
      ></textarea>
      <span class="absolute top-[2.75rem] right-3 group">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_21821_8660)">
            <path
              d="M6.0587 6.0026C6.21543 5.55705 6.5248 5.18134 6.932 4.94203C7.3392 4.70271 7.81796 4.61523 8.28348 4.69508C8.749 4.77493 9.17124 5.01695 9.47542 5.37829C9.77959 5.73963 9.94607 6.19695 9.94536 6.66927C9.94536 8.0026 7.94536 8.66927 7.94536 8.66927M7.9987 11.3359H8.00536M14.6654 8.0026C14.6654 11.6845 11.6806 14.6693 7.9987 14.6693C4.3168 14.6693 1.33203 11.6845 1.33203 8.0026C1.33203 4.32071 4.3168 1.33594 7.9987 1.33594C11.6806 1.33594 14.6654 4.32071 14.6654 8.0026Z"
              stroke="#85888E"
              stroke-width="1.33333"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_21821_8660">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <Tooltip :title="additionalInstructionsTooltipText" content="" />
      </span>
    </div>
    <div
      :class="
        isAssistantPlayground
          ? 'flex-wrap flex'
          : 'xl:flex lg:flex sm:flex-none md:flex-none w-full gap-2 mb-5'
      "
    >
      <div
        :class="
          isAssistantPlayground
            ? 'w-full mb-5'
            : 'xl:w-[50%] lg:w-[50%] sm:w-full md:w-full xl:mb-0 lg:mb-0 sm:mb-5 md:mb-5 mb-5'
        "
      >
        <customLabel
          text="Tool Selection"
          :tooltipText="toolSelectionTooltipText"
        ></customLabel>
        <div>
          <div
            class="input-model relative"
            :class="{ 'flex flex-row': isAssistantPlayground }"
          >
            <div class="absolute z-10 top-[0.675rem] left-[0.875rem]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g opacity="0.5">
                  <path
                    d="M17.5 17.5L14.5834 14.5833M16.6667 9.58333C16.6667 13.4954 13.4954 16.6667 9.58333 16.6667C5.67132 16.6667 2.5 13.4954 2.5 9.58333C2.5 5.67132 5.67132 2.5 9.58333 2.5C13.4954 2.5 16.6667 5.67132 16.6667 9.58333Z"
                    stroke="white"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </svg>
            </div>
            <Multiselect
              ref="multiselectRef"
              v-model="assistantsDetailsToUpdate.toolIds"
              :options="listOfAllTool"
              :mode="multiSelectConfig.mode"
              :close-on-select="multiSelectConfig.closeOnSelect"
              :create-option="multiSelectConfig.createOption"
              :hideSelected="true"
              class="bg-backgroundSidebar border border-solid border-inputSelectBorder rounded-lg px-2 pl-8"
              :class="{ 'pointer-events-none': isDefaultAssistant }"
              :disabled="isDefaultAssistant"
            />
          </div>
        </div>
      </div>
      <div
        :class="
          isAssistantPlayground
            ? 'w-full mb-5'
            : 'xl:w-[50%] lg:w-[50%] sm:w-full md:w-full'
        "
      >
        <customLabel
          text="Vector Store/s"
          :tooltipText="vectorStoreTooltipText"
        ></customLabel>
        <div
          class="input-model relative"
          :class="{ 'flex flex-row': isAssistantPlayground }"
        >
          <div class="absolute z-10 top-[0.675rem] left-[0.875rem]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g opacity="0.5">
                <path
                  d="M17.5 17.5L14.5834 14.5833M16.6667 9.58333C16.6667 13.4954 13.4954 16.6667 9.58333 16.6667C5.67132 16.6667 2.5 13.4954 2.5 9.58333C2.5 5.67132 5.67132 2.5 9.58333 2.5C13.4954 2.5 16.6667 5.67132 16.6667 9.58333Z"
                  stroke="white"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>
          </div>
          <Multiselect
            ref="multiselectRef"
            v-model="assistantsDetailsToUpdate.vectorStoreIds"
            :options="listOfAllVectorStore"
            :mode="multiSelectConfig.mode"
            :close-on-select="multiSelectConfig.closeOnSelect"
            :create-option="multiSelectConfig.createOption"
            :hideSelected="true"
            class="bg-backgroundSidebar border border-solid border-inputSelectBorder rounded-lg px-2 pl-8"
            :class="{ 'pointer-events-none': isDefaultAssistant }"
            :disabled="isDefaultAssistant"
          />
        </div>
      </div>
    </div>
    <div
      :class="
        isAssistantPlayground
          ? 'flex-wrap mb-5 flex w-full'
          : 'xl:flex lg:flex sm:flex-none md:flex-none w-full mb-5 gap-2'
      "
    >
      <div
        :class="
          isAssistantPlayground
            ? 'w-full mb-5'
            : 'w-full 2xl:w-[50%] xl:w-[50%] lg:w-[50%] sm:w-full md:w-full xl:mb-0 lg:mb-0 sm:mb-5 md:mb-5 mb-5'
        "
      >
        <customLabel
          text="Model"
          :tooltipText="modelTooltipText"
          :class="{ '-mb-0': isAssistantPlayground }"
        >
        </customLabel>
        <div>
          <selectbox
            :data="listOfAllModel"
            :selectedModelId="selectedModelId"
            :isDisabled="isDefaultAssistant"
            @onSelect="onModelSelect"
          />
        </div>
      </div>
      <div
        :class="
          isAssistantPlayground
            ? 'w-full mb-5'
            : 'w-full 2xl:w-[50%] xl:w-[50%] lg:w-[50%] sm:w-full md:w-full'
        "
      >
        <customLabel
          text="Output mode"
          :tooltipText="outputModeTooltipText"
          :class="{ '-mb-0': isAssistantPlayground }"
        ></customLabel>
        <div>
          <selectbox
            :data="listOfAllOutputModes"
            :selectedModelId="selectedOutputModeId"
            :isDisabled="isDefaultAssistant"
            @onSelect="onOutputModeSelect"
          />
        </div>
      </div>
    </div>
    <div
      :class="
        isAssistantPlayground
          ? 'flex flex-row flex-wrap'
          : 'xl:flex lg:flex  w-full sm:flex-none md:flex-none gap-2 mb-5'
      "
    >
      <div
        :class="
          isAssistantPlayground
            ? 'w-full mb-5'
            : 'xl:w-[50%] lg:w-[50%] sm:w-full md:w-full xl:mb-0 lg:mb-0 sm:mb-5 md:mb-5 mb-5'
        "
      >
        <customLabel
          text="frequency_penalty"
          :tooltipText="frequencyPenaltyTooltipText"
          :value="assistantsDetailsToUpdate.frequencyPenalty"
        ></customLabel>
        <div>
          <range
            :min="0"
            :max="100"
            :step="1"
            v-model="assistantsDetailsToUpdate.frequencyPenalty"
            :isDisabled="isDefaultAssistant"
            :class="{ 'pointer-events-none': isDefaultAssistant }"
          />
        </div>
      </div>
      <div
        :class="
          isAssistantPlayground
            ? 'w-full mb-5'
            : 'xl:w-[50%] lg:w-[50%] sm:w-full md:w-full xl:mb-0 lg:mb-0 sm:mb-5 md:mb-5'
        "
      >
        <customLabel
          text="logit bias"
          :logitBiasText="formatLogitBiasTooltipText"
          :value="assistantsDetailsToUpdate.logitBias"
        ></customLabel>
        <div>
          <range
            :min="-100"
            :max="100"
            :step="0.1"
            v-model="assistantsDetailsToUpdate.logitBias"
            :isDisabled="isDefaultAssistant"
            :class="{ 'pointer-events-none': isDefaultAssistant }"
          />
        </div>
      </div>
    </div>
    <div
      :class="
        isAssistantPlayground
          ? 'flex flex-row flex-wrap'
          : 'xl:flex lg:flex  w-full sm:flex-none md:flex-none gap-2 mb-5'
      "
    >
      <div
        :class="
          isAssistantPlayground
            ? 'w-full mb-5'
            : 'xl:w-[50%] lg:w-[50%] sm:w-full md:w-full xl:mb-0 lg:mb-0 sm:mb-5 md:mb-5 mb-5'
        "
      >
        <customLabel
          text="presence_penalty"
          :tooltipText="presencePenaltyTooltipText"
          :value="assistantsDetailsToUpdate.presencePenalty"
        ></customLabel>
        <div>
          <range
            :min="-2"
            :max="2"
            :step="0.1"
            v-model="assistantsDetailsToUpdate.presencePenalty"
            :isDisabled="isDefaultAssistant"
            :class="{ 'pointer-events-none': isDefaultAssistant }"
          />
        </div>
      </div>
      <div
        :class="
          isAssistantPlayground
            ? 'w-full mb-5'
            : 'xl:w-[50%] lg:w-[50%] sm:w-full md:w-full xl:mb-0 lg:mb-0 sm:mb-5 md:mb-5 mb-5'
        "
      >
        <customLabel
          text="Top p"
          :tooltipText="topPTooltipText"
          :value="assistantsDetailsToUpdate.topP"
        >
        </customLabel>
        <div>
          <range
            :min="0.01"
            :max="1"
            :step="0.1"
            v-model="assistantsDetailsToUpdate.topP"
            :isDisabled="isDefaultAssistant"
            :class="{ 'pointer-events-none': isDefaultAssistant }"
          />
        </div>
      </div>
    </div>
    <div
      class="flex w-full gap-2 mb-5"
      :class="{ 'flex-wrap': isAssistantPlayground }"
    >
      <div
        class="flex items-center justify-between"
        :class="isAssistantPlayground ? 'w-full' : 'w-[50%]'"
      >
        <customLabel
          text="log probs"
          :tooltipText="logProbsTooltipText"
        ></customLabel>
        <label class="inline-flex cursor-pointer items-center relative">
          <input
            type="checkbox"
            v-model="assistantsDetailsToUpdate.isLogProbs"
            class="peer sr-only"
            :disabled="isDefaultAssistant"
          />
          <div
            class="peer relative h-[30px] w-[56px] rounded-full bg-[#CFCFD0] after:absolute after:start-[2px] after:top-0.5 after:h-[26px] after:w-[26px] after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-[#7BA8EF] peer-checked:after:translate-x-full peer-checked:after:border-white rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-[gray-700]"
          ></div>
        </label>
      </div>
      <div
        class="flex items-center justify-between"
        :class="isAssistantPlayground ? 'w-full' : 'w-[50%]'"
      >
        <customLabel
          text="Streaming"
          :tooltipText="streamingTooltipText"
          value=""
        ></customLabel>
        <label class="inline-flex cursor-pointer items-center relative">
          <input
            type="checkbox"
            v-model="assistantsDetailsToUpdate.isStreaming"
            class="peer sr-only"
            :disabled="isDefaultAssistant"
          />
          <div
            class="peer relative h-[30px] w-[56px] rounded-full bg-[#CFCFD0] after:absolute after:start-[2px] after:top-0.5 after:h-[26px] after:w-[26px] after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-[#7BA8EF] peer-checked:after:translate-x-full peer-checked:after:border-white rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-[gray-700]"
          ></div>
        </label>
      </div>
    </div>
    <div
      :class="
        isAssistantPlayground
          ? 'flex flex-row flex-wrap'
          : 'xl:flex lg:flex  w-full sm:flex-none md:flex-none gap-2 mb-5'
      "
    >
      <div
        :class="
          isAssistantPlayground
            ? 'w-full mb-5'
            : 'xl:w-[50%] lg:w-[50%] sm:w-full md:w-full xl:mb-0 lg:mb-0 sm:mb-5 md:mb-5 mb-5'
        "
      >
        <customLabel
          text="top logprobs"
          :tooltipText="topLogProbsTooltipText"
          :value="assistantsDetailsToUpdate.topLogProbs"
        ></customLabel>
        <div>
          <range
            :min="0"
            :max="20"
            :step="0.1"
            v-model="assistantsDetailsToUpdate.topLogProbs"
            :isDisabled="
              isDefaultAssistant || !assistantsDetailsToUpdate.isLogProbs
            "
            :class="{ 'pointer-events-none': isDefaultAssistant }"
          />
        </div>
      </div>
      <div
        :class="
          isAssistantPlayground
            ? 'w-full mb-5'
            : 'xl:w-[50%] lg:w-[50%] sm:w-full md:w-full xl:mb-0 lg:mb-0 sm:mb-5 md:mb-5 mb-5'
        "
      >
        <customLabel
          text="Temperature"
          :tooltipText="temperatureTooltipText"
          :value="assistantsDetailsToUpdate.temperature"
        ></customLabel>
        <div>
          <range
            :min="0"
            :max="2"
            :step="0.01"
            v-model="assistantsDetailsToUpdate.temperature"
            :isDisabled="isDefaultAssistant"
            :class="{ 'pointer-events-none': isDefaultAssistant }"
          />
        </div>
      </div>
    </div>

    <div
      class="gap-2 mb-5"
      v-if="
        (assistantsDetailsToUpdate.presetPrompts &&
          assistantsDetailsToUpdate.presetPrompts.length > 0) ||
        !isDefaultAssistant
      "
    >
      <customLabel
        text="Preset Prompts"
        :tooltipText="presetPromptTooltipText"
      ></customLabel>
      <div
        class="flex-wrap justify-start"
        :class="
          isAssistantPlayground
            ? 'flex flex-row flex-wrap'
            : 'xl:flex lg:flex flex-none w-full sm:flex md:flex gap-3.5'
        "
      >
        <div
          v-for="(
            presetPrompt, index
          ) in assistantsDetailsToUpdate.presetPrompts"
          :key="index"
          class="h-[3.375rem] rounded-lg flex justify-between items-center cursor-pointer bg-[#1D2E47] p-2 mb-5"
          :class="{
            'w-full': isAssistantPlayground,
            'w-[15.56rem] xl:mb-0 lg:mb-0 sm:mb-5 md:mb-5':
              !isAssistantPlayground,
            'pointer-events-none': isDefaultAssistant,
          }"
          @click="updatePresetPrompt(presetPrompt.id)"
        >
          <div><img src="../../assets/mail-01.svg" alt="" /></div>
          <div class="flex justify-between w-[150px] gap-2">
            <span
              class="text-[0.875rem] font-bold truncate leading-[1.05875rem]"
            >
              {{ presetPrompt.name }} <br />
              {{ presetPrompt.content }}
            </span>
          </div>
          <div><img src="../../assets/edit.svg" alt="" /></div>
        </div>
        <div
          v-if="!isDefaultAssistant"
          class="h-[3.375rem] rounded-lg flex items-center border border-[var(--color-button-blue)] p-2 cursor-pointer"
          :class="isAssistantPlayground ? 'w-full' : 'w-[15.56rem]'"
          @click="openModal"
        >
          <div class="flex justify-center items-center gap-3 w-full">
            <span> <img src="../../assets/plus.svg" alt="" /> </span>
            <span class="text-[0.875rem] font-bold leading-[1.05875rem]"
              >Add a Preset Prompts</span
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex flex-row justify-between items-center mt-16"
      v-if="!isDefaultAssistant"
    >
      <div>
        <button
          type="button"
          v-if="!isCreation"
          class="btn btn-outline-graph-red"
          :class="isAssistantPlayground ? 'w-[5rem]' : 'w-[5.15rem]'"
          @click="openDeleteModal"
        >
          Delete
        </button>
      </div>
      <div class="flex gap-4 items-center">
        <button
          type="button"
          class="btn text-blue w-[5.25rem] border border-solid border-buttonBlue"
          :class="{
            'w-[5rem]': isAssistantPlayground,
            'opacity-50 pointer-events-none': !isAssistantDetailEdited,
          }"
          @click="handleUpdateCancel"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn px-4 btn-primary"
          :class="{
            'w-[5rem]': isAssistantPlayground,
            'w-[4.25rem]': !isAssistantPlayground,
            'opacity-50 pointer-events-none': !isAssistantDetailEdited,
          }"
          @click="handleSave"
        >
          Save
        </button>
      </div>
    </div>
  </div>
  <Modal
    :isVisible="isModalDelete"
    @close="closeDeleteModal"
    :largeModel="false"
  >
    <template v-slot:body>
      <section class="flex items-center justify-start">
        <div
          class="flex flex-col items-center w-[371px] self-stretch mx-auto my-0 p-8 rounded-[40px] bg-[#031525] border-white/10"
        >
          <header class="flex flex-col justify-start w-full">
            <img
              loading="lazy"
              src="../../assets/delete-orangebg.svg"
              alt=""
              class="w-[32px] aspect-square rounded-full object-contain object-center"
            />
            <div class="flex flex-col justify-start w-full mt-5">
              <h2 class="w-full text-[20px] font-bold text-white m-0">
                Delete assistant
              </h2>
              <p
                class="flex-1 w-full mt-2 text-[12px] font-medium text-[#cbd6e3]"
              >
                Are you sure you want to Delete this assistant?
              </p>
            </div>
          </header>
          <footer
            class="flex items-center justify-between w-full mt-5 gap-2.5 text-[17px] font-medium whitespace-nowrap text-center"
          >
            <button class="btn text-white" @click="closeDeleteModal">
              Cancel
            </button>
            <button class="btn btn-danger" @click="deleteAssistant">
              <span>Delete</span>
            </button>
          </footer>
        </div>
      </section>
    </template>
  </Modal>
  <Modal :isVisible="isModalSave" @close="closeSaveModal" :largeModel="false">
    <template v-slot:body>
      <section class="flex items-center justify-start">
        <div
          class="flex flex-col items-center w-[371px] self-stretch mx-auto my-0 p-8 rounded-[40px] bg-[#031525] border-white/10"
        >
          <header class="flex flex-col justify-start w-full">
            <img
              loading="lazy"
              src="../../assets/save-geen.svg"
              alt=""
              class="w-[32px] aspect-square rounded-full object-contain object-center"
            />
            <div class="flex flex-col justify-start w-full mt-5">
              <h2 class="w-full text-[20px] font-bold text-white m-0">
                Assistant saved
              </h2>
              <p
                class="flex-1 w-full mt-2 text-[12px] font-medium text-[#cbd6e3]"
              >
                Your assistant has been successfully saved.
              </p>
            </div>
          </header>
          <footer
            class="flex items-center justify-end w-full mt-5 gap-2.5 text-[17px] font-medium whitespace-nowrap text-center"
          >
            <button
              class="flex-1 w-full min-h-[40px] rounded-md overflow-hidden cursor-pointer text-[17px] font-medium text-[#7ba8ef] bg-transparent border !border-[#7ba8ef] text-center"
              @click="closeSaveModal"
            >
              Close
            </button>
          </footer>
        </div>
      </section>
    </template>
  </Modal>

  <Modal
    :isVisible="isModalDeleteSuccess"
    @close="closeDeleteModalSuccess()"
    :largeModel="false"
  >
    <template v-slot:body>
      <section class="flex items-center justify-start">
        <div
          class="flex flex-col items-center w-[371px] self-stretch mx-auto my-0 p-8 rounded-[40px] bg-[#031525] border-white/10"
        >
          <header class="flex flex-col justify-start w-full">
            <img
              loading="lazy"
              src="../../assets/save-geen.svg"
              alt=""
              class="w-[32px] aspect-square rounded-full object-contain object-center"
            />
            <div class="flex flex-col justify-start w-full mt-5">
              <h2 class="w-full text-[20px] font-bold text-white m-0">
                Assistant deleted
              </h2>
              <p
                class="flex-1 w-full mt-2 text-[12px] font-medium text-[#cbd6e3]"
              >
                Your assistant has been successfully deleted.
              </p>
            </div>
          </header>
          <footer
            class="flex items-center justify-end w-full mt-5 gap-2.5 text-[17px] font-medium whitespace-nowrap text-center"
          >
            <button
              class="flex-1 w-full min-h-[40px] rounded-md overflow-hidden cursor-pointer text-[17px] font-medium text-[#7ba8ef] bg-transparent border !border-[#7ba8ef] text-center"
              @click="closeDeleteModalSuccess"
            >
              Close
            </button>
          </footer>
        </div>
      </section>
    </template>
  </Modal>
  <Modal :isVisible="isModalVisible" @close="closeModal" :largeModel="true">
    <template v-slot:header>
      <div class="w-full bg-background rounded-2xl">
        <div class="flex justify-between items-center">
          <h3 class="text-[1.813rem] font-semibold text-white">
            Create a new Preset Prompt
          </h3>
          <span @click="closeModal" class="cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M8 24L24 8M8 8L24 24"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="flex flex-col relative mb-5">
        <div class="absolute top-[2.125rem] left-[0.875rem]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M10 6.66667V10M10 13.3333H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="w-[18.75rem] mt-6 mb-3">
          <selectbox
            :data="langArray"
            :selectedModelId="selectedPromptId"
            @onSelect="onPromptDrodpwnSelect"
          />
        </div>
        <div class="relative mb-4">
          <label class="mb-2 text-xl font-bold"
            >Title<span class="important">*</span></label
          >
          <input
            type="text"
            placeholder="What is the preset prompt about."
            v-model="newPresetPrompt.name"
            class="font-sans text-base font-normal w-full h-10 rounded-md bg-[var(--color-background-sidebar)] border border-[#333741] p-3 focus-visible:outline-none"
          />
          <span
            class="absolute top-10 right-3 group"
            data-tooltip-placement="top"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_21821_8660)">
                <path
                  d="M6.0587 6.0026C6.21543 5.55705 6.5248 5.18134 6.932 4.94203C7.3392 4.70271 7.81796 4.61523 8.28348 4.69508C8.749 4.77493 9.17124 5.01695 9.47542 5.37829C9.77959 5.73963 9.94607 6.19695 9.94536 6.66927C9.94536 8.0026 7.94536 8.66927 7.94536 8.66927M7.9987 11.3359H8.00536M14.6654 8.0026C14.6654 11.6845 11.6806 14.6693 7.9987 14.6693C4.3168 14.6693 1.33203 11.6845 1.33203 8.0026C1.33203 4.32071 4.3168 1.33594 7.9987 1.33594C11.6806 1.33594 14.6654 4.32071 14.6654 8.0026Z"
                  stroke="#85888E"
                  stroke-width="1.33333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_21821_8660">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <Tooltip :title="presetPromptNameTooltipText" content="" />
          </span>
        </div>
        <div class="relative">
          <label class="mt-3 mb-2 text-xl font-bold">Description</label>
          <textarea
            type="text"
            placeholder="Description of the preset prompt."
            v-model="newPresetPrompt.content"
            class="font-sans text-base font-normal w-full max-h-20 rounded-md bg-[var(--color-background-sidebar)] border border-[#333741] p-3.5 focus-visible:outline-none"
          />
          <span
            class="absolute top-10 right-3 group"
            data-tooltip-placement="top"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_21821_8660)">
                <path
                  d="M6.0587 6.0026C6.21543 5.55705 6.5248 5.18134 6.932 4.94203C7.3392 4.70271 7.81796 4.61523 8.28348 4.69508C8.749 4.77493 9.17124 5.01695 9.47542 5.37829C9.77959 5.73963 9.94607 6.19695 9.94536 6.66927C9.94536 8.0026 7.94536 8.66927 7.94536 8.66927M7.9987 11.3359H8.00536M14.6654 8.0026C14.6654 11.6845 11.6806 14.6693 7.9987 14.6693C4.3168 14.6693 1.33203 11.6845 1.33203 8.0026C1.33203 4.32071 4.3168 1.33594 7.9987 1.33594C11.6806 1.33594 14.6654 4.32071 14.6654 8.0026Z"
                  stroke="#85888E"
                  stroke-width="1.33333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_21821_8660">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <Tooltip title="presetPromptContentTooltipText" content="" />
          </span>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="flex gap-4 justify-end">
        <button
          type="button"
          class="btn btn-outline w-[83px]"
          @click="closeModal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="w-[100px] h-[40px] rounded-md text-[var(--color-background-sidebar)] border bg-[var(--color-button-blue)] border-[var(--color-button-blue)] text-center"
          :class="{
            'cursor-default opacity-50': !newPresetPrompt.name.trim(),
            'cursor-pointer': newPresetPrompt.name.trim(),
          }"
          @click="addPresetPrompt"
          :disabled="!newPresetPrompt.name.trim()"
        >
          Save
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import Multiselect from "@vueform/multiselect";
import selectbox from "../common/CustomSelect.vue";
import Modal from "../common/CustomModelBox.vue";
import range from "../common/Range-slider.vue";
import Tooltip from "../common/ToolTip.vue";
import customLabel from "../common/labelCustom.vue";
import {
  nextTick,
  onMounted,
  ref,
  watch,
  computed,
  reactive,
  onBeforeUnmount,
} from "vue";
import type { PropType } from "vue";
import type { IAssistantDetail } from "@/Models/IAssistantDetail";
import type {
  IUpdateAssistantRequest,
  PresetPromptRequest,
} from "@/Models/IAssistantRequest";
import type { IDropdownOption } from "@/Models/Common/IDropdownOption";
import type { ITool } from "@/Models/ITool";
import type { IModel } from "@/Models/IModel";
import type { IVectorStore } from "../../Models/IVectoreStore";
import type { IOutputMode } from "../../Models/IOutputMode";
import type { IMultiSelectConfig } from "@/Models/Common/IMultiSelectConfig";
import type { ISelectOption } from "@/Models/Common/ISelectOption";
import { HubService } from "@/Services/HubService";
import _ from "lodash";

import { defineProps, defineEmits } from "vue";

const props = defineProps({
  selectedItemData: {
    type: Object as PropType<IAssistantDetail>,
    required: true,
  },
  isAssistantPlayground: {
    type: Boolean,
    required: false,
    default: false,
  },
  toolOptions: {
    type: Array<ITool>,
    required: true,
  },
  modelOptions: {
    type: Array<IModel>,
    required: true,
  },
  vectorStoreOptions: {
    type: Array<IVectorStore>,
    required: true,
  },
  outputModeOptions: {
    type: Array<IOutputMode>,
    required: true,
  },
  isCreation: {
    type: Boolean,
    required: false,
  },
  isDefaultAssistant: {
    type: Boolean,
    required: false,
  },
});
const hubService = new HubService();
const assistantsDetailsToUpdate = ref<IUpdateAssistantRequest>(
  {} as IUpdateAssistantRequest
);
const assistantDetail = ref<IUpdateAssistantRequest>(
  {} as IUpdateAssistantRequest
);

const selectedModelId = computed<number>(
  () => assistantsDetailsToUpdate.value.modelId || 0
);
const selectedOutputModeId = computed<number>(
  () =>
    listOfAllOutputModes.value.filter(
      (o) => o.label === assistantsDetailsToUpdate.value.outputMode
    )[0]?.value | 0
);

const newPresetPrompt = ref<PresetPromptRequest>({
  id: 0,
  name: "",
  content: "",
  logoImage: "",
});

const selectedPromptId = computed<number>(
  () => assistantsDetailsToUpdate.value.modelId || 0
);

const onPromptDrodpwnSelect = (modelId: number) => {
  assistantsDetailsToUpdate.value.modelId = modelId;
};

const listOfAllTool = computed<Array<IDropdownOption>>(() =>
  props.toolOptions.map((t) => ({
    value: t.id,
    label: t.name,
  }))
);

const listOfAllModel = computed<Array<ISelectOption>>(() =>
  props.modelOptions.map((m) => ({
    value: m.id,
    label: m.title,
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3e/LetterA.svg/2000px-LetterA.svg.png",
  }))
);

const listOfAllVectorStore = computed<Array<IDropdownOption>>(() =>
  props.vectorStoreOptions.map((v) => ({
    value: v.storeId,
    label: v.storeName,
  }))
);

const isAssistantUpdated = computed(() => {
  return (
    !_.isEqual(
      assistantDetail.value.name,
      assistantsDetailsToUpdate.value.name
    ) ||
    !_.isEqual(
      assistantDetail.value.instructions,
      assistantsDetailsToUpdate.value.instructions
    ) ||
    !_.isEqual(
      assistantDetail.value.additionalInstructions,
      assistantsDetailsToUpdate.value.additionalInstructions
    ) ||
    !_.isEqual(
      assistantDetail.value.modelId,
      assistantsDetailsToUpdate.value.modelId
    ) ||
    !_.isEqual(
      assistantDetail.value.outputMode,
      assistantsDetailsToUpdate.value.outputMode
    ) ||
    !_.isEqual(
      assistantDetail.value.presencePenalty,
      assistantsDetailsToUpdate.value.presencePenalty
    ) ||
    !_.isEqual(
      assistantDetail.value.logitBias,
      assistantsDetailsToUpdate.value.logitBias
    ) ||
    !_.isEqual(
      assistantDetail.value.frequencyPenalty,
      assistantsDetailsToUpdate.value.frequencyPenalty
    ) ||
    !_.isEqual(
      assistantDetail.value.temperature,
      assistantsDetailsToUpdate.value.temperature
    ) ||
    !_.isEqual(
      assistantDetail.value.topLogProbs,
      assistantsDetailsToUpdate.value.topLogProbs
    ) ||
    !_.isEqual(
      assistantDetail.value.topP,
      assistantsDetailsToUpdate.value.topP
    ) ||
    !_.isEqual(
      assistantDetail.value.isLogProbs,
      assistantsDetailsToUpdate.value.isLogProbs
    ) ||
    !_.isEqual(
      assistantDetail.value.isStreaming,
      assistantsDetailsToUpdate.value.isStreaming
    ) ||
    !_.isEqual(
      assistantDetail.value.toolIds,
      assistantsDetailsToUpdate.value.toolIds
    ) ||
    !_.isEqual(
      assistantDetail.value.vectorStoreIds,
      assistantsDetailsToUpdate.value.vectorStoreIds
    )
  );
});

const isToolUpdated = computed(() => {
  return !_.isEqual(
    assistantDetail.value.toolIds,
    assistantsDetailsToUpdate.value.toolIds
  );
});

const isVectorStoreUpdated = computed(() => {
  return !_.isEqual(
    assistantDetail.value.vectorStoreIds,
    assistantsDetailsToUpdate.value.vectorStoreIds
  );
});

const isPresetPromptUpdated = computed(() => {
  return !_.isEqual(
    assistantDetail.value.presetPrompts,
    assistantsDetailsToUpdate.value.presetPrompts
  );
});

const listOfAllOutputModes = computed<Array<ISelectOption>>(() =>
  props.outputModeOptions.map((o) => ({
    value: o.id,
    label: o.mode,
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/NYCS-bull-trans-D.svg/2000px-NYCS-bull-trans-D.svg.png",
  }))
);

const isAssistantDetailEdited = computed<boolean>(() => {
  if (!props.selectedItemData) return false;
  return !_.isEqual(assistantDetail.value, assistantsDetailsToUpdate.value);
});

const selectedToolIds = ref<number[]>([]);
const selectedModelIds = ref<number[]>([]);

const emit = defineEmits([
  "openPlayground",
  "newAssistantCreated",
  "assistantUpdated",
  "deleteAssistant",
]);
const isModalVisible = ref(false); // State for modal visibility
const isModalDelete = ref(false);
const isModalDeleteSuccess = ref(false);
const isModalSave = ref(false);
let slideOne = ref<number>(20);
let slideTwo = ref<number>(20);
let slideThree = ref<number>(20);
let slideFour = ref<number>(20);
let slideFive = ref<number>(20);
let slideSix = ref<number>(20);

const assistantNameTooltipText = ref<string>(`Name of the assistant`);
const defaultInstructionsTooltipText = ref<string>(
  `Provide Instruction on how you want your assistant to act or answer and specific preferences you'd like.`
);
const additionalInstructionsTooltipText = ref<string>(
  `Provide Instruction on how you want your assistant to act or answer and specific preferences you'd like.`
);
const toolSelectionTooltipText = ref<string>(`You can add additional tools.`);
const temperatureTooltipText = ref<string>(
  "Controls randomness: Lowering results in less random completions. As the temperature approaches zero, the model will become deterministic and repetitive."
);
const topPTooltipText = ref<string>(
  "Controls diversity via nucleus sampling: 0.5 means half of all likelihood-weighted options are considered."
);
const vectorStoreTooltipText = ref<string>(
  "Chose from what Databases your assistant should be able to gather knowledge from."
);
const modelTooltipText = ref<string>(
  "Chose which model should be selected by default to process your data, they all have different strengths and weaknesses."
);
const outputModeTooltipText = ref<string>(
  "Chose which output mode should be selected by default."
);
const presetPromptTooltipText = ref<string>(
  "The prompts you define here will appear as boxes that you will be able to sent to Freddy with one click."
);
const presencePenaltyTooltipText = ref<string>(
  `Number between -2.0 and 2.0. Positive values penalize new Synapses based on whether they appear in the text so far, increasing the model's likelihood to talk about new topics.`
);
const logitBiasTooltipText = ref<string>(
  `Modify the likelihood of specified Synapses appearing in the completion.\n\n Accepts a JSON object that maps tokens (specified by their token ID in the tokenizer) to an associated bias value from -100 to 100. Mathematically, the bias is added to the logits generated by the model prior to sampling. The exact effect will vary per model, but values between -1 and 1 should decrease or increase likelihood of selection; values like -100 or 100 should result in a ban or exclusive selection of the relevant Synaps.`
);
const formatLogitBiasTooltipText = logitBiasTooltipText.value.split("\n");
const frequencyPenaltyTooltipText = ref<string>(
  `Number between -2.0 and 2.0. Positive values penalize new Synapses based on their existing frequency in the text so far, decreasing the model's likelihood to repeat the same line verbatim.`
);
const topLogProbsTooltipText = ref<string>(
  `An integer between 0 and 20 specifying the number of most likely Synapses to return at each Synaps position, each with an associated log probability.logprobs must be set to true if this parameter is used.`
);
const logProbsTooltipText = ref<string>(
  `An integer between 0 and 20 specifying the number of most likely Synapses to return at each Synaps position, each with an associated log probability.logprobs must be set to true if this parameter is used.`
);
const streamingTooltipText = ref<string>("You can choose your streaming.");
const presetPromptNameTooltipText = ref<string>(
  "Provide your preset prompt name."
);
const presetPromptContentTooltipText = ref<string>(
  "Provide your preset prompt content."
);

const multiselectRef = ref<HTMLElement | null>(null); // Reference for the Multiselect component

// Example select box configuration with explicit typing
const multiSelectConfig = ref<IMultiSelectConfig>({
  mode: "tags", // Use a fixed string value
  value: [1], // Use a plain array
  closeOnSelect: false,
  searchable: true,
  createOption: true,
} as IMultiSelectConfig);

const langArray = [
  {
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3e/LetterA.svg/2000px-LetterA.svg.png",
    label: "avvv",
    value: 1,
  },
  {
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/NYCS-bull-trans-B.svg/480px-NYCS-bull-trans-B.svg.png",
    label: "English (AU)",
    value: 2,
  },
  {
    img: "https://glot.io/static/img/c.svg?etag=ZaoLBh_p",
    label: "Chinese (Simplified)",
    value: 3,
  },
  {
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/NYCS-bull-trans-D.svg/2000px-NYCS-bull-trans-D.svg.png",
    label: "German",
    value: 4,
  },
  {
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/MO-supp-E.svg/600px-MO-supp-E.svg.png",
    label: "Danish",
    value: 5,
  },
  {
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/F_icon.svg/267px-F_icon.svg.png",
    label: "French",
    value: 6,
  },
  {
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/2000px-Google_%22G%22_Logo.svg.png",
    label: "Greek",
    value: 7,
  },
  {
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/4H_Emblem.svg/1000px-4H_Emblem.svg.png",
    label: "Italian",
    value: 8,
  },
];

const onModelSelect = (modelId: number) => {
  assistantsDetailsToUpdate.value.modelId = modelId;
};

const onOutputModeSelect = (modeId: number) => {
  assistantsDetailsToUpdate.value.outputMode =
    listOfAllOutputModes.value.filter((o) => o.value === modeId)[0].label;
};
const deleteAssistant = () => {
  emit("deleteAssistant");
  closeDeleteModal();
  openDeleteModalSuccess();
};

const openModal = () => {
  console.log("add modal");

  clearPresetPrompt();
  isModalVisible.value = true;
};

const clearPresetPrompt = () => {
  newPresetPrompt.value.id = 0;
  newPresetPrompt.value.name = "";
  newPresetPrompt.value.content = "";
  newPresetPrompt.value.logoImage = "";
};

const closeModal = () => {
  console.log(assistantsDetailsToUpdate.value.presetPrompts);
  isModalVisible.value = false;
};
const handleSave = async () => {
  if (!isAssistantDetailEdited.value) return;

  if (props.isCreation) {
    let reponse = await hubService.CreateNewAssistant(
      assistantsDetailsToUpdate.value
    );
    emit("newAssistantCreated");
  } else {
    assistantsDetailsToUpdate.value.isAssistantUpdated =
      isAssistantUpdated.value;
    assistantsDetailsToUpdate.value.isPresetPromptUpdated =
      isPresetPromptUpdated.value;
    assistantsDetailsToUpdate.value.isToolUpdated = isToolUpdated.value;
    assistantsDetailsToUpdate.value.isVectorStoreUpdated =
      isVectorStoreUpdated.value;

    let reponse = await hubService.UpdateAssistant(
      props.selectedItemData.id,
      assistantsDetailsToUpdate.value
    );
    emit("assistantUpdated");
  }
  openSaveModal();
};

const handleUpdateCancel = () => {
  assistantsDetailsToUpdate.value = _.cloneDeep(assistantDetail.value);
};

const addPresetPrompt = () => {
  if (newPresetPrompt.value.id !== 0) {
    let newPrompt = _.cloneDeep(newPresetPrompt.value);
    let index = assistantsDetailsToUpdate.value.presetPrompts.findIndex(
      (p) => p.id === newPrompt.id
    );
    assistantsDetailsToUpdate.value.presetPrompts.splice(index, 1, newPrompt);
  } else {
    assistantsDetailsToUpdate.value.presetPrompts.push({
      id: 0,
      content: newPresetPrompt.value.content,
      logoImage: newPresetPrompt.value.logoImage,
      name: newPresetPrompt.value.name,
    });
  }
  closeModal();
};

const updatePresetPrompt = (presetPromptId: number) => {
  if (props.isDefaultAssistant) return;

  openModal();
  newPresetPrompt.value = _.cloneDeep(
    assistantsDetailsToUpdate.value.presetPrompts.filter(
      (p) => p.id === presetPromptId
    )[0]
  );
};

const openDeleteModalSuccess = () => {
  isModalDeleteSuccess.value = true;
};

const closeDeleteModalSuccess = () => {
  isModalDeleteSuccess.value = false;
};

const openDeleteModal = () => {
  isModalDelete.value = true;
};
const closeDeleteModal = () => {
  isModalDelete.value = false;
};

const openSaveModal = () => {
  isModalSave.value = true;
};

const closeSaveModal = () => {
  isModalSave.value = false;
};

const openAssistantPlayground = () => {
  emit("openPlayground");
};
const selectboxVisible = ref(false);
const selectboxContainer = ref<HTMLElement | null>(null);
const handleClickOutside = (event: MouseEvent) => {
  const target = event.target as HTMLElement;
  if (selectboxContainer.value && !selectboxContainer.value.contains(target)) {
    selectboxVisible.value = false;
  }
};

onMounted(() => {
  mapAssistantDetail(props.selectedItemData);
  document.addEventListener("click", handleClickOutside);
  nextTick(() => {
    slideSix.value = props.selectedItemData?.temperature;
    slideTwo.value = props.selectedItemData?.logitBias;
    slideOne.value = props.selectedItemData?.frequencyPenalty;
    slideThree.value = props.selectedItemData?.presencePenalty;
    // Use the reference to access the multiselect input
    if (multiselectRef.value instanceof HTMLElement) {
      const multiselectInput = multiselectRef.value.querySelector(
        ".multiselect-tags-search"
      ) as HTMLElement;
      if (multiselectInput) {
        multiselectInput.style.display = "none";
        multiselectInput.style.background = "transparent";
      }
    }

    // const multiselectCaret = document.querySelector('.multiselect-caret') as HTMLElement;
    // if (multiselectCaret) {
    //   multiselectCaret.style.padding = '20px';
    // }
  });
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});

const mapAssistantDetail = (newData: IAssistantDetail) => {
  if (!newData) return;
  assistantDetail.value = {
    name: newData.name,
    additionalInstructions: newData.additionalInstructions,
    frequencyPenalty: newData.frequencyPenalty,
    instructions: newData.instructions,
    isAssistantUpdated: false,
    isLogProbs: false,
    isPresetPromptUpdated: false,
    isStreaming: false,
    isToolUpdated: false,
    isVectorStoreUpdated: false,
    logitBias: newData.logitBias,
    modelId: newData.modelId,
    outputMode: newData.outputMode ? newData.outputMode : "text",
    presencePenalty: newData.presencePenalty,
    presetPrompts: newData.presetPrompts?.map((p) => ({
      id: p.presetPromptId,
      content: p.presetPromptContent,
      logoImage: p.presetPromptLogoImage,
      name: p.presetPromptName,
    })),
    temperature: newData.temperature,
    toolIds: newData.tools?.map((t) => t.id),
    topLogProbs: newData.topP, //---> assisgn topLogProbs when available.
    topP: newData.topP,
    vectorStoreIds: newData.vectorStores?.map((v) => v.storeId),
  } as IUpdateAssistantRequest;

  assistantsDetailsToUpdate.value = _.cloneDeep(assistantDetail.value);
};

watch(
  () => props.selectedItemData,
  (newData) => {
    mapAssistantDetail(newData);
  }
);
</script>

<style>
@import "@vueform/multiselect/themes/default.css";

.example .multiselect {
  width: 40% !important;
}

.multiselect-caret {
  mask-image: url("../../assets/down-arrow.svg") !important;
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.multiselect-caret.is-open {
  transform: none;
}

.multiselect-dropdown {
  overflow-y: auto;
}

.important {
  color: #e71d41;
}

.multiselect.is-disabled {
  background: var(--color-background-sidebar);
  cursor: default;
}

.multiselect-tag.is-disabled {
  background: var(--ms-tag-bg, #10b981);
  color: var(--ms-tag-color, #fff);
  padding-right: var(--ms-tag-px, 0.5rem);
}
</style>
