<template>
    <div class="flex flex-col h-full pt-5">
        <section class="flex flex-col font-medium rounded-t-2xl border border-solid bg-slate-900 border-zinc-700 border-opacity-40">
            <header class="flex gap-4 items-start p-4 w-full max-md:px-5 max-md:max-w-full">
                <div class="flex flex-col flex-1 shrink justify-center w-full basis-0 min-h-[40px] min-w-[240px] max-md:max-w-full">
                    <div class="flex flex-wrap gap-10 justify-between items-center w-full max-md:max-w-full">
                        <h1 class="gap-2 self-stretch my-auto text-lg text-white">Change Log</h1>
                        <form class="flex overflow-hidden flex-col self-stretch my-auto max-w-screen-sm text-sm rounded-lg shadow-xl min-h-[40px] min-w-[240px] text-white text-opacity-40 w-[566px] max-md:max-w-full"
                            @submit.prevent>
                            <div class="flex flex-col max-w-full min-h-[40px] w-[566px]">
                                <div class="flex flex-col w-full max-md:max-w-full">
                                    <div class="flex overflow-hidden gap-2 items-center px-3.5 w-full rounded-lg border border-solid bg-slate-900 border-zinc-700 border-opacity-40 min-h-[40px] max-md:max-w-full">
                                        <div class="flex flex-1 shrink gap-2 items-center self-stretch my-auto w-full basis-0 min-w-[240px] max-md:max-w-full">
                                            <div class="flex flex-wrap flex-1 shrink gap-2 items-center self-stretch my-auto w-full basis-0 min-w-[240px] max-md:max-w-full">
                                                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/86db1d54c8ef985c77d675a00e7df42b5745ea2a6c1185c4c52f1eff8b78a400?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51" class="object-contain shrink-0 self-stretch my-auto w-5 aspect-square" alt="" />
                                                <label for="searchInput" class="sr-only">Search for logs</label>
                                                <input id="searchInput" 
                                                       type="search" 
                                                       placeholder="Search for logs by User's Id, Name, User name, Email." 
                                                       v-model="searchText"
                                                       @input="handleSearch"
                                                       autocomplete="off"
                                                       class="flex-1 shrink self-stretch my-auto basis-0 max-md:max-w-full bg-transparent border-none text-white text-opacity-40 focus:outline-none" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </header>
            <div class="flex w-full  border-zinc-700 bg-opacity-40 min-h-[1px] max-md:max-w-full"></div>
        </section>
        <main class="relative flex-1 bg-slate-900">
            <div class="max-h-[68vh] overflow-y-auto border border-white border-opacity-10">
                <ChangeLogTable :changeLogItems="changeLogItems" @onSort="applySort" @revokeLog="handleRevokeLog" />
            </div>
            <div v-if="isLoadingResults"
                 class="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-[#031525c4] backdrop-blur-[4px] border border-white border-opacity-10 z-[1000] overflow-auto scroll-width">
                <Spinner />
            </div>
        </main>
        <footer class="h-fit border-zinc-700 text-white border border-solid bg-slate-900 border-zinc-700 border-opacity-40 text-slate-300 rounded-b-2xl p-3">
            <Pagination :currentPage="currentPage" :itemsPerPage="itemsPerPage" :totalItems="totalItems" @update:current-page="handlePageChange" />
        </footer>
    </div>
</template>

<script setup lang="ts">
    import ChangeLogTable from './ChangeLogTable.vue'
    import Pagination from '../../common/Pagination.vue';
    import Spinner from '@/components/common/Spinner.vue';
    import { ref, onMounted, defineProps } from 'vue';
    import type { IChangeLog } from "@/Models/Users/IChangeLog";
    import type { ITableListRequest } from "@/Models/Users/Request/ITableListRequest";
    import { UserService } from '@/Services/UserService';
    import type { ISortOption } from "@/Models/ISortOption";

    const totalItems = ref<number>(0);
    const itemsPerPage = ref<number>(10);
    const currentPage = ref<number>(1);
    const offSetCount = ref<number>(0);
    const sortOption = ref<ISortOption>();
    const changeLogItems = ref<IChangeLog[]>();
    const searchText = ref<string>('');

    const isLoadingResults = ref<boolean>(false);

    const userService = new UserService();

    const props = defineProps({
        selectedUserId: {
            type: Number,
            default: 0
        }
    })

    const handlePageChange = async (page: number) => {
        currentPage.value = page;
        offSetCount.value = currentPage.value * itemsPerPage.value - itemsPerPage.value;

        await getUsersChangeLogs();
    }

    const getUsersChangeLogs = async () => {
        isLoadingResults.value = !isLoadingResults.value

        const changeLogUserRequest = {
            skip: offSetCount.value,
            take: 10,
            sortColumn: sortOption.value?.columnName,
            sortDirection: sortOption.value?.direction,
            searchTerm: searchText.value
        } as ITableListRequest

        const changeLogsResponse = props.selectedUserId === 0
            ? await userService.GetAllOrganizationChangeLog(changeLogUserRequest)
            : await userService.GetOrganizationUserChangeLog(props.selectedUserId as number, changeLogUserRequest)

        changeLogItems.value = changeLogsResponse.usersChangeLogs;
        totalItems.value = changeLogsResponse.usersChangeLogsCount;

        isLoadingResults.value = !isLoadingResults.value
    }

    const applySort = async (newSortOption: ISortOption) => {
        sortOption.value = newSortOption;
        await getUsersChangeLogs();
    }

    const handleRevokeLog = async (changeLogId: number) => {
        const result = await userService.RevokeChangeLogById(changeLogId);
        if (result) {
            await getUsersChangeLogs();
        }
    }

    const handleSearch = async () => {
        await getUsersChangeLogs();
    }

    onMounted(async () => {
        //await getUsersChangeLogs();
    })

</script>

<style scoped>
</style>
