<template>
  <div class="single-invoice-container xl:flex h-[100vh + 130px]">
    <!-- </span> -->
    <div
      class="detail-body detail-left md:w-full 2xl:w-[26.183rem] xl:w-4/12 md:p-6 pl-0 md:mr-10"
    >
      <div class="flex justify-between gap-[11.125rem] xl:gap-[14.5rem] lg:gap-[8.8rem] md:gap-[8.8rem] sm:gap-[8.8rem] xs:gap-0">
       
          <div class="mb-5 -ml-[1.5rem] xl:-ml-[1.5rem] md:-ml-[1.5rem] sm:ml-[0rem] xs:ml-[0rem]">
            <!-- <span class="icon"> -->
            <IconArrowLeft
              :width="30"
              :height="30"
              class="arrow-icon cursor-pointer"
              @click="closeInvoiceDetail()"
            />
          </div>
          <div class="detail-left-head actions flex justify-end mb-5 gap-[1.5rem] -ml-[1.125rem]">
            <div
              class="detail-left-head actions date date-container inline-flex items-center border border-[var(--grey-border-line)]
              justify-between relative w-[8.125rem] h-7"
            >
              <span class="shrink-0"
                ><IconChevronLeft
                  :width="20"
                  :height="21"
                  :class="{ disable: !isMonthDownAction }"
                  @click="downMonth"
              /></span>
              <span class="text-body2 month_view absolute left-1/2 -translate-x-2/4 text-center">{{
                monthList[currentMonth - 1]
              }}</span>
              <span class="shrink-0 ml-auto"
                ><IconChevronRight
                  :width="20"
                  :height="21"
                  :class="{ disable: !isMonthUpAction }"
                  @click="upMonth"
              /></span>
            </div>
          <div
            class="detail-left-head actions btn text-body3 download-button px-3 py-2 rounded-md bg-[var(--color-button-blue)] 
            font-normal !text-background cursor-pointer flex justify-center items-center w-[4.5rem]"
            @click="performDownload"
          >
            Export
          </div>
        </div>
      </div>
      <Loader
        v-if="isLoading"
        :isLoading="isLoading"
        style="width: auto; margin-left: auto; margin-right: auto; height: 100px;"
      />
      <div class="empty-result" v-if="!isLoading && !isInvoiceAvailable && invoiceId">
        <span>No Invoice generated</span>
      </div>
      <div
        v-else-if="!isLoading && isInvoiceAvailable"
        class="detail-left-body left-invoice-section md-ml-10 p-5 bg-[var(--color-background-sidebar)] rounded-3xl !ml-4 w-full 2xl:w-[41rem] xl:!w-[25.438rem] xl:!ml-10
        lg:w-full lg:m-auto lg:!ml-0 md:m-auto md:!ml-0 sm:m-auto sm:w-full sm:!ml-0 xs:m-auto xs:w-full xs:!ml-0"
        key="invoiceDetail"
      >
        <div class="flex justify-center mb-3"><IconInvoice /></div>
        <div class="heading6 invoice-num text-center mb-3 text-xl font-bold">
          Invoice {{ invoiceDetail?.invoiceNumber }}
        </div>
        <div class="detail-left-body invoice-items">
          <div
            class="detail-left-body invoice-item text-body xl:flex-row lg:flex-row md:flex md:flex-col md:justify-between md:items-center px-6 sm:flex sm:items-center sm:justify-between"
            id="invoice_status_due_date"
          >
            <div class="status paid_status flex items-center justify-center">
              <span class="status head text-body-bold !mb-[0.625rem]">Status:</span>
              <span class="status head text-body-bold notify-tag !mb-0">
                <component
                  :is="
                    invoiceDetail?.status !== 'Paid'
                      ? invoiceDetail?.status == 'Pending'
                        ? IconPurseCircleFill
                        : IconCrossCircleFill
                      : IconCheckCircleFill
                  "
                  :title="invoiceDetail?.status"
                ></component>
              </span>
            </div>
            <div class="status due_date justify-center flex md:block">
              <span class="status head text-body-bold due_date_head"
                >Due Date:&nbsp;</span
              >
              <span class="text-body2 invoice_date">{{
                invoiceDetail?.dueOn
              }}</span>
            </div>
          </div>
          <div
            v-for="(item, index) in invoiceDetail?.items"
            :key="index"
            class="detail-left-body invoice-item flex justify-between px-2 text-[0.625rem] py-2"
            id="invoice-left"
          >
            <div
              class="detail-left-body invoice-item name flex gap-3"
              id="ellipse_name"
            >
              <IconEllipse :width="10" :height="10" />
              <span class="text-body3" id="invoice_name">{{ item.name }}</span>
            </div>
            <div class="text-body3" id="invoice_name">{{ item.amount }}.-</div>
          </div>
          <div
            class="detail-left-body invoice-item flex justify-between px-2 text-[0.625rem] py-2"
            id="invoice-left"
          >
            <span class="text-body3 sub_total_detail" id="invoice_name"
              >Sub total:</span
            >
            <div class="text-body3" id="invoice_name">
              {{ invoiceDetail?.subTotal }}.-
            </div>
          </div>
          <div
            class="detail-left-body invoice-item flex justify-between px-2 text-[0.625rem] py-2"
            id="invoice-left"
          >
            <span class="text-body3 tax_detail" id="invoice_name"
              >Tax (8.10%):</span
            >
            <div class="text-body3" id="invoice_name">
              {{ invoiceDetail?.tax }}.-
            </div>
          </div>
          <div
            class="detail-left-body invoice-item total flex justify-between items-center bg-[var(--color-menu-selected)] rounded-2xl h-[2.063rem]
            p-2"
          >
            <span class="text-body-bold m-0" id="total_invoice_head"> Total:</span>
            <span class="text-body-bold m-0" id="total_invoice_value"
              >{{ invoiceDetail?.total }}.-</span
            >
          </div>
          <!--Don't remove this commented code. Enable when payment accepts cards.-->
          <!--<div class="detail-left-body invoice-item btn-grp">
            <button class="button"><IconApplePay /></button>
            <button class="button">
              <div>
                <span><IconCardPay /></span>
                <span>Card</span>
              </div>
            </button>
          </div>-->
        </div>
      </div>

      <!-- <div class="empty-result" v-if="!isLoading && !isInvoiceAvailable">
        <span>No Invoice generated</span>
      </div> -->
    </div>

    <!-- <div class="detail-body detail-right" ref="pdfContainer" v-if="isInvoiceAvailable" :key="invoiceDocUrl">
      
      <VuePdfEmbed :width="pdfContainerWidth - 50" :source="invoiceDocUrl" :key="invoiceDocUrl" >
      </VuePdfEmbed>
    </div> -->
    <div
      :class="{ ConditionalLoaderContainer: !isPdfLoaded }"
      class="detail-body detail-right overflow-y-auto ml-[6rem] xl:ml-[6rem] lg:m-auto lg:w-full md:m-auto md:w-full sm:m-auto sm:w-full
       xs:m-auto xs:w-full"
      ref="pdfContainer"
    >
      <div class="mt-[1.375rem]">
        <Spinner class="CustomLoader" v-if="!isPdfLoaded" />
        <VuePdfEmbed
          class="overflow-y-auto"
          v-show="isPdfLoaded && !isLoading"
          :width="pdfContainerWidth - 50"
          :source="invoiceDocUrl"
          @rendered="
            () => {
              isPdfLoaded = true;
            }
          "
        >
        </VuePdfEmbed>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref, nextTick, onUnmounted } from "vue";
import IconArrowLeft from "@/components/icons/IconArrowLeft.vue";
import IconCheckCircleFill from "@/components/icons/IconCheckCircleFill.vue";
import IconPurseCircleFill from "@/components/icons/IconPurseCircleFill.vue";
import IconCrossCircleFill from "@/components/icons/IconCrossCircleFill.vue";
import IconEllipse from "@/components/icons/IconEllipse.vue";
import IconChevronRight from "@/components/icons/IconChevronRight.vue";
import IconChevronLeft from "@/components/icons/IconChevronLeft.vue";
import IconInvoice from "@/components/icons/IconInvoice.vue";
import { eventBus } from "@/components/common/eventBus";
import Loader from "@/components/Loader.vue";
import VuePdfEmbed from "vue-pdf-embed";
import { type IInvoice } from "@/Models/IUsage";
import { HubService } from "@/Services/HubService";
import Spinner from "@/components/common/Spinner.vue";

const monthList = ref<string[]>([
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]);
const isPdfLoaded = ref(false);
const isLoading = ref<boolean>(true);
const isMonthUpAction = ref<boolean>(false);
const isMonthDownAction = ref<boolean>(true);

const invoiceDocUrl = ref<string>("");
const invoiceId = ref<number>(0);
//const orgId = ref<number>(1);
const tabWidth = window.innerWidth;
const statusName = ref<string>("");
const pdfContainer = ref<null | HTMLElement>(null);
const pdfContainerWidth = ref(0);
const hubService = new HubService();
const isInvoiceAvailable = ref(true);

const currentDate = new Date();

const props = defineProps<{
  monthOfInvoice: number;
  yearOfInvoice: number;
  invoice: IInvoice | null;
}>();

const currentMonth = ref(props.monthOfInvoice);
const currentYear = ref(props.yearOfInvoice);

const emit = defineEmits(["closeInvoiceDetail", "upMonth", "downMonth"]);

const invoiceDetail = ref<IInvoice>();

const closeInvoiceDetail = () => {
  emit("closeInvoiceDetail");
  eventBus.emit("showNavBar", undefined);
};

const getPdfContainerWidth = () => {
  pdfContainerWidth.value = Number(pdfContainer.value?.clientWidth);
};
// const disableFutureMonths = (month, year) => {
//   const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-indexed months
//   const currentYear = currentDate.getFullYear();
//   return year > currentYear || (year === currentYear && month > currentMonth);
// };
const upMonth = async () => {
  if (currentMonth.value == currentDate.getMonth() - 1) {
    isMonthUpAction.value = false;
  }
  isInvoiceAvailable.value = true;
  emit("upMonth");
  isLoading.value = true;

  try {
    await nextTick();

    currentMonth.value += 1;
    if (currentMonth.value > 12) {
      currentMonth.value = 1;
      currentYear.value += 1;
    }
    isPdfLoaded.value = false;
    invoiceDetail.value = await hubService.getInvoiceByMonthAndYear(
      currentMonth.value,
      props.yearOfInvoice,
      invoiceId.value
    );  
    if (invoiceDetail.value.id == 0 || Number(invoiceDetail.value.status) == 500) {
      isInvoiceAvailable.value = false;
    } else isInvoiceAvailable.value = true;
    invoiceDocUrl.value = invoiceDetail.value?.id
      ? `https://freddy.blob.core.windows.net/freddy-invoices/invoice_${invoiceDetail.value.id}.pdf`
      : "";
  } catch (error) {
    console.log(error);
    
    isInvoiceAvailable.value = false;
    isLoading.value = false;
    isPdfLoaded.value = true;
  } finally {
    isPdfLoaded.value = true;
    isLoading.value = false;
  }
};

const downMonth = async () => {
  //isLoading.value = true;
  isMonthUpAction.value = true;

  isInvoiceAvailable.value = false;
  //isMonthUpAction.value = false;
  emit("downMonth");
  isLoading.value = true;
  try {
    // isLoading.value = true;
    await nextTick();
    currentMonth.value -= 1;
    if (currentMonth.value < 1) {
      currentMonth.value = 12;
      currentYear.value -= 1;
    }
    isPdfLoaded.value = false;
    invoiceDetail.value = await hubService.getInvoiceByMonthAndYear(
      currentMonth.value,
      props.yearOfInvoice,
      invoiceId.value
    );
    //  invoiceDocUrl.value = invoiceDetail.value?.id
    //       ? `https://freddy.blob.core.windows.net/freddy-invoices/invoice_${invoiceDetail.value.id}.pdf`
    //       : "";
    if (invoiceDetail.value.id > 0) {
      isInvoiceAvailable.value = true;
      invoiceDocUrl.value = `https://freddy.blob.core.windows.net/freddy-invoices/invoice_${invoiceDetail.value.id}.pdf`;
    } else isInvoiceAvailable.value = false;
    invoiceDocUrl.value = `https://freddy.blob.core.windows.net/freddy-invoices/invoice_0.pdf`;
  } catch (error) {
    isInvoiceAvailable.value = false;
    isLoading.value = false;
    isPdfLoaded.value = true;
  } finally {
    isPdfLoaded.value = true;
    isLoading.value = false;
  }
};

const performDownload = () => {
  const link = document.createElement("a");
  link.href = invoiceDocUrl.value;
  link.target = "_blank"; // Open in a new tab
  link.rel = "noopener"; // Security measure to prevent access to window.opener
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const statusMap: { [key: string]: string } = {
  "1": "Pending",
  "2": "Paid",
  "3": "Failed",
};
const getStatusName = (statusId: string) => {
  return statusMap[statusId] || "Unknown Status";
};

const resizeObserver = new ResizeObserver(() => {
  getPdfContainerWidth();
});

onMounted(async () => {
  isLoading.value = true;

  invoiceId.value = Number(props.invoice?.id);

  invoiceDetail.value = await hubService.getInvoiceById(
    props.monthOfInvoice,
    props.yearOfInvoice,
    invoiceId.value
  );
  statusName.value = getStatusName(invoiceDetail.value.status);

  isLoading.value = false;
  invoiceDocUrl.value = `https://freddy.blob.core.windows.net/freddy-invoices/invoice_${invoiceId.value}.pdf`;
  isInvoiceAvailable.value = true;
  isPdfLoaded.value = true;

  if (pdfContainer.value) {
    resizeObserver.observe(pdfContainer.value);
  }

  // invoiceDocUrl.value =
  //   "https://freddy.blob.core.windows.net/freddy-invoices/invoice_1.pdf";
});

onUnmounted(() => {
  if (pdfContainer.value) {
    resizeObserver.unobserve(pdfContainer.value);
  }
});

//-> Common usefull functions can seperate in a general folder later
const toDateFormat = (dateString: any): string => {
  if (!dateString) return "Invalid Date";
  const date = new Date(dateString);
  const formatter = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const formattedDate = formatter.format(date).replace(",", "");
  return formattedDate;
};
</script>

<style lang="scss" scoped>
.detail-body.detail-right {
  overflow: hidden;
  border-radius: var(--radius-xl, 12px);
  max-width: 1000px;
}

.detail-body.detail-right .vue-pdf-embed {
  display: flex;
  justify-content: center;
  border-radius: var(--radius-xl, 12px);
  background: var(--Gray-0, #f9fafc);
  height: 90vh !important;
  overflow-y: scroll !important;
  overflow-x: hidden;
}

// .detail-body.detail-right .vue-pdf-embed canvas{
// max-width: 1260px !important;
// }
</style>

<!-- 
<style lang="scss" scoped>
@import "@/assets/mainstyle";

.CustomLoader {
  margin: auto;
}

.ConditionalLoaderContainer {
  min-height: 50vh;
  align-items: center;
  display: flex;
}

.single-invoice-container {
  display: flex;
  flex-direction: row;
  // gap: 48px;
  justify-content: left;
  margin-top: -52px;

  .arrow-icon {
    margin-left: -14px;
    margin-top: 10px;
  }

  .download-button {
    color: black;
  }
}

.detail-body {
  height: auto;
  padding: 10px;
  gap: 10px;
  border-radius: 20px;

  &.detail-left {
    display: flex;
    flex-direction: column;

    @media (min-width: 1064px) {
      min-width: 468px;
    }

    // width: 38%;
    // height: 100%;
    // max-width: 500px;
  }

  &.detail-right {
    // display: flex;
    // flex-grow: 1;
    // flex-direction: column;
    width: 58%;
    margin-left: 48px;
    // height: 100%;
    // align-items: center;
    // justify-content: flex-start;
    // overflow-y: auto;
    margin-top: 10px;

    @extend .scroll-width;

    .vue-pdf-embed {
      display: flex;
      justify-content: center;
      border-radius: var(--radius-xl, 12px);
      background: var(--Gray-0, #f9fafc);
      height: 90vh !important;
      overflow-y: scroll !important;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: transparent;
      }

      .vue-pdf-embed__page {
        width: 100% !important;

        canvas {
          height: 100% !important;
          width: 100% !important;
          border-radius: 10px !important;
        }

        div {
          width: 0 !important;
          height: 0 !important;
          display: none !important;
        }
      }
    }
  }
}

#invoice_status_due_date {
  // margin-right: -76px;
  padding: 0 26px;
}

#invoice_name {
  font-size: 10px;
  color: var(--White, #fff);
  font-family: inherit;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 18px;
}

.detail-left-head {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 4px;

  @media (max-width: 1064px) {
    flex-direction: column;
  }

  &.close {
    align-self: flex-start;
  }

  &.actions {
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 28px;
    //margin-left: 68px;

    &.date {
      border: 1px solid rgba(255, 255, 255, 0.09);
      border-radius: 3px;
      width: 128px;
      align-items: center;
      gap: 5px;
      justify-content: space-between;
      margin-right: 42px;
    }

    &.btn {
      align-items: center;
      justify-content: center;
      flex-direction: row;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .month_view {
    margin-left: -8px;
  }
}

.detail-left-body {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #081a2b;
  border-radius: 20px;
  gap: 15px;

  &.id {
  }

  &.invoice-items {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
  }

  &.invoice-item {
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    font-size: 12px;

    &.name {
      justify-content: flex-start;
      gap: 5px;
      max-width: 50%;
      align-items: center;

      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &.total {
      padding: 8px 27px 8px 32px;
      background-color: #13385a;
      border-radius: 10px;
      height: 35px;
    }

    &.btn-grp {
      padding: 10px 0px;
    }
  }
}

.invoice-num {
  margin-left: 26px;
}

.left-invoice-section {
  margin-top: 25px;
}

.status {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  // max-width: 50%;

  &.due_date {
    margin: 0;
  }

  .paid_status {
    margin-bottom: 6px;
  }

  &.head {
    color: white;

    &.due_date_head {
      margin-right: 8px;
    }
  }

  &.notify-tag {
    margin-bottom: -3px;
  }
}

.button {
  display: flex;
  width: 48%;
  padding: 5px;
  color: white;
  border: none;
  height: 40px;
  align-items: center;
  justify-content: space-around;
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Button, #7ba8ef);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  background-color: var(--Background-primary, #031525);
  margin-bottom: auto;
  @extend .pay-btn-highlight;

  &:hover {
    cursor: pointer;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
}

.empty-result {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  top: 171%;
  font-size: 22px;
}

.vue-pdf-embed {
  padding: inherit;
}

#total_invoice_head {
  margin-left: -8px;
}
#total_invoice_value {
  margin-right: -8px;
}

@media (max-width: 2650px) {
  .name {
    margin-left: 13px;
  }

  #invoice_name {
    margin-right: 10px;
  }

  .due_date {
    margin-right: 86px;
  }

  .sub_total_detail {
    margin-left: 13px;
  }
  .tax_detail {
    margin-left: 13px;
  }
  .date {
    margin-right: 57px;
  }

  #total_invoice_head {
    margin-left: -18px;
  }
  #total_invoice_value {
    margin-right: -18px;
  }
}

@media (max-width: 1024px) {
  .single-invoice-container {
    flex-direction: column;
    overflow-y: auto;
    align-items: center;
    overflow-x: hidden;
  }

  .arrow-icon {
    margin-right: auto;
    margin-top: 10px;
    margin-left: 0px;
  }

  .detail-left {
    margin: 0 auto;
    width: 0%;
  }

  .detail-left {
    width: 100%;
  }

  .detail-body.detail-right {
    margin-left: 0px;
    overflow-y: visible;
    width: 95%;
    margin-top: 10px;
  }

  .month_view {
    margin-left: -8px;
  }

  .due_date {
    margin-right: 32px;
    text-wrap: nowrap;
  }
  .actions {
    gap: 0px;
    margin-right: -159px;
  }

  .date {
    margin-right: 0px;
  }

  .detail-left-head {
    margin-top: -12px;
  }

  .btn {
    margin-left: 125px;
  }
}

@media (max-width: 768px) {
  .left-invoice-section {
    margin-left: 110px;
  }

  .detail-left-body {
    max-width: 480px;
  }

  span {
    overflow: visible;
  }

  .detail-left {
    // margin-left: 21%;
    height: auto;
    // margin-left: auto;
    // margin-right: auto;
  }

  .btn {
    margin-left: 16px;
    margin-right: -45px;
  }

  .date {
    margin-right: 66px;
  }
}

@media (max-width: 600px) {
  #invoice_status_due_date {
    flex-direction: column;
    // margin-left: 33px;
    text-wrap: nowrap;
    margin-right: 10px;
  }

  .btn {
    margin-left: -27px;
  }

  .name {
    margin-left: 12px;
  }

  .invoice-num {
    margin-left: 10px;
  }

  .heading6 {
    font-size: 16px;
  }

  .vue-pdf-embed {
    margin: 0 auto;
  }

  .detail-left-body {
    width: 100%;
    margin: 0 auto;
  }

  .total {
    margin-left: 0px;
  }

  #total_invoice_value {
    margin-right: -8px;
  }

  .status {
    gap: 10px;
  }
  .actions {
    margin-right: -42px;
  }
  #invoice_name {
    overflow: visible;
    margin-right: 19px;
  }
  #invoice-left {
    margin-left: 0px;
  }
  #ellipse_name {
    margin-left: 12px;
  }
}
</style> -->
