<template>
  <div class="ContentHeader pt-2.5">
    <!-- vector or Data buttons -->
    <div class="ContentHeader__Buttons">
      <button
        @click="handleVectorOrData(DatalistTypeEnum.data)"
        :class="{ highlight: vectorOrData === DatalistTypeEnum.data }"
        class="px-2.5 py-2 text-[14px] font-medium leading-4"
      >
        Data
      </button>
      <button
        :class="{ highlight: vectorOrData === DatalistTypeEnum.vectorStorage }"
        @click="handleVectorOrData(DatalistTypeEnum.vectorStorage)"
        class="px-2.5 py-2 text-[14px] font-medium leading-4"
      >
        Vector Stores
      </button>
    </div>

    <button class="btn btn-primary" @click="isFileModalOpen = true">
      Upload Files
    </button>
  </div>

  <div class="Input-Containers py-2">
    <!-- Search box -->
    <!-- <div class="connectors-container head actions action"> -->
    <div class="relative connectors-container head actions action search h-10">
      <IconLense />
      <input
        type="search"
        name="connectorSearch"
        placeholder="Search"
        v-model="search"
        @input="fetchResults"
        class="connectors-container head actions action search input "
      />
      <button
      v-if="search"
      @click="search = ''"
      class="absolute right-2 top-1/2 transform -translate-y-1/2 text-[20px] text-[#]  flex items-center justify-center"
    >
      ×
    </button>
    </div>
    <!-- </div> -->

    <!-- Sort buttons -->
    <div class="Input-Containers__SortButtons">
      <button
        @click="handleSortButtonSwitches(FileListSortButtonEnum.date)"
        class="usage-container head actions button cost"
        :class="{ highlight: sortButtonList === FileListSortButtonEnum.date }"
      >
        Date
      </button>
      <button
        class="usage-container head actions button item"
        :class="{ highlight: sortButtonList === FileListSortButtonEnum.size }"
        @click="handleSortButtonSwitches(FileListSortButtonEnum.size)"
      >
        Size
      </button>
      <button
        class="usage-container head actions button item"
        :class="{ highlight: sortButtonList === FileListSortButtonEnum.name }"
        @click="handleSortButtonSwitches(FileListSortButtonEnum.name)"
      >
        Name
      </button>
    </div>

    <!-- Order listing button -->
    <div class="Input-Containers__OrderBy h-8">
      <button
        @click="handleListOrdering(ListAscOrDescEnum.desc)"
        class="px-2.5 py-2 text-[14px] font-medium leading-3"
        :class="{ highlight: listOrderButton === ListAscOrDescEnum.desc }"
      >
        Desc
      </button>
      <button
        class="px-2.5 py-2 text-[14px] font-medium leading-3"
        :class="{ highlight: listOrderButton === ListAscOrDescEnum.asc }"
        @click="handleListOrdering(ListAscOrDescEnum.asc)"
      >
        Asc
      </button>
    </div>
  </div>

  <!-- Listing Container -->
  <div class="MainContent">
    <vectorAndFileListing
      :vectorOrFile="vectorOrData"
      :chosenFile="chosenFile"
      :chosenFileId="chosenFileId"
      :chosenVector="chosenVector"
      :filter="filterData"
      @handleFileSelection="handleFileSelection"
      @handleVectorSelection="handleVectorSelection"
      :fileUploadSuccess="isFileUploaded"
    />

    <!-- <aside>Graph</aside> -->
    <Spinner class="CustomLoader" v-if="isLoading" />
    <aside v-if="!isLoading && !chosenFileId && !chosenVector">
      <div class="RightSideContainer">
        <div>
          <div class="MainContent__ChartContainer">
            <CustomDoughnutChart
              class="chart"
              :chartData="chartData"
              :chosenMonth="1"
              :innerTextFont="'14px'"
              :showCenterText="true"
            />
            <span class="MainContent__ChartContainer__LimitText">
              {{ usageDbData?.totalUsage || "" }}
            </span>
          </div>

          <div class="MainContent__ChartContainer__StoreList">
            <ul>
              <li v-for="(item, index) in trimmedVectorDetails" :key="index">
                <span
                  :style="`background: ${item.color}`"
                  class="MainContent__ChartContainer__StoreList--IconDots"
                ></span>
                {{ item.vectorStoreName }} ({{
                  item.vectorStoreUsagePercentage
                }}
                %)
              </li>
            </ul>

            <div
              v-if="
                usageDbData?.vectorStoreUsage &&
                usageDbData.vectorStoreUsage.length > 4
              "
              class="MainContent__ChartContainer__StoreList--ShowMore"
            >
              <button class="items-center" @click="handleShowMore">
                <span
                  ><IconChevronDown v-if="!isShowMore" />
                  <IconChevronUp class="Icons" v-else
                /></span>
                {{ !isShowMore ? "Show more" : "Show less" }}
              </button>
            </div>
          </div>
        </div>
      </div>
      
      <!-- TO DO IN FUTURE IMPLEMENTATION , COMMENTING OUT FOR NOW -->
      <!-- Storage Efficiency -->
      <!-- <div class="RightSideContainer">
        <h2>Storage Efficiency</h2>

        <div class="RightSideContainer__Legend">
          <div class="RightSideContainer__Legend__Item">
            <span class="dot used-dot"></span>
            <span>Used</span>
          </div>
          <div class="RightSideContainer__Legend__Item">
            <span class="dot expired-dot"></span>
            <span>Expired</span>
          </div>
        </div>

        
        <div class="RightSideContainer__ProgressBar">
          <div
            class="used"
            :style="{ width: storageEfficiency.used + '%' }"
          ></div>
          <div
            class="expired"
            :style="{ width: storageEfficiency.expired + '%' }"
          ></div>
        </div>
      </div>

      
      <div class="RightSideContainer">
        <h2>Neurons usage</h2>
        <div class="RightSideContainer__NeuronDetails">
          <h2>CHF {{ neuronUsageAmount }}</h2>
          <button class="btn btn-primary">view details</button>
        </div>
      </div> -->
    </aside>

    <!-- File info -->
    <ChosenFileDetails
      v-if="chosenFileId"
      :chosenFileId="chosenFileId"
      @fileDeletion="handleDeletion"
    />
    <ChosenVectorDetails
      v-if="chosenVector"
      :chosenVector="chosenVector"
      @vectorCreation="handleVectorCreation"
      @vectorName="RenameStore"
      @vectorDeletion="handleDeletion"
    />

    <!-- <FileUploader /> -->
    <ModalOverlay
      headerClass=""
      v-if="isFileModalOpen"
      :modalTitle="'Upload files'"
      :outerWidth="'86vw'" 
      :innerWidth="'700px'"
      :left="'289px'" 
       @close="isFileModalOpen = false"
    >
      <FileUploader @cancel="isFileModalOpen = false" @fileUploaded="handleFileUploadSuccess" />
    </ModalOverlay>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import IconLense from "@/components/icons/IconLense.vue";
// import IconChevronRight from "@/components/icons/IconChevronRight.vue";
import Spinner from "@/components/common/Spinner.vue";
import ChosenFileDetails from "./ChosenFileDetails.vue";
import { interpolateColor } from "@/helpers";
import {
  DatalistTypeEnum,
  FileListSortButtonEnum,
  ListAscOrDescEnum,
  FilterEnum,
} from "@/enums";
// import { dataFiles, vectorStorageData } from "@/mockData";
import { KnowledgeService } from "@/Services/KnowledgeService";
import CustomDoughnutChart from "@/components/common/CustomDoughnutChart.vue";
import type { IChartData, IVectorStoreUsageModel } from "@/Models";
import { IconChevronUp, IconChevronDown } from "@/components/icons";
import vectorAndFileListing from "@/components/freddycomponents/VectorAndFileListing.vue";
import type {
  IFileDetails,
  IVector,
  IFilterDetails,
  IOraganizationFilesInfo,
} from "@/Models";
import FileUploader from "@/components/freddycomponents/FileUploader.vue";
import ChosenVectorDetails from "./ChosenVectorDetails.vue";
import ModalOverlay from "@/components/common/ModalOverlay.vue";
import { debounce } from "lodash";
import IconClose from "../icons/IconClose.vue";

const vectorOrData = ref<DatalistTypeEnum>(DatalistTypeEnum.data);
const sortButtonList = ref<FileListSortButtonEnum>(FileListSortButtonEnum.date);
const listOrderButton = ref<ListAscOrDescEnum>(ListAscOrDescEnum.desc);
const isFileModalOpen = ref(false);
// const items = ref(dataFiles);
// const vectorItems = ref(vectorStorageData);
const isLoading = ref(false);
const neuronUsageAmount = ref(5.5);
const isShowMore = ref(false);
const knowledgeService = new KnowledgeService();
const chosenFile = ref<IOraganizationFilesInfo | null>(null);
const chosenFileId = ref<number | null>(null);
const chosenVector = ref<IVector | null>(null);
const filterData = ref<IFilterDetails | null>(null);
const searchName = ref<string>("");
const search = ref<string>("");
const usageDbData = ref<IVectorStoreUsageModel>({
  totalUsage: "",
  vectorStoreUsage: [],
});

// const filterData = ref<IFilterDetails | null>(null);
// const searchName = ref<string>("");
// const search = ref<string>("");
const chartData = ref<IChartData>({
  labels: [],
  datasets: [
    {
      data: [],
      backgroundColor: [],
      hoverOffset: 0,
      borderWidth: 0,
    },
  ],
});
const isFileUploaded = ref(false);
// const dbData = {
//   totalLimit: "100GB",
//   vectorDetails: [
//     {
//       vectorName: "Vector store 1",
//       percentage: "10%",
//       vectorSize: 10,
//     },
//     {
//       vectorName: "Vector store 12",
//       percentage: "10%",
//       vectorSize: 15,
//     },
//     {
//       vectorName: "Vector store 4",
//       percentage: "15%",
//       vectorSize: 35,
//     },
//     {
//       vectorName: "Vector store 14",
//       percentage: "10%",
//       vectorSize: 16,
//     },
//     {
//       vectorName: "dsd",
//       percentage: "40%",
//       vectorSize: 12,
//     },
//   ],
// };

// Convert to percentage if needed.`

const getVectorStoreUsage = async () => {
  usageDbData.value = await knowledgeService.GetVectorStoreUsage();
};

const handleFileUploadSuccess = (value:any) => {
  isFileUploaded.value = value;
};

const storageEfficiency = {
  used: 40,
  expired: 60,
};

const handleVectorOrData = (buttonType: DatalistTypeEnum): void => {
  vectorOrData.value = buttonType;
  chosenFileId.value = null;
  chosenFile.value = null;
  chosenVector.value = null;
  setFilter();
};

const handleSortButtonSwitches = (buttonType: FileListSortButtonEnum) => {
  sortButtonList.value = buttonType;
  setFilter();
};

const handleListOrdering = (buttonType: ListAscOrDescEnum) => {
  listOrderButton.value = buttonType;
  setFilter();
};

const handleShowMore = () => {
  isShowMore.value = !isShowMore.value;
};

const trimmedVectorDetails = computed(() => {
  const usageData = usageDbData.value;
  if (!usageData || !usageData.vectorStoreUsage) {
    return [];
  }

  const sortedArr = [...usageData.vectorStoreUsage].sort(
    (a, b) => b.vectorStoreUsagePercentage - a.vectorStoreUsagePercentage
  );
  console.log("sortedArr", sortedArr);
  return isShowMore.value ? sortedArr : sortedArr.slice(0, 4);
});

const handleFileSelection = (file: IOraganizationFilesInfo): void => {
  // debugger;
  chosenVector.value = null;
  chosenFileId.value = file.fileId;
  chosenFile.value = file;
  isLoading.value = false;
};

const handleVectorSelection = (item: IVector): void => {
  chosenFileId.value = null;
  chosenVector.value = item;
  isLoading.value = false;
};

const handleDeletion = () => {
  vectorOrData.value == DatalistTypeEnum.data
    ? (chosenFileId.value = null)
    : (chosenVector.value = null);
  setFilter();
};

const handleVectorCreation = (newVector: IVector) => {
  sortButtonList.value = FileListSortButtonEnum.date;
  listOrderButton.value = ListAscOrDescEnum.desc;
  searchName.value = "";
  setFilter();
  handleVectorSelection(newVector);
};

const RenameStore = (newName: string) => {
  if (chosenVector.value) {
    chosenVector.value.vectorStoreName = newName;
  }
};

const setFilter = () => {
  filterData.value = {
    sort: sortButtonList.value,
    order: listOrderButton.value,
    search: searchName.value,
  };
};

const fetchResults = debounce(async () => {
  if (search.value.length > 2) {
    searchName.value = search.value;
    setFilter();
  }
  if (searchName.value.length>2 && search.value.length == 2) {
    searchName.value = "";
    setFilter();
  }
}, 300);

onMounted(async () => {
  isLoading.value = true;

  // Set the initial filter
  setFilter();

  // Fetch vector store usage data
  await getVectorStoreUsage();

  // Handle the data after a delay
  setTimeout(() => {
    if (!usageDbData.value || !usageDbData.value.vectorStoreUsage.length) {
      console.error("No vector store usage data available.");
      isLoading.value = false;
      return;
    }

    const totalSize = usageDbData.value.vectorStoreUsage.reduce(
      (sum, item) => sum + item.vectorStoreUsagePercentage,
      0
    );
    const limit = 100;
    const maxValue = Math.max(
      ...usageDbData.value.vectorStoreUsage.map(
        (i) => i.vectorStoreUsagePercentage
      )
    );
    console.log(maxValue, "Max val");

    const generateFactorsCentered = (length: number) => {
      const step = 1 / length; // Step size for each value
      return Array.from({ length }, (_, i) => (i + 1) * step);
    };

    generateFactorsCentered(usageDbData.value.vectorStoreUsage.length);

    for (const item of usageDbData.value.vectorStoreUsage.sort(
      (a, b) => b.vectorStoreUsagePercentage - a.vectorStoreUsagePercentage
    )) {
      const index = usageDbData.value.vectorStoreUsage.findIndex(
        (vs) => vs === item
      );
      chartData.value.datasets[0].data.push(item.vectorStoreUsagePercentage);
      chartData.value.datasets[0].backgroundColor.push(
        interpolateColor(
          "#346604",
          "#E3FBCC",
          item.vectorStoreUsagePercentage / maxValue
        )
      );

      console.log(
        item.vectorStoreUsagePercentage / maxValue,
        "Max valuee",
        totalSize
      );

      item["color"] = interpolateColor(
        "#346604",
        "#E3FBCC",
        item.vectorStoreUsagePercentage / maxValue
      );
    }

    // Add remaining space to reach the limit
    chartData.value.datasets[0].data.push(limit - totalSize);
    chartData.value.datasets[0].backgroundColor.push("#0c1e12");

    // Mark loading as complete
    isLoading.value = false;
  }, 50);
});
</script>

<style lang="scss">
@import "@/assets/mainstyle";

button {
  all: unset;
  padding: 4px 12px;
  cursor: pointer;
  color: $highlight-border;
}

.highlight {
  color: black;
}
.CustomLoader {
  margin: auto;
}
.Icons {
  width: 12px;
  height: 12px;
}

.ContentHeader {
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 1px solid #ffffff12;

  &__Buttons {
    display: flex;
    // gap: 4px;
    border: 1px solid #7ba8ef;
    // height: 27px;
    border-radius: 8px;
  }
}

.Input-Containers {
  display: flex;
  align-items: center;
  gap: 40px;
  width: 60%;

  input[type="search"] {
  padding-right: 40px; 
  color: white;
}

input[type="search"]::-webkit-search-cancel-button  {
  display: none; 
}

  &__SortButtons {
    @extend .ContentHeader__Buttons;
    height: 32px;

    button {
      padding: 0 12px;
    }
    input[type="search"]::-webkit-search-cancel-button {
  appearance: none !important; /* Disable the default appearance */
  height: 20px; /* Set the height */
  width: 20px;  /* Set the width */
  background-color: #ffffff; /* Change the color */
  border-radius: 50%; /* Make it circular */
  border: 1px solid #ffffff; /* Optional border */
  background-image: none; /* Disable default icon */
  display: flex;
  justify-content: center;
  align-items: center;
}
  }

  &__OrderBy {
    display: flex;
    border: 1px solid #9e77ed;
    border-radius: 8px;

    button {
      color: #9e77ed;

      &.highlight {
        background: #9e77ed;
        color: white;
      }
    }
  }
}

.selectedItem {
  background: #11385b;
  border-radius: 4px;
}

.MainContent {
  display: flex;
  gap: 16px;
  @extend .scroll-width;
  overflow: auto;

  &__ChartContainer {
    display: flex;
    align-items: center;
    gap: 20px;

    .chart {
      width: 100px;
      height: 100px;
    }

    &__LimitText {
      font-size: 24px;
      font-weight: 700;
    }

    &__StoreList {
      padding: 8px 0;
      ul {
        list-style-type: none; /* Removes bullet points */
        padding: 0; /* Removes default padding */
        margin: 0;
        padding: 4px 0;
        border-bottom: 1px solid #ffffff17;

        li {
          display: flex;
          align-items: center;
          gap: 6px;
          font-size: 12px;
          color: #cbd6e3;
          line-height: 14.5px;
          padding: 4px 0;
        }
      }
    }

    &__StoreList {
      &--IconDots {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: white;
      }

      &--ShowMore {
        text-align-last: center;
        place-items: center;

        button {
          color: white;
          display: flex;
          gap: 4px;
          padding-top: 10px;
        }
      }
    }
  }

  aside {
    @extend .scroll-width;
    width: 40%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .RightSideContainer {
      background: #071a2b;
      width: 100%;
      padding: 20px;
      border-radius: 40px;
      font-family: Inter, sans-serif;

      h2 {
        margin-bottom: 16px;
        font-size: 20px;
        font-weight: 700;
      }

      &__ProgressBar {
        display: flex;
        height: 20px;
        border-radius: 10px;
        background-color: #1e2d3b;
        overflow: hidden;
        max-width: 80%;
      }

      .used {
        background-color: #7ed957; /* Green for 'Used' */
        height: 100%;
      }

      .expired {
        background-color: #f7945a; /* Orange for 'Expired' */
        height: 100%;
      }

      &__Legend {
        display: flex;
        gap: 12px;
        margin-bottom: 8px;

        &__Item {
          display: flex;
          align-items: center;
          gap: 5px;
          font-size: 12px;
          font-weight: 500;

          .dot {
            height: 6px;
            width: 6px;
            border-radius: 50%;
          }

          .used-dot {
            background-color: #7ed957;
          }

          .expired-dot {
            background-color: #f7945a;
          }
        }
      }

      &__NeuronDetails {
        display: flex;
        gap: 12px;
        align-items: center;

        h2 {
          margin: 0;
          font-size: 24px;
          font-weight: 700;
        }

        // button {
        //   font-size: 14px;
        //   font-weight: 600;
        //   padding: 4px 8px;
        // }
      }
    }

    .FileActionContainer {
      button {
        padding: 8px;
        border-radius: 6px;
        display: flex;
        color: #e71d41;
        border: 1px solid #e71d41;
      }

      &__DeleteIcon {
        width: 12px;
      }
    }
  }
}

.connectors-container {
  height: 94%;
  width: 100%;
  display: flex;
  flex-direction: column;

  &.head {
    @media (max-width: 1024px) {
      height: auto;
    }

    &.text {
      gap: 16px;

      @media (max-width: 1024px) {
        height: auto;
      }
    }

    &.actions {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      @media (max-width: 1024px) {
        height: auto;
        gap: 5px;
      }

      &.action {
        display: flex;
        align-items: center;

        &.search {
          border-radius: 8px;
          border: 1px solid #ffffff29;
          background: #071a2b;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          padding: 8px;

          &.input {
            border: none;
            color: white;
            outline: none;
          }
        }
      }
    }
  }
}
</style>