<template>
    <section v-if="!listOfUser || listOfUser.length <= 0" class="max-w-full flex justify-center flex-col items-center h-full">
        <span class="text-gray-500 text-xl">No User found.</span>
    </section>
    <section v-else class="max-w-full">
        <div v-if="isMultipleUserSelected"
             class="flex items-center gap-2 px-5 h-[50px] w-full max-h[50px] sticky top-0 bg-slate-700 z-10">
            <CustomCheckBox :isChecked="isMultipleUserSelected"
                            @update:isChecked="handleAllSelect" />
            <UserBulkActionsDropdown @onDeleteUsers="onDeleteUsers"
                                     @onUsersStatusChangeToActive="onUsersStatusChangeToActive"
                                     @onUsersStatusChangeToInActive="onUsersStatusChangeToInActive"
                                     @onUsersResendInvitation="onUsersResendInvitation" />
        </div>
        <table class="w-full text-sm font-medium border-collapse">
            <thead class="top-0 sticky z-1"
                   :class="{ 'top-[50px]': isMultipleUserSelected }">
                <tr class="w-full">
                    <th v-for="(column, index) in tableColumns"
                        :key="index"
                        class="text-left max-w-[400px] px-5 py-3.5 bg-slate-800 text-slate-100 border-b border-zinc-700 border-opacity-40"
                        :class="{ 'cursor-pointer': column.isSortable }">
                        <div v-if="column.isVisible" class="flex w-fit items-center gap-2">
                            <CustomCheckBox v-if="column.isCheckBoxType"
                                            @update:isChecked="handleAllSelect"
                                            :isChecked="isMultipleUserSelected"
                                            class="border border-solid border-white" />
                            <div v-if="column.isSortable"
                                 class="flex gap-2 items-center"
                                 :class="{
                  'text-blue-300': currentSortedColumn.columnName === column.id,
                }"
                                 @click="applySort(column)">
                                {{ column.name }}
                                <IconArrowUp v-if="
                    currentSortedColumn.columnName === column.id &&
                    currentSortedColumn.direction === 'DESC'
                  "
                                             class="w-[15px] h-[14px] text-[#CBD5E1]" />
                                <IconArrowDown v-else
                                               class="w-[15px] h-[14px] text-[#CBD5E1]" />
                            </div>
                            <div v-else>{{ column.name }}</div>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody class="bg-slate-900"
                   v-for="(user, index) in listOfUser"
                   :key="index">
                <tr class="border-b border-zinc-700 border-opacity-40">
                    <td class="flex items-center gap-2 text-white px-5 py-3.5 text-opacity-40">
                        <CustomCheckBox @update:isChecked="handleItemSelect(user.id)"
                                        :isChecked="isItemSelected(user.id)"
                                        class="border border-solid border-white" />
                        <div class="flex flex-col">
                            <span class="text-white">{{ user.fullName }}</span>
                            <span>{{ user.email }}</span>
                        </div>
                    </td>
                    <td class="text-white px-5 py-3.5 text-opacity-40">
                        <span>{{ user.userName }}</span>
                    </td>
                    <td class="text-white px-5 py-3.5 text-opacity-40">
                        <span class="w-fit px-1.5 py-0.5 rounded-md border border-solid min-h-[20px]"
                              :class="GetUserStatusClass(user.status.id)">{{ user.status.name }}</span>
                    </td>
                    <td class="text-white px-5 py-3.5 text-opacity-40">
                        <span>{{ user.role.name }}</span>
                    </td>
                    <td class="flex px-5 py-3.5 items-center justify-between text-white text-opacity-40">
                        <div class="w-[calc(100% - 100px)] text-opacity-40">
                            <span>{{ user.id }}</span>
                        </div>
                        <nav class="flex gap-3 items-center px-5 py-7"
                             role="navigation"
                             aria-label="Main navigation">
                            <button type="button"
                                    class="flex flex-col self-stretch my-auto w-5"
                                    aria-label="Settings"
                                    @click="openUserActionModal(user, $event)">
                                <img loading="lazy"
                                     src="https://cdn.builder.io/api/v1/image/assets/TEMP/627b72bdb70bfce08460a9913e13d995377330f28efe86b9d7376b5ac9c4131e?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51"
                                     alt=""
                                     class="object-contain w-5 aspect-square" />
                            </button>
                            <button class="shrink-0 self-stretch my-auto"
                                    aria-label="Search"
                                    @click="goToDetail(user.id)">
                                <img loading="lazy"
                                     src="https://cdn.builder.io/api/v1/image/assets/TEMP/575be1804e33920d6541cd5241bd357947b5f44f567394af72954c325f5d7b34?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51"
                                     alt=""
                                     class="object-contain aspect-square w-[18px]" />
                            </button>
                        </nav>
                    </td>
                </tr>
            </tbody>
        </table>

        <UserActionModal v-onClickOutside="closeActionModal"
                         :isVisible="isUserActionModalVisible"
                         :top="userActionModalPositionTop"
                         :left="userActionModalPositionLeft"
                         :statusId="userActionStatusId"
                         @onEditUser="handleEditUser"
                         @onAcceptRequest="handleAcceptRequest"
                         @onDeclineRequest="handleDeclineRequest"
                         @onResendInvitation="handleResendInvitation"
                         @onRemoveUser="handleRemoveUser" />

        <DeleteUserModal v-if="isDeleteModalVisible"
                         :userName="selectedUserName"
                         @onCancel="onDeleteCancel"
                         @onDelete="onDelete" />
    </section>
</template>

<script setup lang="ts">
    import { ref, defineProps, defineEmits, onMounted, computed, nextTick, onActivated } from "vue";
    import type { PropType } from "vue";

    import CustomCheckBox from "../../common/CustomCheckbox.vue";
    import IconArrowDown from "../../icons/IconArrowDown.vue";
    import IconArrowUp from "../../icons/IconArrowUp.vue";

    import UserActionModal from "./UserActionModal.vue";
    import UserBulkActionsDropdown from "./UserBulkActionsDropdown.vue";
    import DeleteUserModal from "./DeleteUserModal.vue";

    import type { IUser } from "@/Models/Users/IUser";
    import type { ITableColumn } from "@/Models/ITableColumn";
    import type { ISortOption } from "@/Models/ISortOption";

    import { GetUserStatusClass } from "@/utility/GetUserStatusClass";

    const emits = defineEmits([
        "onSort",
        "userDetail",
        "onUserEditAction",
        "onUserDeleteAction",
        "onResendInvitationAction",
        "onSelectAll",
        "onBulkActionUsersDelete",
        "onBulkActionUsersStatusChangeToActive",
        "onBulkActionUsersStatusChangeToInActive",
        "onBulkActionUsersResendInvitation"
    ]);

    const props = defineProps({
        listOfUsersId: {
            type: Array<number>,
            default: []
        },
        listOfUser: {
            type: Array as PropType<IUser[]>,
            default: [],
            required: true,
        },
    });

    const isMultipleUserSelected = computed(() => {
        return selectedUserIds.value.length > 1;
    });

    const selectedUserName = computed(() => {
        if (!props.listOfUser || props.listOfUser.length === 0) return '';
        return props.listOfUser.filter((u: IUser) => u.id === userActionSelectdUserId.value)[0].fullName || ''
    });

    const selectedUserIds = ref<number[]>([]);
    const isDeleteModalVisible = ref<boolean>(false);

    const isUserActionModalVisible = ref<boolean>(false);
    const userActionModalPositionTop = ref<number>(0);
    const userActionModalPositionLeft = ref<number>(0);

    const userActionStatusId = ref<number>(0);
    const userActionSelectdUserId = ref<number>(0);

    const currentSortedColumn = ref<ISortOption>({
        columnName: "Id",
        direction: "ASC",
    });

    const tableColumns = ref<ITableColumn[]>([
        {
            id: "FullName",
            name: "User",
            isVisible: true,
            isCheckBoxType: true,
            isSortable: true,
            width: 200,
        },
        {
            id: "UserName",
            name: "User Name",
            isVisible: true,
            isCheckBoxType: false,
            isSortable: true,
            width: 200,
        },
        {
            id: "Status",
            name: "Status",
            isVisible: true,
            isCheckBoxType: false,
            isSortable: true,
            width: 200,
        },
        {
            id: "Role",
            name: "Role",
            isVisible: true,
            isCheckBoxType: false,
            isSortable: true,
            width: 200,
        },
        {
            id: "Uid",
            name: "Uid",
            isVisible: true,
            isCheckBoxType: false,
            isSortable: false,
            width: 200,
        },
    ]);

    const isItemSelected = (userId: number): boolean => {
        return selectedUserIds.value.includes(userId);
    };

    const handleAllSelect = async (value: boolean) => {
        selectedUserIds.value = value ? props.listOfUsersId : [];
    };
    const handleItemSelect = (userId: number) => {
        selectedUserIds.value.includes(userId)
            ? (selectedUserIds.value = selectedUserIds.value.filter((x) => x !== userId))
            : selectedUserIds.value.push(userId);
    };

    const goToDetail = (selectedId: number) => {
        emits("userDetail", selectedId);
    };

    const openUserActionModal = (user: IUser, event: MouseEvent) => {
        userActionStatusId.value = user.status.id;
        userActionSelectdUserId.value = user.id;
        const rect = (event.target as HTMLElement).getBoundingClientRect();
        userActionModalPositionTop.value =
            rect.top - 20 + window.scrollY + rect.height;
        userActionModalPositionLeft.value = rect.left + window.scrollX;
        isUserActionModalVisible.value = true;
    };

    const closeActionModal = () => {
        isUserActionModalVisible.value = false;
    };

    const applySort = (column: ITableColumn) => {
        if (!column.isSortable) return;

        if (
            column.id.toLowerCase() ===
            currentSortedColumn.value.columnName.toLowerCase()
        ) {
            currentSortedColumn.value.direction =
                currentSortedColumn.value.direction === "ASC" ? "DESC" : "ASC";
        } else {
            currentSortedColumn.value.columnName = column.id;
            currentSortedColumn.value.direction = "ASC";
        }
        emits("onSort", currentSortedColumn.value);
    };

    const handleEditUser = () => {
        emits(
            "onUserEditAction",
            props.listOfUser.find((u) => u.id === userActionSelectdUserId.value)
        );
        closeActionModal();
    };

    const handleResendInvitation = () => {
        emits("onResendInvitationAction", userActionSelectdUserId.value);
        closeActionModal();
    };

    const handleRemoveUser = () => {
        isDeleteModalVisible.value = true;
        closeActionModal();
    };

    const handleAcceptRequest = () => {
        closeActionModal();
    };

    const handleDeclineRequest = () => {
        closeActionModal();
    };

    const onDeleteUsers = () => {
        const selectedIds = selectedUserIds.value.filter((id: number) => props.listOfUsersId.includes(id));
        emits("onBulkActionUsersDelete", selectedIds);
        selectedUserIds.value = [];
    }

    const onUsersResendInvitation = () => {
        const selectedIds = selectedUserIds.value.filter((id: number) => props.listOfUsersId.includes(id));
        emits("onBulkActionUsersResendInvitation", selectedIds);
        selectedUserIds.value = [];
    }

    const onUsersStatusChangeToInActive = () => {
        const selectedIds = selectedUserIds.value.filter((id: number) => props.listOfUsersId.includes(id));
        emits("onBulkActionUsersStatusChangeToInActive", selectedIds);
        selectedUserIds.value = [];
    }

    const onUsersStatusChangeToActive = () => {
        const selectedIds = selectedUserIds.value.filter((id: number) => props.listOfUsersId.includes(id));
        emits("onBulkActionUsersStatusChangeToActive", selectedIds);
        selectedUserIds.value = [];
    }

    const onDeleteCancel = () => {
        isDeleteModalVisible.value = false;
    };

    const onDelete = () => {
        emits("onUserDeleteAction", userActionSelectdUserId.value);
        isDeleteModalVisible.value = false;
    };

    onMounted(async () => {
        emits("onSort", currentSortedColumn.value);
        emits('onSelectAll');
    });

    onActivated(() => {
        emits("onSort", currentSortedColumn.value);
    })
</script>
