<template>
    <div class="w-[100%] h-full flex-col">
        <KeepAlive>
            <component v-if="isListView"
                       :is="CrewManagementUsersTable"
                       @onCreateUser="openNewUserForm"
                       @userDetail="goToUserDetails"
                       @onUserEdit="onUserEdit"
                       @onSuccessAction="handleSuccuss"
                       @onError="handleError" />
        </KeepAlive>
        <UserDetails v-if="isDetailView"
                     :selectedUserId="selectedUserId"
                     @onUserEdit="onUserEdit" />
        <NewUserForm v-if="isUserEditForm"
                     :userToEdit="userToEdit"
                     @cancel="closeNewUserForm"
                     @saved="handleNewUserSave"
                     @deleted="handleDeleted"
                     @onSuccessAction="handleSuccuss"
                     @onError="handleError" />


        <!--Modals-->
        <UserAddSuccessModal v-if="isSuccessAction" :messageTitle="messageTitle" :messageText="messageText" />
        <UserErrorModal :isVisible="isErrorAction" message="Something went wrong" />

    </div>
</template>

<script setup lang="ts">
    import { ref, watch } from 'vue';

    import CrewManagementUsersTable from './CrewManagementUsersTable.vue';
    import NewUserForm from './NewUserForm.vue';
    import UserDetails from './UserDetails.vue';
    import UserAddSuccessModal from "@/components/screwmanagementcomponents/CrewManagementUsers/UserAddSuccessModal.vue";
    import UserErrorModal from "../CrewManagementUsers/UserErrorModal.vue";

    import type { IUser } from "@/Models/Users/IUser";

    const selectedUserId = ref(0);
    const userToEdit = ref<IUser>();

    const isListView = ref(true);
    const isDetailView = ref(false);
    const isUserEditForm = ref(false);
    const isLastUsedPageIsListing = ref(false);

    const isSuccessAction = ref(false);
    const isErrorAction = ref(false);

    const messageTitle = ref<string>('');
    const messageText = ref<string>('');

    const openNewUserForm = () => {
        isListView.value = false;
        userToEdit.value = undefined;
        isUserEditForm.value = true;
    }

    const closeNewUserForm = () => {
        isUserEditForm.value = false;
        !userToEdit.value || isLastUsedPageIsListing.value
            ? isListView.value = true
            : isDetailView.value = true;
    }

    const handleDeleted = () => {
        isUserEditForm.value = false;
        isListView.value = true;
    }

    const handleNewUserSave = (newUserId: number) => {
        selectedUserId.value = newUserId;
        isUserEditForm.value = false;
        isLastUsedPageIsListing.value
            ? isListView.value = true
            : isDetailView.value = true;
        handleSuccuss('User saved', 'The user has been successfully saved.');
    }

    const onUserEdit = (userData: IUser) => {
        isLastUsedPageIsListing.value = isListView.value
            ? true : false;
        selectedUserId.value = userData.id;
        userToEdit.value = userData;
        isListView.value = false;
        isDetailView.value = false;
        isUserEditForm.value = true;
    }

    const goToUserDetails = (selectedId: number) => {
        selectedUserId.value = selectedId;
        isListView.value = false;
        isDetailView.value = true;
    }

    const handleSuccuss = (successTitle: string, successText: string) => {
        messageTitle.value = successTitle;
        messageText.value = successText;
        isSuccessAction.value = true;
    }

    const handleError = () => {
        isErrorAction.value = true;
    }

    watch(() => isSuccessAction.value, (newVal) => {
        if (newVal) {
            setTimeout(() => {
                isSuccessAction.value = false;
            }, 3000)
        }
    })
</script>

<style scoped>
</style>