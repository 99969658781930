<template>
    <div class="flex flex-wrap h-[500px] overflow-y-auto gap-6 items-start justify-between w-full pt-5">
        <section class="flex flex-col h-full justify-between gap-6 grow shrink min-w-[200px] w-[200px]">
            <TotalUsage />
            <UserStats />
        </section>
        <section class="flex flex-wrap h-full gap-6 min-w-[240px]">
            <!--grid grid-cols-3 gap-6 min-w-[240px] max-md:grid-cols-1-->
            <UsedSynapses :usage="totalSynapsesUsage" />
            <UsedNeurons />
            <FeatureRequests />
        </section>
    </div>
</template>

<script setup lang="ts">
    import { ref, onMounted } from 'vue';
    import TotalUsage from './TotalUsage.vue';
    import UserStats from './UserStats.vue';
    import UsedSynapses from './UsedSynapses.vue';
    import UsedNeurons from './UsedNeurons.vue';
    import FeatureRequests from './FeatureRequests.vue';

    interface IUsageDetail{
        name: string;
        percentage: number;
        totalAmountInCHF: number;
    }

    const totalSynapsesUsage = ref<IUsageDetail[]>([]);

    onMounted(() => {
        totalSynapsesUsage.value = [
            {
                "name": "assistant 1",
                "percentage": 10,
                "totalAmountInCHF": 20.00
            },
            {
                "name": "assistant 2",
                "percentage": 15,
                "totalAmountInCHF": 30.00
            },
            {
                "name": "assistant 3",
                "percentage": 50,
                "totalAmountInCHF": 90.00
            },
            {
                "name": "assistant 4",
                "percentage": 25,
                "totalAmountInCHF": 50.00
            }
        ]
    });
</script>