<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <!-- Pagination -->
  <nav class="w-full flex gap-1 justify-between" aria-label="Pagination">
    <button
      class="EndButtons"
      :class="{'hover-enabled': currentPage !== 1}"
      @click="goToPage(currentPage - 1)"
      :disabled="currentPage === 1"
    >
      Previous
    </button>
    <div class="flex gap-1">
      <button
        v-for="page in pages"
        :key="page"
        class="NumberButtons"
        @click="goToPage(page)"
        :class="{'bg-white bg-opacity-10 text-white': page === currentPage}"
        :disabled="page === '...' || page === currentPage"
      >
        {{ page }}
      </button>

      <button
        class="EndButtons ml-4"
        :class="{ 'hover-enabled': currentPage !== totalPages }"
        @click="goToPage(currentPage + 1)"
        :disabled="currentPage === totalPages"
      >
        Next
      </button>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import { computed, defineProps, defineEmits } from "vue";

const props = defineProps({
  totalItems: { type: Number, required: true },
  itemsPerPage: { type: Number, required: true },
  currentPage: { type: Number, required: true },
});

const emit = defineEmits(["update:currentPage"]);

const totalPages = computed(() =>
  Math.ceil(props.totalItems / props.itemsPerPage)
);

const pages = computed(() => {
  const result = [];
  const tp = totalPages.value;
  const cp = props.currentPage;

  if (tp <= 7) {
    // If total pages <= 7, show all pages
    for (let i = 1; i <= tp; i++) {
      result.push(i);
    }
  } else {
    // Always show first and last page
    result.push(1);

    // Handle pages near the current page
    if (cp > 3) {
      result.push("...");
    }

    // Show the current page and neighbors
    for (let i = Math.max(cp - 1, 2); i <= Math.min(cp + 1, tp - 1); i++) {
      result.push(i);
    }

    if (cp < tp - 2) {
      result.push("...");
    }

    // Always show the last page
    result.push(tp);
  }

  return result;
});

function goToPage(page: number | string) {
  if (page === "..." || page === props.currentPage) return;
  if (typeof page === "number" && page >= 1 && page <= totalPages.value) {
    emit("update:currentPage", page);
  }
}
</script>

<style lang="scss">
.NumberButtons {
  @apply flex items-center justify-center px-4 h-10 leading-tight rounded-[4px] text-gray-500 cursor-pointer;
}

.EndButtons {
  @apply flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-[#031525] border border-solid border-[#FFFFFF33] rounded-lg cursor-pointer;
}
.hover-enabled:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: white; 
  cursor: pointer; 
}
</style>
