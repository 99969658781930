export enum StreamlineStatus {
  active = "Active",
  inActive = "Inactive",
  inProgress = "In Progress",
  uploading = "Uploading",
}

export enum FileUploadStatus {
  uploading = "Uploading",
  completed = "Completed",
  failed = "Failed",
  idle = "Idle",
}
