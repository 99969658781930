<template>
    <div>
        <div class="flex flex-col w-full pb-5" v-if="isLogs">
            <div @click="toggleDetails" class="pb-2.5 cursor-pointer">
                <div class="flex flex-row w-full h-fit items-center gap-1 pb-2.5">
                    <svg v-if="!isExpanded"
                         width="14"
                         height="14"
                         viewBox="0 0 14 14"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.875 2.70573L8.66667 6.4974L4.875 10.2891"
                              stroke="white"
                              stroke-linecap="round"
                              stroke-linejoin="round" />
                    </svg>
                    <svg v-if="isExpanded"
                         width="14"
                         height="14"
                         viewBox="0 0 14 14"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.2923 5.375L6.50065 9.16667L2.70898 5.375"
                              stroke="white"
                              stroke-linecap="round"
                              stroke-linejoin="round" />
                    </svg>
                    <div>{{ log.title }}</div>
                    <div v-if="isEvents"
                         class="h-auto w-fit rounded-md bg-textTertiary text-textOrange px-1.5 py-0.5 ml-2.5">
                        {{ log.eventsCount }} Events
                    </div>
                </div>
                <div class="flex flex-row w-full h-fit gap-2 ml-3.5">
                    <span class="text-graphGreen">{{ log.httpVerb }}</span>
                    <span class="text-white">{{ log.resourcePath }}</span>
                </div>
            </div>
            <div v-if="isExpanded">
                <div class="flex flex-col w-full pb-5">
                    <div @click="toggleRequestDetails"
                         class="ml-2.5 flex flex-row w-full gap-1 items-center cursor-pointer">
                        <svg v-if="!isRequestExpanded"
                             width="14"
                             height="14"
                             viewBox="0 0 14 14"
                             fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.875 2.70573L8.66667 6.4974L4.875 10.2891"
                                  stroke="white"
                                  stroke-linecap="round"
                                  stroke-linejoin="round" />
                        </svg>
                        <svg v-if="isRequestExpanded"
                             width="14"
                             height="14"
                             viewBox="0 0 14 14"
                             fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.2923 5.375L6.50065 9.16667L2.70898 5.375"
                                  stroke="white"
                                  stroke-linecap="round"
                                  stroke-linejoin="round" />
                        </svg>
                        <span class="font-medium text-sm">Request</span>
                    </div>
                    <div v-if="isRequestExpanded" class="ml-3.5">
                        <pre class="text-graphRed ml-3.5">{{ log.request }}</pre>
                        <!--v-for="(item, index) in log.request" :key="index"-->
                    </div>
                </div>
                <div class="flex flex-col w-full">
                    <div @click="toggleResponseDetails"
                         class="ml-2.5 flex flex-row w-full gap-1 items-center cursor-pointer">
                        <svg v-if="!isResponseExpanded"
                             width="14"
                             height="14"
                             viewBox="0 0 14 14"
                             fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.875 2.70573L8.66667 6.4974L4.875 10.2891"
                                  stroke="white"
                                  stroke-linecap="round"
                                  stroke-linejoin="round" />
                        </svg>
                        <svg v-if="isResponseExpanded"
                             width="14"
                             height="14"
                             viewBox="0 0 14 14"
                             fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.2923 5.375L6.50065 9.16667L2.70898 5.375"
                                  stroke="white"
                                  stroke-linecap="round"
                                  stroke-linejoin="round" />
                        </svg>
                        <span class="font-medium text-sm">Response Stream</span>
                        <div v-if="log.response && log.response.length > 0"
                             class="h-auto w-fit rounded-md bg-textTertiary text-textOrange px-1.5 py-0.5 ml-2.5">
                            {{ log.response.length }} Events
                        </div>
                    </div>
                    <div v-if="isResponseExpanded" class="ml-3.5">
                        <div class="flex flex-col w-full"
                             v-for="(item, index) in log.response"
                             :key="index">
                            <div @click="expandObject(index)"
                                 class="ml-2.5 flex flex-row w-full gap-1 items-start cursor-pointer">
                                <svg v-if="!expandedResponseObject[index]"
                                     width="14"
                                     height="14"
                                     viewBox="0 0 14 14"
                                     fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.875 2.70573L8.66667 6.4974L4.875 10.2891"
                                          stroke="white"
                                          stroke-linecap="round"
                                          stroke-linejoin="round" />
                                </svg>
                                <svg v-if="expandedResponseObject[index]"
                                     width="14"
                                     height="14"
                                     viewBox="0 0 14 14"
                                     fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.2923 5.375L6.50065 9.16667L2.70898 5.375"
                                          stroke="white"
                                          stroke-linecap="round"
                                          stroke-linejoin="round" />
                                </svg>
                                <span v-if="!expandedResponseObject[index]">{...}</span>
                                <pre v-if="expandedResponseObject[index]"
                                     class="text-graphRed">{{ item }}</pre>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-row justify-between mt-3.5 ml-3.5 mr-3.5">
                <!--      Footer-->
                <div v-if="props.log.totalUsageToken > 0" class="flex flex-row gap-2.5 items-center">
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="0.5" width="18" height="18" rx="9" fill="#68C57C" />
                        <path d="M13.5 6.125L7.3125 12.3125L4.5 9.5" stroke="white" stroke-width="1.6666" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Run Completed</span>
                </div>
                <!--<div class="flex flex-row gap-2.5 items-center">
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="0.5" width="18" height="18" rx="9" fill="#FF4D4D" />
                        <path d="M6 6.5L12 12.5M6 12.5L12 6.5" stroke="white" stroke-width="1.6666" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Run Terminated</span>
                </div>-->
                <div v-if="props.log.totalUsageToken > 0">
                    <span>{{props.log.totalUsageToken}} tokens</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, computed, watch } from 'vue';
    import type { IAssistantLogDataModel } from '@/Models/AssistantLogs/IAssistantLogDataModel';
    const props = defineProps<{ log: IAssistantLogDataModel }>();

    const isExpanded = ref<boolean>(false);
    const isRequestExpanded = ref<boolean>(false);
    const isResponseExpanded = ref<boolean>(false);
    const expandedResponseObject = ref<boolean[]>([]);
    const expandedRequestObject = ref<boolean[]>([]);

    const isLogs = computed(() => {
        return Object.keys(props.log).length > 0;
    });

    const isEvents = computed(() => {
        return !!props.log.eventsCount;
    });

    const toggleDetails = () => {
        isExpanded.value = !isExpanded.value;
    };

    const toggleRequestDetails = () => {
        isRequestExpanded.value = !isRequestExpanded.value;
    };

    const toggleResponseDetails = () => {
        isResponseExpanded.value = !isResponseExpanded.value;
    };

    const expandObject = (index: number) => {
        expandedResponseObject.value[index] = !expandedResponseObject.value[index];
    }

    watch(() => props.log,
        (newData) => {
            if (newData) {
                if (newData.request.length > 0) expandedRequestObject.value = newData.request.map(() => false);
                if (newData.response.length > 0) expandedResponseObject.value = newData.response.map(() => false);
            }
            else {
                expandedRequestObject.value = [];
                expandedResponseObject.value = [];
            }
        });
</script>
