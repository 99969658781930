<template>
    <section v-if="isModalVisible" class="absolute bottom-2 z-1001 flex flex-row items-center justify-center w-[99%] pt-4" role="status" aria-live="polite">
        <article class="flex relative flex-1 shrik flex-grow gap-4 items-center p-4 mt-0 rounded-3xl border-2 border-green border-solid shadow-sm bg-slate-900">
            <img loading="lazy"
                 src="https://cdn.builder.io/api/v1/image/assets/TEMP/8cae9f364638f8925d20a1709179dd84eda6f3f9b8fc03fb1a0b459af4cb3d37?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51"
                 class="object-contain z-0 shrink-0 w-8 rounded-3xl aspect-square"
                 alt="User profile" />
            <div class="flex-row flex-1 shrink flex-grow items-center">
                <header class="z-10 self-center text-base font-semibold text-white max-md:max-w-full">
                    {{messageTitle}}
                </header>
                <p class="flex z-0 flex-col flex-1 shrink text-sm font-medium text-white basis-4 min-w-[240px] max-md:max-w-full">
                    {{messageText}}
                </p>
            </div>
            <button class="flex overflow-hidden top-2 right-2 z-0 justify-center items-center p-2 w-9 h-9 rounded-lg min-h-[36px]"
                    aria-label="Close notification"
                    @click="closeNotification">
                <img loading="lazy"
                     src="https://cdn.builder.io/api/v1/image/assets/TEMP/f7d4a6c11c0013d58de7ad3259d9816ca8a3bf508b8502b5560118eeb189691f?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51"
                     class="object-contain self-stretch my-auto w-5 aspect-square"
                     alt="" />
            </button>
        </article>
    </section>
</template>

<script setup lang="ts">
    import { ref, onMounted, defineProps } from 'vue'
   
    const isModalVisible = ref(false);

    const closeNotification = () => {
        isModalVisible.value = false;
    }

    const props = defineProps({
        messageTitle: {
            type: String,
            default: '',
        },
        messageText: {
            type: String,
            default: '',
        }
    })

    onMounted(() => {
        isModalVisible.value = true;
        //setTimeout(() => {
        //    isModalVisible.value = false;
        //}, 3000);
    })
</script>