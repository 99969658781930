export const interpolateColor = (
  start: string,
  end: string,
  factor: number
): string => {
  const hexToRgb = (hex: string) =>
    hex.match(/\w\w/g)!.map((x) => parseInt(x, 16));
  const rgbToHex = (rgb: number[]) =>
    `#${rgb.map((x) => x.toString(16).padStart(2, "0")).join("")}`;

  const startRGB = hexToRgb(start);
  const endRGB = hexToRgb(end);

  const resultRGB = startRGB.map((start, i) =>
    Math.round(start + Math.pow(factor, 0.5) * (endRGB[i] - start))
  );

  return rgbToHex(resultRGB);
};
