<script setup lang="ts">
    import HubLayout from "./components/HubLayout.vue";
    import { computed, onMounted, ref } from "vue";
    import TokenManager from "./Services/TokenManager";
    import AppConfigGlobal from "./core/config/uiSettings";
    import { useRoute } from "vue-router";

    let isAdmin: boolean;
    const route = useRoute();
    const isDevMode = ref(false);
    const setTheme = (theme: string) => {
        document.querySelector("html")?.setAttribute("data-theme", theme);
    };

    onMounted(async () => {
        if (import.meta.env.MODE === "development") {
            isDevMode.value = true;
        } else if (import.meta.env.MODE === "production") {
            isDevMode.value = false;
        }

        setTheme("default");

        const tokenManager = TokenManager.getInstance();
        tokenManager.initializeTokenManagement();
        isAdmin = tokenManager.CheckIsAdmin();
        isAdmin = true;
        if (!isAdmin) {
            window.location.href = `${AppConfigGlobal.FreddyAssistantUrl}/freddy/assistant`;
        }
        //window.addEventListener('load', () => tokenManager.initializeTokenManagement());
    });
    const IsAdmin = computed(() => {
        return TokenManager.getInstance().CheckIsAdmin();
    });
</script>

<template>
    <header>
        <!-- <img alt="Vue logo" class="logo" src="./assets/logo.svg" width="125" height="125" />

            <div class="wrapper">
              <HelloWorld msg="You did it!" />
            </div> -->
        <!-- <HubUI /> -->
        <!--<hub-layout/>-->
    </header>
    <main>
        <!--<DummyLayout />-->
        <!-- TODO: Logic should be changed -->
        <hub-layout v-if="IsAdmin && route.path !== '/icons-list'"
                    class="freddy-font" />
        <router-view v-if="isDevMode"></router-view>
    </main>
</template>

<style scoped>
    /* header {
      line-height: 1.5;
    }

    .logo {
      display: block;
      margin: 0 auto 2rem;
    }

    @media (min-width: 1024px) {
      header {
        display: flex;
        place-items: center;
        padding-right: calc(var(--section-gap) / 2);
      }

      .logo {
        margin: 0 2rem 0 0;
      }

      header .wrapper {
        display: flex;
        place-items: flex-start;
        flex-wrap: wrap;
      }
    } */
</style>
