<template>
    <div class="selection-container">
        <div class="selection-container head">
            <span class="h7">Here you can select which vector store is suppose to  posses what knowledge and should have access to what compartments.</span>
            <!--<span class="icon"><IconArrowLeft :width="52" :height="52" @click="closeView" /></span>
        <div class="title">
            <p class="h5Bold heading5">Source Selection</p>
            <p class="h7 heading7">Here you can select which assistant is suppose to  posses what knowledge and should have access to what compartments.</p>
        </div>-->
        </div>
        <VectorStoreDropDown :options="vectorStoreList"
                             @onOptionChange="onOptionChange"/>
        <div class="selection-container options" v-if="selectedAssistantName && selectedAssistantName.length">
            <AssistantSelectionList :assistantList="selectedAssistantName"/>
        </div>
        <div class="selection-container bodySmall" :class="{'no-assistants': !selectedAssistantName || !selectedAssistantName.length}">
            <div class="separator-container">
                <hr class="separator" />
            </div>
            <SourceOptionsList :sourceList="listOfSpaces"
                               :sourceTitle="sourceTitle"
                               :isFetching="fetchingSpaces"
                               @onSourceChange="onSpaceSelection" />
            <!--<div class="selection-container bodySmall left">-->
            <!--<div class="selection-container bodySmall left action">
        <span class="bodySmall text-body">Assistant</span>
        <ActionDropdown width="100%"
                        height="44px"
                        :options="listOfAssistant"
                        @onItemSelected="onAssistantSelect" />
        <span class="bodySmall text-body-light">Select the assistant to which you want to add the knowledge</span>
    </div>-->
            <!--<div v-if="selectedAssistantConnectionList.length" class="selection-container bodySmall left list-box">
            <span class="bodySmall text-body">Making Changes to</span>
            <ul class="selection-container bodySmall left list-box list">
                <li class="item" v-for="(item, index) in selectedAssistantConnectionList.map(x => x.assistant)">
                    <IconRobotScreen /><span>{{item.name}}</span>
                </li>
            </ul>
            <span class="bodySmall text-body-light">Listed are the assistants of which you have modified the knowledge of.</span>
        </div>
    </div>-->
            <!--<div class="selection-container bodySmall right">
        <div class="selection-container bodySmall right head">
            <span class="h5Bold heading5">Alturos Confluence Spaces</span>
            <div class="selection-container bodySmall right head search">
                <IconLense />
                <input type="search"
                       name="connectorsearch"
                       placeholder="Search"
                       v-model="searchText"
                       class="selection-container bodySmall right head search input"
                       @input="searchConnectors"
                       @keyup.enter="searchConnectors" />
            </div>
        </div>
        <div v-if="listOfSpaces && listOfSpaces.length === 0" class="selection-container bodySmall right list-box">
            <Loader :isLoading="false" />
        </div>
        <div v-else class="selection-container bodySmall right list-box">
            <ul class="selection-container bodySmall right list-box list">
                <li v-if="listOfSpaces && searchedSpaceList.length" class="item" v-for="(item, index) in searchedSpaceList">
                    <div>
                        <input type="checkbox" :value="item" v-model="selectedSpaces">
                        <label>
                            <IconEllipse />{{item.name}}
                        </label>
                    </div>
                    <IconChevronDown />
                </li>
                <li v-else class="empty-item">
                    Nothing found
                </li>
            </ul>
            <div class="save-btn" @click="saveConnection">
                <span>Save</span>
            </div>
        </div>
    </div>-->
        <!--<div class="selection-container footer">-->  <!--:class="{'alert-box': isConnectClicked}"-->
            <!--<div class="connect-btn" :class="{'btn_disable': !selectedVectorStoreIds.length}">
                <span @click="connectSource">Connect</span>
            </div>-->
            <!--<div v-if="isConnectClicked" class="connect-alert-btn">
        <span class="bodySmall text-body" @click="connectSource">Confirm</span>
    </div>
    <div v-if="isConnectClicked" class="selection-container footer text">
        <span class="h6Bold heading6">Are you sure you want to connect this knowledge?</span>
        <span class="h7 heading7">
            Connecting this knowledge might generate a lot of usage and with that cost, is that fine?
        </span>
        <span class="h7 heading7">
            We will notify you upon completion of if something fails.
        </span>
    </div>
    <div v-if="isConnectClicked" class="connect-alert-btn cancel">
        <span class="bodySmall text-body" @click="closeConfirmation">Cancel</span>
    </div>-->
        <!--</div>-->
        </div>
        
    </div>
</template>

<script setup lang="ts">
    import { ref, markRaw, onMounted, computed } from 'vue';
    import type { PropType } from 'vue';
    import { HubService } from '@/Services/HubService';

    import IconArrowLeft from '@/components/icons/IconArrowLeft.vue';
    import IconRobotScreen from '@/components/icons/IconRobotScreen.vue';
    import IconLense from '@/components/icons/IconLense.vue';
    import IconEllipse from '@/components/icons/IconEllipse.vue';
    import IconChevronDown from '@/components/icons/IconChevronDown.vue';

    import HubConnectionConfluenceAuthentication from './HubConnectorConfluenceAuthentication.vue';
    import VectorStoreDropDown from '../VectorStoreDropDown.vue';
    import AssistantSelectionList from '../AssistantSelectionList.vue';
    import ActionDropdown from '@/components/common/ActionDropdown.vue';
    import Loader from '@/components/Loader.vue';
    import SourceOptionsList from '@/components/freddycomponents/connectors/SourceOptionsList.vue';
    import type { ISpaceResponse } from '@/Models/ISpaceResponse';
    import type { IAssistantConnection, IVectorStoreAssistant } from '@/Models/IConnector';

    const hubService = new HubService();

    const props = defineProps({
        sourceLink: {
            type: String,
            default: '',
            required: true
        },
        username: {
            type: String,
            default: '',
            required: true
        },
        password: {
            type: String,
            default: '',
            required: true
        },
        sourceTitle:{
            type:String,
            default:'',
            required:true
        },
    });

    const emits = defineEmits(['vectorStoreIdsSelected', 'spaceKeysSelected']);

    //const isConnectClicked = ref<Boolean>(false);
    /*const isConnected = ref<boolean>(false);*/
    //const isAuthenticated1 = ref<boolean>(false);
    //const isAuthenticated2 = ref<boolean>(false);
    //const isAuthenticated3 = ref<boolean>(false);

    const fetchingSpaces = ref<boolean>(false);

    const vectorStoreList = ref<IVectorStoreAssistant[]>([]);
    const selectedAssistantName = computed(() => {
        return vectorStoreList.value
            .filter(m => selectedVectorStoreIds.value.includes(m.vectorStoreId))
            .map(m => m.assistantName)
        //return props.listOfAssistant.map(m => {
        //    if (selectedAssistantId.includes(m.id))
        //    return m.name
        //})
    });
    const listOfSpaces = ref<ISpaceResponse[]>([]);
    const searchedSpaceList = ref<ISpaceResponse[]>([]);

    //const selectedAssistantConnectionList = ref<IAssistantConnection[]>([]);

    //const selectedSpaceKeys = ref<string[]>([]);
    const selectedVectorStoreIds = ref<number[]>([]);

    const onOptionChange = (option: IVectorStoreAssistant) => {
        selectedVectorStoreIds.value.includes(option.vectorStoreId)
            ? selectedVectorStoreIds.value = selectedVectorStoreIds.value.filter(x => x !== option.vectorStoreId)   
            : selectedVectorStoreIds.value.push(option.vectorStoreId);

        emits('vectorStoreIdsSelected', selectedVectorStoreIds.value);
    };

    const onSpaceSelection = (selectedKeys: string[]) => {
        //selectedSpaceKeys.value = selectedKeys;
        emits('spaceKeysSelected', selectedKeys)
    }

    //const closeView = () => {
    //    emits('ConnectorSourceViewClosed');
    //}

    //const connectSource = async () => {
    //    //const listOfSpaceKey = listOfSpaces.value.filter(space => selectedSpaceKeys.value.includes(space.key))
    //    //let spacekeys: string[] = [];
    //    //let spaceids: string[] = [];
    //    //if (spaces) {
    //    //    spacekeys = spaces.map(space => space?.key);
    //    //    console.log(spacekeys)
    //    //    spaceids = spaces.map(space => space?.id.toString())

    //    //};

    //    isConnected.value = await hubService.connectSource(selectedVectorStoreIds.value, selectedSpaceKeys.value, props.sourceLink, props.username, props.password, props.sourceTitle.toLocaleLowerCase());

    //    if (isConnected.value) emits('SourceConnected', isConnected.value);
    //}

    //const closeConfirmation = () => {
    //    isConnectClicked.value = false;
    //}

    //const searchConnectors = () => {
    //    if (searchText.value.trim() === '') {
    //        searchedSpaceList.value = [...listOfSpaces.value];
    //        return;
    //    }
    //    searchedSpaceList.value = listOfSpaces.value.filter(m =>
    //        m.name.toLowerCase() == searchText.value.toLowerCase() || m.name.toLowerCase().includes(searchText.value.toLowerCase()));
    //};

    //const saveConnection = () => {
    //    //selectedAssistantConnectionList.value[selectedAssistantConnectionList.value.length - 1].spaceList = [...selectedSpaces.value];
    //    console.log(selectedAssistantConnectionList.value);
    //}

    onMounted(async () => {
        try {
            fetchingSpaces.value = true;
            vectorStoreList.value = await hubService.GetVectorAssistant();
            listOfSpaces.value = await hubService.getAllSpaces(props.sourceLink, props.username, props.password, props.sourceTitle?.toLowerCase());
        }
        catch {
            vectorStoreList.value = [];
            listOfSpaces.value = [];
        }
        finally {
            fetchingSpaces.value = false;
            searchedSpaceList.value = [...listOfSpaces.value];
        }
    })

    //const getDummySpaces = (): ISpaceResponse[] => {
    //    return [];
    //}

</script>
<!-- 
<style scopped lang="scss">
    @import '@/assets/mainstyle';

    /*$bg-color-action: #071A2B;*/
    $border-light: 1px solid rgba(255, 255, 255, 0.10);
    $assistant-selected-width: 130px;

    .no-assistants {
        height: calc(100% - 320px + $assistant-selected-width) !important;
    }
    .selection-container {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;

        &.head {
            flex-direction: row;
            align-items: center;
            height: 35px;
            padding: 10px;
            background-color: #1D2E47;
            //gap: 15px;
            /*.title {
                flex-direction: column;
                gap: 8px;
            }

            .icon {
                color: white;
            }*/
        }

        &.options {
            flex-direction: row;
            align-items: center;
            height: $assistant-selected-width;
            padding: 0 20px;
            /*background-color: #1D2E47;*/
        }

        &.bodySmall {
            //height: calc(100vh - 330px);
            height: calc(100% - 320px);
            padding: 0 20px;
            flex-direction: column;
            /*gap: 12px;*/
            gap: 0;
            z-index: 1;
            /*flex: 1;*/

            &.left {
                flex-direction: column;
                padding: 0;
                height: 100%;
                //width: 200px;
                width: 30%;
                max-width: 300px;


                &.action {
                    gap: 5px;
                    padding: 10px;
                    width: 100%;
                    /*max-height: 30%;*/
                    height: auto;
                    border-radius: 30px;
                    background: var(--backgroundSecondary);

                    span {
                        padding: 5px 0px;
                    }
                }

                &.list-box {
                    gap: 5px;
                    padding: 10px;
                    width: 100%;
                    height: auto;
                    max-height: calc(100% - 155px);
                    justify-content: flex-start;
                    border-radius: 30px;
                    background: var(--backgroundSecondary);
                    position: unset;


                    &.list {
                        height: 90%;
                        max-height: 100%;
                        overflow-y: auto;
                        padding: 10px 0;
                        border-radius: 0;
                        @extend .scroll-width;

                        .item {
                            display: flex;
                            gap: 12px;
                            border-radius: 8px;
                            border: 1px solid #D0D5DD;
                            background: var(--backgroundSecondary);
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                            padding: 0px 15px;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            height: 44px;
                        }
                    }
                }
            }

            &.right {
                flex-direction: column;
                padding: 20px;
                height: 100%;
                flex-grow: 1;
                //width: calc(100% - 200px);
                width: 70%;
                gap: 10px;
                border-radius: 30px;
                background: var(--backgroundSecondary);

                &.head {
                    padding: 0;
                    flex-direction: row;
                    justify-content: space-between;
                    height: 10%;
                    width: 100%;
                    border-radius: 0px;

                    &.search {
                        flex-direction: row;
                        align-items: center;
                        width: 50%;
                        height: 44px;
                        border-radius: 8px;
                        border: 1px solid rgba(255, 255, 255, 0.40);
                        background: #11385B;
                        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                        padding: 10px;
                        gap: 0px;
                        max-width: 300px;

                        &.input {
                            height: 20px;
                            border: none;
                            color: white;
                            outline: none;
                        }
                    }

                    span {
                        gap: 0;
                    }
                }

                &.list-box {
                    height: 90%;
                    width: 100%;
                    gap: 10px;
                    padding: 0;
                    border-radius: 0px;


                    &.list {
                        gap: 0;
                        height: 94%;
                        list-style: none;
                        overflow-y: scroll;
                        @extend .scroll-width;

                        .item {
                            display: flex;
                            justify-content: space-between;
                            gap: 10px;
                            height: 50px;
                            padding: 0px 10px;
                            align-items: center;
                            border: $border-light;
                            border-left: none;
                            border-right: none;

                            div {
                                border: none;
                                gap: 10px;
                                display: flex;
                                align-items: center;
                                height: 100%;

                                label {
                                    display: flex;
                                    gap: 10px;
                                    border-left: $border-light;
                                    height: 50px;
                                    align-items: center;
                                    padding: 0px 10px;
                                }
                            }
                        }

                        .empty-item {
                            display: flex;
                            height: 100%;
                            justify-content: space-around;
                            align-items: center;
                        }
                    }

                    .save-btn {
                        @extend .btn;
                        align-self: flex-end;
                    }
                }
            }
        }

        &.footer {
            height: 50px;
            flex-direction: row;
            align-items: center;
            /* padding: 0px 65px; */
            justify-content: flex-end;
            gap: 10px;
            /* bottom: -50px; */
            position: absolute;
            padding: 0 20px;
            bottom: -70px;

            &.text {
                height: 100%;
                flex-direction: column;
                justify-content: flex-start;
                overflow-y: auto;
                gap: 0px;
                border: none;
                box-shadow: none;
                padding: 0;
                @extend .scroll-width;
            }

            .connect-btn {
                @extend .btn;
                justify-content: flex-end;
                gap: 10px;
                width: 100px !important;
                height: 40px !important;
            }

            /*.btn_disable{
                opacity: 0.5;
                pointer-events: none;
            }*/

            .connect-alert-btn {
                @extend .btn;
                width: 100px !important;
                height: 40px !important;
            }
        }
    }

    .btn {
        width: 70px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        @extend .highlight;
        @extend .hover-event;

        span {
            padding: 10px 14px;
        }
    }

    .cancel {
        @extend .hover-event;
        background: rgba(255, 255, 255, 0.20) !important;
    }

    .alert-box {
        position: absolute;
        bottom: 60px;
        height: 90px !important;
        border-top: $border-light;
        box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0.25);
    }

    .separator-container {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .separator {
        border: none;
        border-radius: 24px;
        background-color: rgba(255, 255, 255, 0.09);
        min-height: 1px;
        width: 100%;
    }

    @media (max-width: 991px) {
        .invoice-content,
        .separator-container,
        .separator {
            max-width: 100%;
        }
    }
    

</style> -->