<template>
    <div class="flex mt-2">
      <label
        v-for="option in options"
        :key="option.value"
        class="cursor-pointer"
      >
        <input
          type="radio"
          :name="name"
          :value="option.value"
          class="hidden peer"
          v-model="localValue"
        />
        <div
          class="peer-checked:bg-[#7BA8EF] text-[#7ba8ef] peer-checked:text-[#031525] px-4 py-2 border border-[#7BA8EF] text-center bg-[#031525] hover:bg-[#031525]"
          :class="{
            'rounded-tl-lg rounded-bl-lg': option.value === options[0].value,
            'rounded-tr-lg rounded-br-lg': option.value === options[options.length - 1].value,
            '!px-3 !py-1': !large,
          }"
        >
          {{ option.label }}
        </div>
      </label>
    </div>
  </template>
  
  <script setup lang="ts">
  import { defineProps, defineEmits, ref, watch } from "vue";
  
  // Define props
  interface Option {
    label: string;
    value: string;
  }
  
  const props = defineProps<{
    name: string;
    options: Option[];
    modelValue: string;
    large?: boolean;
  }>();
  
  // Emit event
  const emit = defineEmits<{
    (e: "update:modelValue", value: string): void;
  }>();
  
  // Local reactive value synchronized with modelValue
  const localValue = ref(props.modelValue);
  
  // Watch for changes in localValue and emit updates
  watch(localValue, (newValue) => {
    emit("update:modelValue", newValue);
  });
  
  // Sync modelValue prop changes with localValue
  watch(
    () => props.modelValue,
    (newValue) => {
      localValue.value = newValue;
    }
  );
  </script>
  
  <style scoped>
  /* Add styles here if necessary */
  </style>
  