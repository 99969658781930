import { defineAsyncComponent } from "vue";

export const IconAppHiveHub = defineAsyncComponent(
  () => import("./IconAppHiveHub.vue")
);
export const IconApplePay = defineAsyncComponent(
  () => import("./IconApplePay.vue")
);
export const IconArrowLeft = defineAsyncComponent(
  () => import("./IconArrowLeft.vue")
);
export const IconAvatar = defineAsyncComponent(
  () => import("./IconAvatar.vue")
);
export const IconCheckCircleFill = defineAsyncComponent(
  () => import("./IconCheckCircleFill.vue")
);
export const IconChevronDown = defineAsyncComponent(
  () => import("./IconChevronDown.vue")
);
export const IconChevronLeft = defineAsyncComponent(
  () => import("./IconChevronLeft.vue")
);
export const IconChevronRight = defineAsyncComponent(
  () => import("./IconChevronRight.vue")
);
export const IconChevronUp = defineAsyncComponent(
  () => import("./IconChevronUp.vue")
);
export const IconConnectorConfluence = defineAsyncComponent(
  () => import("./IconConnectorConfluence.vue")
);
export const IconConnectorFigma = defineAsyncComponent(
  () => import("./IconConnectorFigma.vue")
);
export const IconConnectorFreshDesk = defineAsyncComponent(
  () => import("./IconConnectorFreshDesk.vue")
);
export const IconConnectorJira = defineAsyncComponent(
  () => import("./IconConnectorJira.vue")
);
export const IconConnectorLinear = defineAsyncComponent(
  () => import("./IconConnectorLinear.vue")
);
export const IconConnectorNotion = defineAsyncComponent(
  () => import("./IconConnectorNotion.vue")
);
export const IconConnectorSlack = defineAsyncComponent(
  () => import("./IconConnectorSlack.vue")
);
export const IconConnectorZapier = defineAsyncComponent(
  () => import("./IconConnectorZapier.vue")
);
export const IconCrossCircleFill = defineAsyncComponent(
  () => import("./IconCrossCircleFill.vue")
);
export const IconDelete = defineAsyncComponent(
  () => import("./IconDelete.vue")
);
export const IconEllipse = defineAsyncComponent(
  () => import("./IconEllipse.vue")
);
export const IconFreddyHub = defineAsyncComponent(
  () => import("./IconFreddyHub.vue")
);
export const IconHomeFill = defineAsyncComponent(
  () => import("./IconHomeFill.vue")
);
export const IconInfoRounded = defineAsyncComponent(
  () => import("./IconInfoRounded.vue")
);
export const IconInvoice = defineAsyncComponent(
  () => import("./IconInvoice.vue")
);
export const IconLense = defineAsyncComponent(() => import("./IconLense.vue"));
export const IconPeopleHub = defineAsyncComponent(
  () => import("./IconPeopleHub.vue")
);
export const IconPurseCircleFill = defineAsyncComponent(
  () => import("./IconPurseCircleFill.vue")
);
export const IconRobotScreen = defineAsyncComponent(
  () => import("./IconRobotScreen.vue")
);
export const IconScrew = defineAsyncComponent(() => import("./IconScrew.vue"));
export const IconStreamLineHub = defineAsyncComponent(
  () => import("./IconStreamLineHub.vue")
);
export const IconPurpose = defineAsyncComponent(
  () => import("./IconPurpose.vue")
);
export const IconHollowCircle = defineAsyncComponent(
  () => import("./IconHollowCircle.vue")
);
export const IconDocumentation = defineAsyncComponent(
  () => import("./IconDocumentation.vue")
);
export const IconCardPay = defineAsyncComponent(
  () => import("./IconCardPay.vue")
);
export const IconNotification = defineAsyncComponent(
  () => import("./IconNotification.vue")
);
export const IconNewNotification = defineAsyncComponent(
  () => import("./IconNewNotification.vue")
);
export const IconConnectorGitHub = defineAsyncComponent(
  () => import("./IconConnectorGitHub.vue")
);
export const IconEye = defineAsyncComponent(() => import("./IconEye.vue"));
export const IconProgressComplete = defineAsyncComponent(
  () => import("./IconProgressComplete.vue")
);
export const IconPersonCircle = defineAsyncComponent(
  () => import("./IconPersonCircle.vue")
);
export const IconProgressBase = defineAsyncComponent(
  () => import("./IconProgressBase.vue")
);
export const IconSettingsBold = defineAsyncComponent(
  () => import("./IconSettingsBold.vue")
);
export const IconProgressGoing = defineAsyncComponent(
  () => import("./IconProgressGoing.vue")
);
export const IconSun = defineAsyncComponent(() => import("./IconSun.vue"));
export const IconHub = defineAsyncComponent(() => import("./IconHub.vue"));
export const IconPolygonDown = defineAsyncComponent(
  () => import("./IconPolygonDown.vue")
);
export const IconClock = defineAsyncComponent(() => import("./IconClock.vue"));
export const IconSize = defineAsyncComponent(() => import("./IconSize.vue"));
export const IconLocationWithCircleIn = defineAsyncComponent(
  () => import("./IconLocationWithCircleIn.vue")
);
export const IconPencil = defineAsyncComponent(
  () => import("./IconPencil.vue")
);
export const IconAdd = defineAsyncComponent(() => import("./IconAdd.vue"));
export const IconGlobExpand = defineAsyncComponent(
  () => import("./IconGlobExpand.vue")
);
export const IconStatistics = defineAsyncComponent(
  () => import("./IconStatistics.vue")
);
export const IconFile = defineAsyncComponent(() => import("./IconFile.vue"));
export const IconCloudUpload = defineAsyncComponent(
  () => import("./IconCloudUpload.vue")
);
export const IconSearch = defineAsyncComponent(
  () => import("./IconSearch.vue")
);
export const IconClose = defineAsyncComponent(() => import("./IconClose.vue"));
export const IconVerticalDots = defineAsyncComponent(
  () => import("./IconVerticalDots.vue")
);
export const IconArrowDown = defineAsyncComponent(
  () => import("./IconArrowDown.vue")
);
export const IconEdit = defineAsyncComponent(() => import("./IconEdit.vue"));
export const IconCross = defineAsyncComponent(() => import("./IconCross.vue"));
export const IconTick = defineAsyncComponent(() => import("./IconTick.vue"));
export const IconCalendarWithTickInside = defineAsyncComponent(
  () => import("./IconCalendarWithTickInside.vue")
);
export const IconWarningOctal = defineAsyncComponent(
  () => import("./IconWarningOctal.vue")
);
export const IconCheckRounded = defineAsyncComponent(
  () => import("./IconCheckRounded.vue")
);
export const IconSwap = defineAsyncComponent(() => import("./IconSwap.vue"));
export const IconArrowUp = defineAsyncComponent(
  () => import("./IconArrowUp.vue")
);
export const IconSortIdle = defineAsyncComponent(
  () => import("./IconSortIdle.vue")
);
