<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div
    class="border-2 border-[#f3f3f3] border-t-transparent rounded-full w-5 h-5 animate-spin border-current"
  ></div>
</template>

<script></script>

<style lang="scss">
// .Loader {
//   border: 2px solid #f3f3f3;
//   border-radius: 50%;
//   border-top: 1px solid transparent;
//   width: 20px;
//   height: 20px;
//   animation: spin 1s linear infinite;
// }

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
