<template>
  <div class="mt-[45px]">
    <h2 class="text-[17px] font-medium">API usage</h2>
    <p class="font-inter text-xs font-medium leading-[14.52px]">
      Here you can find the activities of your active Freddy APIs.
    </p>
    <div class="block mb-5 mt-5 gap-5 xl:flex 2xl:flex">
      <div
        class="border border-[#ffffff12] bg-[#071A2B] p-7 rounded-[40px] w-[100%] lg:w-[100%] xl:w-[40%] 2xl:w-[360px] h-[307px] relative mb-3 xl:mb-0 2xl:mb-0"
      >
        <span class="absolute" style="left: 50%; top: 50%">
          <Spinner class="CustomLoader" v-if="isLoading" />
        </span>
        <div v-if="!isLoading">
          <h2 class="text-lg font-medium">API Usage Cost</h2>
          <p
            class="font-inter text-xs leading-[14.52px] mb-8 text-[#FFFFFF66] mt-3"
          >
            Dec 1 - 31
          </p>
          <div class="flex gap-6">
            <div class="w-40">
              <CustomDoughnutChart
                class="chart"
                :chartData="chartData"
                :chosenMonth="chosenMonth"
                :innerTextFont="'14px'"
                :showCenterText="true"
              />
            </div>
            <div>
              <h2 class="text-2xl font-bold">CHF 5.50</h2>
              <p class="font-inter text-xs leading-[14.52px] mb-2 mt-2">
                / CHF {{ apiUsageLimit }}
              </p>
              <button
                class="flex px-1 rounded text-black bg-[var(--color-button-blue)] items-center gap-3 h-8 outline-none font-inter text-sm px-2 leading-[14.52px] whitespace-nowrap"
                @click="openIncrementModal"
              >
                Increase limit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="border border-[#ffffff12] rounded-[40px] bg-[#071A2B] p-7 w-[100%] lg:w-[100%] xl:w-[60%] 2xl:w-[75%] h-[307px] relative"
      >
        <span class="absolute" style="left: 50%; top: 50%">
          <Spinner class="CustomLoader" v-if="isLoading" />
        </span>
        <div v-if="!isLoading">
          <h2 class="text-lg font-medium">API Usage – Top 3</h2>
          <p
            class="font-inter text-xs leading-[14.52px] mb-5 text-[#FFFFFF66] mt-3"
          >
            Dec 1 - 31
          </p>
          <div class="line-chart flex gap-7">
            <div
              class="font-inter text-xs leading-[14.52px] text-[#94969C] mt-3 rotate-180 text-center"
              style="writing-mode: tb"
            >
              Api calls
            </div>
            <CustomLineChart
              :Data="parentChartData"
              :chartOptions="parentChartOptions"
              :showCenterText="false"
            />
          </div>
          <div
            class="font-inter text-xs leading-[14.52px] text-[#94969C] mt-3 text-center"
          >
            Days
          </div>
        </div>
      </div>
    </div>
    <div>
      <h2 class="text-[17px] font-medium">Freddy API Keys</h2>
      <p class="font-inter text-xs font-medium leading-[14.52px] mb-5">
        Do not share your API key with others, or expose it in the browser or
        other client-side code. In order to protect the security of your
        account.
      </p>
      <Table
        @showApiDetail="showApiDetailPage"
        @removeApiKey = "DeleteApiKey"
        :columns="[
          { key: 'Name', label: 'Name' },
          { key: 'Key', label: 'Key' },
          { key: 'LastUsed', label: 'Last Used' },
          { key: 'Permissions', label: 'Permissions' },
          { key: 'Status', label: 'Status' },
          { key: 'Action', label: '' },
        ]"
        :data="transformedData"
      >
      </Table>
      <Pagination v-if="listOfApiKeys.length"  class="pt-[14px] pb-[18px] bottom-0 relative" :current-page="currentPage" :itemsPerPage="take"
      :totalItems="totalItems" @update:current-page="handlePageChange"/>
      <button
        class="flex p-2 rounded-md text-black bg-[var(--color-button-blue)] items-center gap-3 h-8 mt-5 outline-none"
        @click="openKeyModal"
      >
        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.5 1V15M1.5 8H15.5"
            stroke="#000"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
        Create New Secret Key
      </button>
    </div>
  </div>
  <Modal
    :isVisible="isModalIncrement"
    @close="closeIncrementModal()"
    :largeModel="true"
  >
    <template v-slot:header>
      <div class="w-full bg-background rounded-2xl">
        <div class="flex justify-between items-center">
          <h4 class="text-[1.813rem] text-3xl text-white">Monthly Limit</h4>
          <span @click="closeIncrementModal" class="cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M8 24L24 8M8 8L24 24"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
      </div>
    </template>
    <template v-slot:body>
      <p class="text-[14px] font-medium text-[#CBD6E3] mt-2 mb-5">
        Manage your API spend by configuring monthly spend limits. Notification
        emails will be sent to members of your organization with the "Owner"
        role. Note that there may be a delay in enforcing limits, and you are
        still responsible for any overage incurred.
      </p>
      <h5 class="text-lg font-bold leading-[24.2px]">Set a monthly budget</h5>
      <p class="text-[14px] font-medium text-[#CBD6E3] mb-3">
        If your organisation exceeds this budget in a given calendar month,
        subsequent API requests will be rejected.
      </p>
      <div
        class="flex items-center border border-[#ffffff12] rounded-md overflow-hidden bg-[#071A2B]"
      >
        <input
          type="text"
          v-model="organizationApiUsageLimit.monthlyApiLimit"
          class="flex-1 px-3 py-2 text-sm text-white focus:outline-none bg-[#071A2B] border border-[#ffffff12] h-10 placeholder-[#CBD6E3]"
          placeholder="123456.00"
        />
        <span class="px-3 text-white text-sm">CHF</span>
      </div>
      <h5 class="text-lg font-bold leading-[24.2px] mt-3">
        Set an email notification threshold
      </h5>
      <p class="text-[14px] font-medium text-[#CBD6E3] mb-3">
        If your organisation exceeds this budget in a given calendar month,
        subsequent API requests will be rejected.
      </p>
      <div
        class="flex items-center border border-[#ffffff12] rounded-md overflow-hidden bg-[#071A2B]"
      >
        <input
          type="number"
          v-model="organizationApiUsageLimit.monthlyEmailThresholdLimit"
          class="flex-1 px-3 py-2 text-sm text-white focus:outline-none bg-[#071A2B] border border-[#ffffff12] h-10 placeholder-[#CBD6E3]"
          placeholder="123456.00"
        />
        <span class="px-3 text-white text-sm">CHF</span>
      </div>
      <div class="flex gap-5 justify-end">
        <button
          type="button"
          class="flex justify-center rounded text-[var(--color-button-blue)] border border-solid border-[var(--color-button-blue)] items-center w-[100px] h-10 mt-5 outline-none"
          @click="closeIncrementModal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="flex justify-center rounded text-black bg-[var(--color-button-blue)] items-center w-[100px] h-10 mt-5 outline-none"
          @click="increaseApiUsageLimit"
        >
          Save
        </button>
      </div>
    </template>
  </Modal>
  <Modal :isVisible="isModalKey" @close="closeKeyModal()" :largeModel="true">
    <template v-slot:header>
      <div class="w-full bg-background rounded-2xl">
        <div class="flex justify-between items-center">
          <h4 class="text-[1.813rem] text-3xl text-white">
            Create new secret key
          </h4>
          <span @click="closeKeyModal" class="cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M8 24L24 8M8 8L24 24"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
      </div>
    </template>
    <template v-slot:body>
      <p class="text-[14px] font-medium text-[#CBD6E3] mt-2 mb-5">
        This API key is linked to your company and can only be used once. You'll
        see it only once upon creation and it cannot be reused or used in
        multiple locations.
      </p>
      <label class="font-inter text-[14px] font-medium">Name*</label>
      <div
        class="flex items-center border border-[#ffffff12] rounded-md overflow-hidden bg-[#071A2B] mt-2 mb-4"
      >
        <input
          type="text"
          v-model="newApiKeyinfo.name"
          class="flex-1 px-3 py-2 text-sm text-white focus:outline-none bg-[#071A2B] border border-[#ffffff12] h-10 placeholder-[#CBD6E3]"
          placeholder="Secret Key Name"
          @focus="clearValidation"
        />
      </div>
      <div class="flex text-red-500 mb-2.5 items-start" v-if="keyError">
        <span class="mr-2.5">
          <i class="bi bi-exclamation-circle"></i>
        </span>
        <p class="text-sm error-message text-[0.813rem] font-medium mt-0.5">{{keyError}}</p>
      </div>

      <div class="flex flex-col space-y-2 mb-1">
        <label
          class="relative pl-10 cursor-pointer select-none font-inter text-[14px]"
        >
          Set a spending Limit per month
          <input
            type="checkbox"
            class="absolute opacity-0 cursor-pointer peer"
            v-model="isCheckboxChecked"
          />
          <span
            class="absolute top-0 left-0 w-6 h-6 bg-[#071A2B] border border-[#ffffff12] rounded peer-hover:bg-grey-400 peer-checked:bg-[var(--color-button-blue)] peer-checked:after:content-[''] peer-checked:after:absolute peer-checked:after:w-2 peer-checked:after:h-3 peer-checked:after:border-white peer-checked:after:border-r-2 peer-checked:after:border-b-2 peer-checked:after:transform peer-checked:after:rotate-45 peer-checked:after:top-[3px] peer-checked:after:left-[7px]"
          >
          </span>
        </label>
      </div>
      <div
        class="flex items-center border border-[#ffffff12] rounded-md overflow-hidden bg-[#071A2B] mt-3 mb-3"
        v-if="isCheckboxChecked"
      >
        <input
          type="text"
          v-model="newApiKeyinfo.limit"
          class="flex-1 px-3 py-2 text-sm text-white focus:outline-none bg-[#071A2B] border border-[#ffffff12] h-10 placeholder-[#CBD6E3]"
          placeholder="CHF 1000.00"
           @input="filterPositiveNumbers"
        />
      </div>
      <div class="flex mb-2.5 items-start" v-if="newApiKeyinfo.limit > 5">
        <p class="text-[#F1FF99] text-[0.75rem] font-normal">Note that the limit exceeds your total API limit</p>
      </div>
      <label class="font-inter text-[14px] font-medium">Permissions*</label>
      <RadioGroup
        name="tabs"
        :options="radioOptions"
        v-model="selectRadioPermission"
        :large="true"
      />
      <div class="flex gap-5 justify-end">
        <button
          type="button"
          class="flex justify-center text-[var(--color-button-blue)] border border-solid border-[var(--color-button-blue)] items-center w-[100px] h-10 mt-5 outline-none rounded-md"
          @click="closeKeyModal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="flex justify-center text-black bg-[var(--color-button-blue)] items-center w-[100px] h-10 mt-5 outline-none rounded-md"
          @click="createNewApiKey"
        >
          Create key
        </button>
      </div>
    </template>
  </Modal>
  <Modal
    :isVisible="isModalCreateKey"
    @close="closeCreateKeyModal()"
    :largeModel="true"
  >
    <template v-slot:header>
      <div class="w-full bg-background rounded-2xl">
        <div class="flex justify-between items-center">
          <h4 class="text-[1.813rem] text-3xl text-white">
            Create new secret key
          </h4>
          <span @click="closeCreateKeyModal" class="cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M8 24L24 8M8 8L24 24"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
      </div>
    </template>
    <template v-slot:body>
      <p class="text-[14px] font-medium text-[#CBD6E3] mt-2 mb-5">
        This API key is linked to your company and can only be used once. You'll
        see it only once upon creation and it cannot be reused or used in
        multiple locations.
      </p>
      <label class="font-inter text-[14px]">New key</label>
      <div
        class="flex items-center border border-[#ffffff12] rounded-md overflow-hidden bg-[#071A2B] mt-2 mb-4"
      >
        <input
          type="text"
          v-model="newApiKeyinfo.key"
          ref="inputBox"
          class="flex-1 px-3 py-2 text-sm text-white focus:outline-none bg-[#071A2B] border border-[#ffffff12] h-10 placeholder-[#CBD6E3]"
          placeholder="Secret Key Name"
        />
        <span
          class="px-3 text-white text-sm flex justify-between items-center gap-2"
          @click="copyToClipboard"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.1665 12.5C3.38993 12.5 3.00165 12.5 2.69536 12.3731C2.28698 12.204 1.96253 11.8795 1.79337 11.4711C1.6665 11.1648 1.6665 10.7766 1.6665 9.99999V4.33332C1.6665 3.3999 1.6665 2.93319 1.84816 2.57667C2.00795 2.26307 2.26292 2.0081 2.57652 1.84831C2.93304 1.66666 3.39975 1.66666 4.33317 1.66666H9.99984C10.7764 1.66666 11.1647 1.66666 11.471 1.79352C11.8794 1.96268 12.2038 2.28714 12.373 2.69552C12.4998 3.0018 12.4998 3.39009 12.4998 4.16666M10.1665 18.3333H15.6665C16.5999 18.3333 17.0666 18.3333 17.4232 18.1517C17.7368 17.9919 17.9917 17.7369 18.1515 17.4233C18.3332 17.0668 18.3332 16.6001 18.3332 15.6667V10.1667C18.3332 9.23324 18.3332 8.76653 18.1515 8.41001C17.9917 8.0964 17.7368 7.84143 17.4232 7.68165C17.0666 7.49999 16.5999 7.49999 15.6665 7.49999H10.1665C9.23308 7.49999 8.76637 7.49999 8.40985 7.68165C8.09625 7.84143 7.84128 8.0964 7.68149 8.41001C7.49984 8.76653 7.49984 9.23324 7.49984 10.1667V15.6667C7.49984 16.6001 7.49984 17.0668 7.68149 17.4233C7.84128 17.7369 8.09625 17.9919 8.40985 18.1517C8.76637 18.3333 9.23308 18.3333 10.1665 18.3333Z"
              stroke="#CBD6E3"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Copy
        </span>
      </div>
      <div class="flex gap-5 justify-end">
        <button
          type="button"
          class="flex justify-center rounded text-[var(--color-button-blue)] border border-solid border-[var(--color-button-blue)] items-center w-[100px] h-10 mt-5 outline-none"
          @click="closeCreateKeyModal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="flex justify-center rounded text-black bg-[var(--color-button-blue)] items-center w-[100px] h-10 mt-5 outline-none"
          @click="closeCreateKeyModal"
        >
          Create key
        </button>
      </div>
    </template>
  </Modal>
</template>
<script setup lang="ts">
import CustomDoughnutChart from "@/components/common/CustomDoughnutChart.vue";
import Table from "@/components/common/ApiTableComponent.vue";
import type { IChartData } from "@/Models/charts";
import type { IInvoice } from "@/Models/IUsage";
import { HubService } from "@/Services/HubService";
import Modal from "../common/CustomModelBox.vue";
import { onMounted, ref, watch,defineEmits } from "vue";
import RadioGroup from "../common/RadioGroup.vue";
import CustomLineChart from "../common/CustomLineChart.vue";
import type { ChartData } from "chart.js";
import Spinner from "@/components/common/Spinner.vue";
import { ApiKeyManagementService } from "@/Services/ApiKeyManagementService";
import type { IApiKeyInfo, IOrganizationApiUsageLimit } from "@/Models/IApiKeyManagement";
import { formatDate } from "@/helpers";
import Pagination from "../common/Pagination.vue";

// State
const isLoading = ref<boolean>(true);
const usage = ref<number>();
const limit = ref<number>();
const startDate = ref<string>();
const endDate = ref<string>();
const listInvoices = ref<IInvoice[]>([]);
const usagePercentage = ref<number>();
const apiUsageLimit = ref<number>(0);
const highChartData = ref([]);
const isModalIncrement = ref(false);
const isModalKey = ref(false);
const isModalCreateKey = ref(false);
const inputValue = ref("");
const keyError = ref("");
const selectRadioPermission = ref("all");
const isCheckboxChecked = ref(false);
const listOfApiKeys = ref<IApiKeyInfo[]>([]);
const newApiKeyinfo = ref<IApiKeyInfo>({
  id: 0, key: "", name: "",limit: 0, isDeleted: false, isActive: true, hasFullAccess: true, lastUsed: new Date() ,
});
const organizationApiUsageLimit = ref<IOrganizationApiUsageLimit>({
  monthlyApiLimit: 0, monthlyEmailThresholdLimit: 0
});

const skip = ref(0);
const take = 5;
const currentPage = ref(1);
const totalItems = ref<number>(0);
const hubService = new HubService();
const apikeyService = new ApiKeyManagementService();
const transformedData = ref();
// Props
const props = defineProps({
  chosenMonth: {
    type: Number,
    required: false,
    default: 0
  }
});

// Emits
const emit = defineEmits(["showApiDetail"]);

// Functions
const radioOptions = [
  { label: "All", value: "all" },
  { label: "Restricted", value: "restricted" },
  { label: "Read only", value: "read" },
];

const clearValidation = () => {
  keyError.value = '';
}

const parentChartData: ChartData<"line", (number | null)[], string> = {
  labels: Array.from({ length: 31 }, (_, i) => (i + 1).toString()), // Days (1-31)
  datasets: [
    {
      label: "API Name 1",
      data: [
        50, 55, 53, 60, 65, 63, 70, 75, 80, 78, 85, 90, 88, 85, 87, 86, 84, 82,
        81, 80, 78, 77, 76, 75, 74, 72, 71, 70, 69, 68, 67,
      ],
      borderColor: "#FEF7C3",
      backgroundColor: "#FEF7C3",
      borderWidth: 2,
      pointRadius: 0, // Show points
      pointHoverRadius: 6, // Make hover points larger
      pointBackgroundColor: "#FEF7C3", // Match border color
      tension: 0.2, // Slight curve for smoothness
    },
    {
      label: "API Name 2",
      data: [
        40, 45, 42, 48, 52, 50, 55, 57, 59, 62, 63, 65, 68, 67, 66, 65, 64, 63,
        62, 61, 60, 58, 57, 56, 55, 54, 53, 52, 51, 50, 49,
      ],
      borderColor: "#FAC515",
      backgroundColor: "#FAC515",
      borderWidth: 2,
      pointRadius: 0,
      pointHoverRadius: 6,
      pointBackgroundColor: "#FAC515",
      tension: 0.2,
    },
    {
      label: "API Name 3",
      data: [
        15, 20, 18, 25, 22, 21, 23, 25, 28, 27, 30, 32, 31, 30, 29, 28, 27, 26,
        25, 24, 23, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13,
      ],
      borderColor: "#A15C07",
      backgroundColor: "#A15C07",
      borderWidth: 2,
      pointRadius: 0,
      pointHoverRadius: 6,
      pointBackgroundColor: "#A15C07",
      tension: 0.2,
    },
  ],
};

const parentChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top",
      labels: {
        color: "#000", // Legend text color
        font: {
          size: 12, // Legend font size
        },
      },
    },
  },
};

const chartData = ref<IChartData>({
  labels: [],
  datasets: [
    {
      data: [0, 0],
      backgroundColor: [],
      hoverOffset: 0,
      borderWidth: 0,
    },
  ],
});

const allWorking = ref(false); //* Might move it later

const setChartData = () => {
  if (usage.value && limit.value) {
    chartData.value = {
      labels: ["Synapsis", "Neurons", ""],
      datasets: [
        // "#A6EF67" Color green
        {
          data: [usage.value, limit.value - usage.value],
          backgroundColor: ["#FEF7C3", "#FFFFFF17"],
          hoverOffset: 0,
          borderWidth: 0,
        },
      ],
    };
  }
};

const handlePageChange = (page: number) => {
  currentPage.value = page;
  skip.value = (currentPage.value - 1) * take;
  getAllKeys();
};

const createNewApiKey = async () => {
if(newApiKeyinfo.value.name === ""){
  keyError.value = 'Enter a secret key name';
  return;
}
const response = await apikeyService.CreateNewApiKey(newApiKeyinfo.value)
isModalCreateKey.value = true;
newApiKeyinfo.value.key = response
}
const DeleteApiKey = async(index:number) => {
const response = await apikeyService.RemoveApiKey(listOfApiKeys.value[index].id);
if(!response){
  console.warn("Api key Deletion Failed");
  return;
}
console.log("Api Key Deleted Successfully");
getAllKeys();
};

const getApiUsageLimit = async () => {
  const response = await apikeyService.GetApiUsageLimit();
  apiUsageLimit.value = response;
}

const filterPositiveNumbers = (event:any) => {
      const input = event.target.value.replace(/[^0-9]/g, "");
      newApiKeyinfo.value.limit = input;
};

const increaseApiUsageLimit = async() => {
  const response = await apikeyService.IncreaseApiLimit(organizationApiUsageLimit.value);
  await getApiUsageLimit();
  isModalIncrement.value = false;
}

watch(
  () => 11,
  async () => {
    await setupBaseData();
  }
);

// Fetching usage data on component mount
onMounted(async () => {
  await setupBaseData();
  await getAllKeys();
  await getApiUsageLimit();
  organizationApiUsageLimit.value = {
  monthlyApiLimit: apiUsageLimit.value, monthlyEmailThresholdLimit: 0
};
});

const getAllKeys = async () => {
const response = await apikeyService.GetAllApiKeys(skip.value,take);
listOfApiKeys.value = response.data;
totalItems.value = response.totalCount;
transformedData.value =  listOfApiKeys.value.map((item) => ({
    Name: item.name,
    Key: item.key,
    LastUsed: formatDate(item.lastUsed),
    Permissions: item.hasFullAccess? "All" : "",
    Status: item.isActive && !item.isDeleted ? "Active" : item.isDeleted ? "Deactivated" : "Inactive",
  }))
};

const setupBaseData = async () => {
  isLoading.value = true;
  allWorking.value = false;
  await hubService
    .getUsageById(11)
    .then(async (usageData) => {
      allWorking.value = true;

      startDate.value = usageData.startDate;
      endDate.value = usageData.endDate;
      usage.value = usageData.usage;
      limit.value = usageData.limit;
      usagePercentage.value = usageData.usagePercentage;
      listInvoices.value = await hubService.getAllInvoices();

      setChartData();
    })
    .catch(() => {
      allWorking.value = false;
    });

  const res = await hubService.getUsageChartData(11);

  const compressedData = res.map((item: any) => ({
    [item.day]: parseFloat(item.cost.toFixed(2)),
  }));
  highChartData.value = compressedData;

  isLoading.value = false;
};



const openIncrementModal = () => {
  isModalIncrement.value = true;
};

const closeIncrementModal = () => {
  isModalIncrement.value = false;
};
const openKeyModal = () => {
  isModalKey.value = true;
};

const closeKeyModal = () => {
  isModalKey.value = false;
};

const openCreateKeyModal = () => {
  isModalCreateKey.value = true;
};

const closeCreateKeyModal = () => {
  isModalCreateKey.value = false;
  isModalKey.value = false;
};

const copyToClipboard = () => {
  navigator.clipboard
    .writeText(inputValue.value)
    .then(() => {})
    .catch((err) => {
      console.error("Failed to copy: ", err);
    });
};

const showApiDetailPage = (id: any) => {
  emit("showApiDetail", listOfApiKeys.value[id],organizationApiUsageLimit.value.monthlyApiLimit);
};
</script>
<style scoped>
.bi-exclamation-circle {
  color: rgb(177, 168, 168);
}
.bi-exclamation-circle {
  color: red;
}
.bi-exclamation-circle::before{
  width: 16px;
  height: 16px;
}
</style>
