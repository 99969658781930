<template>
  <HubApiList
    v-if="!isShowDetailPage"
    @showApiDetail="showApiDetailPage"
  />
  <HubApiDetailView
    v-if="isShowDetailPage"
    :chosenMonth="currentMonth"
    :keyDetails = "selectedKey"
    :apiLimit = "apiLimit"
    @back="handleBackButtonClick"
  />
</template>
<script setup lang="ts">
import HubApiList from "./HubApiList.vue";
import HubApiDetailView from "./HubApiDetail.vue";
import { ref } from "vue";
import type { IApiKeyInfo } from "@/Models/IApiKeyManagement";

const isShowDetailPage = ref(false);
const selectedKey = ref<IApiKeyInfo>();
const apiLimit = ref(0);
const currentMonth = ref(new Date().getMonth() + 1);
const showApiDetailPage = (key: IApiKeyInfo,limit:number) => {
  isShowDetailPage.value = !isShowDetailPage.value;
  selectedKey.value = key;
  apiLimit.value = limit;
};

const handleBackButtonClick = () => {
  isShowDetailPage.value = false;
};
</script>
<style lang="scss" scoped></style>
