<template>
  <div v-if="!isConnectionViewOpened" class="connectors-container h-full">
    <div class="head">
      <div class="text mb-4">
        <div class="text set mt-3 flex align-middle justify-between">
          <span class="heading6 font-bold text-2xl mb-3"
            >Knowledge Connections</span
          >
          <nav class="connector-nav flex">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/4b5cfcaeb3b77a457f87e98ca3fae0577d21ed22eff718622d6218c5d1d3517c?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51"
              alt=""
              class="connector-icon w-4 mr-1"
            />
            <button
              class="request-connector-btn whitespace-nowrap text-sm text-[var(--color-button-blue)]"
              @click="requestNewConnector"
            >
              Request New Connector
            </button>
          </nav>
        </div>
        <span class="text-body text-xs"
          >Here you can connect all your knowledge sources you want to the
          selected assistant to have access to.</span
        >
      </div>
      <div class="flex justify-between mb-7">
        <div class="connectors-container head actions action">
          <ActionDropdown
            v-if="listOfAssistant.length"
            width="220px"
            height="44px"
            :options="listOfAssistant"
            @onItemSelected="onAssistantSelect"
          />
        </div>
        <div
          class="action w-2/5 border rounded-lg border-[var(--grey-border-line)] bg-[var(--color-background-sidebar)] flex"
        >
          <div class="search relative h-full pl-4 flex items-center gap-[10px]">
            <IconLense />
            <input
              type="search"
              name="connectorsearch"
              placeholder="Search"
              v-model="searchText"
              class="input w-full h-full bg-[var(--color-background-sidebar)] focus:outline-none"
              @input="searchConnectors"
              @keyup.enter="searchConnectors"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
      <div class="empty-item" v-if="isLoadingSourceList">
        <Loader :isLoading="isLoadingSourceList" />
      </div>
      <div
        v-else-if="selectedConnectorsList.length"
        class="bg-[var(--color-background-sidebar)] border border-[var(--grey-border-line)] rounded-xl"
        v-for="(item, index) in selectedConnectorsList"
      >
        <div class="">
          <div class="head p-4">
            <div class="title flex gap-3 justify-start items-center">
              <component
                :is="
                  connectorIconList.filter((m) => m.title == item.name)[0]
                    .component
                "
              >
              </component>
              <div class="">{{ item.name }}</div>
            </div>
            <div class="discription mt-2">
              <span>{{ item.description }}</span>
            </div>
          </div>
          <div class="actions-buttons">
            <div
              v-if="isAlreadyConnected(item.id)"
              class="actions-buttons disconnect flex justify-between items-center border-t border-[var(--grey-border-line)] px-4 py-2"
            >
              <div
                class="text-body action-disconnect"
                @click="disconnectConnectorModalOpen(index)"
              >
                <span
                  class="text-body action-disconnect text-[var(--color-danger)] cursor-pointer"
                  >Disconnect</span
                >
              </div>
              <div class="btn btn-primary" @click="ConnectAction(index)">
                <span>Edit</span>
              </div>
            </div>
            <div
              v-else
              class="actions-buttons disconnect flex justify-end items-center border-t border-[var(--grey-border-line)] px-4 py-2"
            >
              <div
                class="connect btn btn-secondary"
                @click="ConnectAction(index)"
              >
                <!--v-if="!item.isConnected"-->
                <span class="">Connect</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="empty-item">Nothing found</div>
      <div
        class="alert-wrapper fixed z-[9998] top-0 left-0 w-full h-full bg-modalBackgroundBlur flex justify-center items-center"
        v-if="showAlertMessage"
      >
        <ConnectMessageAlert :message="connectMessage" />
      </div>
      <div
        class="new-request-connector-model fixed z-[9998] top-0 left-0 w-full h-full bg-modalBackgroundBlur flex justify-center items-center"
        v-if="isNewRequestModelVisible"
      >
        <NewConnectorRequest @closeView="requestNewConnectorClose" />
      </div>
      <div
        class="new-request-connector-model fixed z-[9998] top-0 left-0 w-full h-full bg-modalBackgroundBlur flex justify-center items-center"
        v-if="isDisconnectConnectorModelVisible"
      >
        <DisconnectConnectorModal
          :selectedAssistantId="selectedAssistantId"
          :selectedProviderId="selectedProviderId"
          @closeView="disconnectConnectorClose"
        />
      </div>
    </div>
  </div>
  <div v-if="isConnectionViewOpened" class="connectors-container h-full">
    <SourceConnectorLayout
      :isBodyScrollEnable="
        !isAuthenticatedOrganisationLink || !isAuthenticatedCredential
      "
    >
      <template #header>
        <img
          loading="lazy"
          src="@/assets/Arrow_left28.svg"
          alt="Confluence logo"
          class="icon cursor-pointer"
          @click="goBack"
        />
        <span class="heading5 text-2xl font-bold">Source selection</span>
      </template>
      <template #body>
        <OrganizationLink
          v-if="!isAuthenticatedOrganisationLink"
          :sourceTitle="
            connectorIconList.filter((m) => m.title == selectedSourceTitle)[0]
              .title
          "
          :sourceLogoComponent="
            connectorIconList.filter((m) => m.title == selectedSourceTitle)[0]
              .component
          "
          :selectedConnector="
            connectorIconList.filter((m) => m.title == selectedSourceTitle)[0]
              .title
          "
          @onContinueLink="continueLink"
        />
        <SourceCredential
          v-else-if="
            isAuthenticatedOrganisationLink && !isAuthenticatedCredential
          "
          :sourceTitle="
            connectorIconList.filter((m) => m.title == selectedSourceTitle)[0]
              .title
          "
          :sourceLogoComponent="
            connectorIconList.filter((m) => m.title == selectedSourceTitle)[0]
              .component
          "
          :selectedConnector="
            connectorIconList.filter((m) => m.title == selectedSourceTitle)[0]
              .title
          "
          @onLogin="onLogin"
        />
        <HubConnectorSourceSelector
          v-else
          @ConnectorSourceViewClosed="CloseSourceConnectorView"
          @spaceKeysSelected="spaceKeysSelected"
          @vectorStoreIdsSelected="vectorStoreIdsSelected"
          :sourceLink="organisationLink"
          :username="userName"
          :sourceTitle="
            connectorIconList.filter((m) => m.title == selectedSourceTitle)[0]
              .title
          "
          :password="password"
        />
      </template>
      <template #footer>
        <StepIndicator :completedSteps="stepStatus" />
        <div
          class="connect-btn"
          :class="{ btn_disable: !selectedVectorStoreIds.length }"
          v-if="isAuthenticatedOrganisationLink && isAuthenticatedCredential"
          @click="connectSource"
        >
          <span v-if="!connecting">Connect</span>
          <span v-else>
            <Loader :isLoading="connecting" />
          </span>
        </div>
      </template>
    </SourceConnectorLayout>
  </div>
</template>

<script setup lang="ts">
import { ref, markRaw, onMounted, computed } from "vue";
import type { IConnector, IConnectorIcon } from "@/Models/IConnector";
import type { IAssistant } from "@/Models/IAssistant";
import type { IComponentMap } from "@/Models/IComponentMap";
import type { IFileProvider } from "@/Models/IFileProvider";
import type { IAssistantFileProvider } from "@/Models/IAssistantFileProvider";

import { HubService } from "@/Services/HubService";

import IconChevronDown from "@/components/icons/IconChevronDown.vue";
import IconRobotScreen from "@/components/icons/IconRobotScreen.vue";
import IconLense from "@/components/icons/IconLense.vue";
import Loader from "@/components/Loader.vue";

import IconConnectorLinear from "@/components/icons/IconConnectorLinear.vue";
import IconConnectorGitHub from "@/components/icons/IconConnectorGitHub.vue";
import IconConnectorFigma from "@/components/icons/IconConnectorFigma.vue";
import IconConnectorZapier from "@/components/icons/IconConnectorZapier.vue";
import IconConnectorNotion from "@/components/icons/IconConnectorNotion.vue";
import IconConnectorSlack from "@/components/icons/IconConnectorSlack.vue";
import IconConnectorConfluence from "@/components/icons/IconConnectorConfluence.vue";
import IconConnectorJira from "@/components/icons/IconConnectorJira.vue";

import HubConnectionConfluenceAuthentication from "../connectors/Confluence/HubConnectorConfluenceAuthentication.vue";
import ConnectMessageAlert from "../connectors/ConnectMessageAlert.vue";
import SourceConnectorLayout from "./SourceConnectorLayout.vue";
import HubConnectorJiraAuthentication from "./Jira/HubConnectorJiraAuthentication.vue";
import StepIndicator from "./StepIndicator.vue";

//---> Auth vue
import HubConnectorSourceSelector from "../connectors/Confluence/HubConnectorSourceSelector.vue";
import OrganizationLink from "./Authentication/OrganizationLink.vue";
import SourceCredential from "./Authentication/SourceCredential.vue";
import NewConnectorRequest from "./NewConnectorRequest.vue";
import DisconnectConnectorModal from "./DisconnectConnectorModal.vue";

//import HubConnectorSourceSelector from './HubConnectorSourceSelector.vue';
import ActionDropdown from "@/components/common/ActionDropdown.vue";
import IconConnectorFreshDesk from "@/components/icons/IconConnectorFreshDesk.vue";

const hubService = new HubService();

const selectedAssistantId = ref<number>(0);
const searchText = ref<String>("");
const isConnectionViewOpened = ref<boolean>(false);
const listOfAssistant = ref<IAssistant[]>([]);
const selectedSourceTitle = ref<string>("");
const selectedProviderId = ref<number>(0);
const isLoadingSourceList = ref<boolean>(false);
const showAlertMessage = ref<boolean>(false);
const connecting = ref<boolean>(false);
const isNewRequestModelVisible = ref<boolean>(false);
const isDisconnectConnectorModelVisible = ref<boolean>(false);

//---> Auth vue
const organisationLink = ref<string>("");
const userName = ref<string>("");
const password = ref<string>("");

const isConnected = ref<boolean>(false);

const selectedSpaceKeys = ref<string[]>([]);
const selectedVectorStoreIds = ref<number[]>([]);

const isAuthenticatedOrganisationLink = ref<boolean>(false);
const isAuthenticatedCredential = ref<boolean>(false);

const isPasswordVisible = ref<boolean>(false);
const connectMessage = ref<string>("");

const emits = defineEmits(["closeThisView", "sideMenuBlur"]);

const connectorIconList = ref<IComponentMap[]>([
  {
    title: "Linear",
    component: markRaw(IconConnectorLinear),
    mainComponent: markRaw(HubConnectionConfluenceAuthentication),
    show: true,
  },
  {
    title: "Slack",
    component: markRaw(IconConnectorSlack),
    mainComponent: markRaw(HubConnectionConfluenceAuthentication),
    show: true,
  },
  {
    title: "GitHub",
    component: markRaw(IconConnectorGitHub),
    mainComponent: markRaw(HubConnectionConfluenceAuthentication),
    show: true,
  },
  {
    title: "Notion",
    component: markRaw(IconConnectorNotion),
    mainComponent: markRaw(HubConnectionConfluenceAuthentication),
    show: true,
  },
  {
    title: "Zapier",
    component: markRaw(IconConnectorZapier),
    mainComponent: markRaw(HubConnectionConfluenceAuthentication),
    show: true,
  },
  {
    title: "Figma",
    component: markRaw(IconConnectorFigma),
    mainComponent: markRaw(HubConnectionConfluenceAuthentication),
    show: true,
  },
  {
    title: "Confluence",
    component: markRaw(IconConnectorConfluence),
    mainComponent: markRaw(HubConnectionConfluenceAuthentication),
    show: true,
  },
  {
    title: "Jira",
    component: markRaw(IconConnectorJira),
    mainComponent: markRaw(HubConnectionConfluenceAuthentication),
    show: true,
  },
  {
    title: "FreshDesk",
    component: markRaw(IconConnectorFreshDesk),
    mainComponent: markRaw(HubConnectionConfluenceAuthentication),
    show: true,
  },
]);
const selectedConnectorsList = ref<IFileProvider[]>([]);
const connectorsList = ref<IFileProvider[]>([]);
const listOfAssistantFileProvider = ref<IAssistantFileProvider[]>([]);

const spaceKeysSelected = (spaceKeys: string[]) => {
  selectedSpaceKeys.value = spaceKeys;
};

const vectorStoreIdsSelected = (vectorStoreIds: number[]) => {
  selectedVectorStoreIds.value = vectorStoreIds;
};

const connectSource = async () => {
  connecting.value = true;
  isConnected.value = await hubService.connectSource(
    selectedVectorStoreIds.value,
    selectedSpaceKeys.value,
    organisationLink.value,
    userName.value,
    password.value,
    connectorIconList.value
      .filter((m) => m.title == selectedSourceTitle.value)[0]
      .title.toLocaleLowerCase()
  );
  setTimeout(async () => {
    connecting.value = false;
    connectMessage.value = isConnected.value
      ? `Your knowledge has been connected successfully, we'll notify you once the upload is complete.`
      : `Something went wrong. Please try again.`;
    closeThisView(isConnected.value);
  }, 3000);

  clearSelections();
};

const stepStatus = computed(() => {
  const isNotion =
    connectorIconList.value.filter(
      (m) => m.title == selectedSourceTitle.value
    )[0]?.title === "Notion";

  if (isNotion) {
    // If it's "Notion," only two steps are needed
    return [
      !isAuthenticatedOrganisationLink.value ? "pending" : "success",
      isAuthenticatedOrganisationLink.value
        ? isAuthenticatedCredential.value
          ? "pending"
          : ""
        : "",
    ];
  } else {
    // For other sources, the original three-step logic applies
    return [
      !isAuthenticatedOrganisationLink.value ? "pending" : "success",
      isAuthenticatedOrganisationLink.value
        ? !isAuthenticatedCredential.value
          ? "pending"
          : "success"
        : "",
      isAuthenticatedOrganisationLink.value && isAuthenticatedCredential.value
        ? "pending"
        : "",
    ];
  }
});

const onAssistantSelect = (selectedId: number) => {
  selectedAssistantId.value = selectedId;
  //--> ToDo:- On assistnt select from dropdown
};

const isAlreadyConnected = (selectedId: number): boolean => {
  const connection = listOfAssistantFileProvider.value.find(
    (x) => x.assistantId === selectedAssistantId.value
  );
  if (connection) {
    return connection.fileProviderIds?.includes(selectedId);
  }
  return false;
};

const searchConnectors = () => {
  if (searchText.value.trim() === "") {
    selectedConnectorsList.value = connectorsList.value;
    return;
  }
  selectedConnectorsList.value = connectorsList.value.filter(
    (m) =>
      m.name.toLowerCase() == searchText.value.toLowerCase() ||
      m.name.toLowerCase().includes(searchText.value.toLowerCase())
  );
};

const disconnectAction = (index: number) => {
  connectorsList.value[index].name;
};

const disconnectCancelAction = (index: number) => {};

const ConnectAction = (index: number) => {
  selectedSourceTitle.value = selectedConnectorsList.value[index].name;
  isConnectionViewOpened.value = true;
};

const onAuthViewClose = () => {
  isConnectionViewOpened.value = false;
};

const requestNewConnector = () => {
  isNewRequestModelVisible.value = true;
  //-->To adjust the left box z-index
  const leftBox = document.querySelector<HTMLElement>(".box.left");
  if (leftBox) {
    leftBox.style.zIndex = "1";
  }
};

const requestNewConnectorClose = () => {
  isNewRequestModelVisible.value = false;
  //-->To adjust the left box z-index
  const leftBox = document.querySelector<HTMLElement>(".box.left");
  if (leftBox) {
    leftBox.style.zIndex = "10";
  }
};

const disconnectConnectorModalOpen = (index: number) => {
  selectedProviderId.value = selectedConnectorsList.value[index].id;
  isDisconnectConnectorModelVisible.value = true;
  //-->To adjust the left box z-index
  const leftBox = document.querySelector<HTMLElement>(".box.left");
  if (leftBox) {
    leftBox.style.zIndex = "1";
  }
};

const disconnectConnectorClose = async (message: string, status: boolean) => {
  isDisconnectConnectorModelVisible.value = false;

  if (!message) {
    const leftBox = document.querySelector<HTMLElement>(".box.left");
    if (leftBox) {
      leftBox.style.zIndex = "10";
    }

    return;
  }

  connectMessage.value = message;

  if (status) {
    //listOfAssistantFileProvider.value = await hubService.GetAssistantsFileProviders();
    listOfAssistantFileProvider.value = listOfAssistantFileProvider.value.map(
      (item) => {
        if (item.assistantId === selectedAssistantId.value) {
          return {
            ...item,
            fileProviderIds: item.fileProviderIds.filter(
              (fp) => fp != selectedProviderId.value
            ),
          };
        }
        return item;
      }
    );
  }

  closeThisView(true);
  setTimeout(() => {
    const leftBox = document.querySelector<HTMLElement>(".box.left");
    if (leftBox) {
      leftBox.style.zIndex = "10";
    }
  }, 5000);
};

const goBack = () => {
  if (
    isAuthenticatedOrganisationLink.value &&
    isAuthenticatedCredential.value &&
    !(
      connectorIconList.value.filter(
        (m) => m.title == selectedSourceTitle.value
      )[0].title == "Notion"
    )
  ) {
    isAuthenticatedCredential.value = false;
  } else if (
    isAuthenticatedOrganisationLink.value &&
    !isAuthenticatedCredential.value
  ) {
    isAuthenticatedOrganisationLink.value = false;
  } else if (
    isAuthenticatedOrganisationLink.value &&
    isAuthenticatedCredential.value &&
    connectorIconList.value.filter(
      (m) => m.title == selectedSourceTitle.value
    )[0].title == "Notion"
  ) {
    isAuthenticatedOrganisationLink.value = false;
    isAuthenticatedCredential.value = false;
  } else {
    isConnectionViewOpened.value = false;
  }
  clearSelections();
};

const clearSelections = () => {
  selectedVectorStoreIds.value = [];
  selectedSpaceKeys.value = [];
};

//---> Auth vue

const continueLink = (link: string) => {
  organisationLink.value = link;
  isAuthenticatedOrganisationLink.value = true;
  if (
    connectorIconList.value.filter(
      (m) => m.title == selectedSourceTitle.value
    )[0].title == "Notion"
  )
    isAuthenticatedCredential.value = true;
  console.log(organisationLink.value);
};

const onLogin = (inputUserName: string, inputPassword: string) => {
  //-->ToDo:- Authenticate credential.
  isAuthenticatedCredential.value = true;
  userName.value = inputUserName;
  password.value = inputPassword;
  console.log(userName.value, password.value);
};

const closeThisView = (isConnected: boolean) => {
  isAuthenticatedOrganisationLink.value = false;
  isAuthenticatedCredential.value = false;
  isConnectionViewOpened.value = false;
  showAlertMessage.value = true;
  setTimeout(() => {
    showAlertMessage.value = false;
  }, 5000);
};

const closeAuthCredentialView = () => {
  isAuthenticatedCredential.value = false;
  isAuthenticatedOrganisationLink.value = false;
};

const CloseSourceConnectorView = () => {
  isAuthenticatedCredential.value = false;
};

const showPassword = () => {
  isPasswordVisible.value = !isPasswordVisible.value;
};

onMounted(async () => {
  isLoadingSourceList.value = true;
  organisationLink.value = "";
  userName.value = "";
  password.value = "";
  connectorsList.value = await hubService.GetAllFileProviders();
  listOfAssistantFileProvider.value =
    await hubService.GetAssistantsFileProviders();
  listOfAssistant.value = listOfAssistantFileProvider.value.map(
    (ap) =>
      ({
        id: ap.assistantId,
        name: ap.assistantName,
      } as IAssistant)
  );
  selectedAssistantId.value = listOfAssistant.value[0].id; //---> Initially select first assistantId;
  selectedConnectorsList.value = connectorsList.value;
  isLoadingSourceList.value = false;
});
</script>
<style>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  /* translucent background */
  z-index: 1000;
}
</style>
