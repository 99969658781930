<template>
    <div>
        <input type="range" :min="min" :max="max" :step="step" :value="value" @input="onInput" :style="{ backgroundSize }"
               class="test" :disabled="isDisabled" />
        <!-- <div class="data">Slider value: {{ value }}/{{ max }}</div> -->
    </div>
</template>

<script>
    export default {
        props: {
            min: { type: Number, default: 0 },
            max: { type: Number, default: 100 },
            step: { type: Number, default: 1 },
            modelValue: { type: Number, default: 20 },
            isDisabled: { type: Boolean, default: false }
        },
        data() {
            return {
                value: this.modelValue,
                backgroundSize: `${((this.modelValue - this.min) * 100) / (this.max - this.min)}% 100%`
            };
        },
        watch: {
            modelValue(newValue) {
                this.value = newValue;
                this.updateBackgroundSize(newValue);
            }
        },
        methods: {
            onInput(event) {
                const newValue = Number(event.target.value);
                this.value = newValue;
                this.$emit("update:modelValue", newValue);
                this.updateBackgroundSize(newValue);
            },
            updateBackgroundSize(value) {
                this.backgroundSize = `${((value - this.min) * 100) / (this.max - this.min)}% 100%`;
            }
        },
        mounted() {
            this.updateBackgroundSize(this.value);
        }
    };
</script>

<style scoped>
    .data {
        text-align: center;
        margin-top: 24px;
    }


    input[type="range"] {
        -webkit-appearance: none;
        width: 100%;
        margin: 16px 0;
        background: #3e3e3f;
        background-image: linear-gradient(to right, #7BA8EF 0%, #7BA8EF 100%);
        background-repeat: no-repeat;
    }

        input[type="range"]:focus {
            outline: none !important;
            box-shadow: none;
        }

        input[type="range"]::-webkit-slider-runnable-track {
            height: 8px;
            background: transparent;
        }

        input[type="range"]::-webkit-slider-thumb {
            border: 4px solid #ADD8E6;
            height: 25px;
            width: 25px;
            border-radius: 50%;
            background: #FFFFFF;
            cursor: pointer;
            -webkit-appearance: none;
            margin-top: -6px;
        }

        input[type="range"]::-moz-range-thumb {
            border: 4px solid #ADD8E6;
            height: 16px;
            width: 16px;
            background: #ffffff;
            cursor: pointer;
        }

        input[type="range"]::-ms-thumb {
            border: 4px solid #ADD8E6;
            height: 16px;
            width: 16px;
            background: #ffffff;
            cursor: pointer;
        }

    input[type=range]:focus::-webkit-slider-runnable-track {
        background: transparent;
    }
</style>
