<template>
    <nav v-if="isVisible" class="fixed inset-0 z-50 flex overflow-hidden flex-col text-lg font-medium rounded-xl bg-slate-900 h-fit w-[250px] max-h[240px] max-w-[400px] shadow-box-shadow-white" aria-label="Download options"
         :style="{ top: `${top}px`, left: `${left - 250}px` }">
        <ul class="flex flex-col w-full">
            <li class="flex flex-col  w-full">
                <button class="flex items-center gap-3 px-4 w-full min-h-[44px] hover:bg-slate-800" @click="handlePdfDownload" tabindex="0">
                    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/d68e0b508cb674652e50eeb888851b903292815d4d698a7ced624352c317d75a?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51"
                         alt="PDF file icon" class="object-contain shrink-0 my-auto w-5 aspect-square" />
                    <span class="flex-1 shrink basis-0 text-ellipsis">Download PDF</span>
                </button>
            </li>
            <li class="flex flex-col w-full">
                <button class="flex items-center gap-3 px-4 w-full min-h-[44px] hover:bg-slate-800" @click="handleCsvDownload" tabindex="0">
                    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/d68e0b508cb674652e50eeb888851b903292815d4d698a7ced624352c317d75a?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51"
                         alt="CSV file icon" class="object-contain shrink-0 my-auto w-5 aspect-square" />
                    <span class="flex-1 shrink basis-0 text-ellipsis">Download CSV</span>
                </button>
            </li>
        </ul>
    </nav>
</template>

<script setup lang="ts">
    import { ref, defineProps, defineEmits } from 'vue'

    const props = defineProps({
        isVisible: { type: Boolean, default: false },
        top: { type: Number, default: 0 },
        left: { type: Number, default: 0 }
    })

    const emits = defineEmits(['onDownloadCSV', 'onDownloadPDF'])

    const handlePdfDownload = () => {
        emits('onDownloadPDF');
    }

    const handleCsvDownload = () => {
        emits('onDownloadCSV');
    }
</script>