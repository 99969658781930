export function formatDateToLocaleString(dateString: Date | string): string {
  const date = new Date(dateString);
  // Check if the date is valid
  if (isNaN(date.getTime())) {
    console.warn("Invalid date format provided:", dateString);
    return "Invalid Date";
  }
  return new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  }).format(date);
}


// Output: 12 Dec 2023 at 12:34 (depending on current date/time)
export function readableDateFormat(date: Date | string): string {
  const validDate = typeof date === "string" ? new Date(date) : date;

  if (isNaN(validDate.getTime())) {
    // Handle invalid date if the conversion failed
    return "Invalid Date";
  }

  return (
    validDate
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      })
      .replace(",", "") +
    " at " +
    validDate.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })
  );
}

export function formatDate(inputDate: Date | string) {
  const date = inputDate instanceof Date ? inputDate : new Date(inputDate);

  if (isNaN(date.getTime())) {
    throw new Error("Invalid date provided");
  }

  const now = new Date();

  const isToday = date.toDateString() === now.toDateString();

  const isYesterday =
    date.toDateString() === new Date(now.setDate(now.getDate() - 1)).toDateString();

  if (isToday) {
    return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
  } else if (isYesterday) {
    return 'Yesterday';
  } else {
    return date.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' });
  }
}