import type { IInvoice, IProforma } from "../Models/IUsage";
import type {
  ConnectorDisconnectPayload,
  IConnectorPayload,
  IVectorStoreAssistant,
} from "../Models/IConnector";
import type { IAssistant } from "../Models/IAssistant";
import type { ITool } from "../Models/ITool";
import type { IModel } from "../Models/IModel";
import type { IVectorStore } from "../Models/IVectoreStore";
import type { IOutputMode } from "../Models/IOutputMode";
import type { IAssistantDetail } from "../Models/IAssistantDetail";
import type { ISpaceResponse } from "../Models/ISpaceResponse";
import type { ISpaceRequest } from "../Models/ISpaceRequest";
import Core from "./Core";
import type { AxiosRequestConfig } from "axios";
import type { IRefreshedTokenResponse } from "@/Models/IRefreshedTokenResponse";
import type { IRefreshToken } from "@/Models/IRefreshToken";
import AppConfigGlobal from "@/core/config/uiSettings";
import type { IUserConfiguration } from "@/Models/IUserConfiguration";
import type { IFileProvider } from "@/Models/IFileProvider";
import type { IAssistantFileProvider } from "@/Models/IAssistantFileProvider";
import type { IUpdateAssistantRequest } from "@/Models/IAssistantRequest";
import type { IMessageResponse } from "@/Models/Response/Message/IMessageResponse";

const { FreddyAssistantApiUrl, CurrentOrganizationId } = AppConfigGlobal;
export class HubService extends Core {
  public async GetAdminUserConfiguration(): Promise<IUserConfiguration> {
    try {
      const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/admin/user/configurations`;
      return await this.PerformGet<IUserConfiguration>(requestUrl);
    } catch (err: any) {
      console.log("An unexpected error occured", err);
      return err;
    }
  }

  public async GetAllFileProviders(): Promise<IFileProvider[]> {
    try {
      return await this.PerformGet<IFileProvider[]>(
        `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/files/providers`
      );
    } catch (err: any) {
      console.log("An unexpected error occured", err);
      return [];
    }
  }

  public async GetAssistantsFileProviders(): Promise<IAssistantFileProvider[]> {
    try {
      return await this.PerformGet<IAssistantFileProvider[]>(
        `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/organizations/${AppConfigGlobal.CurrentOrganizationId}/assistants/file-providers`
      );
    } catch (err: any) {
      console.log("An unexpected error occured", err);
      return [];
    }
  }

  public async getAllInvoices(): Promise<IInvoice[]> {
    try {
      const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/hub/organizations/${AppConfigGlobal.CurrentOrganizationId}/invoices`;
      return await this.PerformGet<IInvoice[]>(requestUrl);
    } catch (err: any) {
      console.log("An unexpected error occured", err);
      return [];
    }
  }

  public async getInvoiceById(
    month: number,
    year: number,
    invoiceId: number
  ): Promise<IInvoice> {
    try {
      return await this.PerformGet<IInvoice>(
        `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/hub/invoices/${invoiceId}`
      );
    } catch (err: any) {
      console.log("An unexpected error occured", err);
      return err;
    }
  }

  public async getInvoiceByMonthAndYear(
    month: number,
    year: number,
    invoiceId: number
  ): Promise<IInvoice> {
    try {
      return await this.PerformGet<IInvoice>(
        `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/hub/invoices/${AppConfigGlobal.CurrentOrganizationId}/${month}/${year}`
      );
    } catch (err: any) {
      console.log("An unexpected error occured", err);
      return err;
    }
  }

  public async CreateclickUpTask(
    name: string,
    description: string
  ): Promise<boolean> {
    const data = {
      name: name,
      description: description,
    };
    return await this.PerformPost<boolean>(
      `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/clickUp/create`,
      data
    );
  }

  public async OrganisationUsageLimitUpdate(
    newLimit: string,
    emailLimit: string
  ): Promise<boolean> {
    const data = {
      newLimit: Number(newLimit),
      emailLimit: Number(emailLimit),
    };
    return await this.PerformPost<boolean>(
      `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/organizations/${AppConfigGlobal.CurrentOrganizationId}/updateusage`,
      data
    );
  }

  public async getAllSpaces(
    sourceLink: string,
    userName: string,
    password: string,
    endpoint: string
  ): Promise<ISpaceResponse[]> {
    const spaceRequest: ISpaceRequest = {
      url: sourceLink,
      userName: userName,
      password: password,
      token: sourceLink,
    };
    try {
      return await this.PerformPost<ISpaceResponse[]>(
        `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/${endpoint}/get/spaces`,
        spaceRequest
      );
    } catch {
      return [];
    }
  }

  public async getUsageById(chosenMonth: number): Promise<IProforma> {
    try {
      const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/hub/organizations/${AppConfigGlobal.CurrentOrganizationId}/${chosenMonth}/usage`;
      return await this.PerformGet<IProforma>(requestUrl);
    } catch (err: any) {
      console.log("An unexpected error occured", err);
      return err;
    }
  }

  public async getUsageChartData(chosenMonth: number) {
    try {
      const response = await fetch(
        `${FreddyAssistantApiUrl}/v1/hub/organizations/${CurrentOrganizationId}/usage-daily/month/${chosenMonth}/year/2024`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      return result;
    } catch (err) {
      console.error(err);
    }
  }

  public async GetVectorAssistant(): Promise<IVectorStoreAssistant[]> {
    try {
      return await this.PerformGet<IVectorStoreAssistant[]>(
        `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/organizations/${AppConfigGlobal.CurrentOrganizationId}/vectors/assistants-vectors`
      );
    } catch (err: any) {
      console.log("An unexpected error occured", err);
      return [];
    }
  }

  public async connectSource(
    vectorIds: number[],
    spaceKeys: string[],
    sourceUrl: string,
    userName: string,
    password: string,
    endpoint: string
  ): Promise<boolean> {
    const connectionPayload: IConnectorPayload = {
      organizationId: AppConfigGlobal.CurrentOrganizationId,
      url: sourceUrl,
      userName: userName,
      password: password,
      vectorIds: vectorIds,
      spaceKeys: spaceKeys,
      token: sourceUrl,
    };

    return await this.PerformPost<boolean>(
      `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/${endpoint}/connect`,
      connectionPayload
    );
  }

  public async DisconnectSource(
    providerId: number,
    assistantId: number
  ): Promise<boolean> {
    const disconnectPayload: ConnectorDisconnectPayload = {
      organizationId: AppConfigGlobal.CurrentOrganizationId,
      providerId: providerId,
      assistantId: assistantId,
    };

    return await this.PerformPost<boolean>(
      `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/connectors/disconnect`,
      disconnectPayload
    );
  }

  public GetRefreshToken(
    refreshtoken: string,
    expiry: Date,
    deviceId: string,
    signal?: AbortSignal
  ): Promise<IRefreshedTokenResponse> {
    const url = `https://freddy-api.aitronos.ch/auth/refresh`;
    const refreshToken: IRefreshToken = {
      token: refreshtoken,
      expiry: expiry,
    };
    const data = refreshToken;
    const config: AxiosRequestConfig = {
      headers: {
        deviceId: deviceId,
      },
      
    };
    return this.PerformPost<IRefreshedTokenResponse>(url, data, config);
  }

  // #region Assistant Tab

  public async GetAllAssistants(): Promise<IAssistant[]> {
    try {
      return await this.PerformGet<IAssistant[]>(
        `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/organizations/${AppConfigGlobal.CurrentOrganizationId}/assistants`
      );
    } catch (err: any) {
      console.log("An unexpected error occured", err);
      return [];
    }
  }

  public async GetAssistantDetailById(
    assistantId: number
  ): Promise<IAssistantDetail> {
    try {
      return await this.PerformGet<IAssistantDetail>(
        `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/organizations/${AppConfigGlobal.CurrentOrganizationId}/assistants/${assistantId}`
      );
    } catch (err: any) {
      console.log("An unexpected error occured", err);
      return err;
    }
  }

  public async GetAllTools(): Promise<ITool[]> {
    try {
      return await this.PerformGet<ITool[]>(
        `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/organizations/${AppConfigGlobal.CurrentOrganizationId}/tools`
      );
    } catch (err: any) {
      console.log("An unexpected error occured", err);
      return [];
    }
  }

  public async GetAllModels(): Promise<IModel[]> {
    try {
      return await this.PerformGet<IModel[]>(
        `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/organizations/${AppConfigGlobal.CurrentOrganizationId}/models`
      );
    } catch (err: any) {
      console.log("An unexpected error occured", err);
      return [];
    }
  }

  public async GetAllVectorStores(): Promise<IVectorStore[]> {
    try {
      return await this.PerformGet<IVectorStore[]>(
        `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/organizations/${AppConfigGlobal.CurrentOrganizationId}/vector-stores`
      );
    } catch (err: any) {
      console.log("An unexpected error occured", err);
      return [];
    }
  }

  public async GetAllOutputModes(): Promise<IOutputMode[]> {
    try {
      return await this.PerformGet<IOutputMode[]>(
        `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/organizations/${AppConfigGlobal.CurrentOrganizationId}/output-modes`
      );
    } catch (err: any) {
      console.log("An unexpected error occured", err);
      return [];
    }
  }

  public async CreateNewAssistant(
    request: IUpdateAssistantRequest
  ): Promise<boolean> {
    try {
      const url = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/organizations/${AppConfigGlobal.CurrentOrganizationId}/assistants/new`;
      return await this.PerformPost<boolean>(url, request);
    } catch (error) {
      console.error("Error creating assistant:", error);
      throw error; // re-throw if you want to handle it outside
    }
  }

  public async UpdateAssistant(
    assisatntId: number,
    request: IUpdateAssistantRequest
  ): Promise<boolean> {
    try {
      const url = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/organizations/${AppConfigGlobal.CurrentOrganizationId}/assistants/${assisatntId}`;
      return await this.PerformPost<boolean>(url, request);
    } catch (error) {
      console.error("Error creating assistant:", error);
      throw error; // re-throw if you want to handle it outside
    }
  }

  public async DeleteAssistantById(assistantId: number) {
    await this.PerformDelete<boolean>(
      `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/organizations/${AppConfigGlobal.CurrentOrganizationId}/assistants/${assistantId}`
    );
  }

  public async DeleteThreadMessages() {
    await this.PerformDelete<boolean>(
      `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/threads/${AppConfigGlobal.CurrentThreadId}/messages`
    );
  }

  public async GetThreadMessages(): Promise<Array<IMessageResponse>> {
    return await this.PerformGet<Array<IMessageResponse>>(
      `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/assistants/${AppConfigGlobal.CurrentAssistantId}/threads/${AppConfigGlobal.CurrentThreadId}/messages`
    );
  }

  // #endregion
}
