<template>
  <div
    ref="dropdownRef"
    class="flex box-border items-start gap-2 w-full border border-solid border-[#333741] px-3.5 rounded-lg"
    @click="focusInput"
  >
    <IconSearch class="w-5 h-5 stroke-[#FFFFFF66] self-center" />
    <!-- <div class="tag-list w-full self-center"> -->
    <input
      type="text"
      class="flex-1 w-full text-[14px] self-center font-medium leading-[16px] bg-transparent border-none text-[#CBD6E3] focus:outline-none bodySmall placeholder-[#cbd6e3] placeholder-opacity-7"
      ref="searchInput"
      v-model="inputValue"
      @input="handleInput"
      :placeholder="placeholder"
    />

    <Spinner
      v-if="isLoading"
      class="self-center border-[#497889] border-[3px]"
    />
    <!-- </div> -->
  </div>
</template>

<script setup lang="ts">
import { ref, defineAsyncComponent } from "vue";

// *** Icons with lazy loading ***
const IconSearch = defineAsyncComponent(
  () => import("@/components/icons/IconSearch.vue")
);

const Spinner = defineAsyncComponent(
  () => import("@/components/common/Spinner.vue")
);

// variables
const inputValue = ref<string | null>(null);
const searchInput = ref<HTMLInputElement | null>(null);
const dropdownRef = ref<HTMLElement | null>(null);

// *** Compiler macros ***
defineProps({
  placeholder: {
    type: String,
    default: () => "Search here...",
  },
  isLoading: {
    type: Boolean,
    default: () => false,
  },
});

const emit = defineEmits<{
  (event: "update:searchInput", inputValue: string | null): void;
}>();

// *** Methods ***
const focusInput = () => {
  searchInput.value?.focus();
};
const handleInput = () => {
  emit("update:searchInput", inputValue.value);
};
</script>
