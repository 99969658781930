<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 9 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5 1.5L4.5 11.5M4.5 11.5L8.25 7.75M4.5 11.5L0.75 7.75"
      stroke="currentcolor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
