<template>
  <article
    class="flex overflow-hidden flex-col h-full p-5 mt-0 my-auto font-medium bg-slate-900 rounded-[40px] w-[244px]"
  >
    <h2
      class="flex gap-2.5 items-start self-start text-xl font-bold text-white"
    >
      Used Synapses
    </h2>
    <div class="mt-5 w-[150px] h-[150px]">
      <!-- Might change  into Doughnut chart -->
      <svg viewBox="0 0 100 100" class="w-full h-full">
        <circle
          v-for="(segment, index) in pieSlices"
          :key="index"
          class="circle"
          cx="50"
          cy="50"
          r="40"
          fill="none"
          :stroke="segment.color"
          stroke-width="12"
          :stroke-dasharray="`${segment.dashArray}, 251.328`"
          :stroke-dashoffset="calculateOffset(index)"
          stroke-linecap="square"
          :style="{
            animationDelay: `${index * 0.2}s`,
            strokeDashoffset: calculateOffset(index),
          }"
        />
      </svg>
    </div>
    <ul class="flex flex-col mt-5 mb-5 w-full">
      <li
        v-for="(item, index) in usage"
        :key="index"
        class="flex flex-col justify-center items-start mt-2 w-full"
      >
        <div class="flex items-center">
          <span
            class="content-[''] inline-block w-1.5 h-1.5 lime rounded-full mr-2"
            :class="featureRequests[index].class"
          ></span>
          <h3 class="gap-1.5 self-stretch text-sm text-white">
            <span>{{ item.name }}</span>
            <span>({{ item.percentage }}%)</span>
          </h3>
        </div>
        <p class="gap-2.5 self-stretch pl-4 mt-1 text-xs text-slate-300">
          CHF {{ item.totalAmountInCHF }}
        </p>
      </li>
    </ul>
  </article>
</template>

<script setup lang="ts">
import { ref, computed, defineProps } from "vue";
import type { PropType } from "vue";

interface FeatureRequest {
  topic: string;
  count: number;
  color: string;
  class: string;
}

interface IUsageDetail{
        name: string;
        percentage: number;
        totalAmountInCHF: number;
    }

interface PieSlice {
  color: string;
  dashArray: number;
  rotation: number;
}

const props = defineProps({
  usage: {
    type: Array<IUsageDetail>,
    required: true,
    default: () => [] as IUsageDetail[],
  },
});

const showDetails = ref(false);
const total = ref(0);
const featureRequests = ref<FeatureRequest[]>([
  {
    topic: "Assistant 1 (60%)",
    count: 60.0,
    color: "#4229ff",
    class: "bg-[#7b5ad6]",
  },
  {
    topic: "Assistant 2 (50%)",
    count: 50.25,
    color: "#6929ff",
    class: "bg-[#6929ff]",
  },
  {
    topic: "Assistant 3 (20%)",
    count: 20.0,
    color: "#3b1496",
    class: "bg-[#3b1496]",
  },
  {
    topic: "Assistant 4 (15%)",
    count: 15.26,
    color: "#7b5ad6",
    class: "bg-[#7b5ad6]",
  },
]);

const toggleDetails = () => {
  showDetails.value = !showDetails.value;
};

const pieSlices = computed<PieSlice[]>(() => {
  //total.value = featureRequests.value.reduce(
  //    (sum, request) => sum + request.count,
  //    0
  //);
  let currentAngle = 0;

  return props.usage.map((request, index) => {
    const percentage = request.percentage;
    const dashArray = (percentage * 251.328) / 100;
    const rotation = currentAngle; // Rotation for each slice, based on its starting angle
    currentAngle += percentage;

    return {
      color: featureRequests.value.map((m) => m.color)[index],
      dashArray,
      rotation,
    };
  });
});

const calculateOffset = (index: number) => {
  const offset = pieSlices.value
    .slice(0, index)
    .reduce((sum, segment) => sum + segment.dashArray, 0);
  return -offset; // Negative offset to shift each slice properly
};
</script>

<style scoped lang="scss">
@keyframes draw {
  from {
    stroke-dashoffset: 0; /* Starting from a full circle */
  }

  to {
    stroke-dashoffset: 251.328; /* Fully drawn */
  }
}

.circle {
  animation: draw 1s ease-out reverse;
}
</style>
