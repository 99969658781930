import type { IApiKeyInfo, IOrganizationApiUsageLimit } from "@/Models/IApiKeyManagement";
import Core from "./Core";
import AppConfigGlobal from "@/core/config/uiSettings";
import type { IRequestParams } from "@/Models/knowledge";
import type {
  IPaginationResponse
} from "@/Models/knowledge";
export class ApiKeyManagementService extends Core {
  

 public async GetAllApiKeys(skip:number,take:number): Promise<IPaginationResponse<IApiKeyInfo>>{
  const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl
        }/v1/Organizations/${AppConfigGlobal.CurrentOrganizationId}/api/api-keys?skip=${skip}&take=${take}`;
        return await this.PerformGet<IPaginationResponse<IApiKeyInfo>>(requestUrl);      
 }

 public async UpdateApiKey(keyData :IApiKeyInfo ):Promise<boolean>{
 const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/Organizations/${AppConfigGlobal.CurrentOrganizationId}/api/update`;
 return await this.PerformPost<boolean>(requestUrl,keyData);

 }

  public async RemoveApiKey(
    apiKeyId: number | null
  ): Promise<boolean> {
    const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/Organizations/${AppConfigGlobal.CurrentOrganizationId}/api/Deactivate/${apiKeyId}`;
    return await this.PerformPost<boolean>(requestUrl,{});
  }
  
  public async CreateNewApiKey(apikeyInfo :IApiKeyInfo): Promise<string>{
   const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/Organizations/${AppConfigGlobal.CurrentOrganizationId}/api/new-key`
   return await this.PerformPost<string>(requestUrl, apikeyInfo)
  }

  public async IncreaseApiLimit(organizationApiUsageLimit : IOrganizationApiUsageLimit): Promise<boolean>{
    const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/Organizations/${AppConfigGlobal.CurrentOrganizationId}/api/increase-limit`
    return await this.PerformPost<boolean>(requestUrl, organizationApiUsageLimit)
  }

  public async GetApiUsageLimit(): Promise<number>{
    const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/Organizations/${AppConfigGlobal.CurrentOrganizationId}/api/usage-limit`
    return await this.PerformGet<number>(requestUrl);
  }
}
