<template>
  <section class="max-w-full">
    <table class="w-full text-sm font-medium border-collapse">
      <thead class="top-0 sticky z-10">
        <tr class="w-full">
          <th
            v-for="(column, index) in tableColumns"
            :key="index"
            class="text-left max-w-[400px] px-5 py-3.5 bg-slate-800 text-slate-100 border-b border-zinc-700 border-opacity-40"
            :class="{ 'cursor-pointer': column.isSortable }"
          >
            <div v-if="column.isVisible" class="flex w-fit items-center gap-2">
              <!-- To bottom div -->
              <!-- :class="{
                  'text-blue-300':
                    currentSortedColumn.columnName ===
                    column.name.toLowerCase(),
                }" -->
              <div
                v-if="column.isSortable"
                class="flex gap-2 items-center"
                @click="applySort(column)"
              >
                {{ column.name }}
                <IconArrowUp
                  v-if="
                    currentSortedColumn.columnName ===
                      column.name.toLowerCase() &&
                    currentSortedColumn.direction === 'DSC'
                  "
                  class="w-[15px] h-[14px] text-[#CBD5E1]"
                />
                <IconArrowDown
                  v-else
                  class="w-[15px] h-[14px] text-[#CBD5E1]"
                />
              </div>
              <div v-else>{{ column.name }}</div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="bg-slate-900">
        <tr
          class="border-b border-zinc-700 border-opacity-40"
          v-for="(item, index) in featureRequestItems"
          :key="index"
        >
          <td class="text-white px-5 py-3.5 text-opacity-40">
            <span>{{ item.date }}</span>
          </td>
          <td class="px-5 py-3.5 text-white text-opacity-40">
            <span>{{ item.user }}</span>
          </td>
          <td class="px-5 py-3.5 text-white text-opacity-40">
            <span>{{ item.topic }}</span>
          </td>
          <td class="px-5 py-3.5 text-white text-opacity-40">
            <span>{{ item.summary }}</span>
          </td>
          <td class="px-5 py-3.5 text-white text-opacity-40">
            <span>{{ item.description }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script setup lang="ts">
import { ref, onMounted, defineEmits } from "vue";
import type { ITableColumn } from "@/Models/ITableColumn";
import type { ISortOption } from "@/Models/ISortOption";
import IconArrowDown from "@/components/icons/IconArrowDown.vue";
import IconArrowUp from "@/components/icons/IconArrowUp.vue";

interface IFeatureRequestItem {
  id: number;
  date: string;
  user: string;
  topic: string;
  summary: string;
  description: string;
}
const emits = defineEmits(["onSort"]);
const currentSortedColumn = ref<ISortOption>({
  columnName: "date",
  direction: "ASC",
});
const tableColumns = ref<ITableColumn[]>([
  {
    id: "Date",
    name: "Date",
    isVisible: true,
    isCheckBoxType: false,
    isSortable: true,
    width: 200,
  },
  {
    id: "User",
    name: "User",
    isVisible: true,
    isCheckBoxType: false,
    isSortable: true,
    width: 200,
  },
  {
    id: "Topic",
    name: "Topic",
    isVisible: true,
    isCheckBoxType: false,
    isSortable: true,
    width: 200,
  },
  {
    id: "Summary",
    name: "Summary",
    isVisible: true,
    isCheckBoxType: false,
    isSortable: false,
    width: 500,
  },
  {
    id: "Description",
    name: "Description",
    isVisible: true,
    isCheckBoxType: false,
    isSortable: false,
    width: 500,
  },
]);

const featureRequestItems = ref<IFeatureRequestItem[]>([
  {
    id: 1,
    date: "28.10.2023, 19.00",
    user: "Tom Keller",
    topic: "Topic 01",
    summary: "Summary text",
    description: "This is tool would be helpful and cool to have because XY.",
  },
  {
    id: 1,
    date: "28.10.2023, 19.00",
    user: "Tom Keller",
    topic: "Topic 01",
    summary: "Summary text",
    description: "This is tool would be helpful and cool to have because XY.",
  },
  {
    id: 1,
    date: "28.10.2023, 19.00",
    user: "Tom Keller",
    topic: "Topic 01",
    summary: "Summary text",
    description: "This is tool would be helpful and cool to have because XY.",
  },
  {
    id: 1,
    date: "28.10.2023, 19.00",
    user: "Tom Keller",
    topic: "Topic 01",
    summary: "Summary text",
    description: "This is tool would be helpful and cool to have because XY.",
  },
  {
    id: 1,
    date: "28.10.2023, 19.00",
    user: "Tom Keller",
    topic: "Topic 01",
    summary: "Summary text",
    description: "This is tool would be helpful and cool to have because XY.",
  },
  {
    id: 1,
    date: "28.10.2023, 19.00",
    user: "Tom Keller",
    topic: "Topic 01",
    summary: "Summary text",
    description: "This is tool would be helpful and cool to have because XY.",
  },
  {
    id: 1,
    date: "28.10.2023, 19.00",
    user: "Tom Keller",
    topic: "Topic 01",
    summary: "Summary text",
    description: "This is tool would be helpful and cool to have because XY.",
  },
  {
    id: 1,
    date: "28.10.2023, 19.00",
    user: "Tom Keller",
    topic: "Topic 01",
    summary: "Summary text",
    description: "This is tool would be helpful and cool to have because XY.",
  },
]);

const applySort = (column: ITableColumn) => {
  if (!column.isSortable) return;

  if (
    column.name.toLowerCase() === currentSortedColumn.value.columnName.toLowerCase()
  ) {
    currentSortedColumn.value.direction = currentSortedColumn.value.direction === "ASC" ? "DSC" : "ASC";
  } else {
    currentSortedColumn.value.columnName = column.name.toLowerCase();
    currentSortedColumn.value.direction = "ASC";
  }
  emits("onSort", currentSortedColumn.value);
};

onMounted(async () => {
  emits("onSort", currentSortedColumn.value);
});
</script>
