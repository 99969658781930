<template>
  <div class="usageLimit" v-if="isIncreaseLimitModalOpen">
    <UsageLimitUpdate 
    :initialLimit="limit"
    @closeLimitIncreaseModal="CloseIncreaseLimitModal"
    @closeIncreaseLimit="CloseLimitModal" />
    
  </div>
  <div class="cost-container md:flex md:flex-col lg:flex-row gap-5" v-else>
    <div
      class="cost-container invoice-list mb-3 w-100 lg:w-[50%] xl:w-[76%] bg-[var(--color-background-sidebar)] rounded-3xl p-5 pt-4 mt-1.5"
    >
      <h4 class="text-xl font-bold">Invoices</h4>
      <ul class="border-t border-[var(--grey-border-line)] mt-3 text-[0.625rem]">
        <!-- <li class="Invoice__Title"> -->

        <!--<div class="cost-container invoice-list actions">
            <div class="cost-container invoice-list actions btn cancel">
              <span class="text-body3" @click="cancelExport">Cancel</span>
            </div>-->
        <!-- Remove v-if="isPerformExport" to enable the export action in the usage -->
        <!--<div class="cost-container invoice-list actions btn">
              <span class="text-body3" @click="performExport">Export</span>
            </div>
          </div>-->
        <!-- </li> -->
        <Loader
          v-if="isLoading && !listInvoices.length"
          :isLoading="isLoading"
          style="padding-top: 20px"
        />
        <li
          @click="openInvoiceDetail(item.month, item.year, item)"
          class="py-3 px-2 flex justify-between border-b hover:bg-[var(--color-menu-selected)] cursor-pointer border-[var(--grey-border-line)]"
          v-for="item in listInvoices"
          :key="item.id"
        >
          <!--<input v-if="isPerformExport" type="checkbox" :value="item.id" v-model="selectedInvoiceListForExport" />-->
          <div class="item text-body4 month-status">
            <div class="item status-grp flex items-center gap-2">
              <div class="month flex flex-col">
                <span>{{ monthList[item.month - 1].slice(0, 3) }} <br />
                  {{ item.year }}
                </span>
              </div>
              <div
                class="status flex content-center bg-[var(--color-yellow)] text-[var(--color--black-alter)] p-1 rounded-md"
                :class="item.statusName">
                {{ item.statusName }}
              </div>
            </div>
          </div>
          <span class="Item__Amount flex items-center">${{ item.total }}
            <IconChevronRight :width="17" :height="17" />
          </span>
        </li>
      </ul>
    </div>
    <Spinner class="CustomLoader" v-if="isLoading" />

    <div class="NoDataPage" v-else-if="!isLoading && !usage">No Data found</div>
    <div class="invoice-chart  mt-3 md:mt-0 sm:w-[50%] md:w-[auto]" v-else>
      <!--- Doughnut Chart --->
      <div
        class="invoice-chart chart-box min-h-100 top bg-[var(--color-background-sidebar)] p-5 rounded-3xl mb-3 mt-1.5 w-[25rem] h-auto
        xs:w-auto">
        <div class="chart-head flex gap-[0.625rem] items-center mb-3">
          <span class="text-xl font-bold xs:text-[1rem]"> Monthly Bill </span>
          <div class="text-sm">{{ startDate }} - {{ endDate }}</div>
        </div>

        <div class="ChartContainer flex h-[10.75rem] gap-5 my-4">
          <!-- Donut chart -->
          <div class="wrap-chart">
            <CustomDoughnutChart
              v-if="chartData && forceRerenderVal"
              :chartData="chartData"
              :chosenMonth="chosenMonth"
              :showCenterText="true"
            />
          </div>
          <!-- Chart split data -->
          <div class="chart-main chart-title flex flex-col justify-center gap-3 items-start">
            <div>
              <span class="text-3xl font-bold xs:text-[1.5rem]">${{ usage }}</span>
            </div>
            <div>
              <span class="heading7">/ ${{ limit }}</span>
            </div>
            <button class="btn btn-primary" @click="openIncreaseLimitModal">
              Increase Limit
            </button>
          </div>
        </div>

        <!-- Ring split data -->
        <div>
          <div class="SynapsisData flex items-center gap-2 text-sm mt-3">
            <span class="blue-dot bg-[var(--color-blue)] w-2 h-2 inline-block rounded-full"></span>
            <div class="SynapsisData__TextContainer">
              <span class="dot-text">Synapses ({{ `${getSynapsisPercentage()}%` }})</span>
              <!-- <span class="withoutdot-text">APIs (5%)</span> -->
            </div>
          </div>
        </div>
      </div>
      <div
        class="cost-container invoice-chart chart-box bg-[var(--color-background-sidebar)] p-4 rounded-3xl mt-[1.5rem] w-[25rem] h-auto
        xs:w-auto"
      >
        <div class="chart-head flex gap-[0.625rem] items-center">
          <span class="text-xl font-bold  xs:text-[1rem] leading-[16px]"> Cost in CHF </span>
          <div class="text-sm leading-[16px]">{{ startDate }} - {{ endDate }}</div>
        </div>

        <HigHChartAreaSpline
          :chosenMonth="chosenMonth"
          :chartData="highChartData"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { eventBus } from "@/components/common/eventBus";
import { ref, onMounted, watch } from "vue";
import type { IInvoice } from "@/Models/IUsage";
import IconChevronRight from "@/components/icons/IconChevronRight.vue";
import { HubService } from "@/Services/HubService";
import Loader from "@/components/Loader.vue";
import Spinner from "@/components/common/Spinner.vue";
import UsageLimitUpdate from "./UsageLimitUpdate.vue";
import CustomDoughnutChart from "@/components/common/CustomDoughnutChart.vue";
import type { IChartData } from "@/Models/charts";
import { calculatePercentage } from "@/helpers";
import HigHChartAreaSpline from "@/components/common/HigHChartAreaSpline.vue";

const emits = defineEmits<{
  openDetailView: [month: number, year: number, item: IInvoice];
}>();

const props = defineProps({
  chosenMonth: {
    type: Number,
    required: true,
  },
});

// Should be an api
const highChartData = ref([]);

const isLoading = ref<boolean>(true);
const listInvoices = ref<IInvoice[]>([]);
const isPerformExport = ref<boolean>(false);
const startDate = ref<string>();
const usage = ref<number>();
const limit = ref<number>();
const usagePercentage = ref<number>();
const endDate = ref<string>();
const selectedInvoiceListForExport = ref<string[]>([]);
const isIncreaseLimitModalOpen = ref<boolean>(false);
const hubService = new HubService();
const chartData = ref<IChartData>({
  labels: [],
  datasets: [
    {
      data: [0, 0],
      backgroundColor: ["#528BFF", "#FFFFFF17"],
      hoverOffset: 0,
      borderWidth: 0,
    },
  ],
});
const forceRerenderVal = ref(true);
const allWorking = ref(false); //* Might move it later
const monthList = ref<string[]>([
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]);
const openInvoiceDetail = (month: number, year: number, item: IInvoice) => {
  emits("openDetailView", month, year, item);
  eventBus.emit("hideNavBar", undefined);
};

const performExport = () => {
  if (!isPerformExport.value) {
    isPerformExport.value = true;
    return;
  }

  if (!selectedInvoiceListForExport.value.length) return;
  //-->Todo:- Export the selected invoices.
  cancelExport();
};

// accessing from parent
const reRenderChart = () => {
  forceRerenderVal.value = false;
  forceRerenderVal.value = true;
};
defineExpose({ reRenderChart });

const getSynapsisPercentage = () => {
  if (limit.value && usage.value)
    return calculatePercentage(usage.value, limit.value);
  return 0;
};

const cancelExport = () => {
  isPerformExport.value = false;
};

const openIncreaseLimitModal = () => {
  isIncreaseLimitModalOpen.value = true;
  const leftBox = document.querySelector<HTMLElement>(".box.left");
  if (leftBox) {
    leftBox.style.zIndex = "1";
  }
};

const CloseIncreaseLimitModal = async () => {
  await hubService.getUsageById(props.chosenMonth).then(async (usageData) => {
      limit.value = usageData.limit;
      usage.value = usageData.usage;
      usagePercentage.value = usageData.usagePercentage;
      setChartData();
    })
  isIncreaseLimitModalOpen.value = false;
  const leftBox = document.querySelector<HTMLElement>(".box.left");
  if (leftBox) {
    leftBox.style.zIndex = "10";
  }
};

const CloseLimitModal = async () =>{
  isIncreaseLimitModalOpen.value = false;
  const leftBox = document.querySelector<HTMLElement>(".box.left");
  if (leftBox) {
    leftBox.style.zIndex = "10";
  }
}

const setChartData = () => {
  if (usage.value && limit.value) {
    chartData.value = {
      labels: ["Synapsis", "Neurons", ""],
      datasets: [
        // "#A6EF67" Color green
        {
          data: [usage.value, limit.value - usage.value],
          backgroundColor: ["#528BFF", "#FFFFFF17"],
          hoverOffset: 0,
          borderWidth: 0,
        },
      ],
    };
  }
};

watch(
  () => props.chosenMonth,
  async () => {
    await setupBaseData();
  }
);

// Fetching usage data on component mount
onMounted(async () => {
  await setupBaseData();
});

const setupBaseData = async () => {
  isLoading.value = true;
  allWorking.value = false;
  await hubService
    .getUsageById(props.chosenMonth)
    .then(async (usageData) => {
      allWorking.value = true;

      startDate.value = usageData.startDate;
      endDate.value = usageData.endDate;
      usage.value = usageData.usage;
      limit.value = usageData.limit;
      usagePercentage.value = usageData.usagePercentage;
      listInvoices.value = await hubService.getAllInvoices();
  
      setChartData();
    })
    .catch(() => {
      allWorking.value = false;
    });

  const res = await hubService.getUsageChartData(props.chosenMonth);

  const compressedData = res.map((item: any) => ({
    [item.day]: parseFloat(item.cost.toFixed(2)),
  }));
  highChartData.value = compressedData;

  isLoading.value = false;
};

</script>
