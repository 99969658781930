<script setup lang="ts">
import { ref, computed } from "vue";

import IconChevronRight from "@/components/icons/IconChevronRight.vue";
import IconChevronLeft from "@/components/icons/IconChevronLeft.vue";
import HubUsageCost from "./HubUsageCost.vue";
import HubUsageActivity from "./HubUsageActivity.vue";
import HubInvoiceDetail from "./HubInvoiceDetail.vue";
import type { IInvoice } from "@/Models/IUsage";

// const selectedMonthIndex = ref<number>(11);
// const isIncreaseLimitActive = ref<boolean>(false);
const isCostView = ref<boolean>(true);
const hubUsageCostComponent = ref<null | typeof HubUsageCost>(null);
const isInvoiceDetailOpen = ref<boolean>(false);
const monthOfInvoiceSelected = ref<number>(1);
const yearOfInvoiceSelected = ref<number>(1);

const selectedInvoice = ref<IInvoice | null>(null);
//--> Use a common function to get the month name from number instead.
const monthList = ref<string[]>([
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]);

const costView = () => {
  isCostView.value = true;
};

const activityView = () => {
  //--> Uncomment when the activity page is ready.
  //isCostView.value = false;
};

// Change month methods
const currentMonth = computed(() => {
  return new Date().getMonth() + 1;
});

const chosenMonth = ref(currentMonth.value);

const changeMonth = (updateVal: number) => {
  chosenMonth.value = chosenMonth.value + updateVal;
};

//This method will change the month of both child and parent.

const upMonth = () => {
  // if (monthOfInvoiceSelected.value == 12) {
  //   monthOfInvoiceSelected.value = 1;
  //   yearOfInvoiceSelected.value += 1;
  // } else {
  //   monthOfInvoiceSelected.value += 1;
  // }
  // if (hubUsageCostComponent.value) hubUsageCostComponent.value.reRenderChart();
};

const downMonth = () => {
  //   if (monthOfInvoiceSelected.value == 1) {
  //     monthOfInvoiceSelected.value = 12;
  //     yearOfInvoiceSelected.value -= 1;
  //   } else {
  //     monthOfInvoiceSelected.value -= 1;
  //   }
  //   if (hubUsageCostComponent.value) hubUsageCostComponent.value.reRenderChart();
};

//This method will change the month of both child and parent.

// const upMonth = () => {
//   if (monthOfInvoiceSelected.value == 12) {
//     monthOfInvoiceSelected.value = 1;
//     yearOfInvoiceSelected.value += 1;
//   } else {
//     monthOfInvoiceSelected.value += 1;
//   }
//   if (hubUsageCostComponent.value) hubUsageCostComponent.value.reRenderChart();
// };

// const downMonth = () => {
//   if (monthOfInvoiceSelected.value == 1) {
//     monthOfInvoiceSelected.value = 12;
//     yearOfInvoiceSelected.value -= 1;
//   } else {
//     monthOfInvoiceSelected.value -= 1;
//   }
//   if (hubUsageCostComponent.value) hubUsageCostComponent.value.reRenderChart();
// };

const openDetailView = (
  monthOfInvoice: number,
  yearOfInvoice: number,
  item: IInvoice
) => {
  isInvoiceDetailOpen.value = true;
  selectedInvoice.value = item;
  monthOfInvoiceSelected.value = monthOfInvoice;
  yearOfInvoiceSelected.value = yearOfInvoice;
};

const closeDetailView = () => {
  isInvoiceDetailOpen.value = false;
};
</script>

<template>
  <div v-if="!isInvoiceDetailOpen" class="usage-container">
    <!-- TODO: Enable in the case of cost button -->
    <div class="usage-container head flex justify-end py-3 border-b border-[var(--grey-border-line)] text-sm mb-3">
      <!-- <div class="usage-container head actions">
    <div class="usage-container head actions button text-body">
                    <span class="usage-container head actions cost" :class="{ highlight: true }">Cost</span>
    <span class="usage-container head actions button cost" :class="{ highlight: isCostView }" @click="costView()">Cost</span>
                    <span class="usage-container head actions button item" :class="{ highlight: !isCostView }" @click="activityView()">Item</span>
    </div>
    </div> -->
      <!-- <div class="usage-container head model-month-export"> -->
      <!-- <div class="usage-container head model-month-export model">
                    <select name=""
                            id=""
                            class="usage-container head model-month-export model select">
                        <option value="model">Model</option>
                        <option value="model">Model</option>
                        <option value="model">Model</option>
                        <option value="model">Model</option>
                    </select>
                </div> -->
      <!-- <div class="usage-container head model-month-export month">
    <CustomMonthPicker />
    <span>
                        <IconChevronLeft :width="20"
                                         :height="21"
                                         :class="{ disable: selectedMonthIndex == 0 }"
                                         @click="downMonth()" />
                    </span>
                    <span class="text-body2">
                        {{
          monthList[monthOfInvoiceSelected - 1]
                        }}
                    </span>
                    <span>
                        <IconChevronRight :width="20"
                                          :height="21"
                                          :class="{ disable: selectedMonthIndex == 12 }"
                                          @click="upMonth()" />
                    </span>
                </div> -->
      <!-- <div class="usage-container head model-month-export export">
                    <button class="usage-container head model-month-export export button"
                            type="button">
                        Export
                    </button>
                </div> -->
      <!-- </div> -->
      <!--Don't remove this lines. Uncomment this when charts feature is ready'-->
      <div
        class="date-container inline-flex items-center justify-center border border-[var(--grey-border-line)] w-[130px] space-x-2">
        <span>
          <IconChevronLeft :width="20" :height="21" :class="{ disable: chosenMonth === 1 }" @click="changeMonth(-1)" />
        </span>
        <span class="text-body2 flex-grow text-center">{{ monthList[chosenMonth - 1] }}</span>
        <span>
          <IconChevronRight :width="20" :height="21" :class="{
            disable: chosenMonth === 12 || currentMonth === chosenMonth,
          }" @click="changeMonth(1)" />
        </span>
      </div>
    </div>
    <div class="usage-container main body">
      <HubUsageCost :chosenMonth="chosenMonth" ref="hubUsageCostComponent" v-if="isCostView"
        @openDetailView="openDetailView" />
      <HubUsageActivity v-else />
    </div>
  </div>
  <div v-if="isInvoiceDetailOpen" class="usage-container">
    <HubInvoiceDetail :monthOfInvoice="monthOfInvoiceSelected" :yearOfInvoice="yearOfInvoiceSelected"
      :invoice="selectedInvoice" @closeInvoiceDetail="closeDetailView" @downMonth="downMonth" @upMonth="upMonth" />
  </div>
</template>
<!-- 
<style lang="scss" scoped>
@import "@/assets/mainstyle";

.usage-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  gap: 10px;

  &.head {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: auto;
    padding-bottom: 16px;
    border-bottom: 1px solid $light-border;
    justify-content: flex-end;
    padding-right: 40px;
    @include disable-text-selection;

    .date-container {
      margin: 0;
      display: flex;
      text-align: center;
      align-items: center;
      padding: 4px;
      border: 1px solid #ffffff17;
      border-radius: 4px;

      .text-body2 {
        min-width: 80px;
      }
    }

    &.model-month-export {
      justify-content: end;
      margin-left: 29%;

      &.model {
        display: flex;
        margin-left: 350px;
        justify-content: left;
        align-items: center;
        height: 24px;
        width: 90px;
        padding: var(--spacing-none, 0px) 5px;
        gap: var(--spacing-md, 8px);
        border-radius: 3px;
        border: 1px solid
          var(--Seperator-semi-transparent, rgba(255, 255, 255, 0.09));

        .select {
          margin-left: -6px;
          height: 24px;
          background: transparent;
          border: none;
          color: var(--Text-Tetriary, #cbd6e3);
          font-family: Inter;
          font-size: 14px;
          font-weight: 500;
        }
      }

      &.month {
        display: flex;
        width: 220px;
        height: 24px;
        justify-content: space-between;
        align-items: center;
        margin-left: 0px;
        border-radius: 3px;
        border: 1px solid
          var(--Seperator-semi-transparent, rgba(255, 255, 255, 0.09));
        margin-right: -150px;
      }

      &.export {
        display: flex;
        width: auto;
        height: 24px;
        padding: var(--spacing-sm, 6px) var(--spacing-md, 8px);
        align-items: center;
        gap: 10px;
        border-radius: var(--radius-xs, 4px);
        background: var(--Button, #7ba8ef);

        &.button {
          color: var(--Background-primary, #031525);
          font-family: Inter;
          font-size: 14px;
          font-weight: 600;
          background: transparent;
          border: none;
        }
      }
    }

    &.actions {
      height: 27px;
      justify-content: space-between;
      border: 1px solid $highlight-border;
      border-radius: 5px;
      width: 115px;
      margin: 16px 0px;

      &.button {
        justify-content: center;
        border: none;
        gap: 0px;

        &.cost {
          color: var(--Background-primary, #031525);
          font-family: Inter;
          font-size: 14px;
          font-weight: 500;

          &:hover {
            cursor: pointer;
          }
        }

        &.item {
          color: var(--Button, #7ba8ef);
          font-family: Inter;
          font-size: 14px;
          font-weight: 500;

          &:hover {
            cursor: pointer;
          }
        }
      }

      &.date {
        gap: 0;
        align-items: center;
        border: 1px solid $light-border;
        font-size: 14px;
      }
    }
  }

  &.body {
    flex-direction: row;
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100% - 80px);
    @extend .scroll-width;
  }
}

/*golbal styles*/

@media (max-width: 1024px) {
  .model {
    margin-left: 0px !important;
  }

  .month {
    width: 108px !important;
    margin-right: -88px !important;
  }
}

@media (max-width: 768px) {
  .export {
    padding: var(--spacing-sm, 6px) var(--spacing-md, 12px) !important;
  }
}

@media (max-width: 600px) {
  .head {
    margin-left: -14px;
  }

  .model-month-export {
    margin-left: 0% !important;
  }

  .actions {
    width: 76px !important;
  }

  .export {
    padding: var(--spacing-sm, 6px) var(--spacing-md, 2px) !important;
    width: 54px !important;
  }
}
.mr-10 {
  margin-right: 10px;
}
</style> -->
