<template>
    <div class="icon-purse-circle-fill">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="8" fill="#ECF2A6" />
            <g clip-path="url(#clip0_11911_45725)">
                <path d="M10 5.99998V4.2503C10 3.83443 10 3.6265 9.9124 3.49871C9.83586 3.38707 9.71732 3.31122 9.58388 3.28852C9.43114 3.26252 9.24235 3.34966 8.86475 3.52394L4.42951 5.57097C4.09275 5.7264 3.92438 5.80411 3.80105 5.92463C3.69203 6.03118 3.60881 6.16125 3.55775 6.30489C3.5 6.46737 3.5 6.65282 3.5 7.02371V9.49997M10.25 9.24997H10.255M3.5 7.59998L3.5 10.9C3.5 11.46 3.5 11.7401 3.60899 11.954C3.70487 12.1421 3.85785 12.2951 4.04601 12.391C4.25992 12.5 4.53995 12.5 5.1 12.5H10.9C11.4601 12.5 11.7401 12.5 11.954 12.391C12.1422 12.2951 12.2951 12.1421 12.391 11.954C12.5 11.7401 12.5 11.46 12.5 10.9V7.59998C12.5 7.03992 12.5 6.7599 12.391 6.54599C12.2951 6.35782 12.1422 6.20484 11.954 6.10897C11.7401 5.99998 11.4601 5.99998 10.9 5.99998L5.1 5.99997C4.53995 5.99997 4.25992 5.99998 4.04601 6.10897C3.85785 6.20484 3.70487 6.35782 3.60899 6.54598C3.5 6.7599 3.5 7.03992 3.5 7.59998ZM10.5 9.24997C10.5 9.38805 10.3881 9.49997 10.25 9.49997C10.1119 9.49997 10 9.38805 10 9.24997C10 9.1119 10.1119 8.99997 10.25 8.99997C10.3881 8.99997 10.5 9.1119 10.5 9.24997Z" stroke="white" stroke-width="1.6666" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_11911_45725">
                    <rect width="12" height="12" fill="white" transform="translate(2 2)" />
                </clipPath>
            </defs>
        </svg>
        <span class="bodySmall title">{{ props.title }}</span>
    </div>
</template>

<script setup lang="ts">
    const props = defineProps({
        title: {type: String, default: '', required: false}
    })
</script>

<style scoped lang="scss">
    @import '@/assets/mainstyle.scss';

    .icon-purse-circle-fill {
        display: flex;
        flex-direction: row;
        font-size: 16px;
        padding: 10px;
        color: green;
        gap: 5px;
        align-items: center;
    }

    .title {
        @extend .text-body2;
    }
</style>