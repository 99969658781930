<template>
  <section class="space-list-body">
    <header class="header">
      <h1 class="h5Bold title">{{ sourceTitle }} Spaces</h1>
      <form class="search-form" @submit.prevent>
        <label for="searchInput" class="visually-hidden"
          >Search Confluence Spaces</label
        >
        <div class="search-input-wrapper">
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/31e80f0c12e7e8d749ec5d545d02b3345dc57e3d243c20883b622f648009ea27?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51"
            alt=""
            class="search-icon"
          />
          <input
            type="search"
            id="searchInput"
            class="paragraph search-input"
            placeholder="Search"
            aria-label="Search Confluence Spaces"
            v-model="searchText"
            @input="searchConnectors"
            @keyup.enter="searchConnectors"
          />
        </div>
      </form>
    </header>
    <ul class="space-list-table">
      <li class="empty-item" v-if="isFetching">
        <Loader :isLoading="isFetching" />
      </li>
      <li
        class="invoice-cell"
        v-else-if="searchedSpaceList && searchedSpaceList.length"
        v-for="(item, index) in searchedSpaceList"
      >
        <div class="invoice-content">
          <div class="invoice-left">
            <div class="checkbox-wrapper">
              <img
                v-if="!selectedSpaceKeys.includes(item.key)"
                loading="lazy"
                src="@/assets/Check_box_base.svg"
                class="checkbox-image"
                alt="Checkbox"
                @click="onSourceChange(item, true)"
              />
              <img
                v-else
                loading="lazy"
                src="@/assets/Check_box_fill.svg"
                class="checkbox-image"
                alt="Checkbox"
                @click="onSourceChange(item, false)"
              />
            </div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/28183f61130c03893dad049503e886542ce56f89b6f5e7f73bc925e19ffbff07?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51"
              class="separator-line"
              alt=""
            />
            <div class="company-info">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/aed9b62a1bf9c1f4239781dc191b0eb4ce848eb106ef2c72fb619aecd79c5f01?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51"
                class="company-logo"
                alt="Alturos Group Management Logo"
              />
              <span class="bodySmall company-name">{{ item.name }}</span>
            </div>
          </div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/785f3e07a0ae9b8cde58c516e40dea81f63c9277c9aba1ea5b1dddf3f4b764fb?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51"
            class="action-icon"
            alt="Action Icon"
          />
        </div>
        <div class="separator-container">
          <hr class="separator" />
        </div>
      </li>
      <li v-else class="empty-item">Nothing found</li>
    </ul>
  </section>
</template>

<script setup lang="ts">
import { ref, computed, onUpdated } from "vue";
import Loader from "@/components/Loader.vue";
import type { ISpaceResponse } from "../../../Models/ISpaceResponse";

const emits = defineEmits(["onSourceChange"]);

const props = defineProps({
  sourceList: {
    type: Array<ISpaceResponse>,
    default: [],
    required: false,
  },
  sourceTitle: {
    type: String,
    default: "",
    required: false,
  },
  isFetching: {
    type: Boolean,
    default: true,
    required: true,
  },
});

const searchedSpaceList = computed(() => {
  return searchText.value.trim() === ""
    ? [...props.sourceList]
    : props.sourceList.filter(
        (m) =>
          m.name.toLowerCase() == searchText.value.trim().toLowerCase() ||
          m.name.toLowerCase().includes(searchText.value.trim().toLowerCase())
      );
});

const selectedSpaceKeys = ref<string[]>([]);
const searchText = ref<string>("");
const isLoadingSpaceList = ref<boolean>(false);

const onSourceChange = (option: ISpaceResponse, isSelected: boolean) => {
  if (!isSelected) {
    selectedSpaceKeys.value = selectedSpaceKeys.value.filter(
      (x) => x !== option.key
    );
    return;
  }
  selectedSpaceKeys.value.push(option.key);

  emits("onSourceChange", selectedSpaceKeys.value);
  //--> ToDo:- Get the assistant associated with the vector store
};

const searchConnectors = () => {
  //if (searchText.value.trim() === '') {
  //    searchedSpaceList.value = [...props.sourceList];
  //    return;
  //}
  //searchedSpaceList.value = props.sourceList.filter(m =>
  //    m.name.toLowerCase() == searchText.value.toLowerCase() || m.name.toLowerCase().includes(searchText.value.toLowerCase()));
};

onUpdated(() => {
  isLoadingSpaceList.value = props.isFetching;
  console.log(props.isFetching);
});
</script>

<style scoped lang="scss">
@import "@/assets/mainstyle";

.space-list-body {
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: 50px;
}

.title {
  color: #fff;
  align-self: stretch;
  flex: 1;
  flex-basis: 0%;
  margin: auto 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.search-form {
  align-self: stretch;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  color: var(--Colors-Text-text-placeholder, #667085);
  width: 320px;
  margin: auto 0;
}

.search-input-wrapper {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: var(--Background-secondary, #071a2b);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  padding: 8px 12px;
  gap: 8px;
}

.search-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.search-input {
  background: transparent;
  border: none;
  color: inherit;
  flex: 1;
  outline: none;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@media (max-width: 991px) {
  .title,
  .search-form {
    max-width: 100%;
  }
}

.space-list-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  height: calc(100% - 50px);
  max-height: 100%;
  justify-content: flex-start;
  padding: 0;
  overflow-y: auto;
  @extend .scroll-width;
}

.invoice-cell {
  display: flex;
  flex-direction: column;
  /*overflow: hidden;*/
  justify-content: flex-end;
  width: 100%;
}

.invoice-content {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 40px 100px;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 5px 0 10px;
}

.invoice-left {
  align-self: stretch;
  display: flex;
  min-width: 240px;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
  margin: auto 0;
}

.checkbox-wrapper {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  margin: auto 0;
  padding: 12px 0;
}

.checkbox-image {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  border-radius: 6px;
  align-self: stretch;
  margin: auto 0;
}

.separator-line {
  aspect-ratio: 0.02;
  object-fit: contain;
  object-position: center;
  width: 1px;
  align-self: stretch;
}

.company-info {
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  justify-content: flex-start;
  margin: auto 0;
  padding: 12px 0;
  font: 500 14px Inter, sans-serif;
}

.company-logo {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  border-radius: 50%;
  align-self: stretch;
  margin: auto 0;
}

.company-name {
  align-self: stretch;
  margin: auto 0;
}

.action-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  align-self: stretch;
  margin: auto 0;
}

.separator-container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.separator {
  border: none;
  border-radius: 24px;
  background-color: rgba(255, 255, 255, 0.09);
  min-height: 1px;
  width: 100%;
}

.empty-item {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

@media (max-width: 991px) {
  .invoice-content,
  .separator-container,
  .separator {
    max-width: 100%;
  }
}
</style>
