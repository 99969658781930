<template>
  <div class="icon-folder">
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
      <path d="M27 4.5H18.708C18.476 4.50153 18.2467 4.45024 18.0375 4.35L13.3035 1.974C12.6786 1.66282 11.9901 1.50058 11.292 1.5H9C7.01161 1.50238 5.10534 2.29332 3.69933 3.69933C2.29332 5.10534 1.50238 7.01161 1.5 9V27C1.50238 28.9884 2.29332 30.8947 3.69933 32.3007C5.10534 33.7067 7.01161 34.4976 9 34.5H27C28.9884 34.4976 30.8947 33.7067 32.3007 32.3007C33.7067 30.8947 34.4976 28.9884 34.5 27V12C34.4976 10.0116 33.7067 8.10534 32.3007 6.69933C30.8947 5.29332 28.9884 4.50238 27 4.5ZM9 4.5H11.292C11.524 4.49847 11.7533 4.54976 11.9625 4.65L16.6965 7.0185C17.3208 7.33228 18.0093 7.49709 18.708 7.5H27C27.8971 7.50146 28.7732 7.771 29.516 8.27402C30.2587 8.77703 30.8342 9.49056 31.1685 10.323L4.5 10.491V9C4.5 7.80653 4.97411 6.66193 5.81802 5.81802C6.66193 4.97411 7.80653 4.5 9 4.5ZM27 31.5H9C7.80653 31.5 6.66193 31.0259 5.81802 30.182C4.97411 29.3381 4.5 28.1935 4.5 27V13.491L31.5 13.3215V27C31.5 28.1935 31.0259 29.3381 30.182 30.182C29.3381 31.0259 28.1935 31.5 27 31.5Z" fill="white" />
    </svg>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
.icon-folder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  color: rgb(177, 168, 168);

  &:hover {
    cursor: pointer;
  }
}
</style>