<template>
    <div class="icon-figma">
        <svg width="53" height="52" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_11937_1997)">
                <rect x="0.5" width="52" height="52" rx="8" fill="white" />
                <rect x="0.5" width="52" height="52" rx="8" fill="url(#paint0_linear_11937_1997)" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.5006 3.3131C13.971 3.3131 3.81371 13.4704 3.81371 26C3.81371 38.5296 13.971 48.6869 26.5006 48.6869C39.0302 48.6869 49.1875 38.5296 49.1875 26C49.1875 13.4704 39.0302 3.3131 26.5006 3.3131ZM3.6875 26C3.6875 13.4007 13.9013 3.18689 26.5006 3.18689C39.0999 3.18689 49.3137 13.4007 49.3137 26C49.3137 38.5993 39.0999 48.8131 26.5006 48.8131C13.9013 48.8131 3.6875 38.5993 3.6875 26Z" fill="#D0D5DD" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.5 19.6266C22.9799 19.6266 20.1262 22.4802 20.1262 26.0004C20.1262 29.5205 22.9799 32.3742 26.5 32.3742C30.0201 32.3742 32.8738 29.5205 32.8738 26.0004C32.8738 22.4802 30.0201 19.6266 26.5 19.6266ZM20 26.0004C20 22.4105 22.9101 19.5004 26.5 19.5004C30.0899 19.5004 33 22.4105 33 26.0004C33 29.5902 30.0899 32.5004 26.5 32.5004C22.9101 32.5004 20 29.5902 20 26.0004Z" fill="#D0D5DD" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.5001 21.6451C24.0953 21.6451 22.1457 23.5947 22.1457 25.9995C22.1457 28.4044 24.0953 30.3539 26.5001 30.3539C28.905 30.3539 30.8545 28.4044 30.8545 25.9995C30.8545 23.5947 28.905 21.6451 26.5001 21.6451ZM22.0195 25.9995C22.0195 23.5249 24.0256 21.5189 26.5001 21.5189C28.9747 21.5189 30.9807 23.5249 30.9807 25.9995C30.9807 28.4741 28.9747 30.4801 26.5001 30.4801C24.0256 30.4801 22.0195 28.4741 22.0195 25.9995Z" fill="#D0D5DD" />
                <path d="M26.4375 0H26.5637V52H26.4375V0Z" fill="#D0D5DD" />
                <path d="M52.5 25.9365L52.5 26.0627L0.5 26.0627L0.5 25.9365L52.5 25.9365Z" fill="#D0D5DD" />
                <path d="M43.7285 0H43.8547V52H43.7285V0Z" fill="#D0D5DD" />
                <path d="M17.791 0H17.9172V52H17.791V0Z" fill="#D0D5DD" />
                <path d="M35.082 0H35.2082V52H35.082V0Z" fill="#D0D5DD" />
                <path d="M9.14648 0H9.2727V52H9.14648V0Z" fill="#D0D5DD" />
                <path d="M52.5 43.2275L52.5 43.3538L0.5 43.3538L0.5 43.2275L52.5 43.2275Z" fill="#D0D5DD" />
                <path d="M52.5 17.291L52.5 17.4172L0.5 17.4172L0.5 17.291L52.5 17.291Z" fill="#D0D5DD" />
                <path d="M52.5 34.582L52.5 34.7082L0.5 34.7082L0.5 34.582L52.5 34.582Z" fill="#D0D5DD" />
                <path d="M52.5 8.64551L52.5 8.77172L0.5 8.77172L0.5 8.64551L52.5 8.64551Z" fill="#D0D5DD" />
                <g filter="url(#filter0_dd_11937_1997)">
                    <circle cx="26.5" cy="26" r="13" fill="url(#paint1_linear_11937_1997)" />
                </g>
                <g filter="url(#filter1_b_11937_1997)">
                    <path d="M0.5 26H52.5V39.2C52.5 43.6804 52.5 45.9206 51.6281 47.6319C50.8611 49.1372 49.6372 50.3611 48.1319 51.1281C46.4206 52 44.1804 52 39.7 52H13.3C8.81958 52 6.57937 52 4.86808 51.1281C3.36278 50.3611 2.13893 49.1372 1.37195 47.6319C0.5 45.9206 0.5 43.6804 0.5 39.2V26Z" fill="white" fill-opacity="0.2" />
                </g>
            </g>
            <rect x="0.6" y="0.1" width="51.8" height="51.8" rx="7.9" stroke="#D0D5DD" stroke-width="0.2" />
            <defs>
                <filter id="filter0_dd_11937_1997" x="10.5" y="11" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11937_1997" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow_11937_1997" result="effect2_dropShadow_11937_1997" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_11937_1997" result="shape" />
                </filter>
                <filter id="filter1_b_11937_1997" x="-4.5" y="21" width="62" height="36" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.5" />
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_11937_1997" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_11937_1997" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_11937_1997" x1="26.5" y1="0" x2="26.5" y2="52" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" />
                    <stop offset="1" stop-color="#D0D5DD" />
                </linearGradient>
                <linearGradient id="paint1_linear_11937_1997" x1="20" y1="39" x2="33" y2="13" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#53389E" />
                    <stop offset="1" stop-color="#6941C6" />
                </linearGradient>
                <clipPath id="clip0_11937_1997">
                    <rect x="0.5" width="52" height="52" rx="8" fill="white" />
                </clipPath>
            </defs>
        </svg>

    </div>
</template>

<script setup lang="ts">
</script>
<!-- 
<style>
    .icon-figma {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        color: rgb(177, 168, 168);
    }
</style> -->