<template>
    <div id="curve_chart"></div>
</template>

<script>
    export default {
        mounted() {
            google.charts.load('current', { 'packages': ['corechart'] });
            google.charts.setOnLoadCallback(this.drawChart);
        },
        methods: {
            drawChart() {
                const data = new google.visualization.arrayToDataTable([
                    ['Year', 'Sales'],
                    ['2004', 0],
                    ['2005', 900],
                    ['2006', 300],
                    ['2007', 1500],
                    ['2008', 950],
                    ['2009', 0]
                ]);

                const options = {
                    title: '',
                    titleTextStyle: {
                        color: 'white',
                        fontSize: 18,
                        bold: true
                    },
                    curveType: 'function',
                    colors: ['red', '#004411'],
                    legend: { position: 'bottom' },
                    backgroundColor: ''
                };

                const chart = new google.visualization.LineChart(document.getElementById('curve_chart'));
                chart.draw(data, options);
            }
        }
    };
</script>

<style scoped>
    #curve_chart {
        position: relative;
        padding: 10px;
        width: 400px;
        top: -92px;
    }

    @media (max-width: 1024px) {
        #curve_chart {
            top: 30px;
            left: -33px;
        }
    }
</style>

