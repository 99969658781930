<template>
  <div class="flex flex-col pl-2.5 h-full pt-[21px]">
    <div class="flex flex-row justify-between items-center w-full h-[56px]">
      <!--      header-->
      <div>Logs</div>
      <button class="flex flex-row gap-2.5" @click="hideLogs">
        <svg
          width="24"
          height="22"
          viewBox="0 0 24 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 2H17C17.7956 2 18.5587 2.28446 19.1213 2.79081C19.6839 3.29716 20 3.98392 20 4.7V17.3C20 18.0161 19.6839 18.7028 19.1213 19.2092C18.5587 19.7155 17.7956 20 17 20H7C6.20435 20 5.44129 19.7155 4.87868 19.2092C4.31607 18.7028 4 18.0161 4 17.3V4.7C4 3.98392 4.31607 3.29716 4.87868 2.79081C5.44129 2.28446 6.20435 2 7 2ZM8 8.3C7.73478 8.3 7.48043 8.39482 7.29289 8.56361C7.10536 8.73239 7 8.96131 7 9.2C7 9.4387 7.10536 9.66761 7.29289 9.8364C7.48043 10.0052 7.73478 10.1 8 10.1H16C16.2652 10.1 16.5196 10.0052 16.7071 9.8364C16.8946 9.66761 17 9.4387 17 9.2C17 8.96131 16.8946 8.73239 16.7071 8.56361C16.5196 8.39482 16.2652 8.3 16 8.3H8ZM8 15.5C7.73478 15.5 7.48043 15.5948 7.29289 15.7636C7.10536 15.9324 7 16.1613 7 16.4C7 16.6387 7.10536 16.8676 7.29289 17.0364C7.48043 17.2052 7.73478 17.3 8 17.3H13C13.2652 17.3 13.5196 17.2052 13.7071 17.0364C13.8946 16.8676 14 16.6387 14 16.4C14 16.1613 13.8946 15.9324 13.7071 15.7636C13.5196 15.5948 13.2652 15.5 13 15.5H8ZM8 4.7C7.73478 4.7 7.48043 4.79482 7.29289 4.9636C7.10536 5.13239 7 5.36131 7 5.6C7 5.8387 7.10536 6.06761 7.29289 6.2364C7.48043 6.40518 7.73478 6.5 8 6.5H16C16.2652 6.5 16.5196 6.40518 16.7071 6.2364C16.8946 6.06761 17 5.8387 17 5.6C17 5.36131 16.8946 5.13239 16.7071 4.9636C16.5196 4.79482 16.2652 4.7 16 4.7H8ZM8 11.9C7.73478 11.9 7.48043 11.9948 7.29289 12.1636C7.10536 12.3324 7 12.5613 7 12.8C7 13.0387 7.10536 13.2676 7.29289 13.4364C7.48043 13.6052 7.73478 13.7 8 13.7H16C16.2652 13.7 16.5196 13.6052 16.7071 13.4364C16.8946 13.2676 17 13.0387 17 12.8C17 12.5613 16.8946 12.3324 16.7071 12.1636C16.5196 11.9948 16.2652 11.9 16 11.9H8Z"
            fill="#F8F8F8"
          />
        </svg>
        <span>Hide logs</span>
      </button>
    </div>
    <div class="justify-self-stretch h-full overflow-y-auto overflow-x-hidden">
      <!--      content goes here-->
      <LogCard v-for="(item, index) in logs" :key="index" :log="item"></LogCard>
    </div>
    <!--<div class="flex flex-row justify-between mt-2.5">-->
    <!--      Footer-->
    <!--<div class="flex flex-row gap-2.5 items-center">
            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="0.5" width="18" height="18" rx="9" fill="#68C57C" />
                <path d="M13.5 6.125L7.3125 12.3125L4.5 9.5" stroke="white" stroke-width="1.6666" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <span>Run Completed</span>
        </div>
        <div class="flex flex-row gap-2.5 items-center">
            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="0.5" width="18" height="18" rx="9" fill="#FF4D4D" />
                <path d="M6 6.5L12 12.5M6 12.5L12 6.5" stroke="white" stroke-width="1.6666" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <span>Run Terminated</span>
        </div>
        <div>
            <span>1200 tokens</span>
        </div>
    </div>-->
  </div>
</template>
<script setup lang="ts">
import type { PropType } from "vue";
import LogCard from "@/components/freddycomponents/LogCard.vue";
import type { IAssistantLogDataModel } from "@/Models/AssistantLogs/IAssistantLogDataModel";

const props = defineProps({
  logs: {
    default: {},
    required: false,
    type: Array as PropType<IAssistantLogDataModel[]>,
  },
});

const emit = defineEmits(["hide"]);

const hideLogs = () => {
  emit("hide");
};
</script>
