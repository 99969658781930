<template>
    <div class="icon-confluence">
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_12166_41395)">
                <rect x="2" y="1" width="52" height="52" rx="8" fill="white" shape-rendering="crispEdges" />
                <rect x="2.5" y="1.5" width="51" height="51" rx="7.5" stroke="#EAECF0" shape-rendering="crispEdges" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3055 36.4824C10.9331 37.1143 10.515 37.8475 10.1599 38.4317C9.84197 38.9908 10.0126 39.7118 10.5436 40.0532L17.9902 44.8222C18.2515 44.9902 18.5668 45.0419 18.8651 44.9657C19.1635 44.8894 19.4199 44.6917 19.5768 44.4169C19.8747 43.8982 20.2585 43.2246 20.6766 42.5033C23.6266 37.4362 26.5938 38.0562 31.9438 40.7149L39.3273 44.3692C39.6069 44.5077 39.9286 44.5225 40.2189 44.4102C40.5091 44.2979 40.7434 44.068 40.8682 43.7731L44.4139 35.4272C44.6644 34.8312 44.4095 34.1361 43.841 33.8654C42.283 33.1023 39.1841 31.5822 36.3945 30.1813C26.3589 25.1082 17.8298 25.4361 11.3055 36.4824Z" fill="url(#paint0_linear_12166_41395)" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M44.6944 17.5433C45.0667 16.9114 45.4848 16.1781 45.84 15.5939C46.1579 15.0349 45.9872 14.3139 45.4562 13.9724L38.0097 9.20339C37.7462 9.01867 37.4208 8.95615 37.1112 9.03074C36.8017 9.10534 36.5359 9.31037 36.3772 9.59684C36.0793 10.1155 35.6955 10.7891 35.2774 11.5104C32.3274 16.5775 29.3603 15.9576 24.0102 13.2988L16.6496 9.66241C16.37 9.52389 16.0484 9.5091 15.7581 9.62141C15.4678 9.73372 15.2336 9.96356 15.1088 10.2585L11.5631 18.6044C11.3126 19.2004 11.5675 19.8955 12.1359 20.1662C13.6939 20.9293 16.7928 22.4494 19.5824 23.8503C29.6409 28.9174 38.1701 28.5776 44.6944 17.5433Z" fill="url(#paint1_linear_12166_41395)" />
            </g>
            <defs>
                <filter id="filter0_d_12166_41395" x="0" y="0" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12166_41395" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_12166_41395" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_12166_41395" x1="44.2134" y1="47.2843" x2="35.2998" y2="27.6354" gradientUnits="userSpaceOnUse">
                    <stop offset="0.18" stop-color="#0052CC" />
                    <stop offset="1" stop-color="#2684FF" />
                </linearGradient>
                <linearGradient id="paint1_linear_12166_41395" x1="11.7865" y1="6.73541" x2="20.714" y2="26.3934" gradientUnits="userSpaceOnUse">
                    <stop offset="0.18" stop-color="#0052CC" />
                    <stop offset="1" stop-color="#2684FF" />
                </linearGradient>
            </defs>
        </svg>
    </div>
</template>

<script setup lang="ts">
</script>

<style>
    .icon-confluence {
     
        font-size: 14px;
        color: rgb(177, 168, 168);
    }
</style>