<template>
  <div class="action-dropdown-container">
    <div
      class="action-dropdown-container dropdown"
      :style="{ width: props.width, height: props.height }"
    >
      <div class="item">
        <IconRobotScreen />
        <span>{{ options.find((m) => m.id === selectedItemId)?.name }}</span>
      </div>
      <IconChevronDown @click="openList()" />
    </div>
    <ul
      v-if="isListOpened"
      class="action-dropdown-container dropdown modal"
      :style="{ width: props.width }"
    >
      <li
        class="item"
        v-for="(item, index) in options"
        :key="index"
        @click="selectItem(item.id)"
      >
        <IconRobotScreen /><span>{{ item.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";

import IconChevronDown from "@/components/icons/IconChevronDown.vue";
import IconRobotScreen from "@/components/icons/IconRobotScreen.vue";
import type { IAssistant } from "../../Models/IAssistant";

const props = defineProps({
  width: {
    type: String,
    default: "220px",
    required: false,
  },
  height: {
    type: String,
    default: "44px",
    required: false,
  },
  options: {
    type: Array<IAssistant>,
    default: [],
    required: true,
  },
});

const emits = defineEmits(["onItemSelected"]);

const selectedItemId = ref<number>(0);
const isListOpened = ref<boolean>(false);

const openList = () => {
  isListOpened.value = !isListOpened.value;
};

const selectItem = (id: number) => {
  selectedItemId.value = id;
  isListOpened.value = !isListOpened.value;
  emits("onItemSelected", selectedItemId.value);
};

onMounted(() => {
  selectedItemId.value = props.options[0].id;
});
</script>

<style scoped lang="scss">
@import "@/assets/mainstyle";

.action-dropdown-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;

  &.dropdown {
    border-radius: 8px;
    background: #071a2b;
    padding: 0px 10px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    .item {
      width: 84%;
      display: flex;
      flex-direction: row;
      gap: 12px;
      justify-content: flex-start;
      padding: 0px 0px 0px 10px;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &.modal {
      max-height: 220px;
      position: absolute;
      flex-direction: column;
      top: 46px;
      overflow-y: scroll;
      align-items: flex-start;
      gap: 8px;
      padding: 10px 5px;
      box-shadow: none;
      @extend .scroll-width;
      /*background-color: #071A2B;*/

      li {
        border-radius: 8px;
        width: 100%;
        padding: 5px 15px;
      }

      :hover {
        cursor: pointer;
        @extend .active;
      }
    }

    @media (max-width: 1024px) {
      width: 130px !important;
    }
  }
}
</style>
