<template>
  <!-- <p>This is the stream line hub view</p> -->
  <TabList class="mb-[44px]" :tabList="tabList" @tabSwitch="handleTabSwitch" />
  <div
    v-if="chosenTab && chosenTab.id === 0"
    class="border border-solid border-[var(--grey-border-line)] rounded-2xl py-5 h-[calc(100vh-200px)] overflow-y-auto"
  >
    <!-- Header parts -->
    <div class="flex gap-5 px-6 mb-5">
      <p class="flex self-center items-end gap-2">
        Automation
        <span
          class="bg-[#FFFFFF17] text-[#A7D0F8] py-0.5 px-1.5 text-xs rounded-md"
        >
          {{ count }}
        </span>
      </p>

      <CustomSearchInput
        :placeholder="'Search'"
        :isLoading="isSearchLoading"
        @update:searchInput="handleFilteredItems"
      />

      <button
        @click="isRequestAutomationModalOpen = true"
        class="whitespace-nowrap w-fit px-1 text-[#7BA8EF]"
      >
        Request Automation
      </button>

      <button
        class="btn btn-primary whitespace-nowrap leading-[16px] h-fit py-[14px] self-center"
        @click="(isModalOpen = true), (isEditMode = false)"
      >
        Add Automation
      </button>

      <button>
        <IconVerticalDots class="w-5 h-5" />
      </button>
    </div>

    <!-- grid -->
    <div class="grid w-full border border-[#FFFFFF17] rounded-lg">
      <!-- Header row -->
      <div
        class="grid grid-cols-[0.75fr,0.75fr,0.75fr,2.10fr,auto,auto] gap-2 bg-[#071A2B] border-b border-t border-[#FFFFFF17]"
      >
        <div class="flex items-center gap-3 pl-5 py-1">
          <CustomCheckbox
            @update:isChecked="handleCheckAll"
            class="border border-solid border-[#333741]"
          />
          <button
            class="flex gap-2 items-center text-[#CBD6E3]"
            @click="toggleSortOrder('name')"
          >
            Name
            <!-- <IconSwap class="w-[15px] h-[14px] text-[#CBD5E1]" /> -->
            <component
              :is="sortIconType.name"
              class="w-[15px] h-[15px] text-white"
            />
          </button>
        </div>

        <!-- Last run -->
        <div class="flex items-center gap-3 py-3.5 text-[#CBD6E3] pl-1">
          <button
            class="flex gap-2 items-center text-[#CBD6E3]"
            @click="toggleSortOrder('lastRun')"
          >
            Last run
            <component
              :is="sortIconType.lastRun"
              class="w-[15px] h-[15px] text-white"
            />
            <!-- <IconArrowDown class="w-[15px] h-[15px] text-[#CBD5E1]" /> -->
          </button>
        </div>

        <!-- Status -->
        <div class="flex gap-3 items-center py-3.5 text-[#CBD6E3]">
          <button
            class="flex gap-2 items-center text-[#CBD6E3]"
            @click="toggleSortOrder('status')"
          >
            Status
            <component
              :is="sortIconType.status"
              class="w-[15px] h-[15px] text-white"
            />
            <!-- <IconArrowDown class="w-[15px] h-[15px] text-[#CBD5E1]" /> -->
          </button>
        </div>

        <div class="w-1/8"><span></span></div>
        <div class="w-2"><span></span></div>
        <div class="w-10"><span></span></div>
      </div>

      <!-- Header Row End -->

      <div
        v-for="(automation, index) in filteredAutomation"
        :key="index"
        class="grid pr-5 grid-cols-[0.75fr,0.75fr,0.75fr,2fr,auto,auto] gap-2 items-center hover:bg-[#162b3d] border-b border-[#FFFFFF17] py-[26px]"
      >
        <!-- Name Column -->
        <div class="flex items-center gap-3 pl-5 text-[#CBD6E3]">
          <CustomCheckbox
            :isChecked="automation.isChecked"
            class="border border-solid border-[#333741]"
          />
          <span class="w-[150px] overflow-hidden truncate">
            {{ automation.name }}
          </span>
        </div>

        <!-- Last run  Column -->
        <div class="text-[#FFFFFF66] flex justify-start">
          {{ automation.lastRun }}
        </div>

        <!-- Status Column -->
        <div class="flex justify-start items-center">
          <div
            class="border border-solid text-[12px] py-1 px-1.5 rounded-md"
            :class="getStatusClass(automation)"
          >
            {{ automation.status }}
          </div>
        </div>

        <!-- Progress Bar Column -->
        <div
          v-if="automation.status === StreamlineStatus.inProgress"
          class="sm:w-[100px] md:w-[200px] lg:w-[250px]"
        >
          <div
            class="bg-gray-600 h-2 rounded overflow-hidden w-[calc(100%-32px)]"
          >
            <div
              class="bg-[#a7d0f8] h-full transition-width duration-300 ease-in-out"
              :style="{ width: mockProgress + '%' }"
            ></div>
          </div>
        </div>

        <div
          v-if="automation.isFailed || automation.isCompleted"
          class="text-gray-500"
        >
          <span
            v-if="automation.isFailed"
            class="text-[#F14D4D] flex gap-3 items-center"
          >
            <IconWarningOctal class="w-5 h-5 gap-3.5" />
            Something went wrong
          </span>

          <span
            v-if="automation.isCompleted"
            class="text-[#68C57C] flex gap-3 items-center"
          >
            <IconCheckRounded class="w-5 h-5 gap-3.5" />
            Successfully completed
          </span>
        </div>
        <!-- Placeholder -->

        <!-- Action Button Column -->
        <div class="flex justify-end item-center">
          <button
            @click="
              automation.status === StreamlineStatus.inProgress
                ? CancelAutomation(automation.id)
                : RunStreamlineAutomation(automation.id)
            "
            class="btn border border-solid"
            :class="{
              'border-[#F14D4D] text-[#F14D4D]':
                automation.status === StreamlineStatus.inProgress,
              'btn-primary':
                automation.status === StreamlineStatus.inActive ||
                automation.status === StreamlineStatus.active,
            }"
          >
            {{
              automation.status === StreamlineStatus.inProgress
                ? "Stop"
                : automation.status === StreamlineStatus.active
                ? "Run"
                : "Run"
            }}
          </button>
        </div>

        <!-- Options Button Column -->
        <div class="relative flex items-center">
          <button @click="toggleModal(index)" class="relative">
            <IconVerticalDots class="w-5 h-5" />

            <!-- subModal -->
            <div
              v-if="showSubModal[index]"
              class="absolute flex flex-col w-max min-w-56 left-[-222px] bg-[#071A2B] rounded-xl"
            >
              <button
                class="text-white flex hover:bg-slate-700 rounded-xl gap-3 items-center py-3 px-4"
                @click="openEditModal(automation)"
              >
                <IconEdit class="h-4 w-4 text-white" /> Edit
              </button>

              <button
                class="text-[#F14D4D] py-3 hover:bg-slate-700 rounded-xl items-center gap-3 px-4 flex"
                @click="deleteAutomation(automation.id, automation.name)"
              >
                <IconDelete class="text-[#F14D4D] h-4 w-4" /> Remove Automation
              </button>
            </div>
          </button>
        </div>
      </div>
    </div>
    <!-- grid end -->

    <!-- Pagination -->

    <Pagination
      v-if="filteredAutomation.length"
      class="pt-[14px] pb-[18px] px-6"
      :current-page="currentPage"
      :items-per-page="10"
      :total-items="count"
      @update:current-page="handlePageChange"
    />
  </div>

  <div class="flex" v-if="chosenTab?.id !== 0">
    <span class="m-auto text-gray-500 text-xl font-semibold">
      Coming soon
    </span>
  </div>

  <ModalOverlay
    v-if="isModalOpen"
    :disableClickOutside="true"
    headerClass="!text-[20px] !font-bold"
    :modalTitle="
      isEditMode
        ? 'Update a streamline Automation'
        : 'Add a streamline Automation'
    "
    @close="closeModal"
  >
    <div class="flex items-center justify-center flex-col gap-3">
      <div
        class="border border-dashed border-spacing-1 border-[#A7D0F8] flex w-fit px-4 py-3"
      >
        <IconStreamLineHub class="h-[75px] w-[75px]" />
      </div>

      <p class="text-[17px] font-medium text-center">
        Please upload your automation Python scripts
        <br />
        <span v-if="!isEditMode" class="text-[#FFFFFF66] text-sm">
          Code structure according to this
          <a
            class="text-[#7BA8EF] border-b border-solid border-[#7BA8EF]"
            href=""
            >documentation </a
          ><br />
        </span>
        <span class="text-[#FFFFFF66] text-sm">
          The script file must be named
        </span>
        <span class="text-sm font-bold">main.py</span>
      </p>

      <div class="w-full flex flex-col gap-[10px] max-w-96">
        <div>
          <!-- Input Field -->
          <input
            type="text"
            class="bg-[#071A2B] text-white w-full px-3 py-2 rounded-lg border border-solid border-[#FFFFFF33]"
            placeholder="Name"
            v-model="automationServiceName"
            @blur="isInputFocused = true"
            @keypress="nameInputChanged"
          />
          <span
            v-if="!automationServiceName?.trim() && isInputFocused"
            class="text-red-500 text-sm ml-2"
            >Name is required</span
          >
        </div>

        <!-- File list -->
        <!-- <div v-if="isEditMode && currentFileInfo">
          <div
            class="flex justify-between border border-solid border-[#FFFFFF66] rounded-2xl p-3"
          >
            <div class="flex gap-[10px]">
              <IconFile class="w-5 h-5 self-start stroke-white" />
              <div class="flex flex-col leading-[20px]">
                <span class="font-medium">{{ currentFileInfo.fileName }}</span>
                <span class="text-[0.9rem] text-gray-400">{{
                  formatSize(currentFileInfo.fileSize)
                }}</span>
              </div>
            </div> -->

        <!-- Chosen File list -->
        <div v-if="isEditMode && currentFileInfo">
          <div
            class="flex justify-between border border-solid border-[#FFFFFF66] rounded-2xl p-3"
          >
            <div class="flex gap-[10px]">
              <IconFile class="w-5 h-5 self-start stroke-white" />
              <div class="flex flex-col leading-[20px]">
                <span class="font-medium">{{ currentFileInfo.fileName }}</span>
                <span class="text-[0.9rem] text-gray-400">{{
                  formatSize(currentFileInfo.fileSize)
                }}</span>
              </div>
            </div>

            <!-- Delete button -->
            <button
              class="bg-transparent border-0 text-[#e53e3e] cursor-pointer text-[1.2rem] p-0"
              @click="removeAutomationFile"
            >
              <IconDelete class="w-4 h-4" />
            </button>
          </div>
        </div>

        <!-- Drag-and-Drop Zone -->
        <div
          class="text-center rounded-xl border border-solid bg-[#FFFFFF17] border-[#A7D0F8] px-6 py-4 text-[#cbd6e3] text-sm font-normal"
          @dragover.prevent="handleDragOver"
          @dragleave="handleDragLeave"
          @drop.prevent="handleDrop"
          :class="{ 'bg-[#a7d0f84f] border-[#fff]': isDragging }"
        >
          <IconCloudUpload
            class="border border-white p-2.5 rounded-lg mb-3 w-10 h-10 mx-auto"
          />
          <div @click="triggerFileInput">
            <input
              ref="fileInput"
              type="file"
              @change="handleFileSelect"
              accept=".zip, .py, .java"
              style="display: none"
            />
            <label class="block"
              ><span class="text-[#a7d0f8] font-semibold cursor-pointer"
                >Click to upload</span
              >
              or drag and drop</label
            >
            <span class="text-gray-500 text-xs"
              >Zip, Py, Java (max. 10 MB)</span
            >
          </div>

          <!--New File List -->
          <div>
            <div
              v-if="files.length"
              class="max-h-[300px] overflow-y-auto flex flex-col gap-2.5 mt-3"
            >
              <div
                v-for="(file, index) in files"
                :key="index"
                class="p-4 items-start rounded-[16px] border border-[#333741] pt-6"
                :class="{
                  'border border-solid border-[#e53e3e]':
                    file.status === 'failed',
                }"
              >
                <div class="flex justify-between">
                  <div class="flex gap-[10px]">
                    <IconFile
                      class="w-5 h-5 self-start stroke-white"
                      :class="{
                        'text-[#e53e3e]': file.status === 'failed',
                      }"
                    />
                    <div class="flex flex-col leading-[20px]">
                      <span class="font-medium">{{ file.name }}</span>
                      <span
                        v-if="file.status !== 'failed'"
                        class="text-[0.9rem] text-gray-400 text-left"
                        >{{ formatSize(file.size) }}</span
                      >
                    </div>
                  </div>

                  <div class="text-xl ml-2.5">
                    <IconDelete
                      class="text-[#E71D41] h-5 w-5 cursor-pointer"
                      @click.prevent="removeFile(index)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Validators -->
        <div v-if="fileErrors.length" class="flex flex-col gap-3 items-center">
          <span
            class="flex gap-2 items-center text-sm"
            v-for="(note, index) in fileErrors"
            :key="index"
          >
            <IconTick
              class="w-5 h-5 p-1 rounded-full bg-[#A7D0F8] text-white"
              v-if="note.success"
            />
            <IconCross
              class="w-5 h-5 p-1 rounded-full bg-[#F14D4D] text-white"
              v-if="note.error"
            />
            {{ note.error ? note.error : note.success }}
          </span>
        </div>

        <!-- Documentation -->
        <div
          v-if="fileErrors.find((note) => note.error)"
          class="border border-solid border-white p-4 gap-4 rounded-3xl flex"
        >
          <IconInfoRounded
            class="h-8 w-8 border-4 stroke-2 border-[#FFFFFF33] text-white rounded-full p-1 border-solid"
          />
          <p class="text-sm">
            Please verify your code structure according to<br />
            this <a href="#" class="text-[#7BA8EF]">documentation</a>
          </p>
        </div>

        <button
          class="btn btn-primary mt-5"
          :disabled="!canUpload()"
          :class="{ 'btn-disabled': !canUpload() }"
          @click="uploadFile"
        >
          {{ isEditMode ? "Update" : "Upload" }}
        </button>
      </div>
    </div>
    <!-- </div> -->
    <!-- </div> -->
  </ModalOverlay>

  <!-- Toast message -->
  <div
    v-if="toastMessageStatus !== FileUploadStatus.idle"
    class="sticky w-full bottom-0 bg-[--color-background-sidebar]"
  >
    <div class="border border-solid border-white p-4 gap-4 rounded-3xl flex">
      <IconInfoRounded
        v-if="
          toastMessageStatus === FileUploadStatus.uploading ||
          toastMessageStatus === FileUploadStatus.failed
        "
        class="h-8 w-8 border-4 stroke-2 rounded-full p-1 border-solid text-white"
        :class="{
          'border-[#FFFFFF33] bg-[#FFFFFF17]':
            toastMessageStatus === FileUploadStatus.uploading,
          'border-[#F79009] bg-[#FDB022]':
            toastMessageStatus === FileUploadStatus.failed,
        }"
      />

      <IconCheckRounded
        v-if="toastMessageStatus === FileUploadStatus.completed"
        class="h-8 w-8 border-4 stroke-2 rounded-full p-1 border-solid bg-[#68C57C] border-[#17B26A] text-white"
      />
      <div class="w-full">
        <p class="text-sm flex flex-col">
          <span class="font-semibold">
            {{ toastMessages.title }}
          </span>
          <span class="font-medium">
            {{ toastMessages.description }}
          </span>
        </p>

        <!-- Progress bar -->
        <div
          v-if="toastMessageStatus === FileUploadStatus.uploading"
          class="flex gap-3 w-full mt-2.5 items-center"
        >
          <div
            class="bg-gray-600 h-2 a overflow-hidden my-1 w-[calc(100%-32px)]"
          >
            <div
              class="bg-[#a7d0f8] h-full transition-width duration-300 rounded ease-in-out"
              :style="{ width: progress + '%' }"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ModalOverlay
    v-if="isRequestAutomationModalOpen"
    :disableClickOutside="false"
    headerClass="!text-[20px] !font-bold"
    modalTitle="Request Automation"
    @close="isRequestAutomationModalOpen = false"
  >
    <AutomationRequest />
  </ModalOverlay>

  <div
    v-if="isLoading"
    class="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-[#031525c4] backdrop-blur-[4px] z-[1000] overflow-auto scroll-width"
  >
    <Spinner />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref } from "vue";
import TabList from "../common/TabList.vue";
import CustomSearchInput from "../common/CustomSearchInput.vue";
import {
  IconVerticalDots,
  IconEdit,
  IconDelete,
  IconArrowDown,
  IconStreamLineHub,
  IconCloudUpload,
  IconFile,
  IconTick,
  IconCross,
  IconInfoRounded,
  IconCheckRounded,
  IconSortIdle,
  IconArrowUp,
} from "@/components/icons";
import Spinner from "../common/Spinner.vue";
import CustomCheckbox from "../common/CustomCheckbox.vue";
import { FileUploadStatus, StreamlineStatus } from "@/enums";
import type {
  IAutomationList,
  IEditFileInfo,
  IFileErrorList,
  IStreamSortOptions,
} from "@/Models";
import ModalOverlay from "../common/ModalOverlay.vue";
import AutomationRequest from "./AutomationRequest.vue";
import { streamlineService } from "@/Services/StreamlineService";
import type {
  IStreamlineAutomationListingResponse,
  IStreamlineAutomationsQuery,
  IStreamlineAutomationUpdateRequest,
} from "@/Models/IStreamline";
import type { IStreamlineUploadFilesRequest } from "@/Models/IStreamlineUploadFilesRequest ";
import AppConfigGlobal from "@/core/config/uiSettings";
import Pagination from "../common/Pagination.vue";
import IconWarningOctal from "../icons/IconWarningOctal.vue";
import { getFileNameFromUrl } from "@/helpers";
import JSZip from "jszip";
import { openDeleteConfirmModal } from "@/utility/modal.utility";

const service = new streamlineService();
const automationList = ref<IAutomationList[]>([]);
const abortControllers: Record<number, AbortController> = {};
const tabList = ref([
  {
    title: "Overview",
    id: 0,
  },
  {
    title: "Builder",
    id: 1,
  },
  {
    title: "Settings",
    id: 2,
  },
]);
const isDragging = ref(false);
const automationServiceName = ref<string | null>(null);
const progress = service.progress;
const currentFileInfo = ref<IEditFileInfo | null>(null);
const toastMessageStatus = ref<FileUploadStatus>(FileUploadStatus.idle);
const fileInput = ref<HTMLInputElement | null>(null);
const isModalOpen = ref(false);
const fileErrors = ref<IFileErrorList[]>([]);
const isRequestAutomationModalOpen = ref(false);
const fileToUpload = ref<File>();
const isNameValid = ref<boolean>(true);
const isNameDuplicated = ref<boolean>(false);
const isInputFocused = ref(false);
const isProjectValid = ref<boolean>(false);
const isLoading = ref<boolean>(false);
const isSearchLoading = ref(false);
const sortOption = ref<IStreamSortOptions>({
  name: null,
  lastRun: null,
  status: null,
});
const automationDetailsToUpdate = ref<IStreamlineAutomationUpdateRequest>(
  {} as IStreamlineAutomationUpdateRequest
);
const chosenTab = ref<{ title: string; id: number } | null>(tabList.value[0]);
const currentPage = ref<number>(1);
const isEditMode = ref(false);
const mockProgress = ref<number>(15);
const files = ref<{ name: string; size: number; status: string }[]>([]);
const filteredAutomation = ref<IAutomationList[]>([]);
const errorText = ref<string | null>(null);
const searchText = ref<string>("");

const handleFilteredItems = async (inputVal: string | null) => {
  isSearchLoading.value = true;
  await fetchData(inputVal);
  isSearchLoading.value = false;
  searchText.value = inputVal || "";
};

// Toggle sort order and reset other fields
const toggleSortOrder = async (sortBy: keyof typeof sortOption.value) => {
  for (const key in sortOption.value) {
    if (Object.prototype.hasOwnProperty.call(sortOption.value, key)) {
      const field = key as keyof typeof sortOption.value; // Type assertion for valid key
      sortOption.value[field] =
        field === sortBy
          ? sortOption.value[field] === "desc"
            ? "asc"
            : "desc"
          : null;
    }
  }
  isLoading.value = true;
  await fetchData(searchText.value);
};

// Computed property for icons
const sortIconType = computed(() =>
  Object.keys(sortOption.value).reduce((icons, key) => {
    const order = sortOption.value[key as keyof typeof sortOption.value];
    icons[key] =
      order === "asc"
        ? IconArrowUp
        : order === "desc"
        ? IconArrowDown
        : IconSortIdle;
    return icons;
  }, {} as Record<string, typeof IconArrowUp | typeof IconArrowDown | typeof IconSortIdle>)
);

// Trigger file input click
const triggerFileInput = async () => {
  if (isEditMode.value && currentFileInfo.value) {
    await openDeleteConfirmModal(
      "Replace File",
      "Are you sure you want to replace the automation file?"
    ).then((res) => {
      if (res) {
        currentFileInfo.value = null;
        fileInput.value?.click();
      } else {
        return;
      }
    });
    return;
  }
  fileInput.value?.click();
};

// Single computed property for messages
const toastMessages = computed(() => {
  switch (toastMessageStatus.value) {
    case FileUploadStatus.uploading:
      return {
        title: "Uploading",
        description: "We're currently uploading your automation",
      };
    case FileUploadStatus.completed:
      return {
        title: "Upload completed",
        description: "We have uploaded your automation successfully",
      };
    case FileUploadStatus.failed:
      return {
        title: "Something went wrong",
        description:
          "An error occurred. Please try again later or contact the administrator",
      };
    default:
      return { title: "", description: "" };
  }
});

const openEditModal = (automation: IAutomationList) => {
  currentFileInfo.value = {
    fileName: getFileNameFromUrl(automation.url || "") || "",
    fileSize: 400,
  };

  // files.value.push({
  //   name: getFileNameFromUrl(automation.url || "") || "",
  //   size: 400,
  //   status: StreamlineStatus.active,
  // });
  automationServiceName.value = automation.name;
  automationDetailsToUpdate.value.id = automation.id;
  //automationDetailsToUpdate.value.Name = automationServiceName.value;
  automationDetailsToUpdate.value.OrganizationId =
    AppConfigGlobal.CurrentOrganizationId;
  isModalOpen.value = true;
  isEditMode.value = !!automation.id;
};

const closeModal = () => {
  files.value = [];
  isModalOpen.value = false;
  fileErrors.value = [];
  automationServiceName.value = "";
  isInputFocused.value = false;
  isNameDuplicated.value = false;
  isEditMode.value = false;
  isNameValid.value = true;
};

const RunStreamlineAutomation = async (id: number) => {
  const automation = automationList.value.find(
    (auto: IAutomationList) => auto.id === id
  );
  if (!automation) {
    console.error(`Automation with ID ${id} not found`);
    return;
  }
  automation.status = StreamlineStatus.inProgress;

  const controller = new AbortController();
  abortControllers[id] = controller;

  try {
    await service.runStreamlineAutomation(id, controller.signal).then(() => {
      // Callback function to reset progress
      incrementProgress(() => {
        automation.status = StreamlineStatus.active;
        automation.isCompleted = true;

        setTimeout(() => {
          automation.isCompleted = false;
        }, 3000);
        automation.lastRun = new Date().toLocaleDateString("de-DE");
        delete abortControllers[id];
      });
    });
  } catch (error: any) {
    if (error.name === "CanceledError") {
      automation.status = StreamlineStatus.active;
      return;
    }
    automation.status = StreamlineStatus.active;
    automation.isFailed = true;

    setTimeout(() => {
      automation.isFailed = false;
      automation.status = StreamlineStatus.active;
      delete abortControllers[id];
    }, 3000);
  }
};

// Mock progress bar
function incrementProgress(onComplete: Function) {
  const steps = [35, 70, 100];
  const duration = 2000;
  const interval = duration / steps.length;
  let currentIndex = 0;

  const intervalId = setInterval(() => {
    mockProgress.value = steps[currentIndex];
    currentIndex++;

    if (currentIndex >= steps.length) {
      clearInterval(intervalId);

      // Callback
      setTimeout(() => {
        mockProgress.value = 0; // reset
        onComplete();
      }, 1000);
    }
  }, interval);
}

const CancelAutomation = (id: number) => {
  const controller = abortControllers[id];
  if (controller) {
    controller.abort();
    delete abortControllers[id];
    const automation = automationList.value.find(
      (auto: IAutomationList) => auto.id === id
    );
    if (automation) automation.status = StreamlineStatus.active;
  }
};

const fetchData = async (searchTerm?: string | null) => {
  count.value = await service.getCount();

  const activeSortColumn = Object.keys(sortOption.value).find((key) => {
    return sortOption.value[key as keyof typeof sortOption.value] !== null;
  }) as keyof typeof sortOption.value | undefined;

  const activeSortOrder = activeSortColumn
    ? sortOption.value[activeSortColumn]
    : null;
  const sortColumnMapping: Record<string, string> = {
    name: "Name",
    lastRun: "RunDate",
    status: "Status",
  };

  const mappedSortColumn = activeSortColumn
    ? sortColumnMapping[activeSortColumn as string] || String(activeSortColumn)
    : "";
  const query: IStreamlineAutomationsQuery = {
    organizationId: AppConfigGlobal.CurrentOrganizationId,
    pageNumber: currentPage.value,
    pageSize: 10,
    searchTerm: searchTerm || "",
    sortColumn: mappedSortColumn ? String(mappedSortColumn) : "",
    sortOrder: activeSortOrder || "",
  };
  const fileList = await service.getAllAutomations(query);

  automationList.value = fileList.map(
    (file: IStreamlineAutomationListingResponse) => ({
      name: file.name.replace(/\.zip$/, ""),
      lastRun: file.lastRun
        ? new Date(file.lastRun).toLocaleDateString("de-DE")
        : "",
      status: file.status
        ? (file.status as StreamlineStatus)
        : StreamlineStatus.inActive,
      id: file.id,
      url: file.fileUrl || "",
    })
  );
  filteredAutomation.value = automationList.value;
  isLoading.value = false;
};

const removeAutomationFile = () => {
  currentFileInfo.value = null;
};

const deleteAutomation = async (id: number, name: string) => {
  try {
    var res = await service.deleteAutomationById(id, name);
    if (res) {
      isLoading.value = true;
      await fetchData();
    }
  } catch (error) {
    // console.error(`Failed to delete automation with id ${id}:`, error);
  }
};

const nameInputChanged = () => {
  if (isNameDuplicated.value) {
    isNameDuplicated.value = false;
  }
};

// Handle file selection
const handleFileSelect = async (event: Event) => {
  let selectedFiles: FileList | File[] | null = [];

  // Check if the event is a drag-and-drop event
  if ((event as DragEvent).dataTransfer?.files) {
    selectedFiles = (event as DragEvent).dataTransfer?.files || [];
  } else {
    selectedFiles = (event.target as HTMLInputElement).files;
  }

  if (selectedFiles) {
    for (const file of selectedFiles) {
      await validateFile(file);

      fileToUpload.value = file;
      files.value.push({
        name: file.name,
        size: file.size,
        status: "ready",
      });
    }
  }
};

const handleDragOver = (event: DragEvent) => {
  event.preventDefault();
  isDragging.value = true;
};

const handleDragLeave = () => {
  isDragging.value = false;
};

const handleDrop = async (event: DragEvent) => {
  event.preventDefault();
  isDragging.value = false;
  await handleFileSelect(event); // Reuse the `handleFileSelect` method
};

const removeFile = (index: number) => {
  files.value.splice(index, 1);
  fileErrors.value = [];
};

const formatSize = (size: number) => {
  if (size < 1024) {
    return size + " B";
  } else if (size < 1024 * 1024) {
    return (size / 1024).toFixed(2) + " KB";
  } else {
    return (size / 1024 / 1024).toFixed(2) + " MB";
  }
};
const handlePageChange = async (page: number) => {
  currentPage.value = page;
  isLoading.value = true;
  await fetchData();
};

const isFileValid = () => {
  if (!fileToUpload.value && isEditMode.value == true) {
    isProjectValid.value = true;
    return true;
  } else if (!fileToUpload.value) return false;
  if (fileToUpload.value.size > 10 * 1024 * 1024) {
    // 10 MB size limit
    fileErrors.value.push({ error: "File exceeds 10 MB size limit" });
    return false;
  }
  if (
    !fileToUpload.value.name.endsWith(".py") &&
    !fileToUpload.value.name.endsWith(".zip") &&
    !fileToUpload.value.name.endsWith(".java")
  ) {
    fileErrors.value.push({
      error: "Invalid file type. Accepted: .zip, .py",
    });
    return false;
  }
  return true;
};

const canUpload = () => {
  const automationName = automationServiceName.value?.trim();

  const isFileAvailable = isEditMode.value
    ? currentFileInfo.value || files.value.length
    : files.value.length;

  return !!(
    automationName &&
    isFileValid() &&
    isProjectValid.value &&
    isFileAvailable
  );
};

const validateFile = async (file: File) => {
  if (!file) {
    fileErrors.value = [{ error: "No file selected or invalid file type." }];
    return;
  }

  try {
    const zip = await JSZip.loadAsync(file);
    const requiredFiles = [
      "requirements.txt",
      "config.freddy.json",
      "knowledge.py",
      "documentation.txt",
      "execution_log.json",
    ];
    const allFiles = Object.keys(zip.files);
    const projectDirPattern = /^(?:[^/]+\/)*src\/main\/[^/]+\.py$/;

    // Check for src/main directory
    const matchingDirs = allFiles.filter((file) =>
      projectDirPattern.test(file)
    );

    const dirExists = matchingDirs.length > 0;
    if (!dirExists) {
      throw new Error(
        "'src/main' directory (or projectname/src/main/ .py) is missing."
      );
    }

    // Check for .py file in src/main directory
    const pyFileExists = allFiles.some((file) => projectDirPattern.test(file));
    if (!pyFileExists) {
      throw new Error("No .py file found inside the 'src/main' directory.");
    }

    // Check for required files
    const missingFiles = requiredFiles.filter(
      (reqFile) => !allFiles.some((file) => file.endsWith(reqFile))
    );
    if (missingFiles.length > 0) {
      throw new Error(
        `The following files are missing: ${missingFiles.join(", ")}`
      );
    }

    fileErrors.value = [];
    isProjectValid.value = true;
    fileErrors.value = [
      { success: "main.py script structure" },
      { success: "Code structure" },
    ];
  } catch (error) {
    isProjectValid.value = false;
    fileErrors.value = [
      {
        error:
          "Something went wrong with the file. Try reuploading or try another file.",
      },
    ];
  }
};

const showSubModal = reactive<{ [key: number]: boolean }>({});

const count = ref<number>(0);

function getStatusClass(automation: IAutomationList) {
  return automation.status === StreamlineStatus.active
    ? "border-[#68C57C] text-[#68C57C]"
    : automation.status === StreamlineStatus.inProgress
    ? "border-[#A7D0F8] text-[#A7D0F8]"
    : automation.status === StreamlineStatus.inActive
    ? "border-[#FFFFFF66] text-[#FFFFFF66]"
    : "";
}

// Methods
const handleTabSwitch = (tabId: number) => {
  const res = tabList.value.find((tab) => tab.id === tabId) || null;
  chosenTab.value = res;
};

const handleCheckAll = (isChecked: boolean) => {
  filteredAutomation.value.map((item) => {
    item.isChecked = isChecked;
  });
};

// Function to toggle the modal for a specific row
const toggleModal = (index: number) => {
  // If the clicked modal is already open, close it
  if (showSubModal[index]) {
    showSubModal[index] = false;
  } else {
    // Close all other modals
    Object.keys(showSubModal).forEach((key: any) => {
      showSubModal[key] = false;
    });

    // Open the modal for the clicked index
    showSubModal[index] = true;
  }
};
const convertToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve((reader.result as string).split(",")[1]); // Get base64 part
    reader.onerror = (error) => reject(error);
  });
};

const updateAutomation = async () => {
  try {
    automationDetailsToUpdate.value.Name =
      automationServiceName.value as string;

    if (fileToUpload.value) {
      const base64File = await convertToBase64(fileToUpload.value);
      automationDetailsToUpdate.value.FileBytes = base64File;
    }

    await service.updateAutomationById(automationDetailsToUpdate.value);
    handleToasterState(FileUploadStatus.completed);
  } catch (err: any) {
    handleToasterState(FileUploadStatus.failed);
    errorText.value = err?.response?.data || "An unknown error occurred.";
  } finally {
    closeModal();
  }
};

const handleToasterState = (status: FileUploadStatus) => {
  toastMessageStatus.value = status;

  setTimeout(() => {
    toastMessageStatus.value = FileUploadStatus.idle;
  }, 3000);
};

const uploadFile = async () => {
  if (!canUpload()) return;

  if (isEditMode.value) {
    updateAutomation();
    return;
  }

  if (fileToUpload.value) {
    const base64File = await convertToBase64(fileToUpload.value);
    const requestData: IStreamlineUploadFilesRequest = {
      zipFile: base64File,
      name: automationServiceName.value?.trim() || "",
      organizationId: AppConfigGlobal.CurrentOrganizationId,
      fileSize: fileToUpload.value.size,
      originalFileName: fileToUpload.value.name,
    };

    isModalOpen.value = false;
    // isToastMessage.value = true;
    handleToasterState(FileUploadStatus.uploading);
    // closeModal();
    await service
      .fileUploaderWithProgress(requestData)
      .then(() => {
        setTimeout(() => {
          handleToasterState(FileUploadStatus.completed);
        }, 3000);
      })
      .catch((err) => {
        handleToasterState(FileUploadStatus.failed);
        errorText.value = err?.response?.data;
      })
      .finally(async () => {
        closeModal();
        isLoading.value = true;
        await fetchData();
      });
  }
};

onMounted(async () => {
  isLoading.value = true;
  await fetchData();
});
</script>

<style lang="scss">
.stroke-2 {
  stroke-width: 2px;
}
</style>
