<template>
  <div class="rounded-lg flex w-[100%] py-4 px-2 border border-[#FFFFFF33] relative" >
    <div class="flex items-start gap-2 w-full" @click="focusInput">
      <!-- <IconSearch class="w-[18px] h-[24px]" /> -->
      <div class="flex items-center  flex-wrap w-full">
        <div v-for="(tag, index) in tags" :key="index"
          class="rounded-[6px] bg-[#071A2B] ml-2 flex items-center gap-[3px] px-[5px] py-[2px] border border-[#FFFFFF33] bg-[#11385B] text-[16px] font-normal !z-30">
          <div class="flex items-center gap-[3px]">
            <div v-if="tag.iconSrc" class="relative w-[16px] h-[16px] overflow-hidden">
              <img :src="tag.iconSrc" :alt="tag.storeName + ' icon'"
                class="absolute inset-0 w-full h-full object-cover" />
              <div class="relative rounded-full border border-white h-[16px]"></div>
            </div>
            <span class="text-[#CBD6E3] bodySmall">{{ tag.storeName }}</span>
          </div>
          <button class="flex justify-center items-center w-[16px] h-[16px] p-0.5 bg-none border-0 cursor-pointer"
            @click="removeTag(index)" aria-label="Remove tag">
            x
            <!-- <IconClose class="w-3 h-3" /> -->
          </button>
        </div>
        <div class="absolute flex items-center bg-[#071A2B] border-[#FFFFFF33] font-roboto rounded-lg w-[97%] left-1" :style="{marginLeft: props.marginLeft}">
          <img v-if="searchIcon" loading="lazy" src="../../assets/search.svg" alt="Search icon" 
            class="w-4 h-4 text-![#CBD6E3]" />
          <input type="text"
          :class="{'ml-1': !searchIcon, 'pl-3': searchIcon}" 
            class="absolute ml-3 flex-1 text-base font-normal leading-6 bg-transparent border-none outline-none w-full !truncate md:!overflow-hidden"
            
            ref="searchInput" v-model="inputValue" @input="handleInput" @click="flag = !flag" 
            @keydown.down.prevent="moveDown" @keydown.up.prevent="moveUp"  @keydown.enter.prevent="selectHighlighted"
            :placeholder="tags.length === 0 ? placeholder : ''" />
          <img v-if="dropDownArrow && tags.length === 0" @click="flag = !flag" loading="lazy" src="../../assets/Chevron_down.svg"
            alt="Chevron icon"  class="absolute right-2 w-3 h-3 text-[#A7D0F8] cursor-pointer" />
        </div>
        <div class=" overflow-visible">
          <div v-if="!isDropdownVisible" ref="dropdownRef">
            <ul v-if="flag && filteredSuggestions.length"
              class="absolute top-full left-0 right-0 border border-[#FFFFFF33] rounded-[6px] mt-1 z-10 max-h-[150px]  px-0 py-1 overflow-y-auto  scrollbar-thin bg-[#071A2B] dropdown">
              <li v-for="(suggestion, index) in filteredSuggestions" :key="index" class="hover:bg-[#071A2B]" :class="[
                'flex items-center gap-2 p-2.5 mb-0.5 cursor-pointer',
                {
                  highlighted:
                    index === highlightedIndex || suggestion.isSelected,
                },
              ]" @click="selectSuggestion(index)" @mouseover="highlightedIndex = index">
                <img v-if="suggestion.iconSrc" :src="suggestion.iconSrc"
                  class="flex justify-center items-center w-6 h-6 rounded-full bg-[#d6cfb7]"
                  style="background-image: url('<path-to-image>'); background-size: cover; background-position: 50%;"
                  alt="" />
                <span class="body">{{ suggestion.storeName }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { IconSearch } from "@/components/icons";
import type { IVectorStore } from "@/Models";

const props = defineProps({
  listOfItems: {
    type: Array<IVectorStore>,
    default: () => [],
  },
  fileIndex: { type: Number, default: -1 },
  placeholder: {
    type: String,
    default: "Please select multiple files to use this function",
  },
  searchIcon: {
    type: Boolean,
    default: false,
  },
  dropDownArrow: {
    type: Boolean,
    default: false,
  },
  disableClickOutside: {
    type: Boolean,
    deafault: false,
  },
leftPadding: {
  type: String,
  default: ""
},
marginLeft: {
    type: String,
    default: "0px",
  },
});

const emit = defineEmits(['update:tags']); // Define the emit event
const tags = ref<IVectorStore[]>([]);
const suggestions = ref<IVectorStore[]>(props.listOfItems);
const inputValue = ref("");
const highlightedIndex = ref(-1);
const flag = ref(false);
const searchInput = ref<HTMLInputElement | null>(null);
const dropdownRef = ref<HTMLElement | null>(null);
const isDropdownVisible = ref(false);

const filteredSuggestions = computed(() =>
  suggestions.value.filter((suggestion) =>
    suggestion.storeName.toLowerCase().includes(inputValue.value.toLowerCase())
  )
);

const focusInput = () => {
  searchInput.value?.focus();
};

const removeTag = (index: number) => {
  tags.value.splice(index, 1);
  emit("update:tags", tags.value, props.fileIndex); // Emit updated tags array
};

const handleInput = () => {
  highlightedIndex.value = -1; // Reset the highlight when typing
};

const selectSuggestion = (index: number) => {
  if (!filteredSuggestions.value[index].isSelected) {
    tags.value.push(filteredSuggestions.value[index]);
  } else {
    tags.value = tags.value.filter(
      (tag) => tag.storeName !== filteredSuggestions.value[index].storeName
    );
    console.log(" tags.value", tags.value)
  }
  filteredSuggestions.value[index].isSelected = !filteredSuggestions.value[index].isSelected;
  inputValue.value = "";
  emit("update:tags", tags.value, props.fileIndex);
  console.log(tags.value)
};

const moveDown = () => {
  if (highlightedIndex.value < filteredSuggestions.value.length - 1) {
    highlightedIndex.value++;
  }
};

const moveUp = () => {
  if (highlightedIndex.value > 0) {
    highlightedIndex.value--;
  }
};

const selectHighlighted = () => {
  if (highlightedIndex.value >= 0) {
    selectSuggestion(highlightedIndex.value);
  }
};

const handleClickOutside = (event: MouseEvent) => {
  const target = event.target as HTMLElement;
  if (dropdownRef.value && !dropdownRef.value.contains(target)) {
    flag.value = false; 
    isDropdownVisible.value = false; 
  }
};

onMounted(() => {
  document.addEventListener("click", handleClickOutside, true);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside, true);
});
</script>

<!-- <style scoped>
.input-container {
  border-radius: 12px;
  display: flex;
  width: 100%;
  padding: 8px 14px;
  border: 1px solid #ffffff33;
  position: relative;
}

.input-content {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.search-icon {
  width: 18px;
  height: 24px;
}

.tag-list {
  display: flex;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;
}

.tag {
  border-radius: 6px;
  background-color: rgba(7, 26, 43, 1);
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 2px 4px 2px 5px;
  border: 1px solid rgba(255, 255, 255, 0.4);
}

.tag-content {
  display: flex;
  align-items: center;
  gap: 5px;
}

.tag-icon-wrapper {
  position: relative;
  width: 16px;
  height: 16px;
  overflow: hidden;
}

.tag-icon {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tag-icon-border {
  position: relative;
  border-radius: 133px;
  border: 1px solid rgba(255, 255, 255, 1);
  height: 16px;
}

.tag-name {
  color: var(--Text-Tetriary, #cbd6e3);
}

.tag-close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  padding: 2px;
  background: none;
  border: none;
  cursor: pointer;
}

.close-icon {
  width: 12px;
  height: 12px;
  object-fit: contain;
}

.input-field {
  flex: 1;
  background: none;
  border: none;
  color: var(--Text-Tetriary, #cbd6e3);
  outline: none;
}

.input-field::placeholder {
  color: var(--Text-Tetriary, #cbd6e3);
  opacity: 0.7;
}

.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 6px;
  margin-top: 4px;
  z-index: 10;
  max-height: 150px;
  overflow-y: auto;
  padding-inline-start: 0px;
  scrollbar-width: thin;
  padding: 4px;
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  margin-bottom: 2px;
  cursor: pointer;
}

.dropdown-item.highlighted {
  border-radius: var(--radius-sm, 6px);
  /* background: var(--Background-secondary, #071a2b); */
}

.dropdown-item-icon {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
  background: var(--avatar-user-square-phoenix-baker-color-background,
      url(<path-to-image>) lightgray 50% / cover no-repeat,
      #d6cfb7);
}
</style> -->
