const scrollToElement = (targetElement: string) => {
  const target: HTMLElement | null = document.getElementById(targetElement); // Use the ID of the target element
  if (target) {
    target.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
};

export { scrollToElement };
