import { createRouter, createWebHistory } from "vue-router";

// Define your routes
const routes = [
  {
    path: "/icons-list",
    name: "DevIconList",
    component: () => import("@/views/IconsListForDev.vue"),
  },
  {
    path: "/stream-line",
    name: "Stream-line",
    component: () => import("@/views/Stream-line.vue"),
  },
];

// Create and configure the router
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL), // Use Vite's `import.meta.env.BASE_URL`
  routes,
});

export default router;
