<template>
    <main v-if="userDetail" class="flex h-fit overflow-hidden gap-5 flex-col pt-5">
        <header class="flex flex-col w-full max-md:max-w-full">
            <h1 class="flex flex-wrap gap-5 items-start w-full max-md:max-w-full">
                <span class="flex-1  text-3xl font-semibold shrink w-full min-w-[320px] max-md:max-w-full">User</span>
                <button v-if="!isChangeLogView"
                        type="button"
                        @click="handleEdit"
                        class="text-center overflow-hidden self-stretch py-0.5 my-auto w-[60] min-h-[30px] rounded-md border border-blue-400 border-solid min-w-[60px] max-w-[80px] hover:bg-blue-400">
                    Edit
                </button>
            </h1>
            <div class="flex mt-2.5 w-full bg-gray-200 fill-gray-200 min-h-[1px] max-md:max-w-full"></div>
        </header>
        <div class="border border-white w-fit rounded-lg sticky top-0">
            <button @click="showUserDetailView" class="px-2.5 py-2 text-[14px] rounded-l-lg rounded-r-none font-medium leading-4" :class="{'highlight' : !isChangeLogView}">
                User Details
            </button>
            <button @click="showChangeLogView" class="px-2.5 py-2 text-[14px] rounded-r-lg rounded-l-none font-medium leading-4" :class="{'highlight' : isChangeLogView}">
                Change Log
            </button>
        </div>
        <div class="flex flex-col flex-1 overflow-y-auto gap-5 pr-2" v-if="!isChangeLogView">
            <section class="max-w-full h-fit">
                <table class="w-full h-full text-sm font-medium border-collapse">
                    <thead class="top-0 sticky z-1">
                        <tr class="w-full">
                            <th class="text-left w-[200px] px-5 py-3.5 bg-slate-900 text-slate-100 border-b border-zinc-700 border-opacity-40">
                                <div class="flex items-center gap-2">
                                    User
                                </div>
                            </th>
                            <th class="text-left w-[calc(100% - 200px)] px-5 py-3.5 bg-slate-900 text-slate-300 border-b  border-zinc-700 border-opacity-40">
                                <div class="flex items-center gap-2">
                                    Username
                                </div>
                            </th>
                            <th class="text-left w-[calc(100% - 200px)] px-5 py-3.5 bg-slate-900 text-slate-300 border-b  border-zinc-700 border-opacity-40">
                                <div class="flex items-center gap-2">
                                    Status
                                </div>
                            </th>
                            <th class="text-left w-[calc(100% - 200px)] px-5 py-3.5 bg-slate-900 text-slate-300 border-b  border-zinc-700 border-opacity-40">
                                <div class="flex items-center gap-2">
                                    Role
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="bg-slate-900">
                        <tr class="border-b  border-zinc-700 border-opacity-40">
                            <td class="flex gap-2 text-white px-5 py-3.5 text-opacity-40">
                                <div class="flex flex-col">
                                    <span class="text-white">{{userDetail.fullName}}</span>
                                    <span>{{userDetail.email}}</span>
                                </div>
                            </td>
                            <td class="text-white px-5 py-3.5 text-opacity-40">
                                <span>{{userDetail.userName}}</span>
                            </td>
                            <td class="text-white px-5 py-3.5 text-opacity-40">
                                <span class="w-fit px-1.5 py-0.5 rounded-md border border-solid min-h-[20px]" :class="GetUserStatusClass(userDetail.status.id)">{{userDetail.status.name}}</span>
                            </td>
                            <td class="text-white px-5 py-3.5 text-opacity-40">
                                <span>{{userDetail.role.name}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </section>
            <section class="flex flex-col max-w-full whitespace-nowrap w-fit">
                <div class="flex flex-col gap-1 w-full max-md:max-w-full">
                    <h2 class="text-sm font-medium">UID</h2>
                    <div class="flex items-start rounded-[var(] max-md:max-w-full">
                        <div class="flex relative overflow-hidden flex-1 shrink gap-2 items-center px-3 py-2 text-base rounded-l-lg border-t border-b border-l basis-0 bg-slate-900 border-zinc-700 min-w-[240px]">
                            <p class="max-w-[90%] w-max truncate">{{userDetail.id}}</p>
                            <span class="absolute top-[0.75rem] right-3 group">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_21821_8660)">
                                        <path d="M6.0587 6.0026C6.21543 5.55705 6.5248 5.18134 6.932 4.94203C7.3392 4.70271 7.81796 4.61523 8.28348 4.69508C8.749 4.77493 9.17124 5.01695 9.47542 5.37829C9.77959 5.73963 9.94607 6.19695 9.94536 6.66927C9.94536 8.0026 7.94536 8.66927 7.94536 8.66927M7.9987 11.3359H8.00536M14.6654 8.0026C14.6654 11.6845 11.6806 14.6693 7.9987 14.6693C4.3168 14.6693 1.33203 11.6845 1.33203 8.0026C1.33203 4.32071 4.3168 1.33594 7.9987 1.33594C11.6806 1.33594 14.6654 4.32071 14.6654 8.0026Z"
                                              stroke="#85888E"
                                              stroke-width="1.33333"
                                              stroke-linecap="round"
                                              stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_21821_8660">
                                            <rect width="16" height="16" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <Tooltip title="Copy text" content="sdsa" />
                            </span>
                        </div>
                        <button class="flex overflow-hidden gap-1 justify-center items-center px-3.5 py-2.5 text-sm font-medium rounded-r-lg border border-solid bg-slate-900 border-zinc-700">
                            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/ea65424a0690c666c53c3990e534158be71d4e401956e5fc26fc504325fbcb4b?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51" alt="" class="object-contain shrink-0 self-stretch my-auto w-5 aspect-square" />
                            <span class="self-stretch px-0.5 my-auto">Copy</span>
                        </button>
                    </div>
                </div>
            </section>
            <!--Don't remove!!. The below commented section data is not available for now-->
            <!--<section class="flex flex-wrap gap-6 items-start">
                <article class="flex flex-col min-w-[240px] w-[246px]">
                    <div class="flex overflow-hidden flex-col p-5 w-full bg-slate-900 rounded-[40px]"
                         role="region"
                         aria-label="Total session time">
                        <h2 class="flex gap-2.5 items-start self-start text-xl text-white">
                            Total session time
                        </h2>
                        <div class="flex gap-5 justify-center items-center mt-5 text-3xl font-semibold text-white">
                            <div class="flex shrink-0 self-stretch my-auto w-3.5 h-20 rounded-md bg-white bg-opacity-40"
                                 role="presentation">
                            </div>
                            <time class="self-stretch my-auto">{{userDetail.totalSessionTime.days}}d {{userDetail.totalSessionTime.hours}}h {{userDetail.totalSessionTime.minutes}}min</time>
                        </div>
                    </div>
                </article>

                <article class="flex flex-col w-[236px]">
                    <div class="flex overflow-hidden flex-col p-5 w-full bg-slate-900 rounded-[40px]"
                         role="region"
                         aria-label="Frequency of use">
                        <h2 class="flex gap-2.5 items-start self-start text-xl text-white">
                            Frequency of use
                        </h2>
                        <div class="flex gap-5 justify-center items-center mt-5 text-3xl font-semibold text-white">
                            <div class="flex shrink-0 self-stretch my-auto w-3.5 h-20 rounded-md bg-white bg-opacity-40"
                                 role="presentation"></div>
                            <p class="self-stretch my-auto">{{ userDetail.frequencyOfUserPerWeek }}x/Week</p>
                        </div>
                    </div>
                </article>
                <article class="flex flex-grow shrink flex-1">
                    <totalUsage />
                </article>
            </section>
            <section class="flex gap-5">
                <UsedNeurons />
                <UsedSynapses :usage="userDetail.synapseUsages"/>
            </section>-->
        </div>
        <div class="flex flex-col flex-1 overflow-y-auto gap-5 pr-2" v-if="isChangeLogView">
            <ChangeLog :selectedUserId="userId"/>
        </div>
    </main>
</template>

<script setup lang="ts">
    import { ref, defineEmits, defineProps, onMounted, computed } from 'vue';
    import totalUsage from '../CrewManagementDashBoard/UsageStats/TotalUsage.vue';
    import UsedNeurons from '../CrewManagementDashBoard/UsageStats/UsedNeurons.vue';
    import UsedSynapses from '../CrewManagementDashBoard/UsageStats/UsedSynapses.vue';
    import ChangeLog from '../CrewManagementDashBoard/ChangeLog.vue';

    import { GetUserStatusClass } from "@/utility/GetUserStatusClass";

    import { UserService } from '@/Services/UserService';

    import Tooltip from "../../common/ToolTip.vue";

    import type { IUserDetail } from "@/Models/Users/IUserDetail";
    import type { IUser } from "@/Models/Users/IUser";

    const userService = new UserService();

    interface IUserRequestItem {
        user: string;
        email: string;
        userName: string;
        status: string;
        department: string;
        role: string;
        uId: string;
    }
    interface IUserStatus {
        label: string;
        class: string;
    }

    const userStatuses = ref<IUserStatus[]>([
        { label: 'Active', class: 'text-[#86efac] border-[#86efac]' },
        { label: 'Invitation sent', class: 'text-[#d9f99d] border-[#d9f99d]' },
        { label: 'Access Request', class: 'text-[#fb923c] border-[#fb923c]' },
        { label: 'Denied Request', class: 'text-[#ef4444] border-[#ef4444]' },
        { label: 'Inactive', class: 'text-[#64748b] border-[#64748b]' },
    ] as IUserStatus[]);


    interface UsageStats {
        totalTime: string;
        frequency: string;
        neurons: {
            percentage: number;
            amount: number;
        };
        synapses: {
            percentage: number;
            amount: number;
        };
    }

    const stats = ref<UsageStats>({
        totalTime: '1d 4h 23min',
        frequency: '⌀ 2x/Week',
        neurons: {
            percentage: 30,
            amount: 36.00
        },
        synapses: {
            percentage: 70,
            amount: 84.00
        }
    })

    const emits = defineEmits(['onUserEdit']);
    const props = defineProps({
        selectedUserId: {
            type: Number,
            Default: 0,
            isRequired: true
        }
    })
    const userId = computed(() => {
        return props.selectedUserId || 0;
    })

    const userDetail = ref<IUserDetail>();
    const isChangeLogView = ref(false);

    const showUserDetailView = () => {
        isChangeLogView.value = false;
    }

    const showChangeLogView = () => {
        isChangeLogView.value = true;
    }

    const handleEdit = () => {
        const userData = {
            id: userDetail.value?.id,
            fullName: userDetail.value?.fullName,
            userName: userDetail.value?.userName,
            email: userDetail.value?.email,
            role: {
                id: userDetail.value?.role.id,
                name: userDetail.value?.role.name
            },
            status: {
                id: userDetail.value?.status.id,
                name: userDetail.value?.status.name
            },
            organizationId: userDetail.value?.organizationId
        } as IUser;
        emits('onUserEdit', userData);
    }

    onMounted(async () => {
        userDetail.value = await userService.GetUserDetailById(userId.value);
    })

</script>