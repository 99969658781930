<template>
    <div class="icon-notion">
        <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_11450_19063)">
                <rect x="2.33203" y="1" width="52" height="52" rx="8" fill="white" shape-rendering="crispEdges" />
                <rect x="2.83203" y="1.5" width="51" height="51" rx="7.5" stroke="#EAECF0" shape-rendering="crispEdges" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.906 46.8267L7.74699 40.3997C6.50573 38.8534 5.83203 36.9519 5.83203 34.9948V11.7225C5.83203 8.79096 8.17741 6.35845 11.2031 6.15188L35.1302 4.51827C36.8685 4.39959 38.5962 4.86164 40.0242 5.82706L48.4309 11.5109C49.9357 12.5283 50.832 14.1915 50.832 15.9664V42.4248C50.832 45.2936 48.5259 47.6689 45.5635 47.8513L19.007 49.486C16.643 49.6315 14.358 48.6355 12.906 46.8267Z" fill="white" />
                <path d="M21.2061 23.3681V23.0634C21.2061 22.291 21.8247 21.6505 22.6219 21.5973L28.4289 21.2096L36.4595 33.0353V22.6562L34.3925 22.3807V22.2359C34.3925 21.4545 35.0252 20.8098 35.8326 20.7684L41.1164 20.4977V21.2581C41.1164 21.6151 40.8509 21.9204 40.4871 21.9819L39.2156 22.197V39.0055L37.6199 39.5543C36.2867 40.0128 34.8025 39.522 34.0394 38.3704L26.2431 26.6058V37.8344L28.6428 38.2937L28.6093 38.5163C28.5046 39.2135 27.9027 39.7408 27.1745 39.7732L21.2061 40.0388C21.1272 39.2891 21.6891 38.6183 22.4643 38.5369L23.2494 38.4544V23.4829L21.2061 23.3681Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M35.3463 7.45017L11.4192 9.08378C9.98597 9.18163 8.875 10.3339 8.875 11.7225V34.9948C8.875 36.2995 9.32413 37.5672 10.1516 38.5981L15.3107 45.025C16.1448 46.0642 17.4575 46.6363 18.8155 46.5527L45.3719 44.918C46.733 44.8342 47.7926 43.7429 47.7926 42.4248V15.9664C47.7926 15.1509 47.3808 14.3867 46.6894 13.9193L38.2827 8.23544C37.4259 7.65619 36.3893 7.37897 35.3463 7.45017ZM12.6057 12.0855C12.2729 11.8391 12.4313 11.3258 12.8493 11.2958L35.5073 9.67066C36.2292 9.61888 36.9465 9.81768 37.5304 10.2313L42.0765 13.4522C42.2491 13.5745 42.1681 13.8384 41.9545 13.8501L17.9598 15.155C17.2336 15.1945 16.5169 14.9812 15.9395 14.5537L12.6057 12.0855ZM16.8343 19.2461C16.8343 18.4665 17.4641 17.8226 18.2694 17.7788L43.6386 16.3972C44.4234 16.3544 45.0841 16.958 45.0841 17.7177V40.6279C45.0841 41.406 44.4566 42.0492 43.6529 42.095L18.4443 43.5293C17.5709 43.579 16.8343 42.9078 16.8343 42.0622V19.2461Z" fill="black" />
            </g>
            <defs>
                <filter id="filter0_d_11450_19063" x="0.332031" y="0" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11450_19063" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_11450_19063" result="shape" />
                </filter>
            </defs>
        </svg>
    </div>
</template>

<script setup lang="ts">
</script>

<!-- <style>
    .icon-notion {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        color: rgb(177, 168, 168);
    }
</style> -->