<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.0714 16.1451C21.9031 15.5451 21.4048 15.1595 20.9358 14.9816C20.4993 19.0754 17.1935 22.3174 12.9889 22.3174H11.2255C6.8449 22.2872 3.49208 19.0706 3.05517 14.9773C2.67294 15.1226 2.35305 15.3974 2.14923 15.747C1.9972 16.0079 1.90991 16.3106 1.90991 16.6324V19.3205C1.90991 21.9141 4.0321 24.0363 6.62573 24.0363H17.5522C20.1459 24.0363 22.1362 21.9836 22.1362 19.39L22.1606 16.7451C22.1606 16.5197 22.1175 16.3082 22.0719 16.1451H22.0714Z"
      fill="url(#paint0_linear_23715_1276)"
    />
    <path
      d="M21.0667 9.77943C21.0667 9.77511 21.0662 9.7708 21.0657 9.76648H21.0648C21.0652 9.7708 21.0662 9.77463 21.0667 9.77895V9.77943Z"
      fill="url(#paint1_linear_23715_1276)"
    />
    <path
      d="M10.0822 12.1079C9.88936 11.3334 9.2798 10.7243 8.50574 10.5315C9.27548 10.7324 9.8812 11.3382 10.0822 12.1079Z"
      fill="url(#paint2_linear_23715_1276)"
    />
    <path
      d="M10.0822 12.1079C9.88936 11.3334 9.2798 10.7243 8.50574 10.5315C9.27548 10.7324 9.8812 11.3382 10.0822 12.1079Z"
      fill="url(#paint3_linear_23715_1276)"
    />
    <path
      d="M10.0822 12.1079C9.88936 11.3334 9.2798 10.7243 8.50574 10.5315C9.27548 10.7324 9.8812 11.3382 10.0822 12.1079Z"
      fill="url(#paint4_linear_23715_1276)"
    />
    <path
      d="M6.70868 12.6326C6.70868 13.3203 7.26596 13.8776 7.9537 13.8776C8.64143 13.8776 9.19871 13.3203 9.19871 12.6326C9.19871 11.9449 8.64143 11.3876 7.9537 11.3876C7.26596 11.3876 6.70868 11.9449 6.70868 12.6326ZM8.4141 11.6504C8.76948 11.6504 9.05771 11.9386 9.05771 12.294C9.05771 12.6494 8.76948 12.9376 8.4141 12.9376C8.05873 12.9376 7.77049 12.6494 7.77049 12.294C7.77049 11.9386 8.05873 11.6504 8.4141 11.6504Z"
      fill="url(#paint5_linear_23715_1276)"
    />
    <path
      d="M6.70868 12.6326C6.70868 13.3203 7.26596 13.8776 7.9537 13.8776C8.64143 13.8776 9.19871 13.3203 9.19871 12.6326C9.19871 11.9449 8.64143 11.3876 7.9537 11.3876C7.26596 11.3876 6.70868 11.9449 6.70868 12.6326ZM8.4141 11.6504C8.76948 11.6504 9.05771 11.9386 9.05771 12.294C9.05771 12.6494 8.76948 12.9376 8.4141 12.9376C8.05873 12.9376 7.77049 12.6494 7.77049 12.294C7.77049 11.9386 8.05873 11.6504 8.4141 11.6504Z"
      fill="url(#paint6_linear_23715_1276)"
    />
    <path
      d="M6.70868 12.6326C6.70868 13.3203 7.26596 13.8776 7.9537 13.8776C8.64143 13.8776 9.19871 13.3203 9.19871 12.6326C9.19871 11.9449 8.64143 11.3876 7.9537 11.3876C7.26596 11.3876 6.70868 11.9449 6.70868 12.6326ZM8.4141 11.6504C8.76948 11.6504 9.05771 11.9386 9.05771 12.294C9.05771 12.6494 8.76948 12.9376 8.4141 12.9376C8.05873 12.9376 7.77049 12.6494 7.77049 12.294C7.77049 11.9386 8.05873 11.6504 8.4141 11.6504Z"
      fill="url(#paint7_linear_23715_1276)"
    />
    <path
      d="M6.70868 12.6326C6.70868 13.3203 7.26596 13.8776 7.9537 13.8776C8.64143 13.8776 9.19871 13.3203 9.19871 12.6326C9.19871 11.9449 8.64143 11.3876 7.9537 11.3876C7.26596 11.3876 6.70868 11.9449 6.70868 12.6326ZM8.4141 11.6504C8.76948 11.6504 9.05771 11.9386 9.05771 12.294C9.05771 12.6494 8.76948 12.9376 8.4141 12.9376C8.05873 12.9376 7.77049 12.6494 7.77049 12.294C7.77049 11.9386 8.05873 11.6504 8.4141 11.6504Z"
      fill="url(#paint8_linear_23715_1276)"
    />
    <path
      d="M6.70868 12.6326C6.70868 13.3203 7.26596 13.8776 7.9537 13.8776C8.64143 13.8776 9.19871 13.3203 9.19871 12.6326C9.19871 11.9449 8.64143 11.3876 7.9537 11.3876C7.26596 11.3876 6.70868 11.9449 6.70868 12.6326ZM8.4141 11.6504C8.76948 11.6504 9.05771 11.9386 9.05771 12.294C9.05771 12.6494 8.76948 12.9376 8.4141 12.9376C8.05873 12.9376 7.77049 12.6494 7.77049 12.294C7.77049 11.9386 8.05873 11.6504 8.4141 11.6504Z"
      fill="#A8BDE1"
    />
    <path
      d="M8.753 12.8408C9.05536 12.6539 9.14897 12.2573 8.96208 11.9549C8.7752 11.6526 8.37858 11.559 8.07622 11.7458C7.77386 11.9327 7.68025 12.3293 7.86714 12.6317C8.05403 12.9341 8.45064 13.0277 8.753 12.8408Z"
      fill="url(#paint9_linear_23715_1276)"
    />
    <path
      d="M8.753 12.8408C9.05536 12.6539 9.14897 12.2573 8.96208 11.9549C8.7752 11.6526 8.37858 11.559 8.07622 11.7458C7.77386 11.9327 7.68025 12.3293 7.86714 12.6317C8.05403 12.9341 8.45064 13.0277 8.753 12.8408Z"
      fill="url(#paint10_linear_23715_1276)"
    />
    <path
      d="M8.753 12.8408C9.05536 12.6539 9.14897 12.2573 8.96208 11.9549C8.7752 11.6526 8.37858 11.559 8.07622 11.7458C7.77386 11.9327 7.68025 12.3293 7.86714 12.6317C8.05403 12.9341 8.45064 13.0277 8.753 12.8408Z"
      fill="url(#paint11_linear_23715_1276)"
    />
    <path
      d="M8.753 12.8408C9.05536 12.6539 9.14897 12.2573 8.96208 11.9549C8.7752 11.6526 8.37858 11.559 8.07622 11.7458C7.77386 11.9327 7.68025 12.3293 7.86714 12.6317C8.05403 12.9341 8.45064 13.0277 8.753 12.8408Z"
      fill="url(#paint12_linear_23715_1276)"
    />
    <path
      d="M8.753 12.8408C9.05536 12.6539 9.14897 12.2573 8.96208 11.9549C8.7752 11.6526 8.37858 11.559 8.07622 11.7458C7.77386 11.9327 7.68025 12.3293 7.86714 12.6317C8.05403 12.9341 8.45064 13.0277 8.753 12.8408Z"
      fill="url(#paint13_linear_23715_1276)"
    />
    <path
      d="M8.753 12.8408C9.05536 12.6539 9.14897 12.2573 8.96208 11.9549C8.7752 11.6526 8.37858 11.559 8.07622 11.7458C7.77386 11.9327 7.68025 12.3293 7.86714 12.6317C8.05403 12.9341 8.45064 13.0277 8.753 12.8408Z"
      fill="#F7F5F9"
    />
    <path
      d="M18.1532 12.1079C17.9604 11.3334 17.3508 10.7243 16.5768 10.5315C17.3465 10.7324 17.9522 11.3382 18.1532 12.1079Z"
      fill="url(#paint14_linear_23715_1276)"
    />
    <path
      d="M18.1532 12.1079C17.9604 11.3334 17.3508 10.7243 16.5768 10.5315C17.3465 10.7324 17.9522 11.3382 18.1532 12.1079Z"
      fill="url(#paint15_linear_23715_1276)"
    />
    <path
      d="M18.1532 12.1079C17.9604 11.3334 17.3508 10.7243 16.5768 10.5315C17.3465 10.7324 17.9522 11.3382 18.1532 12.1079Z"
      fill="url(#paint16_linear_23715_1276)"
    />
    <path
      d="M16.4851 12.9376C16.8406 12.9376 17.1287 12.6495 17.1287 12.294C17.1287 11.9385 16.8406 11.6504 16.4851 11.6504C16.1297 11.6504 15.8415 11.9385 15.8415 12.294C15.8415 12.6495 16.1297 12.9376 16.4851 12.9376Z"
      fill="url(#paint17_linear_23715_1276)"
    />
    <path
      d="M16.4851 12.9376C16.8406 12.9376 17.1287 12.6495 17.1287 12.294C17.1287 11.9385 16.8406 11.6504 16.4851 11.6504C16.1297 11.6504 15.8415 11.9385 15.8415 12.294C15.8415 12.6495 16.1297 12.9376 16.4851 12.9376Z"
      fill="url(#paint18_linear_23715_1276)"
    />
    <path
      d="M16.4851 12.9376C16.8406 12.9376 17.1287 12.6495 17.1287 12.294C17.1287 11.9385 16.8406 11.6504 16.4851 11.6504C16.1297 11.6504 15.8415 11.9385 15.8415 12.294C15.8415 12.6495 16.1297 12.9376 16.4851 12.9376Z"
      fill="url(#paint19_linear_23715_1276)"
    />
    <path
      d="M16.4851 12.9376C16.8406 12.9376 17.1287 12.6495 17.1287 12.294C17.1287 11.9385 16.8406 11.6504 16.4851 11.6504C16.1297 11.6504 15.8415 11.9385 15.8415 12.294C15.8415 12.6495 16.1297 12.9376 16.4851 12.9376Z"
      fill="url(#paint20_linear_23715_1276)"
    />
    <path
      d="M16.4851 12.9376C16.8406 12.9376 17.1287 12.6495 17.1287 12.294C17.1287 11.9385 16.8406 11.6504 16.4851 11.6504C16.1297 11.6504 15.8415 11.9385 15.8415 12.294C15.8415 12.6495 16.1297 12.9376 16.4851 12.9376Z"
      fill="url(#paint21_linear_23715_1276)"
    />
    <path
      d="M22.057 16.1528C21.4561 20.3583 17.817 23.6186 13.4522 23.6186H10.6955C6.24635 23.6186 2.55063 20.2303 2.05905 15.9072C1.90702 16.1681 1.81973 16.4707 1.81973 16.7926V19.4807C1.81973 22.0743 3.94192 24.1965 6.53555 24.1965H17.4621C20.0557 24.1965 22.1779 22.0743 22.1779 19.4807V16.7926C22.1779 16.5671 22.1347 16.3518 22.057 16.1528Z"
      fill="url(#paint22_linear_23715_1276)"
    />
    <path
      opacity="0.47"
      d="M10.6955 23.6185H13.4522C17.817 23.6185 21.4561 20.3582 22.0571 16.1527C21.8743 15.6861 21.4988 15.3139 21.0298 15.136C20.5933 19.2298 17.1038 22.4464 12.8988 22.4464H11.0955C6.89096 22.4464 3.40194 19.2307 2.96503 15.1369C2.58279 15.2823 2.26291 15.5571 2.05908 15.9067C2.55018 20.2302 6.24591 23.618 10.6955 23.618V23.6185Z"
      fill="url(#paint23_linear_23715_1276)"
    />
    <path
      d="M10.6955 23.6185H13.4522C17.817 23.6185 21.4561 20.3582 22.0571 16.1527C21.8743 15.6861 21.4988 15.3139 21.0298 15.136C20.5933 19.2298 17.1038 22.4464 12.8988 22.4464H11.0955C6.89096 22.4464 3.40194 19.2307 2.96503 15.1369C2.58279 15.2823 2.26291 15.5571 2.05908 15.9067C2.55018 20.2302 6.24591 23.618 10.6955 23.618V23.6185Z"
      fill="url(#paint24_linear_23715_1276)"
    />
    <path
      d="M11.9631 18.697C11.9775 18.697 11.9923 18.697 12.0067 18.697C13.4182 18.697 14.2709 17.815 14.3064 17.7771C14.4704 17.6044 14.4627 17.3316 14.2901 17.1675C14.1174 17.0035 13.8445 17.0112 13.6805 17.1838C13.6743 17.1906 13.0345 17.8346 12.0058 17.8346C12 17.8346 11.9938 17.8346 11.9875 17.8346H11.9813C10.944 17.8428 10.3157 17.2117 10.2869 17.1819C10.1229 17.0107 9.85144 17.004 9.67927 17.1675C9.50662 17.3316 9.49942 17.6044 9.66296 17.7771C9.69893 17.815 10.5512 18.697 11.9626 18.697H11.9631Z"
      fill="#0C132C"
    />
    <path
      d="M11.9631 18.697C11.9775 18.697 11.9923 18.697 12.0067 18.697C13.4182 18.697 14.2709 17.815 14.3064 17.7771C14.4704 17.6044 14.4627 17.3316 14.2901 17.1675C14.1174 17.0035 13.8445 17.0112 13.6805 17.1838C13.6743 17.1906 13.0345 17.8346 12.0058 17.8346C12 17.8346 11.9938 17.8346 11.9875 17.8346H11.9813C10.944 17.8428 10.3157 17.2117 10.2869 17.1819C10.1229 17.0107 9.85144 17.004 9.67927 17.1675C9.50662 17.3316 9.49942 17.6044 9.66296 17.7771C9.69893 17.815 10.5512 18.697 11.9626 18.697H11.9631Z"
      fill="#0B132F"
    />
    <path
      d="M16.0578 8.94647C15.5523 8.82753 14.4147 8.82609 13.8009 8.68461C11.8681 8.23859 7.0962 6.24301 6.77727 5.98547C5.21572 6.75282 4.2057 8.25154 3.90644 9.89462C3.84889 10.2097 3.81915 10.5339 3.81915 10.8643V12.9721C3.81915 13.6795 3.91028 14.3663 4.08101 15.0224C4.99703 18.5402 8.20981 21.1568 12.0038 21.1568C15.7984 21.1568 19.0107 18.5402 19.9267 15.0224C20.0974 14.3668 20.1885 13.6795 20.1885 12.9721V10.919C20.095 10.8471 19.7794 9.8222 16.0578 8.94647ZM7.9537 14.8742C6.73458 14.8742 5.74615 13.8858 5.74615 12.6667C5.74615 11.4475 6.73458 10.4591 7.9537 10.4591C8.14698 10.4591 8.3345 10.484 8.51291 10.5306C9.28745 10.7233 9.89652 11.3329 10.0893 12.107C10.1358 12.2854 10.1608 12.4729 10.1608 12.6662C10.1608 13.8853 9.17234 14.8737 7.95322 14.8737L7.9537 14.8742ZM16.0243 14.8742C14.8051 14.8742 13.8167 13.8858 13.8167 12.6667C13.8167 11.4475 14.8051 10.4591 16.0243 10.4591C16.2175 10.4591 16.405 10.484 16.5835 10.5306C17.358 10.7233 17.9671 11.3329 18.1599 12.107C18.2064 12.2854 18.2313 12.4729 18.2313 12.6662C18.2313 13.8853 17.2429 14.8737 16.0238 14.8737L16.0243 14.8742Z"
      fill="url(#paint25_linear_23715_1276)"
    />
    <path
      d="M2.83551 9.74834C2.83551 9.74834 2.83599 9.74018 2.83743 9.7258C2.83599 9.72532 2.83455 9.72436 2.83311 9.72388C2.83407 9.73203 2.83455 9.74018 2.83503 9.74834H2.83551Z"
      fill="url(#paint26_linear_23715_1276)"
    />
    <path
      opacity="0.2"
      d="M7.2324 15.5105C6.04062 14.7523 5.06609 13.6118 5.409 12.0935C5.73752 10.6379 6.88278 10.2504 7.47412 10.1473C7.64389 10.1175 7.73262 9.92954 7.64581 9.78087C7.2396 9.08642 6.27802 7.54118 5.81521 7.62511C5.48669 7.68506 4.89488 8.44473 4.50209 9.85473C4.49586 9.87631 4.49298 9.89933 4.49298 9.92187V12.6651C4.49298 15.3062 5.88907 17.637 7.97913 18.9574C8.01318 18.9789 8.05298 18.9914 8.09327 18.9948C8.09423 18.9948 8.09519 18.9948 8.09567 18.9948C8.16185 19 8.22707 18.977 8.27695 18.9334C9.77615 17.626 8.40356 16.2568 7.23192 15.511L7.2324 15.5105Z"
      fill="url(#paint27_linear_23715_1276)"
    />
    <path
      opacity="0.2"
      d="M17.1158 15.5711C18.3076 14.8128 18.7948 13.9731 18.7138 12.1046C18.6327 10.2361 16.2765 10.2088 16.2765 10.2088C16.2765 10.2088 17.7119 7.52738 18.3076 7.63577C18.6385 7.6962 19.2375 8.46738 19.6298 9.89848L19.413 12.8082C19.1421 16.1121 17.3911 18.1297 16.1077 18.9085C16.0161 18.9728 15.9317 19.0304 15.9317 19.0304C14.3068 17.7033 15.9245 16.3293 17.1163 15.5711H17.1158Z"
      fill="url(#paint28_linear_23715_1276)"
    />
    <path
      d="M14.7644 6.37727C15.9542 6.37727 17.0769 6.84487 17.9258 7.69327C18.7747 8.54214 19.2418 9.66487 19.2418 10.8547V12.9625C19.2418 14.8833 18.4879 16.6957 17.1187 18.0649C15.7494 19.4341 13.9375 20.188 12.0163 20.188C10.0951 20.188 8.28316 19.4341 6.91393 18.0649C5.5447 16.6957 4.79079 14.8838 4.79079 12.9625V10.8547C4.79079 9.66487 5.25839 8.54214 6.10678 7.69327C6.95566 6.84439 8.07838 6.37727 9.26824 6.37727H14.7639M14.7639 5.41809H9.26824C6.278 5.41809 3.8316 7.86448 3.8316 10.8547V12.9625C3.8316 17.464 7.51486 21.1472 12.0163 21.1472C16.5177 21.1472 20.201 17.464 20.201 12.9625V10.8547C20.201 7.86448 17.7546 5.41809 14.7644 5.41809H14.7639Z"
      fill="url(#paint29_linear_23715_1276)"
    />
    <path
      d="M12.0427 18.8341C12.0278 18.8341 12.013 18.8341 11.9986 18.8341C10.5598 18.8341 9.66584 17.8212 9.68215 17.7656C9.75888 17.508 9.69606 17.4658 9.87255 17.2793C10.0481 17.0927 10.1464 17.1882 10.3133 17.3834C10.3435 17.4184 10.9526 17.8505 11.9986 17.8505C12.0062 17.8505 12.0134 17.8505 12.0206 17.8505C12.0278 17.8505 12.035 17.8505 12.0422 17.8505C13.0906 17.8505 13.8824 17.3234 13.8886 17.3157C14.0555 17.1186 14.0311 17.0309 14.2071 17.2179C14.3831 17.4049 14.4502 17.6524 14.2833 17.8495C14.2469 17.8927 13.481 18.8341 12.0427 18.8346V18.8341Z"
      fill="#F7F5F9"
    />
    <path
      d="M12.0336 18.697C12.0192 18.697 12.0043 18.697 11.9899 18.697C10.5785 18.697 9.7258 17.815 9.69031 17.7771C9.52629 17.6045 9.53397 17.3316 9.70662 17.1676C9.87879 17.004 10.1507 17.0112 10.3147 17.1824C10.3445 17.2131 10.9641 17.8347 11.9899 17.8347C11.9971 17.8347 12.0043 17.8347 12.012 17.8347C12.0192 17.8347 12.0264 17.8347 12.0331 17.8347C13.0618 17.8347 13.7016 17.1906 13.7078 17.1839C13.8714 17.0112 14.1443 17.0036 14.3174 17.1676C14.4901 17.3316 14.4972 17.6045 14.3337 17.7771C14.2977 17.815 13.445 18.697 12.0341 18.697H12.0336Z"
      fill="#0C132C"
    />
    <path
      d="M10.1469 12.6326C10.1469 13.8287 9.17712 14.7985 7.98102 14.7985C6.78492 14.7985 5.81519 13.8287 5.81519 12.6326C5.81519 11.4365 6.78492 10.4668 7.98102 10.4668C8.16183 10.4668 8.33784 10.4893 8.5057 10.5311C8.32729 10.4845 8.13977 10.4596 7.94649 10.4596C6.72737 10.4596 5.73894 11.448 5.73894 12.6672C5.73894 13.8863 6.72737 14.8747 7.94649 14.8747C9.16561 14.8747 10.154 13.8863 10.154 12.6672C10.154 12.4739 10.1291 12.2864 10.0826 12.1079C10.1243 12.2758 10.1469 12.4518 10.1469 12.6326Z"
      fill="url(#paint30_linear_23715_1276)"
    />
    <path
      d="M10.1469 12.6326C10.1469 13.8287 9.17712 14.7985 7.98102 14.7985C6.78492 14.7985 5.81519 13.8287 5.81519 12.6326C5.81519 11.4365 6.78492 10.4668 7.98102 10.4668C8.16183 10.4668 8.33784 10.4893 8.5057 10.5311C8.32729 10.4845 8.13977 10.4596 7.94649 10.4596C6.72737 10.4596 5.73894 11.448 5.73894 12.6672C5.73894 13.8863 6.72737 14.8747 7.94649 14.8747C9.16561 14.8747 10.154 13.8863 10.154 12.6672C10.154 12.4739 10.1291 12.2864 10.0826 12.1079C10.1243 12.2758 10.1469 12.4518 10.1469 12.6326Z"
      fill="url(#paint31_linear_23715_1276)"
    />
    <path
      d="M10.1469 12.6326C10.1469 13.8287 9.17712 14.7985 7.98102 14.7985C6.78492 14.7985 5.81519 13.8287 5.81519 12.6326C5.81519 11.4365 6.78492 10.4668 7.98102 10.4668C8.16183 10.4668 8.33784 10.4893 8.5057 10.5311C8.32729 10.4845 8.13977 10.4596 7.94649 10.4596C6.72737 10.4596 5.73894 11.448 5.73894 12.6672C5.73894 13.8863 6.72737 14.8747 7.94649 14.8747C9.16561 14.8747 10.154 13.8863 10.154 12.6672C10.154 12.4739 10.1291 12.2864 10.0826 12.1079C10.1243 12.2758 10.1469 12.4518 10.1469 12.6326Z"
      fill="#F7F5F9"
    />
    <path
      d="M5.81522 12.6325C5.81522 13.8286 6.78495 14.7983 7.98105 14.7983C9.17715 14.7983 10.1469 13.8286 10.1469 12.6325C10.1469 12.4517 10.1243 12.2757 10.0826 12.1078C9.88166 11.3381 9.27594 10.7324 8.5062 10.5314C8.33834 10.4897 8.16233 10.4672 7.98153 10.4672C6.78543 10.4672 5.8157 11.4369 5.8157 12.633L5.81522 12.6325ZM9.19873 12.6325C9.19873 13.3202 8.64144 13.8775 7.95371 13.8775C7.26598 13.8775 6.70869 13.3202 6.70869 12.6325C6.70869 11.9448 7.26598 11.3875 7.95371 11.3875C8.64144 11.3875 9.19873 11.9448 9.19873 12.6325Z"
      fill="url(#paint32_linear_23715_1276)"
    />
    <path
      d="M5.81522 12.6325C5.81522 13.8286 6.78495 14.7983 7.98105 14.7983C9.17715 14.7983 10.1469 13.8286 10.1469 12.6325C10.1469 12.4517 10.1243 12.2757 10.0826 12.1078C9.88166 11.3381 9.27594 10.7324 8.5062 10.5314C8.33834 10.4897 8.16233 10.4672 7.98153 10.4672C6.78543 10.4672 5.8157 11.4369 5.8157 12.633L5.81522 12.6325ZM9.19873 12.6325C9.19873 13.3202 8.64144 13.8775 7.95371 13.8775C7.26598 13.8775 6.70869 13.3202 6.70869 12.6325C6.70869 11.9448 7.26598 11.3875 7.95371 11.3875C8.64144 11.3875 9.19873 11.9448 9.19873 12.6325Z"
      fill="url(#paint33_linear_23715_1276)"
    />
    <path
      d="M5.81522 12.6325C5.81522 13.8286 6.78495 14.7983 7.98105 14.7983C9.17715 14.7983 10.1469 13.8286 10.1469 12.6325C10.1469 12.4517 10.1243 12.2757 10.0826 12.1078C9.88166 11.3381 9.27594 10.7324 8.5062 10.5314C8.33834 10.4897 8.16233 10.4672 7.98153 10.4672C6.78543 10.4672 5.8157 11.4369 5.8157 12.633L5.81522 12.6325ZM9.19873 12.6325C9.19873 13.3202 8.64144 13.8775 7.95371 13.8775C7.26598 13.8775 6.70869 13.3202 6.70869 12.6325C6.70869 11.9448 7.26598 11.3875 7.95371 11.3875C8.64144 11.3875 9.19873 11.9448 9.19873 12.6325Z"
      fill="url(#paint34_linear_23715_1276)"
    />
    <path
      d="M5.81522 12.6325C5.81522 13.8286 6.78495 14.7983 7.98105 14.7983C9.17715 14.7983 10.1469 13.8286 10.1469 12.6325C10.1469 12.4517 10.1243 12.2757 10.0826 12.1078C9.88166 11.3381 9.27594 10.7324 8.5062 10.5314C8.33834 10.4897 8.16233 10.4672 7.98153 10.4672C6.78543 10.4672 5.8157 11.4369 5.8157 12.633L5.81522 12.6325ZM9.19873 12.6325C9.19873 13.3202 8.64144 13.8775 7.95371 13.8775C7.26598 13.8775 6.70869 13.3202 6.70869 12.6325C6.70869 11.9448 7.26598 11.3875 7.95371 11.3875C8.64144 11.3875 9.19873 11.9448 9.19873 12.6325Z"
      fill="#091334"
    />
    <path
      d="M18.2174 12.6326C18.2174 13.8287 17.2477 14.7985 16.0516 14.7985C14.8555 14.7985 13.8857 13.8287 13.8857 12.6326C13.8857 11.4365 14.8555 10.4668 16.0516 10.4668C16.2324 10.4668 16.4084 10.4893 16.5763 10.5311C16.3978 10.4845 16.2103 10.4596 16.017 10.4596C14.7979 10.4596 13.8095 11.448 13.8095 12.6672C13.8095 13.8863 14.7979 14.8747 16.017 14.8747C17.2362 14.8747 18.2246 13.8863 18.2246 12.6672C18.2246 12.4739 18.1997 12.2864 18.1531 12.1079C18.1949 12.2758 18.2174 12.4518 18.2174 12.6326Z"
      fill="url(#paint35_linear_23715_1276)"
    />
    <path
      d="M18.2174 12.6326C18.2174 13.8287 17.2477 14.7985 16.0516 14.7985C14.8555 14.7985 13.8857 13.8287 13.8857 12.6326C13.8857 11.4365 14.8555 10.4668 16.0516 10.4668C16.2324 10.4668 16.4084 10.4893 16.5763 10.5311C16.3978 10.4845 16.2103 10.4596 16.017 10.4596C14.7979 10.4596 13.8095 11.448 13.8095 12.6672C13.8095 13.8863 14.7979 14.8747 16.017 14.8747C17.2362 14.8747 18.2246 13.8863 18.2246 12.6672C18.2246 12.4739 18.1997 12.2864 18.1531 12.1079C18.1949 12.2758 18.2174 12.4518 18.2174 12.6326Z"
      fill="url(#paint36_linear_23715_1276)"
    />
    <path
      d="M18.2174 12.6326C18.2174 13.8287 17.2477 14.7985 16.0516 14.7985C14.8555 14.7985 13.8857 13.8287 13.8857 12.6326C13.8857 11.4365 14.8555 10.4668 16.0516 10.4668C16.2324 10.4668 16.4084 10.4893 16.5763 10.5311C16.3978 10.4845 16.2103 10.4596 16.017 10.4596C14.7979 10.4596 13.8095 11.448 13.8095 12.6672C13.8095 13.8863 14.7979 14.8747 16.017 14.8747C17.2362 14.8747 18.2246 13.8863 18.2246 12.6672C18.2246 12.4739 18.1997 12.2864 18.1531 12.1079C18.1949 12.2758 18.2174 12.4518 18.2174 12.6326Z"
      fill="#F7F5F9"
    />
    <path
      d="M13.8858 12.6325C13.8858 13.8286 14.8555 14.7983 16.0516 14.7983C17.2477 14.7983 18.2174 13.8286 18.2174 12.6325C18.2174 12.4517 18.1949 12.2757 18.1532 12.1078C17.9522 11.3381 17.3465 10.7324 16.5768 10.5314C16.4089 10.4897 16.2329 10.4672 16.0521 10.4672C14.856 10.4672 13.8863 11.4369 13.8863 12.633L13.8858 12.6325ZM17.2693 12.6325C17.2693 13.3202 16.712 13.8775 16.0243 13.8775C15.3365 13.8775 14.7793 13.3202 14.7793 12.6325C14.7793 11.9448 15.3365 11.3875 16.0243 11.3875C16.712 11.3875 17.2693 11.9448 17.2693 12.6325Z"
      fill="url(#paint37_linear_23715_1276)"
    />
    <path
      d="M13.8858 12.6325C13.8858 13.8286 14.8555 14.7983 16.0516 14.7983C17.2477 14.7983 18.2174 13.8286 18.2174 12.6325C18.2174 12.4517 18.1949 12.2757 18.1532 12.1078C17.9522 11.3381 17.3465 10.7324 16.5768 10.5314C16.4089 10.4897 16.2329 10.4672 16.0521 10.4672C14.856 10.4672 13.8863 11.4369 13.8863 12.633L13.8858 12.6325ZM17.2693 12.6325C17.2693 13.3202 16.712 13.8775 16.0243 13.8775C15.3365 13.8775 14.7793 13.3202 14.7793 12.6325C14.7793 11.9448 15.3365 11.3875 16.0243 11.3875C16.712 11.3875 17.2693 11.9448 17.2693 12.6325Z"
      fill="url(#paint38_linear_23715_1276)"
    />
    <path
      d="M13.8858 12.6325C13.8858 13.8286 14.8555 14.7983 16.0516 14.7983C17.2477 14.7983 18.2174 13.8286 18.2174 12.6325C18.2174 12.4517 18.1949 12.2757 18.1532 12.1078C17.9522 11.3381 17.3465 10.7324 16.5768 10.5314C16.4089 10.4897 16.2329 10.4672 16.0521 10.4672C14.856 10.4672 13.8863 11.4369 13.8863 12.633L13.8858 12.6325ZM17.2693 12.6325C17.2693 13.3202 16.712 13.8775 16.0243 13.8775C15.3365 13.8775 14.7793 13.3202 14.7793 12.6325C14.7793 11.9448 15.3365 11.3875 16.0243 11.3875C16.712 11.3875 17.2693 11.9448 17.2693 12.6325Z"
      fill="url(#paint39_linear_23715_1276)"
    />
    <path
      d="M13.8858 12.6325C13.8858 13.8286 14.8555 14.7983 16.0516 14.7983C17.2477 14.7983 18.2174 13.8286 18.2174 12.6325C18.2174 12.4517 18.1949 12.2757 18.1532 12.1078C17.9522 11.3381 17.3465 10.7324 16.5768 10.5314C16.4089 10.4897 16.2329 10.4672 16.0521 10.4672C14.856 10.4672 13.8863 11.4369 13.8863 12.633L13.8858 12.6325ZM17.2693 12.6325C17.2693 13.3202 16.712 13.8775 16.0243 13.8775C15.3365 13.8775 14.7793 13.3202 14.7793 12.6325C14.7793 11.9448 15.3365 11.3875 16.0243 11.3875C16.712 11.3875 17.2693 11.9448 17.2693 12.6325Z"
      fill="#0B1332"
    />
    <path
      d="M14.7797 12.6326C14.7797 13.3203 15.337 13.8776 16.0247 13.8776C16.7124 13.8776 17.2697 13.3203 17.2697 12.6326C17.2697 11.9449 16.7124 11.3876 16.0247 11.3876C15.337 11.3876 14.7797 11.9449 14.7797 12.6326ZM16.4851 11.6504C16.8405 11.6504 17.1287 11.9386 17.1287 12.294C17.1287 12.6494 16.8405 12.9376 16.4851 12.9376C16.1297 12.9376 15.8415 12.6494 15.8415 12.294C15.8415 11.9386 16.1297 11.6504 16.4851 11.6504Z"
      fill="url(#paint40_linear_23715_1276)"
    />
    <path
      d="M14.7797 12.6326C14.7797 13.3203 15.337 13.8776 16.0247 13.8776C16.7124 13.8776 17.2697 13.3203 17.2697 12.6326C17.2697 11.9449 16.7124 11.3876 16.0247 11.3876C15.337 11.3876 14.7797 11.9449 14.7797 12.6326ZM16.4851 11.6504C16.8405 11.6504 17.1287 11.9386 17.1287 12.294C17.1287 12.6494 16.8405 12.9376 16.4851 12.9376C16.1297 12.9376 15.8415 12.6494 15.8415 12.294C15.8415 11.9386 16.1297 11.6504 16.4851 11.6504Z"
      fill="url(#paint41_linear_23715_1276)"
    />
    <path
      d="M14.7797 12.6326C14.7797 13.3203 15.337 13.8776 16.0247 13.8776C16.7124 13.8776 17.2697 13.3203 17.2697 12.6326C17.2697 11.9449 16.7124 11.3876 16.0247 11.3876C15.337 11.3876 14.7797 11.9449 14.7797 12.6326ZM16.4851 11.6504C16.8405 11.6504 17.1287 11.9386 17.1287 12.294C17.1287 12.6494 16.8405 12.9376 16.4851 12.9376C16.1297 12.9376 15.8415 12.6494 15.8415 12.294C15.8415 11.9386 16.1297 11.6504 16.4851 11.6504Z"
      fill="url(#paint42_linear_23715_1276)"
    />
    <path
      d="M14.7797 12.6326C14.7797 13.3203 15.337 13.8776 16.0247 13.8776C16.7124 13.8776 17.2697 13.3203 17.2697 12.6326C17.2697 11.9449 16.7124 11.3876 16.0247 11.3876C15.337 11.3876 14.7797 11.9449 14.7797 12.6326ZM16.4851 11.6504C16.8405 11.6504 17.1287 11.9386 17.1287 12.294C17.1287 12.6494 16.8405 12.9376 16.4851 12.9376C16.1297 12.9376 15.8415 12.6494 15.8415 12.294C15.8415 11.9386 16.1297 11.6504 16.4851 11.6504Z"
      fill="url(#paint43_linear_23715_1276)"
    />
    <path
      d="M14.7797 12.6326C14.7797 13.3203 15.337 13.8776 16.0247 13.8776C16.7124 13.8776 17.2697 13.3203 17.2697 12.6326C17.2697 11.9449 16.7124 11.3876 16.0247 11.3876C15.337 11.3876 14.7797 11.9449 14.7797 12.6326ZM16.4851 11.6504C16.8405 11.6504 17.1287 11.9386 17.1287 12.294C17.1287 12.6494 16.8405 12.9376 16.4851 12.9376C16.1297 12.9376 15.8415 12.6494 15.8415 12.294C15.8415 11.9386 16.1297 11.6504 16.4851 11.6504Z"
      fill="#A8BDE1"
    />
    <path
      d="M15.9302 11.425C16.6539 11.425 17.2189 11.8791 17.2405 12.7352C17.2429 12.8402 17.228 12.9419 17.2045 13.0397C17.2434 12.9179 17.2592 12.7884 17.2649 12.6537C17.2956 11.9559 16.6894 11.3588 16.0036 11.3923C15.8233 11.401 15.6516 11.4307 15.4962 11.4988C15.6319 11.4513 15.7777 11.425 15.9302 11.425Z"
      fill="#F7F5F9"
    />
    <path
      d="M7.85156 11.4355C8.57526 11.4355 9.12535 11.9036 9.1618 12.7458C9.16611 12.8503 9.14933 12.9525 9.12583 13.0503C9.16468 12.9285 9.1805 12.799 9.18626 12.6642C9.21743 11.9281 8.61075 11.3694 7.92493 11.4029C7.74461 11.4116 7.57291 11.4413 7.41753 11.5094C7.55325 11.4619 7.69905 11.4355 7.85156 11.4355Z"
      fill="#F7F5F9"
    />
    <path
      d="M16.4146 13.1016C16.8302 13.1016 17.1671 12.7647 17.1671 12.3492C17.1671 11.9336 16.8302 11.5967 16.4146 11.5967C15.9991 11.5967 15.6622 11.9336 15.6622 12.3492C15.6622 12.7647 15.9991 13.1016 16.4146 13.1016Z"
      fill="#F7F5F9"
    />
    <path
      d="M8.32731 13.1151C8.74289 13.1151 9.07978 12.7782 9.07978 12.3626C9.07978 11.947 8.74289 11.6101 8.32731 11.6101C7.91172 11.6101 7.57483 11.947 7.57483 12.3626C7.57483 12.7782 7.91172 13.1151 8.32731 13.1151Z"
      fill="#F7F5F9"
    />
    <g opacity="0.2">
      <path
        d="M5.8728 2.00051C5.83875 1.94535 5.80566 1.88924 5.77353 1.83313C5.80614 1.88924 5.83923 1.94535 5.8728 2.00051Z"
        fill="url(#paint44_linear_23715_1276)"
      />
      <path
        d="M5.98935 2.18515C5.9716 2.15781 5.95434 2.13048 5.93707 2.10266C5.95434 2.13 5.97208 2.15781 5.98935 2.18515Z"
        fill="url(#paint45_linear_23715_1276)"
      />
      <path
        d="M7.1888 3.7218C7.2099 3.7453 7.23148 3.7688 7.25258 3.79278C7.24347 3.78271 7.23388 3.77264 7.22477 3.76257C7.21278 3.74914 7.20079 3.73571 7.1888 3.7218Z"
        fill="url(#paint46_linear_23715_1276)"
      />
      <path
        d="M7.05167 3.56689C7.07996 3.59951 7.10874 3.63212 7.13751 3.66425C7.10874 3.63212 7.08044 3.59951 7.05167 3.56689Z"
        fill="url(#paint47_linear_23715_1276)"
      />
      <path
        d="M20.1608 10.9622C20.1315 10.637 20.1339 10.6821 20.0754 10.3099C19.8428 8.74695 19.0846 7.58058 18.2208 6.73219C17.4635 5.98786 16.6252 5.48813 15.9912 5.17975C15.9097 5.13995 15.8286 5.10302 15.7476 5.06705C14.6373 4.57451 13.5793 4.42056 12.6384 4.2834C11.9132 4.17789 11.2284 4.07813 10.5948 3.84409C8.77336 3.17027 8.17627 2.05762 7.98683 1.50705L7.64872 1.45142H6.6622C6.30635 1.45142 5.96008 1.49218 5.62677 1.5694C5.33566 1.63702 5.05462 1.73294 4.787 1.85283C3.03794 2.54392 1.79196 4.25318 1.79196 6.24061V8.6141C1.79196 9.31718 2.20777 9.92818 2.80534 10.2131C2.80678 10.2135 2.80821 10.2145 2.80965 10.215C2.83939 10.2289 2.8696 10.2423 2.90078 10.2548C3.01444 8.3297 4.14148 6.666 5.75242 5.79267C5.92603 5.96292 6.22242 6.23917 6.63774 6.57488C8.00314 7.67842 9.73878 8.22371 11.3929 8.73208C12.9209 9.20208 14.4829 9.54786 16.0305 9.94592C17.4669 10.3157 18.9858 10.6715 20.1852 11.5914L20.1761 11.2821C20.179 11.284 20.1603 10.9617 20.1603 10.9617L20.1608 10.9622Z"
        fill="url(#paint48_linear_23715_1276)"
      />
    </g>
    <g opacity="0.2">
      <path
        d="M20.1717 10.9147C20.1621 10.8068 20.1559 10.7397 20.1497 10.6845C19.9736 10.5872 19.7928 10.4975 19.6092 10.414C19.4945 10.3675 19.3794 10.3224 19.2643 10.2778C19.2643 10.2783 19.2643 10.2793 19.2639 10.2797C19.3075 10.5239 19.3483 10.7694 19.3627 11.015C19.6533 11.1694 19.9329 11.3444 20.1971 11.5468L20.188 11.2365C20.1909 11.2385 20.1722 10.9147 20.1722 10.9147H20.1717Z"
        fill="url(#paint49_linear_23715_1276)"
      />
      <path
        d="M6.7902 5.93896C6.78445 5.93224 6.77869 5.92505 6.77294 5.91833C6.63098 6.05262 6.46408 6.1658 6.28375 6.25309C6.37775 6.33366 6.48038 6.41903 6.59117 6.50871C6.7408 6.62957 6.89571 6.74371 7.0535 6.8521C7.25253 6.74995 7.4693 6.67993 7.68799 6.64731C7.35756 6.46891 7.05206 6.24494 6.7902 5.93896Z"
        fill="url(#paint50_linear_23715_1276)"
      />
    </g>
    <path
      d="M2.85278 10.684C2.85278 10.5224 2.70843 10.3982 2.54872 10.4236C1.54062 10.5843 0.770401 11.4571 0.770401 12.5103C0.770401 13.5635 1.5411 14.4363 2.54872 14.597C2.70843 14.6224 2.8523 14.4982 2.8523 14.3366V10.6845L2.85278 10.684Z"
      fill="url(#paint51_linear_23715_1276)"
    />
    <path
      d="M2.8528 11.6293C2.8528 11.507 2.74345 11.4125 2.62211 11.4317C1.85717 11.5535 1.27303 12.2158 1.27303 13.0148C1.27303 13.8138 1.85765 14.4761 2.62211 14.5979C2.74297 14.6171 2.8528 14.5231 2.8528 14.4003V11.6298V11.6293Z"
      fill="#2B70B8"
    />
    <path
      d="M21.1472 10.684C21.1472 10.5224 21.2916 10.3982 21.4513 10.4236C22.4594 10.5843 23.2296 11.4571 23.2296 12.5103C23.2296 13.5635 22.4589 14.4363 21.4513 14.597C21.2916 14.6224 21.1477 14.4982 21.1477 14.3366V10.6845L21.1472 10.684Z"
      fill="url(#paint52_linear_23715_1276)"
    />
    <path
      d="M21.1472 11.6293C21.1472 11.507 21.2566 11.4125 21.3779 11.4317C22.1428 11.5535 22.727 12.2158 22.727 13.0148C22.727 13.8138 22.1424 14.4761 21.3779 14.5979C21.257 14.6171 21.1472 14.5231 21.1472 14.4003V11.6298V11.6293Z"
      fill="#2B70B8"
    />
    <path
      d="M17.3973 0.809187H8.82895C8.86875 0.884482 8.91911 0.966972 8.98098 1.05426C8.98817 1.06433 8.99489 1.07392 9.00208 1.08447C9.37424 1.59188 9.98764 2.01248 10.7761 2.30071C11.3228 2.5007 11.9372 2.58895 12.6489 2.69158C13.738 2.84841 14.972 3.02633 16.3168 3.67282C17.193 4.09438 18.4184 4.83631 19.4073 6.0137C19.673 6.33023 19.9219 6.67794 20.142 7.05969C20.5497 7.76613 21.0484 8.07978 21.189 9.03129C21.3415 8.7373 21.2264 9.31377 21.2264 9.31377C21.8762 9.05095 22.1122 8.6385 22.1122 7.89754V5.52404C22.1122 2.93042 19.99 0.808228 17.3964 0.808228L17.3973 0.809187Z"
      fill="url(#paint53_linear_23715_1276)"
    />
    <path
      d="M17.4462 0.907104H8.87784C8.91764 0.9824 8.968 1.06489 9.02987 1.15218C9.03706 1.16225 9.04378 1.17184 9.05097 1.18239C9.42313 1.6898 10.0365 2.1104 10.825 2.39863C11.3717 2.59862 11.9861 2.68686 12.6978 2.7895C13.7869 2.94632 15.0209 3.12425 16.3657 3.77074C17.2419 4.1923 18.4672 4.93423 19.4562 6.11162C19.7219 6.42815 19.9708 6.77585 20.1909 7.15761C20.5986 7.86404 20.9084 8.68606 21.0489 9.63805H21.0498C21.6997 9.37523 22.1615 8.73738 22.1615 7.99641V5.62292C22.1615 3.02929 20.0393 0.907104 17.4457 0.907104H17.4462Z"
      fill="url(#paint54_linear_23715_1276)"
    />
    <path
      d="M5.8727 1.34536C5.83865 1.2902 5.80555 1.23409 5.77342 1.17798C5.80603 1.23409 5.83913 1.2902 5.8727 1.34536Z"
      fill="white"
    />
    <path
      d="M5.98926 1.53049C5.97151 1.50315 5.95425 1.47581 5.93698 1.448C5.95425 1.47533 5.97199 1.50315 5.98926 1.53049Z"
      fill="white"
    />
    <path
      d="M7.18874 3.06665C7.20984 3.09015 7.23142 3.11365 7.25252 3.13763C7.24341 3.12756 7.23382 3.11749 7.22471 3.10742C7.21272 3.09399 7.20073 3.08056 7.18874 3.06665Z"
      fill="white"
    />
    <path
      d="M7.05161 2.91223C7.0799 2.94484 7.10868 2.97746 7.13745 3.00959C7.10868 2.97746 7.08038 2.94484 7.05161 2.91223Z"
      fill="white"
    />
    <path
      d="M20.1472 10.2058C20.118 9.88451 20.1098 9.89986 20.0345 9.53441C19.7185 8.00356 19.0427 6.90338 18.1795 6.06458C17.4222 5.32888 16.629 4.84546 15.9949 4.54044C15.9134 4.50111 15.7887 4.45315 15.7082 4.41766C14.5979 3.93088 13.5802 3.8105 12.6393 3.67478C11.9141 3.57023 11.2293 3.46903 10.5957 3.23739C8.77423 2.57124 8.17714 1.3478 7.9877 0.803946H7.64959L6.66307 0.803467C6.30722 0.803467 5.96095 0.843752 5.62764 0.920007C5.33653 0.98667 5.05549 1.08163 4.78787 1.20057C3.03881 1.88398 1.79283 3.57406 1.79283 5.53847V7.8851C1.79283 8.58051 2.20864 9.18383 2.80621 9.46583C2.80764 9.46631 2.80908 9.46727 2.81052 9.46775C2.84026 9.48166 2.87047 9.49461 2.90164 9.50708C3.01531 7.60406 4.14235 5.95907 5.75329 5.09532C5.9269 5.26366 6.22329 5.53655 6.63861 5.86842C8.00401 6.95949 9.73965 7.49855 11.3938 8.00116C12.9217 8.46589 14.4838 8.80783 16.0314 9.20158C17.4678 9.56703 18.9866 9.91905 20.1861 10.8288L20.177 10.5233C20.1799 10.5252 20.1477 10.2063 20.1477 10.2063L20.1472 10.2058Z"
      fill="url(#paint55_linear_23715_1276)"
    />
    <path
      d="M20.1631 10.32C20.1338 9.99868 20.1281 9.98908 20.0523 9.62364C19.7363 8.09278 19.0423 7.01227 18.179 6.17346C17.4218 5.43777 16.6285 4.95434 15.9945 4.64932C15.913 4.61 15.7883 4.56204 15.7077 4.52655C14.5974 4.03976 13.5798 3.91939 12.6388 3.78366C11.9137 3.67911 11.2288 3.57792 10.5953 3.34628C8.77377 2.68012 8.17668 1.45669 7.98724 0.912833H7.64913L6.66262 0.912354C6.30676 0.912354 5.9605 0.952639 5.62718 1.02889C5.33607 1.09556 5.05503 1.19052 4.78742 1.30945C3.03835 1.99287 1.79237 3.68295 1.79237 5.64735V7.99399C1.79237 8.68939 2.20818 9.29272 2.80575 9.57472C2.80719 9.5752 2.80863 9.57616 2.81006 9.57664C2.8398 9.59054 2.87001 9.60349 2.90119 9.61596C3.01485 7.71295 4.14189 6.06795 5.75283 5.20421C5.92644 5.37255 6.22283 5.64543 6.63816 5.97731C8.00355 7.06838 9.73919 7.60744 11.3933 8.11005C12.9213 8.57477 14.4833 8.91672 16.0309 9.31046C17.4673 9.67591 18.9862 10.0279 20.1856 10.9377L20.1765 10.6322C20.1794 10.6341 20.1631 10.3205 20.1631 10.3205V10.32Z"
      fill="url(#paint56_linear_23715_1276)"
    />
    <path
      d="M19.3167 10.3368C19.3167 10.3368 18.5527 5.66176 12.6647 4.82056C12.6647 4.82056 16.476 6.0368 17.0961 9.57857C17.0961 9.57857 19.3171 10.3085 19.3171 10.3368H19.3167Z"
      fill="url(#paint57_linear_23715_1276)"
    />
    <path
      d="M5.74807 5.20902C5.74807 5.20902 5.14522 4.62248 4.9553 3.8302C4.9553 3.8302 2.76453 6.22576 2.90217 9.63852C2.90217 9.63852 2.97267 6.70678 5.74807 5.20854V5.20902Z"
      fill="url(#paint58_linear_23715_1276)"
    />
    <path
      d="M21.0508 9.63801C21.0508 9.63801 21.9438 5.54327 17.746 3.56592C17.746 3.56592 15.2473 2.69019 12.6983 2.78946C12.6983 2.78946 15.8502 2.91607 18.3958 5.05553C20.9415 7.19498 21.0508 9.63801 21.0508 9.63801Z"
      fill="url(#paint59_linear_23715_1276)"
    />
    <path
      d="M6.99405 0.911865C6.99405 0.911865 7.53551 3.75536 11.8149 4.54093C16.0943 5.3265 16.3379 6.0032 17.2045 6.59933C17.2045 6.59933 15.8094 4.08675 12.5055 3.76015C9.21935 3.43499 8.25873 1.75307 7.96906 0.912824H6.99405V0.911865Z"
      fill="url(#paint60_linear_23715_1276)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_23715_1276"
        x1="3.21776"
        y1="23.1284"
        x2="21.3199"
        y2="14.514"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#92D6FF" />
        <stop offset="1" stop-color="#1A77AA" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_23715_1276"
        x1="4.5689"
        y1="22.5826"
        x2="21.8639"
        y2="9.14746"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_23715_1276"
        x1="2.30079"
        y1="16.7508"
        x2="17.2458"
        y2="5.14376"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_23715_1276"
        x1="0.892712"
        y1="17.8448"
        x2="18.1867"
        y2="4.41286"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_23715_1276"
        x1="5.76775"
        y1="14.0584"
        x2="9.56035"
        y2="11.1128"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_23715_1276"
        x1="5.82144"
        y1="14.2886"
        x2="9.68694"
        y2="11.2864"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_23715_1276"
        x1="2.43265"
        y1="16.9206"
        x2="17.3776"
        y2="5.31356"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_23715_1276"
        x1="1.02457"
        y1="18.0146"
        x2="18.3186"
        y2="4.58266"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_23715_1276"
        x1="5.89961"
        y1="14.2282"
        x2="9.69221"
        y2="11.2825"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_23715_1276"
        x1="5.83093"
        y1="14.3005"
        x2="9.69595"
        y2="11.2983"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_23715_1276"
        x1="2.44166"
        y1="16.9325"
        x2="17.3867"
        y2="5.32543"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_23715_1276"
        x1="1.03358"
        y1="18.026"
        x2="18.3281"
        y2="4.59453"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_23715_1276"
        x1="5.90863"
        y1="14.2401"
        x2="9.70123"
        y2="11.2944"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_23715_1276"
        x1="6.77237"
        y1="13.5691"
        x2="8.95259"
        y2="11.8757"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_23715_1276"
        x1="5.33755"
        y1="20.6604"
        x2="20.2825"
        y2="9.0534"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_23715_1276"
        x1="3.92899"
        y1="21.7544"
        x2="21.2235"
        y2="8.3225"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_23715_1276"
        x1="13.8383"
        y1="14.0584"
        x2="17.6309"
        y2="11.1128"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_23715_1276"
        x1="5.47852"
        y1="20.8422"
        x2="20.4235"
        y2="9.23516"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_23715_1276"
        x1="4.07044"
        y1="21.9362"
        x2="21.3645"
        y2="8.50426"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_23715_1276"
        x1="13.9016"
        y1="14.3006"
        x2="17.7671"
        y2="11.2984"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_23715_1276"
        x1="13.9793"
        y1="14.2402"
        x2="17.7719"
        y2="11.2945"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_23715_1276"
        x1="14.843"
        y1="13.5692"
        x2="17.0232"
        y2="11.8758"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_23715_1276"
        x1="11.9281"
        y1="19.4217"
        x2="11.4873"
        y2="27.2093"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0088CA" />
        <stop offset="0.47" stop-color="#02B6EE" />
        <stop offset="1" stop-color="#15FF72" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_23715_1276"
        x1="3.82925"
        y1="23.8804"
        x2="22.3649"
        y2="9.48497"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_23715_1276"
        x1="6.80607"
        y1="20.5064"
        x2="28.1119"
        y2="10.3674"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.36" stop-color="#4687CE" />
        <stop offset="1" stop-color="#15FF72" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_23715_1276"
        x1="5.19654"
        y1="18.0553"
        x2="18.9862"
        y2="6.88519"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="1" stop-color="#0EE59B" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_23715_1276"
        x1="1.34717"
        y1="10.8908"
        x2="16.9255"
        y2="-1.20903"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint27_linear_23715_1276"
        x1="6.86983"
        y1="18.8312"
        x2="6.46602"
        y2="9.53772"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_23715_1276"
        x1="14.4051"
        y1="18.8309"
        x2="18.4879"
        y2="11.1066"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint29_linear_23715_1276"
        x1="6.96045"
        y1="19.6826"
        x2="15.7355"
        y2="6.92688"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.05" stop-color="#304F96" />
        <stop offset="0.73" stop-color="#51C2C4" stop-opacity="0.1" />
      </linearGradient>
      <linearGradient
        id="paint30_linear_23715_1276"
        x1="5.83534"
        y1="14.3064"
        x2="9.70084"
        y2="11.3046"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint31_linear_23715_1276"
        x1="2.44655"
        y1="16.9389"
        x2="17.3916"
        y2="5.33133"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint32_linear_23715_1276"
        x1="5.832"
        y1="14.3015"
        x2="9.69702"
        y2="11.2997"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint33_linear_23715_1276"
        x1="2.44273"
        y1="16.934"
        x2="17.3877"
        y2="5.32643"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint34_linear_23715_1276"
        x1="1.03466"
        y1="18.0274"
        x2="18.3292"
        y2="4.59601"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint35_linear_23715_1276"
        x1="5.48332"
        y1="20.8485"
        x2="20.4283"
        y2="9.24143"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint36_linear_23715_1276"
        x1="4.07476"
        y1="21.942"
        x2="21.3693"
        y2="8.51054"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint37_linear_23715_1276"
        x1="5.47953"
        y1="20.8436"
        x2="20.4245"
        y2="9.23653"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint38_linear_23715_1276"
        x1="4.07145"
        y1="21.937"
        x2="21.3655"
        y2="8.50564"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint39_linear_23715_1276"
        x1="13.9026"
        y1="14.3015"
        x2="17.7681"
        y2="11.2997"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint40_linear_23715_1276"
        x1="5.4694"
        y1="20.8302"
        x2="20.4144"
        y2="9.22318"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint41_linear_23715_1276"
        x1="4.06085"
        y1="21.9242"
        x2="21.3554"
        y2="8.49276"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint42_linear_23715_1276"
        x1="13.8925"
        y1="14.2886"
        x2="17.7575"
        y2="11.2864"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint43_linear_23715_1276"
        x1="13.9701"
        y1="14.2282"
        x2="17.7627"
        y2="11.2825"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint44_linear_23715_1276"
        x1="5.77305"
        y1="1.91658"
        x2="5.8728"
        y2="1.91658"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint45_linear_23715_1276"
        x1="5.93659"
        y1="2.14439"
        x2="5.98935"
        y2="2.14439"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint46_linear_23715_1276"
        x1="7.1888"
        y1="3.75729"
        x2="7.25258"
        y2="3.75729"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint47_linear_23715_1276"
        x1="7.05167"
        y1="3.61533"
        x2="7.13751"
        y2="3.61533"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint48_linear_23715_1276"
        x1="1.79196"
        y1="6.52213"
        x2="20.1857"
        y2="6.52213"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stop-opacity="0.2" />
      </linearGradient>
      <linearGradient
        id="paint49_linear_23715_1276"
        x1="1.72472"
        y1="10.9123"
        x2="20.1967"
        y2="10.9123"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stop-opacity="0.4" />
      </linearGradient>
      <linearGradient
        id="paint50_linear_23715_1276"
        x1="1.72477"
        y1="6.38498"
        x2="20.1967"
        y2="6.38498"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint51_linear_23715_1276"
        x1="0.770401"
        y1="12.5103"
        x2="2.85278"
        y2="12.5103"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint52_linear_23715_1276"
        x1="23.2296"
        y1="12.5103"
        x2="21.1472"
        y2="12.5103"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358ACA" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint53_linear_23715_1276"
        x1="8.82943"
        y1="5.06172"
        x2="22.1131"
        y2="5.06172"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#009CD4" />
        <stop offset="1" stop-color="#CFFFFF" />
      </linearGradient>
      <linearGradient
        id="paint54_linear_23715_1276"
        x1="9.76604"
        y1="-1.47118"
        x2="25.1489"
        y2="8.71196"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#42BAE1" />
        <stop offset="0.47" stop-color="#02B6E6" />
        <stop offset="0.79" stop-color="#5FC4B9" />
      </linearGradient>
      <linearGradient
        id="paint55_linear_23715_1276"
        x1="19.2634"
        y1="13.4766"
        x2="3.58218"
        y2="3.13092"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#009CD4" />
        <stop offset="1" stop-color="#83DBEF" />
      </linearGradient>
      <linearGradient
        id="paint56_linear_23715_1276"
        x1="1.79189"
        y1="5.92455"
        x2="20.1856"
        y2="5.92455"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#009CD4" />
        <stop offset="1" stop-color="#CFFFFF" />
      </linearGradient>
      <linearGradient
        id="paint57_linear_23715_1276"
        x1="21.4954"
        y1="11.3531"
        x2="11.1459"
        y2="3.76306"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FDFEFF" />
        <stop offset="1" stop-color="#CFFFFF" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint58_linear_23715_1276"
        x1="2.89594"
        y1="6.7346"
        x2="5.74807"
        y2="6.7346"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FDFEFF" />
        <stop offset="1" stop-color="#CFFFFF" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint59_linear_23715_1276"
        x1="12.6978"
        y1="6.2099"
        x2="21.13"
        y2="6.2099"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#009CD4" stop-opacity="0" />
        <stop offset="1" stop-color="#CFFFFF" />
      </linearGradient>
      <linearGradient
        id="paint60_linear_23715_1276"
        x1="7.17965"
        y1="0.994355"
        x2="18.0668"
        y2="7.79207"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#009CD4" />
        <stop offset="1" stop-color="#CFFFFF" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
// Useless
export default {
  props: {
    width: {
      type: String,
      required: false,
    },
    height: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
