<template>
  <div id="chart-container" style="width: 100%; height: 300px">
    <div id="donut_single" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    limit: {
      type: Number,
      required: false,
    },
    usage: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      Proforma: {
        limit: this.limit,
        usage: this.usage,
      },
    };
  },
  methods: {
    loadGoogleCharts() {
      const script = document.createElement("script");
      script.src = "https://www.gstatic.com/charts/loader.js";
      script.onload = this.drawChart;
      document.head.appendChild(script);
    },
    drawChart() {
      google.charts.load("current", { packages: ["corechart"] });
      google.charts.setOnLoadCallback(this.renderChart);
    },
    renderChart() {
      const remaining = this.limit - this.usage;
      const data = google.visualization.arrayToDataTable([
        ["Effort", "Amount given"],
        ["Usage", this.usage],
        ["Remaining", remaining],
      ]);

      const options = {
        width: "100%",
        height: "100%",
        pieHole: 0.8,
        pieSliceText: "",
        pieSliceTextStyle: {
          color: "",
          fontSize: 20,
          bold: true,
        },
        legend: "none",
        slices: {
          0: { color: "#A6EF67" },
          1: { color: "#1e2f3e" },
        },
        animation: {
          startup: true,
          duration: 3000,
          easing: "out",
        },
        backgroundColor: "transparent",
      };

      const chart = new google.visualization.PieChart(
        document.getElementById("donut_single")
      );
      chart.draw(data, options);
    },
    redrawChartOnResize() {
      window.addEventListener("resize", this.renderChart);
    },
  },
  mounted() {
    this.loadGoogleCharts();
    this.redrawChartOnResize();
  },
  beforeUpdate() {
    window.removeEventListener("resize", this.renderChart);
  },
});
</script>
