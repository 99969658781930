<template>
    <section v-if="!changeLogItems || changeLogItems.length <= 0" class="max-w-full flex justify-center flex-col items-center h-[100px]">
        <span class="text-gray-500 text-xl">No logs found.</span>
    </section>
    <section v-if="changeLogItems && changeLogItems.length > 0" class="max-w-full">
        <table class="w-full text-sm font-medium border-collapse">
            <thead class="top-0 sticky z-10">
                <tr class="w-full">
                    <th v-for="(column, index) in tableColumns"
                        :key="index"
                        class="text-left max-w-[400px] px-5 py-3.5 bg-slate-800 text-slate-100 border-b border-zinc-700 border-opacity-40"
                        :class="{ 'cursor-pointer': column.isSortable }">
                        <div v-if="column.isVisible" class="flex w-fit items-center gap-2">
                            <div v-if="column.isSortable"
                                 class="flex gap-2 items-center"
                                 :class="{ 'text-blue-300': currentSortedColumn.columnName === column.id }"
                                 @click="applySort(column)">
                                {{ column.name }}
                                <IconArrowUp v-if=" currentSortedColumn.columnName === column.id && currentSortedColumn.direction === 'DESC'"
                                             class="w-[15px] h-[14px] text-[#CBD5E1]" />
                                <IconArrowDown v-else
                                               class="w-[15px] h-[14px] text-[#CBD5E1]" />
                            </div>
                            <div v-else>{{ column.name }}</div>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody class="bg-slate-900"
                   v-for="(item, index) in changeLogItems"
                   :key="index">
                <tr class="border-b border-zinc-700 border-opacity-40">
                    <td class="text-white px-5 py-1 text-opacity-40  h-[70px]">
                        <span>{{ formatDate(item.createdOn) }}</span>
                    </td>
                    <td class="flex flex-row px-5 py-1 justify-between items-center text-white text-opacity-40 h-[70px]">
                        <div class="w-[calc(100% - 100px)] text-opacity-40"
                             v-html="resolveTemplate(item)"></div>
                        <button v-if="item.revocable"
                                @click="handleRevoke(item.id)"
                                class="px-3.5 py-4 text-center items-end text-blue-400 rounded-md border border-blue-400 border-solid shadow-sm hover:bg-blue-400 hover:text-white transition-colors duration-200">
                            Revoke change
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>
</template>

<script setup lang="ts">
    import { ref, onMounted, defineEmits, defineProps } from "vue";
    import IconArrowDown from "@/components/icons/IconArrowDown.vue";
    import IconArrowUp from "@/components/icons/IconArrowUp.vue";

    import type { IChangeLog } from "@/Models/Users/IChangeLog";
    import type { IUserStatus } from '@/Models/Users/IUserStatus';
    import type { IUserRole } from '@/Models/Users/IUserRole';

    import type { ITableColumn } from "@/Models/ITableColumn";
    import type { ISortOption } from "@/Models/ISortOption";
    import { getUserRoles, getUserStatuses } from '@/Services/MasterDataService';

    const emits = defineEmits(["onSort", "userDetail", "revokeLog"]);

    const userStatuses = getUserStatuses();
    const userRoles = getUserRoles();

    const props = defineProps({
        changeLogItems: {
            type: Array<IChangeLog>,
            default: [],
            required: true
        }
    })

    const currentSortedColumn = ref<ISortOption>({
      columnName: "CreatedOn",
      direction: "DESC",
    });

    const tableColumns = ref<ITableColumn[]>([
        {
            id: "CreatedOn",
            name: "Date",
            isVisible: true,
            isCheckBoxType: false,
            isSortable: true,
            width: 200,
        },
        {
            id: "ChangeLog",
            name: "Change Log",
            isVisible: true,
            isCheckBoxType: false,
            isSortable: false,
            width: 500,
        },
    ]);

    const handleRevoke = (changeLogId: number) => {
        emits('revokeLog', changeLogId);
    };

    const goToDetail = (userId: number) => {
      console.log(`Go to User details ${userId}`);
    };

    const resolveTemplate = (item: IChangeLog): string => {
      item.template = item.template.replace(/\\/g, "");
      item.template = item.template.replace("editorUserId", item.editorUserId.toString());
      item.template = item.template.replace("editedUserId", item.editedUserId.toString());
      item.template = item.template.replace("{{editorUserName}}", item.editorUserName);
      item.template = item.template.replace("{{editedUserName}}", item.editedUserName);
      item.template = item.template.replace("{{previousStateName}}", resolveStatusName(item.actionTypeId, item.previousStateId));
      item.template = item.template.replace("{{currentStateName}}", resolveStatusName(item.actionTypeId, item.currentStateId));
      item.template = item.template.replace("{{requestedStateName}}", resolveStatusName(item.actionTypeId, item.requestedStateId));
      return item.template;
    };

    const resolveStatusName = (actionTypeId: number, statusId: number): string => {
        //---> For status change
        if (actionTypeId === 1) {
            return userStatuses.value.find((x: IUserStatus )=> x.id === statusId)?.name || '';
        }
        //---> For role change
        else if (actionTypeId === 2) {
            return userRoles.value.find((x: IUserRole) => x.id === statusId)?.name || '';
        }
        else {
            return '';
        }
    }

    const formatDate = (date: Date): string => {
        if (!date) return '';

        const newDate = new Date(date);
        const options: Intl.DateTimeFormatOptions = {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hourCycle: "h23",
        };

        const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(newDate);
        return formattedDate.replace(/\//g, ".");
    };

    const applySort = (column: ITableColumn) => {
        if (!column.isSortable) return;

        if (column.id.toLowerCase() === currentSortedColumn.value.columnName.toLowerCase()) {
            currentSortedColumn.value.direction = currentSortedColumn.value.direction === "ASC" ? "DESC" : "ASC";
        }
        else {
            currentSortedColumn.value.columnName = column.id;
            currentSortedColumn.value.direction = "ASC";
        }
        emits("onSort", currentSortedColumn.value);
    };

    onMounted(async () => {
        emits("onSort", currentSortedColumn.value);
    });
</script>
