<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.75 23C4.78553 23 5.625 22.1605 5.625 21.125C5.625 20.0895 4.78553 19.25 3.75 19.25C2.71447 19.25 1.875 20.0895 1.875 21.125C1.875 22.1605 2.71447 23 3.75 23Z"
      fill="#F8F8F8"
    />
    <path
      d="M12.657 28.6251C11.2399 28.623 9.84365 28.2839 8.58355 27.6356C7.32345 26.9873 6.23569 26.0484 5.41016 24.8966L6.93453 23.8054C7.58636 24.715 8.44528 25.4564 9.44033 25.9685C10.4354 26.4805 11.538 26.7484 12.657 26.7501C16.378 26.7501 19.4614 23.8438 19.6761 20.1332L21.5483 20.2419C21.2764 24.9426 17.3698 28.6251 12.657 28.6251Z"
      fill="#F8F8F8"
    />
    <path
      d="M26.25 23.9375C27.2855 23.9375 28.125 23.098 28.125 22.0625C28.125 21.027 27.2855 20.1875 26.25 20.1875C25.2145 20.1875 24.375 21.027 24.375 22.0625C24.375 23.098 25.2145 23.9375 26.25 23.9375Z"
      fill="#F8F8F8"
    />
    <path
      d="M28.7259 18.4016L26.9222 17.8906C27.0988 17.2653 27.188 16.6186 27.1875 15.9688C27.1875 12.0922 24.0338 8.93752 20.1562 8.93752C19.1579 8.93507 18.1706 9.14704 17.2613 9.55908L16.4887 7.85096C17.6407 7.32865 18.8914 7.05978 20.1562 7.06252C25.0669 7.06252 29.0625 11.0581 29.0625 15.9688C29.0625 16.7938 28.95 17.6131 28.7259 18.4016ZM19.1381 14.1735L16.3256 11.361C15.9722 11.0085 15.5006 10.8125 15 10.8125C14.4994 10.8125 14.0278 11.0075 13.6744 11.3619L10.8619 14.1744C10.4963 14.54 10.3125 15.02 10.3125 15.5C10.3125 15.98 10.4963 16.46 10.8619 16.8256L13.6744 19.6381C14.0278 19.9925 14.4994 20.1875 15 20.1875C15.5006 20.1875 15.9722 19.9925 16.3256 19.6381L19.1381 16.8256C19.5037 16.46 19.6875 15.98 19.6875 15.5C19.6875 15.02 19.5037 14.54 19.1381 14.1744M15 18.3125L12.1875 15.5L15 12.6875L17.8125 15.5L15 18.3125Z"
      fill="#F8F8F8"
    />
    <path
      d="M15 5.1875C16.0355 5.1875 16.875 4.34803 16.875 3.3125C16.875 2.27697 16.0355 1.4375 15 1.4375C13.9645 1.4375 13.125 2.27697 13.125 3.3125C13.125 4.34803 13.9645 5.1875 15 5.1875Z"
      fill="#F8F8F8"
    />
    <path
      d="M7.52344 19.1394C6.0993 18.38 4.90854 17.2476 4.07868 15.8634C3.24883 14.4791 2.81114 12.8952 2.8125 11.2813C2.8125 6.56845 6.495 2.66283 11.1956 2.39001L11.3044 4.2622C9.77504 4.35351 8.31734 4.9417 7.15291 5.93735C5.98848 6.93299 5.181 8.28163 4.85325 9.77823C4.52549 11.2748 4.69539 12.8375 5.3371 14.2287C5.97882 15.6199 7.05725 16.7635 8.40844 17.4856L7.52344 19.1394Z"
      fill="#F8F8F8"
    />
  </svg>
</template>