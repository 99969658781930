<template>
    <div class="flex flex-col h-full pt-5 pb-5">
        <section class="flex flex-col rounded-t-2xl border border-solid bg-slate-900 border-zinc-700 border-opacity-40">
            <header class="flex gap-4 items-start px-6 py-4 w-full max-md:px-5 max-md:max-w-full">
                <div class="flex flex-col flex-1 shrink justify-center w-full basis-0 min-h-[52px] min-w-[240px] max-md:max-w-full">
                    <div class="flex flex-wrap gap-10 justify-between items-center w-full max-md:max-w-full">
                        <div class="flex gap-2 items-center self-stretch my-auto font-medium">
                            <h1 class="self-stretch my-auto text-lg text-white">Users</h1>
                            <span class="flex items-start self-stretch my-auto text-xs text-blue-300 w-[68px]">
                                <span class="overflow-hidden self-stretch px-1.5 py-1 rounded-md bg-white bg-opacity-10 min-h-[20px]">
                                    {{totalNumberOfUsers > 0 ? totalNumberOfUsers : 0 }} users
                                </span>
                            </span>
                        </div>

                        <form class="flex overflow-hidden flex-col self-stretch my-auto max-w-screen-sm text-sm font-medium rounded-lg shadow-xl bg-slate-900 min-h-[44px] min-w-[240px] text-white text-opacity-40 w-[566px] max-md:max-w-full"
                              @submit.prevent>
                            <div class="flex flex-col max-w-full min-h-[44px] w-[566px]">
                                <div class="flex flex-col w-full max-md:max-w-full">
                                    <div class="flex overflow-hidden gap-2 items-center px-3.5 w-full rounded-lg border border-solid bg-slate-900 border-zinc-700 min-h-[44px] max-md:max-w-full">
                                        <div class="flex flex-1 shrink gap-2 items-center self-stretch my-auto w-full basis-0 min-w-[240px] max-md:max-w-full">
                                            <label for="userSearch" class="sr-only">Search for a user</label>
                                            <div class="flex flex-wrap flex-1 shrink gap-2 items-center self-stretch my-auto w-full basis-0 min-w-[240px] max-md:max-w-full">
                                                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/20488440d751706546e5a915e32433ac547cfcbffa77615cb860210289cb9b80?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51" alt="Search icon" class="object-contain shrink-0 self-stretch my-auto w-5 aspect-square" />
                                                <input type="search"
                                                       id="userSearch"
                                                       v-model="searchText"
                                                       @input="handleSearch"
                                                       placeholder="Search for a user by Id, Name, User name, Status, Role."
                                                       autocomplete="off"
                                                       class="flex-1 shrink self-stretch my-auto basis-0 max-md:max-w-full bg-transparent border-none focus:outline-none" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="flex gap-10 items-center self-stretch my-auto">
                            <button type="button"
                                    class="overflow-hidden flex-1 shrink self-stretch px-0 py-0.5 my-auto text-sm font-medium text-center bg-blue-400 rounded-md shadow-sm min-h-[44px] text-slate-900 w-[100px]"
                                    @click="handleNewUser">
                                Add user
                            </button>
                            <button type="button"
                                    class="flex flex-col self-stretch my-auto w-5"
                                    aria-label="Settings" @click="OpenDownloadOptionsModal($event)">
                                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/627b72bdb70bfce08460a9913e13d995377330f28efe86b9d7376b5ac9c4131e?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51" alt="" class="object-contain w-5 aspect-square" />
                            </button>
                        </div>
                    </div>
                </div>
            </header>
        </section>
        <main class="relative flex-1 bg-slate-900">
            <div class="lg:h-[62vh] kx:h-[60vh] overflow-y-auto border border-white border-opacity-10">
                <UsersTable :key="'currentPage'"
                            :listOfUser="listOfUser"
                            :listOfUsersId="listOfUsersId"
                            @onSort="applySort"
                            @onSelectAll="getAllUserIds"
                            @userDetail="goToUserDetail"
                            @onUserEditAction="onUserEdit"
                            @onUserDeleteAction="onUserDelete"
                            @onResendInvitationAction="onResendInvitation"
                            @onBulkActionUsersResendInvitation="handleBulkActionUsersResendInvitation"
                            @onBulkActionUsersStatusChangeToInActive="handleBulkActionUsersStatusChangeToInActive"
                            @onBulkActionUsersStatusChangeToActive="handleBulkActionUsersStatusChangeToActive"
                            @onBulkActionUsersDelete="handleBulkActionUsersDelete" />
                <div v-if="isLoadingResults"
                     class="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-[#031525c4] backdrop-blur-[4px] border border-white border-opacity-10 z-[1000] overflow-auto scroll-width">
                    <Spinner />
                </div>
            </div>
        </main>
        <footer class="h-fit border-zinc-700 text-white border border-solid bg-slate-900 border-zinc-700 border-opacity-40 text-slate-300 rounded-b-2xl p-3">
            <Pagination :currentPage="currentPage" :itemsPerPage="itemsPerPage" :totalItems="totalNumberOfUsers" @update:current-page="handlePageChange" />
        </footer>
        <UserDownloadOptionModal v-onClickOutside="closeDownloadOptionsModal" 
                                 :isVisible="isUserDownloadOptionsModalVisible" 
                                 :top="userDownloadOptionsModalPositionTop" 
                                 :left="userDownloadOptionsModalPositionLeft" 
                                 @onDownloadCSV="handleDownloadCSV"
                                 @onDownloadPDF="handleDownloadPDF"/>
    </div>
</template>

<script setup lang="ts">
    import { ref, defineEmits, onMounted, watch } from 'vue';
    import UsersTable from '../CrewManagementUsers/UsersTable.vue';
    import Pagination from '../../common/Pagination.vue';
    import UserDownloadOptionModal from './UserDownloadOptionModal.vue';
    import Spinner from '@/components/common/Spinner.vue';
    import Axios from "axios";
    import AppConfigGlobal from "@/core/config/uiSettings";


    import { UserService } from '@/Services/UserService';

    import type { IUser } from '@/Models/Users/IUser';
    import type { ITableListRequest, ITableFilter } from '@/Models/Users/Request/ITableListRequest';
    import type { IUsersStatusChangeRequest } from '@/Models/Users/Request/IUsersStatusChangeRequest';
    import type { ISortOption } from '@/Models/ISortOption';

    const userService = new UserService();

    const emits = defineEmits(['onCreateUser', 'userDetail', 'onUserEdit', 'onSuccessAction', 'onError']);

    const listOfUser = ref<IUser[]>([]);
    const listOfUsersId = ref<number[]>([]);

    const isUserDownloadOptionsModalVisible = ref<boolean>(false);
    const userDownloadOptionsModalPositionTop = ref<number>(0);
    const userDownloadOptionsModalPositionLeft = ref<number>(0);
    const itemsPerPage = ref<number>(10);
    const offSetCount = ref<number>(0);
    const isLoadingResults = ref<boolean>(false);
    const isActionSuccess = ref<boolean>(false);
    const searchText = ref<string>('');

    const sortOption = ref<ISortOption>();

    const totalNumberOfUsers = ref<number>(0);
    const currentPage = ref<number>(1);

    const handlePageChange = async (page: number) => {
        currentPage.value = page;
        offSetCount.value = currentPage.value * itemsPerPage.value - itemsPerPage.value;

        await getUserList();
    }

    const handleNewUser = () => {
        emits('onCreateUser');
    }

    const goToUserDetail = (selectedId: number) => {
        emits('userDetail', selectedId);
    }

    const OpenDownloadOptionsModal = (event: MouseEvent) => {
        const rect = (event.target as HTMLElement).getBoundingClientRect();
        userDownloadOptionsModalPositionTop.value = rect.top - 35 + window.scrollY + rect.height; // Below the button
        userDownloadOptionsModalPositionLeft.value = rect.left + window.scrollX; // Align left
        isUserDownloadOptionsModalVisible.value = true;
    };

    const closeDownloadOptionsModal = () => {
        isUserDownloadOptionsModalVisible.value = false;
    };

    const applySort = async (newSortOption: ISortOption) => {
        sortOption.value = newSortOption;
        await getUserList();
    }

    const onUserEdit = (selectedUser: IUser) => {
        emits('onUserEdit', selectedUser);
    }

    const onUserDelete = async (selectedUserId: number) => {
        isLoadingResults.value = true;
        const result = await userService.DeleteUserById(selectedUserId);
        if (result) {
            await getUserList();
            emits('onSuccessAction', 'User removed', 'The user has been successfully removed.');
        }
        else {
            emits('onError');
        }
    }

    const onResendInvitation = async (selectedUserId: number) => {
        try {
            const isUpdated = await userService.ResendUserInvitation(selectedUserId);
            if (isUpdated) {
                await getUserList();
            }
        } catch (e) {

        }
    }

    const handleBulkActionUsersResendInvitation = async (selectedUserIds: number[]) => {
        try {
            console.log(selectedUserIds);
            await getUserList();
        } catch (e) {

        }
    }

    const handleBulkActionUsersStatusChangeToActive = async (selectedUserIds: number[]) => {
        const usersStatusChangeRequest = {
            statusId: 1, //---> Need to make an enum: status - Active;
            userIds: selectedUserIds
        } as IUsersStatusChangeRequest;
        try {
            const isUpdated = await userService.ChangeUsersStatus(usersStatusChangeRequest);
            if (isUpdated) {
                await getUserList();
            }
        } catch (e) {

        }
    }

    const handleBulkActionUsersStatusChangeToInActive = async (selectedUserIds: number[]) => {
        const usersStatusChangeRequest = {
            statusId: 2, //---> Need to make an enum: status - InActive;
            userIds: selectedUserIds
        } as IUsersStatusChangeRequest;
        try {
            const isUpdated = await userService.ChangeUsersStatus(usersStatusChangeRequest);
            if (isUpdated) {
                await getUserList();
            }
        } catch (e) {

        }
    }

    const handleBulkActionUsersDelete = async (selectedUserIds: number[]) => {
        try {
            const isDeleted = await userService.DeleteUsers(selectedUserIds);
            if (isDeleted) {
                await getUserList();
            }
        } catch (e) {

        }
    }

    const getAllUserIds = async () => {
        const request = {
            searchTerm: searchText.value
        } as ITableFilter
        listOfUsersId.value = await userService.GetAllOrganizationUsersIds(request);
    }

    const getUserList = async () => {
        isLoadingResults.value = true;

        const request = {
            skip: offSetCount.value,
            take: itemsPerPage.value,
            sortColumn: sortOption.value?.columnName,
            sortDirection: sortOption.value?.direction,
            searchTerm: searchText.value
        } as ITableListRequest;

        const userListResponse = await userService.GetAllOrganizationUsers(request);
        listOfUser.value = userListResponse.users;
        totalNumberOfUsers.value = userListResponse.totalUsersCount;

        isLoadingResults.value = false;
    }

    const handleSearch = async () => {
        await getUserList();
        await getAllUserIds();
    }

    const handleDownloadPDF = async () => {
        const request = {
            skip: 0,
            take: listOfUsersId.value.length,
            sortColumn: sortOption.value?.columnName,
            sortDirection: sortOption.value?.direction,
            searchTerm: searchText.value
        } as ITableListRequest;

        try {
            const result = await userService.UsersExportAsPDF(request);
            const url = window.URL.createObjectURL(new Blob([result], { type: 'application/pdf' }));

            await CreateDownloadElement(url, 'users.pdf');

            window.URL.revokeObjectURL(url);
        } catch (e) {
            console.log(e);
        }
        closeDownloadOptionsModal();
    }

    const handleDownloadCSV = async () => {
        const request = {
            skip: 0,
            take: listOfUsersId.value.length,
            sortColumn: sortOption.value?.columnName,
            sortDirection: sortOption.value?.direction,
            searchTerm: searchText.value
        } as ITableListRequest;

        try {
            const result = await userService.UsersExportAsCSV(request);
            const url = window.URL.createObjectURL(new Blob([result], { type: 'text/csv' }));

            await CreateDownloadElement(url, 'users.csv');

            window.URL.revokeObjectURL(url);
        } catch (e) {
            console.log(e);
        }
        closeDownloadOptionsModal();
    }

    const CreateDownloadElement = (downloadUrl: string, fileName: string) => {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = downloadUrl;
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
    }

    onMounted(async () => {
    })
</script>

<style scoped>
</style>
