<template>
    <nav v-onClickOutside="closeDropdownModal" class="relative z-50 w-[250px] max-w-[300px] min-w-[250px]">
        <button @click="toggleDropdown"
                :aria-expanded="isOpen"
                aria-controls="bulk-actions-menu"
                class="flex items-center justify-between w-[250px] px-4 py-2 text-base font-medium text-white bg-slate-900 rounded-lg">
            <span class="truncate w-[80%]">Bulk Actions</span>
            <svg width="18"
                 height="18"
                 viewBox="0 0 14 14"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M10.2923 5.375L6.50065 9.16667L2.70898 5.375"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
            </svg>
        </button>
        <section v-if="isOpen"
                 id="bulk-actions-menu"
                 class="absolute left-0 z-50 w-[282px] mt-2 bg-slate-900 rounded-lg shadow-box-shadow-white"
                 role="menu">
            <ul class="flex flex-col py-2 gap-2 w-max">
                <li v-for="action in actions" :key="action.id" role="none" class="w-[250px]">
                    <button @click="handleAction(action.id)"
                            class="flex items-center w-full px-4 py-2 text-sm text-left text-white rounded-md hover:bg-slate-800 focus:bg-gray-100 focus:outline-none"
                            role="menuitem"
                            tabindex="0">
                        {{ action.label }}
                    </button>
                </li>
            </ul>
        </section>
    </nav>
</template>

<script setup lang="ts">
    import { ref, defineEmits } from "vue";

    interface Action {
        id: string;
        label: string;
    }

    const emits = defineEmits([
        "onDeleteUsers",
        "onUsersStatusChangeToActive",
        "onUsersStatusChangeToInActive",
        "onUsersResendInvitation"
    ]);

    const isOpen = ref(false);
    const actions = ref<Action[]>([
        { id: "delete", label: "Delete Users" },
        { id: "activate", label: "Change Status to Active" },
        { id: "deactivate", label: "Change Status to Inactive" },
        { id: "resend", label: "Resend Invitation" },
    ]);

    const toggleDropdown = () => {
        isOpen.value = !isOpen.value;
    };

    const closeDropdownModal = () => {
        isOpen.value = false;
    };

    const handleAction = (actionId: string) => {
        switch (actionId) {
            case 'delete':
                emits("onDeleteUsers");
                break;

            case 'activate':
                emits("onUsersStatusChangeToActive");
                break;

            case 'deactivate':
                emits("onUsersStatusChangeToInActive");
                break;

            case 'resend':
                emits("onUsersResendInvitation");
                break;
        }

        isOpen.value = false;
    };
</script>