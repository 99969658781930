<template>
  <div class="overflow-x-auto">
    <table class="min-w-full">
      <thead class="">
        <tr class="border-b border-[#ffffff12]">
          <th
            v-for="column in columns"
            :key="column.key"
            class="text-left text-xs font-bold leading-[12.1px] p-3"
          >
            {{ column.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, rowIndex) in data"
          :key="rowIndex"
          class="border-b border-[#ffffff12]"
        >
          <td
            v-for="(column, index) in columns"
            :key="column.key"
            class="text-left text-xs leading-[12.1px] font-medium p-3"
          >
            <span
              :class="{
                'text-apiActivate border border-apiActivate rounded-md py-[0.219rem] px-1.5 text-[0.625rem] font-medium':
                  row[column.key] === 'Active',
                'border rounded-md border-textGrey text-textGrey py-[0.219rem] px-1.5 text-[0.625rem] font-medium':
                  row[column.key] == 'Inactive',
                'text-apiDeactivate border border-apiDeactivate rounded-md py-[0.219rem] px-1.5 text-[0.625rem] font-medium':
                  row[column.key] == 'Deactivated'
              }"
            >
              {{ row[column.key] }}</span
            >

            <div class="flex justify-end items-center">
              <span v-if="row[column.key] === 'Deactivated'" class="-mt-3 -mr-5 cursor-pointer">
                <IconPlay />
              </span>
              <span v-else-if="row[column.key] === 'Active' || row[column.key] === 'Inactive'"
              class="-mt-3 -mr-5 cursor-pointer">
                <IconPauseCircle />
              </span>
            </div>
            <span
              class="flex items-center justify-end gap-3 cursor-pointer"
              v-if="index === columns.length - 1"
            > 
              <IconDelete
                class="w-4 h-4 cursor-pointer"
                @click="openDeleteModal(rowIndex)"
              />
              <IconChevronRight @click="goToApiDetailpage(rowIndex)" />
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <Modal
    :isVisible="isModalDelete"
    @close="closeDeleteModal"
    :largeModel="false"
  >
    <template v-slot:body>
      <section class="flex items-center justify-start">
        <div
          class="flex flex-col items-center w-[371px] self-stretch mx-auto my-0 p-8 rounded-[40px] bg-[#031525] border-white/10"
        >
          <header class="flex flex-col justify-start w-full">
            <img
              loading="lazy"
              src="../../assets/delete-orangebg.svg"
              alt=""
              class="w-[32px] aspect-square rounded-full object-contain object-center"
            />
            <div class="flex flex-col justify-start w-full mt-5">
              <h2 class="w-full text-[20px] font-bold text-white m-0">
                Delete API
              </h2>
              <p
                class="flex-1 w-full mt-2 text-[12px] font-medium text-[#cbd6e3]"
              >
                Are you sure you want to delete this API key? This action cannot
                be undone.
              </p>
            </div>
          </header>
          <footer
            class="flex items-center justify-between w-full mt-5 gap-2.5 text-[17px] font-medium whitespace-nowrap text-center"
          >
            <button
              class="btn text-white w-6/12 h-10 !border-[#7BA8EF] !text-[#7BA8EF] focus-visible:outline-none items-center justify-center"
              style="border: 1px solid"
              @click="closeDeleteModal"
            >
              Cancel
            </button>
            <button
              class="btn btn-danger w-6/12 h-10 focus-visible:outline-none !text-white items-center justify-center"
              @click="removeSelectedApiKey"
            >
              <span>Delete</span>
            </button>
          </footer>
        </div>
      </section>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { onMounted, ref,defineEmits,defineProps } from "vue";
import type { PropType } from "vue"; // Use type-only import
// import IconChevronRight from "@/components/icons/IconChevronRight.vue";
import Modal from "../common/CustomModelBox.vue";
import { IconDelete, IconChevronRight } from "../icons";
import IconPlay from "../icons/IconPlay.vue";
import IconPauseCircle from "../icons/IconPauseCircle.vue";

// Props
const props = defineProps<{
  columns: Array<{ key: any; label: string; }>;
  data: Array<Record<string, any>>;
}>();

// Emits
const emit = defineEmits<{
  (e: "showApiDetail", id: any): void;
  (e: "removeApiKey", id: any): void;
}>();

// State

const isModalDelete = ref<boolean>(false);
const toDelete = ref<number>();

// Methods
const openDeleteModal = (row:number) => {
  toDelete.value = row;
  isModalDelete.value = true;
};

const closeDeleteModal = () => {
  isModalDelete.value = false;
}


const removeSelectedApiKey =  () => {
  isModalDelete.value = false;
  emit("removeApiKey", toDelete.value);
};

// const removeSelectedApiKey = async () => {
//   // isDeleted.value = await apikeyService.RemoveApiKey();
//   isModalDelete.value = false;
// };

const goToApiDetailpage = (id: any) => {
  emit("showApiDetail", id);
};

onMounted( () => {
});
</script>


<style scoped>
/* Set a fixed width for table cells */
th,
td {
  width: 19%;
  text-align: left;
}

th:last-child,
td:last-child {
  width: 7%;
}

@media screen and (max-width: 768px) {
  th,
  td {
    white-space: nowrap;
    /* Prevent text from wrapping */
  }
}
</style>
