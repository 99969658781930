<template>
    <div class="h-full w-full flex flex-col">
        <div class="flex flex-row border-b-2 border-[#FFFFFF17] pr-2.5">
            <div v-for="(tab, index) in tabList" :key="index" class="w-fit h-[40px] mr-6 cursor-pointer" v-show="tab['show']" @click="changeTab(tab)">
                <span>{{ tab.title }}</span>
                <div class="h-[3px] w-full" :class="{ 'bg-white rounded-tl-full rounded-tr-full': activeTab === tab }" />
            </div>
        </div>
        <div class="relative lg:h-[80vh] xl:h-[83vh] w-full overflow-y-auto md:h-[65vh] sm:min-h-[300px]">
            <div v-if="!isMaterDataLoaded"
                 class="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-[#031525c4] backdrop-blur-[4px] z-[1000] overflow-auto scroll-width">
                <Spinner />
            </div>
            <div v-else class="pr-3">
                <component :is="activeTab['component']"></component>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, onMounted, markRaw, onBeforeUnmount, provide, reactive } from "vue";
    import Spinner from '@/components/common/Spinner.vue';
    import CrewManagementDashBoard from './CrewManagementDashBoard/CrewManagementDashBoard.vue';
    import CrewManagementUsersTab from './CrewManagementUsers/CrewManagementUsersTab.vue';
    import CrewManagementSettings from './CrewManagementUsers/CrewManagementSettings.vue';

    import type { IComponentMap } from "@/Models/IComponentMap";

    import { GetMasterData } from '@/Services/MasterDataService';

    const isMaterDataLoaded = ref<boolean>(false);
    const tabList = ref<IComponentMap[]>([
        {
            title: "DashBoard",
            component: markRaw(CrewManagementDashBoard),
            show: true,
            mainComponent: {},
        },
        {
            title: "Users",
            component: markRaw(CrewManagementUsersTab),
            show: true,
            mainComponent: {},
        },
        {
            title: "Settings",
            component: markRaw(CrewManagementSettings),
            show: true,
            mainComponent: {},
        }
    ]);

    const activeTab = ref<IComponentMap>(tabList.value[0]);

    const changeTab = (selectedTab: IComponentMap) => {
        activeTab.value = selectedTab;
    };

    onMounted(() => {
        GetMasterData()
            .then(() => {
                isMaterDataLoaded.value = true;
            })
            .catch((e) => {
                
            });
        
    })

</script>
<!--
<style scoped lang="scss">
@import "@/assets/mainstyle";

    $nav-font: 14px;
    $head-font: 24px;
    $light-border: rgba(255, 255, 255, 0.09);
    $highlight-border: #7ba8ef;

    .freddy-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        gap: 12px;

  .text-body-bold {
    padding: 14px 4px;
    margin: 0;
  }

  &.head {
    width: 100%;
    height: 46px;
    flex-direction: column;
    gap: 10px;
    padding: 0 20px;
    /*margin-top: -34px;*/

            &.title {
                font-size: $head-font;
                margin-top: 0px;

                .heading1 {
                    color: #fff;
                    font-family: Inter;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }

            &.nav-bar {
                font-size: $nav-font;
                gap: 14px;
                flex-direction: row;
                list-style: none;
                padding-left: 0;
                border-bottom: 1px solid #142636;
                overflow: auto hidden;
                scrollbar-width: thin;
                height: 46px;

      li:hover {
        cursor: pointer;
      }
    }
  }

        .main {
            flex-direction: column;
            //height: calc(100% - 100px);
            height: calc(100% - 56px);
            padding: 0 20px;
            //margin-top: -30px;

            @media (max-width: 1064px) {
                //max-height: calc(100% - 130px);
            }
        }
    }

    @media (max-width: 1024px) {
        .title {
            margin-left: 8px;
        }
    }
</style> -->
