<template>
    <div class="sm-container" :class="{ hide: isSideMenuClosed }">
        <div class="sm-container head flex justify-start gap-3 pb-6">
            <!-- <span><IconHub :width="59" :height="50" title="Freddy Hub"/></span> -->
            <img src="../assets/freddy_hub_logo.svg"
                 alt=""
                 class="sm-container hub_logo" />
            <img src="../assets/freddy_hub_title.svg" alt="" />
        </div>
        <div class="sm-container body border-t border-[--grey-border-line]">
            <div class="sm-container body head flex justify-between py-3 border-b border-[--grey-border-line] mb-3">
                <IconHomeFill title="Home overview" />
                <!-- <span><IconSettingsBold/></span> -->
                <span class="sm-container home_icon">
                    <img src="../assets/home.svg" alt="" />
                </span>
            </div>
            <div class="sm-container body options flex flex-col gap-1">
                <!-- Latest -->
                <div class="sm-container body options item p-1.5 gap-4 hover:bg-[--ms-dropdown-bg] flex items-center rounded-lg cursor-pointer text-[0.938rem] text-[--sidebar-menu-text]"
                     v-for="(item, index) in navigationList.filter(
                     (item)=>
                    item.isActive
                    )"
                    :key="index"
                    :class="{
                    'active bg-[--color-menu-selected]': selectedNavIndex == index,
                    }"
                    @click="changeNav(index)"
                    >
                    <span>
                        <component class="h-[30px] w-[30px]" :is="item.icon"></component>
                    </span>
                    <span class="alertTextSemibold sm-container body options item name">
                        {{
            item.name
                        }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref, markRaw } from "vue";
    import IconFreddyHub from "./icons/IconFreddyHub.vue";
    import IconStreamLineHub from "./icons/IconStreamLineHub.vue";
    import IconPeopleHub from "./icons/IconPeopleHub.vue";
    import IconAppHiveHub from "./icons/IconAppHiveHub.vue";
    import IconHomeFill from "./icons/IconHomeFill.vue";
    import IconCrew from "./icons/IconScrew.vue";

    const emit = defineEmits(["onSideMenuOptionChange"]);
    defineProps({
        isSideMenuClosed: { type: Boolean, default: false },
    });

    // const navItems = ref<string[]>(["Freddy", "Stream Line"]);

    const navigationList = ref<any[]>([
        {
            id: 0,
            isActive: true,
            name: "Freddy",
            icon: markRaw(IconFreddyHub),
            slugged: "freddy",
        },
        {
            id: 1,
            isActive: true,
            name: "Screw",
            icon: markRaw(IconCrew),
            slugged: "crew",
        },
        {
            id: 2,
            isActive: true,
            name: "Stream Line",
            icon: markRaw(IconStreamLineHub),
            slugged: "stream-line",
        },
        {
            id: 3,
            isActive: true,
            name: "Crew Management",
            icon: markRaw(IconPeopleHub),
            slugged: "crew-management",
        }
    ]);

    const selectedNavIndex = ref<number>(0);

    const changeNav = (index: number) => {
        selectedNavIndex.value = index;
        emit("onSideMenuOptionChange", selectedNavIndex.value);
        //-> Notify nav option changed to the right side body;
    };
</script>
