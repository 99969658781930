<template>
  <div
    class="relative flex flex-row"
    :class="{ 'pointer-events-none': isDisabled }"
  >
    <button
      class="mt-2.5 w-full rounded-lg border border-solid border-inputSelectBorder bg-backgroundSidebar flex items-center p-2.5 cursor-pointer text-white gap-2 font-normal text-base leading-6 h-10"
      @click="toggleDropdown"
    >
      <div class="flex items-center gap-2" v-if="selectedModelId">
        <img
          v-if="selectedLang.img"
          :src="selectedLang.img"
          alt=""
          class="w-5 h-5"
        />
        <span class="text-white">{{ selectedLang.label }}</span>
      </div>
      <div class="flex gap-2 items-center" v-else>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 6.66667V10M10 13.3333H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span class="font-normal text-[1rem] text-choosePrompt"
          >Choose an icon</span
        >
      </div>
      <img
        src="../../assets/down-arrow.svg"
        alt=""
        class="w-5 h-5 mr-2 last:ml-auto"
      />
    </button>

    <!-- Dropdown list -->
    <div
      v-show="dropdownVisible"
      class="absolute top-full left-0 w-full max-w-[21.875rem] z-10 p-1.5 rounded-lg border border-dropdownContainerBorder bg-backgroundSidebar max-h-[15.5rem] overflow-y-auto"
    >
      <ul class="pl-0 list-none m-0">
        <li
          :class="getClass(lang)"
          v-for="(lang, index) in data"
          :key="index"
          @click="selectLanguage(lang)"
        >
          <img
            :src="lang.img"
            alt=""
            :value="lang.value"
            class="h-5 w-5 mr-1.5"
          />
          <span class="text-white">{{ lang.label }}</span>
          <img
            src="../../assets/tick.svg"
            alt=""
            :value="lang.value"
            class="h-5 w-5 mr-1.5 ml-auto"
            v-if="selectedLang.label === lang.label"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  ref,
  onMounted,
  defineProps,
  onBeforeUnmount,
  defineEmits,
  computed,
} from "vue";
import type { ISelectOption } from "../../Models/Common/ISelectOption";

// Define props
const props = defineProps<{
  data: { img: string; label: string; value: number }[];
  selectedModelId?: number;
  isDisabled?: boolean;
}>();

const emits = defineEmits(["onSelect"]);

// Reactive variables
const dropdownVisible = ref(false);
const selectedLang = computed<ISelectOption>(
  () =>
    props.data.find((x) => x.value === props.selectedModelId) ||
    ({} as ISelectOption)
);

// Methods
const toggleDropdown = () => {
  dropdownVisible.value = !dropdownVisible.value;
};

const selectLanguage = (lang: ISelectOption) => {
  //selectedLang.value = lang;
  dropdownVisible.value = false;
  //localStorage.setItem("lang", lang.value.toString());
  emits("onSelect", lang.value);
};

const initializeSelectedLanguage = () => {
  //console.log(props.selectedModelId);
  //const selected = props.data.find((lang) => lang.value === props.selectedModelId);
  //if (selected) selectedLang.value = selected;
  //console.log(props.data, 'aa'); // Check if props.data contains the expected array
  //const sessionLang = localStorage.getItem("lang");
  //if (sessionLang) {
  //    const selected = props.data.find((lang) => lang.value === parseInt(sessionLang));
  //    if (selected) selectedLang.value = selected;
  //} else {
  //    selectedLang.value = props.data[0]; // Default to the first item
  //}
};

const close = (e: MouseEvent) => {
  const target = e.target as HTMLElement;
  if (!target.closest(".relative")) {
    dropdownVisible.value = false;
  }
};

const getClass = (lang: ISelectOption) => {
  return selectedLang.value.label === lang.label
    ? "p-2.5 cursor-pointer text-white text-base font-medium leading-6 flex items-center gap-2 hover:rounded-md bg-menuSelected"
    : "p-2.5 cursor-pointer text-white text-base font-medium leading-6 flex items-center gap-2 hover:rounded-md hover:bg-menuSelected";
};

// Lifecycle hook
onMounted(() => {
  initializeSelectedLanguage();
  document.addEventListener("click", close);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", close);
});
</script>
