<template>
  <div class="flex gap-[15px] h-full">
    <div class="w-fit">
      <button @click="back">
        <svg
          width="52"
          height="52"
          viewBox="0 0 52 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M41.1673 26.0026H10.834M10.834 26.0026L26.0007 41.1693M10.834 26.0026L26.0007 10.8359"
            stroke="white"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
    <div class="w-full">
      <div>
        <div
          class="flex flex-row w-full text-2.5xl font-semibold py-2.5 leading-[35.1px]"
        >
          API key details
        </div>
        <hr />
      </div>
      <div class="block mb-5 mt-5 gap-5 xl:flex 2xl:flex">
        <div
          class="border border-[#ffffff12] bg-[#071A2B] p-7 rounded-[40px] w-[100%] lg:w-[100%] xl:w-[40%] 2xl:w-[25%] h-[307px] relative mb-3 xl:mb-0 2xl:mb-0"
        >
          <span class="absolute" style="left: 50%; top: 50%">
            <Spinner class="CustomLoader" v-if="isLoading" />
          </span>
          <div v-if="!isLoading">
            <h2 class="text-lg font-medium">API Usage Cost</h2>
            <p
              class="font-inter text-xs leading-[14.52px] mb-8 text-[#FFFFFF66] mt-3"
            >
              Dec 1 - 31
            </p>
            <div class="flex gap-6">
              <div class="w-40">
                <CustomDoughnutChart
                  class="chart"
                  :chartData="chartData"
                  :chosenMonth="chosenMonth"
                  :innerTextFont="'14px'"
                  :showCenterText="true"
                />
              </div>
              <div>
                <h2 class="text-2xl font-medium">CHF 5.50</h2>
                <p class="font-inter text-xs leading-[14.52px] mb-2 mt-2">
                  / CHF {{ keyLimit }}
                </p>
                <button
                  class="flex px-1 rounded text-[#031525] bg-[var(--color-button-blue)] items-center gap-3 h-8 outline-none font-inter text-sm px-2 leading-[14.52px] whitespace-nowrap"
                  @click="openIncrementModal"
                >
                  Increase limit
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="border border-[#ffffff12] rounded-[40px] bg-[#071A2B] p-7 w-[100%] lg:w-[100%] xl:w-[60%] 2xl:w-[75%] h-[307px] relative"
        >
          <span class="absolute" style="left: 50%; top: 50%">
            <Spinner class="CustomLoader" v-if="isLoading" />
          </span>
          <div v-if="!isLoading">
            <h2 class="text-lg font-medium">API Usage – Top 3</h2>
            <p
              class="font-inter text-xs leading-[14.52px] mb-5 text-[#FFFFFF66] mt-3"
            >
              Dec 1 - 31
            </p>
            <div class="line-chart flex gap-7">
              <div
                class="font-inter text-xs leading-[14.52px] text-[#94969C] mt-3 rotate-180 text-center"
                style="writing-mode: tb"
              >
                Api calls
              </div>
              <CustomLineChart
                :Data="parentChartData"
                :chartOptions="parentChartOptions"
                :showCenterText="false"
              />
            </div>
            <div
              class="font-inter text-xs leading-[14.52px] text-[#94969C] mt-3 text-center"
            >
              Days
            </div>
          </div>
        </div>
      </div>
      <div class="border border-[#ffffff12] p-7 mt-5 rounded-3xl">
        <h2 class="text-[17px] font-medium">Settings</h2>
        <p class="font-inter text-xs leading-[14.52px] my-3">
          Here are your API key settings, which you can change anytime.
        </p>
        <label class="font-inter text-[14px]">Name*</label>
        <div
          class="flex items-center border border-[#ffffff12] rounded-md overflow-hidden bg-[#071A2B] mt-2 mb-4"
        >
          <input
            type="text"
            class="flex-1 px-3 py-2 text-sm text-white focus:outline-none bg-[#071A2B] border border-[#ffffff12] h-10 placeholder-[#CBD6E3]"
            v-model="name"
          />
        </div>
        <div class="flex flex-col space-y-2 mb-3">
          <label
            class="relative pl-10 cursor-pointer select-none font-inter text-[14px] flex items-center"
          >
            <span class="mt-[2px]">Set a spending Limit per month</span>
            <input
              type="checkbox"
              class="absolute opacity-0 cursor-pointer peer"
              v-model="isCheckboxChecked"
            />
            <span
              class="absolute top-0 left-0 w-6 h-6 bg-[#071A2B] border border-[#ffffff2e] rounded peer-hover:bg-grey-400 peer-checked:bg-[var(--color-button-blue)] peer-checked:after:content-[''] peer-checked:after:absolute peer-checked:after:w-2 peer-checked:after:h-3 peer-checked:after:border-white peer-checked:after:border-r-2 peer-checked:after:border-b-2 peer-checked:after:transform peer-checked:after:rotate-45 peer-checked:after:top-[3px] peer-checked:after:left-[7px]"
            >
            </span>
          </label>
        </div>
        <div
          v-if="isCheckboxChecked"
          class="flex items-center border border-[#ffffff12] rounded-md overflow-hidden bg-[#071A2B] mb-3"
        >
          <input
            type="text"
            class="flex-1 px-3 py-2 text-sm text-white focus:outline-none bg-[#071A2B] border border-[#ffffff12] h-10 placeholder-[#CBD6E3]"
            v-model="keyUsageLimit"
          />
        </div>
        <label class="font-inter text-[14px]">Permissions*</label>
        <RadioGroup
          name="Permission"
          :options="radioOptions"
          v-model="selectedPermission"
          :large="true"
        />
        <div class="mt-2" v-if="selectedPermission === 'restricted'">
          <div
            class="flex justify-between border-b border-[#ffffff12] pb-2 mb-2"
          >
            <span class="font-inter text-xs">Resources</span>
            <span class="font-inter text-xs">Permissions</span>
          </div>
          <div class="flex justify-between">
            <customLabel
              text="Models"
              :tooltipText="'Models'"
              :colonHide="true"
            ></customLabel>

            <RadioGroup
              name="model"
              :options="modalradioOptions"
              v-model="modalselectedPermission"
              :large="false"
            />
          </div>
          <div class="font-inter text-xs border-b border-[#ffffff12]">
            v1/models
          </div>
          <div class="flex justify-between">
            <customLabel
              text="Model capabilities"
              :tooltipText="'Model capabilities'"
              :colonHide="true"
            >
            </customLabel>
            <RadioGroup
              name="modelCapability"
              :options="modalradioOptions"
              v-model="modelCapability"
              :large="false"
            />
          </div>
          <div class="font-inter text-xs">v1/models</div>
          <div class="font-inter text-xs">v1/models</div>
          <div class="font-inter text-xs">v1/models</div>
          <div class="font-inter text-xs">v1/models</div>
          <div class="font-inter text-xs">v1/models</div>
          <div class="font-inter text-xs">v1/models</div>
          <div class="font-inter text-xs border-b border-[#ffffff12]">
            v1/models
          </div>
          <div class="flex justify-between">
            <customLabel
              text="Assistants setting"
              :tooltipText="'Assistants setting'"
              :colonHide="true"
            >
            </customLabel>
            <RadioGroup
              name="assistants"
              :options="radioOptions3"
              v-model="modelAssistants"
              :large="false"
            />
          </div>
          <div class="font-inter text-xs">v1/models</div>
          <div class="font-inter text-xs">v1/models</div>
          <div class="font-inter text-xs border-b border-[#ffffff12]">
            v1/models
          </div>
          <div class="flex justify-between">
            <customLabel
              text="Assistant selection"
              :tooltipText="'Assistant selection'"
              :colonHide="true"
            >
            </customLabel>
            <RadioGroup
              name="assitantSelection"
              :options="modalradioOptions"
              v-model="assitantSelection"
              :large="false"
            />
          </div>
          <div class="font-inter text-xs">v1/models</div>
          <div class="font-inter text-xs border-b border-[#ffffff12]">
            v1/models
          </div>
          <div class="flex justify-between">
            <customLabel
              text="Threads"
              :tooltipText="'Threads'"
              :colonHide="true"
            ></customLabel>
            <RadioGroup
              name="Threads"
              :options="modalradioOptions"
              v-model="Threads"
              :large="false"
            />
          </div>
          <div class="font-inter text-xs">v1/models</div>
          <div class="font-inter text-xs border-b border-[#ffffff12]">
            v1/models
          </div>
          <div class="flex justify-between">
            <customLabel
              text="Fine-tuning"
              :tooltipText="'Fine-tuning'"
            ></customLabel>
            <RadioGroup
              name="Fine-tuning"
              :options="modalradioOptions"
              v-model="Finetuning"
              :large="false"
            />
          </div>
          <div class="font-inter text-xs">v1/models</div>
          <div class="font-inter text-xs border-b border-[#ffffff12]">
            v1/models
          </div>
          <div class="flex justify-between">
            <customLabel
              text="Files"
              :tooltipText="'Files'"
              :colonHide="true"
            ></customLabel>
            <RadioGroup
              name="files"
              :options="modalradioOptions"
              v-model="filesRadio"
              :large="false"
            />
          </div>
          <div class="font-inter text-xs">v1/models</div>
          <div class="font-inter text-xs border-b border-[#ffffff12]">
            v1/models
          </div>
        </div>
        <div class="flex gap-5 justify-end">
          <button
            type="button"
            class="flex justify-center rounded text-[var(--color-button-blue)] border border-solid border-[var(--color-button-blue)] items-center w-[100px] h-10 mt-5 outline-none"
            @click = "back"
          >
            Cancel
          </button>
          <button
            type="button"
            class="flex justify-center rounded text-black bg-[var(--color-button-blue)] items-center w-[100px] h-10 mt-5 outline-none"
            @click = "updateKey"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
  <Modal
    :isVisible="isModalIncrement"
    @close="closeIncrementModal()"
    :largeModel="true"
  >
    <template v-slot:header>
      <div class="w-full bg-background rounded-2xl">
        <div class="flex justify-between items-center">
          <h4 class="text-[1.813rem] text-3xl text-white">Monthly Limit</h4>
          <span @click="closeIncrementModal" class="cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M8 24L24 8M8 8L24 24"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
      </div>
    </template>
    <template v-slot:body>
      <p class="text-[14px] font-medium text-[#CBD6E3] mt-2 mb-5">
        Manage your API spend by configuring monthly spend limits. Notification
        emails will be sent to members of your organization with the "Owner"
        role. Note that there may be a delay in enforcing limits, and you are
        still responsible for any overage incurred.
      </p>
      <h5 class="text-lg font-bold leading-[24.2px]">Set a monthly budget</h5>
      <p class="text-[14px] font-medium text-[#CBD6E3] mb-3">
        If your organisation exceeds this budget in a given calendar month,
        subsequent API requests will be rejected.
      </p>
      <div
        class="flex items-center border border-[#ffffff12] rounded-md overflow-hidden bg-[#071A2B]"
      >
        <input
          type="text"
          class="flex-1 px-3 py-2 text-sm text-white focus:outline-none bg-[#071A2B] border border-[#ffffff12] h-10 placeholder-[#CBD6E3]"
          v-model="keyLimit"
        />
        <span class="px-3 text-white text-sm">CHF</span>
      </div>
      <h5 class="text-lg font-bold leading-[24.2px] mt-3">
        Set an email notification threshold
      </h5>
      <p class="text-[14px] font-medium text-[#CBD6E3] mb-3">
        If your organisation exceeds this budget in a given calendar month,
        subsequent API requests will be rejected.
      </p>
      <div
        class="flex items-center border border-[#ffffff12] rounded-md overflow-hidden bg-[#071A2B]"
      >
        <input
          type="text"
          class="flex-1 px-3 py-2 text-sm text-white placeholder-[#CBD6E3] focus:outline-none bg-[#071A2B] border border-[#ffffff12] h-10 placeholder-[#CBD6E3]"
          placeholder="123456.00"
        />
        <span class="px-3 text-white text-sm">CHF</span>
      </div>
      <div class="flex gap-5 justify-end">
        <button
          type="button"
          class="flex justify-center rounded text-[var(--color-button-blue)] border border-solid border-[var(--color-button-blue)] items-center w-[100px] h-10 mt-5 outline-none"
          @click="closeIncrementModal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="flex justify-center rounded text-black bg-[var(--color-button-blue)] items-center w-[100px] h-10 mt-5 outline-none"
          @click = "updateLimit"
        >
          Save
        </button>
      </div>
    </template>
  </Modal>
</template>
<script setup lang="ts">
import CustomDoughnutChart from "@/components/common/CustomDoughnutChart.vue";
import type { IChartData } from "@/Models/charts";
import type { IInvoice } from "@/Models/IUsage";
import { HubService } from "@/Services/HubService";
import Modal from "../common/CustomModelBox.vue";
import { onMounted, ref, watch,defineProps } from "vue";
import customLabel from "../common/labelCustom.vue";
import RadioGroup from "../common/RadioGroup.vue";
import CustomLineChart from "../common/CustomLineChart.vue";
import type { ChartData } from "chart.js";
import Spinner from "@/components/common/Spinner.vue";
import type { IApiKeyInfo } from "@/Models/IApiKeyManagement";
import type { PropType } from "vue"; // Use type-only import
import { ApiKeyManagementService } from "@/Services/ApiKeyManagementService";

const isLoading = ref<boolean>(true);
const hubService = new HubService();
const usage = ref<number>();
const limit = ref<number>();
const startDate = ref<string>();
const endDate = ref<string>();
const listInvoices = ref<IInvoice[]>([]);
const usagePercentage = ref<number>();
const highChartData = ref([]);
const isModalIncrement = ref(false);
const selectedPermission = ref("all");
const isCheckboxChecked = ref(false);
const modalselectedPermission = ref("none");
const modelCapability = ref("none");
const modelAssistants = ref("none");
const assitantSelection = ref("none");
const Threads = ref("none");
const Finetuning = ref("none");
const filesRadio = ref("none");
const props = defineProps({
  chosenMonth: {
    type: Number,
    required: true,
  },
  keyDetails: {
    type:Object as PropType<IApiKeyInfo>,
    required: false,
    default: () => ({
      Id: 0,
    })
  },
  apiLimit : {
    type: Number,
    required: true,
  }
});
const keyData = ref(props.keyDetails);
const name = ref(props.keyDetails?.name);
const keyLimit = ref(props.keyDetails?.limit);
const keyUsageLimit = ref(props.keyDetails?.limit);
const radioOptions = [
  { label: "All", value: "all" },
  { label: "Restricted", value: "restricted" },
  { label: "Read only", value: "read" },
];

const modalradioOptions = [
  { label: "None", value: "none" },
  { label: "Read", value: "read" },
];

const radioOptions3 = [
  { label: "None", value: "none" },
  { label: "Read", value: "read" },
  { label: "Write", value: "write" },
];

const parentChartData: ChartData<"line", (number | null)[], string> = {
  labels: Array.from({ length: 31 }, (_, i) => (i + 1).toString()), // Days (1-31)
  datasets: [
    {
      label: "API Name 1",
      data: [
        50, 55, 53, 60, 65, 63, 70, 75, 80, 78, 85, 90, 88, 85, 87, 86, 84, 82,
        81, 80, 78, 77, 76, 75, 74, 72, 71, 70, 69, 68, 67,
      ],
      borderColor: "#FEF7C3",
      backgroundColor: "#FEF7C3",
      borderWidth: 2,
      pointRadius: 0, // Show points
      pointHoverRadius: 6, // Make hover points larger
      pointBackgroundColor: "#FEF7C3", // Match border color
      tension: 0.2, // Slight curve for smoothness
    },
    {
      label: "API Name 2",
      data: [
        40, 45, 42, 48, 52, 50, 55, 57, 59, 62, 63, 65, 68, 67, 66, 65, 64, 63,
        62, 61, 60, 58, 57, 56, 55, 54, 53, 52, 51, 50, 49,
      ],
      borderColor: "#FAC515",
      backgroundColor: "#FAC515",
      borderWidth: 2,
      pointRadius: 0,
      pointHoverRadius: 6,
      pointBackgroundColor: "#FAC515",
      tension: 0.2,
    },
    {
      label: "API Name 3",
      data: [
        15, 20, 18, 25, 22, 21, 23, 25, 28, 27, 30, 32, 31, 30, 29, 28, 27, 26,
        25, 24, 23, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13,
      ],
      borderColor: "#A15C07",
      backgroundColor: "#A15C07",
      borderWidth: 2,
      pointRadius: 0,
      pointHoverRadius: 6,
      pointBackgroundColor: "#A15C07",
      tension: 0.2,
    },
  ],
};

const parentChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top",
      labels: {
        color: "#000", // Legend text color
        font: {
          size: 12, // Legend font size
        },
      },
    },
  },
};

const chartData = ref<IChartData>({
  labels: [],
  datasets: [
    {
      data: [0, 0],
      backgroundColor: [],
      hoverOffset: 0,
      borderWidth: 0,
    },
  ],
});

const allWorking = ref(false); //* Might move it later

const setChartData = () => {
  if (usage.value && limit.value) {
    chartData.value = {
      labels: ["Synapsis", "Neurons", ""],
      datasets: [
        // "#A6EF67" Color green
        {
          data: [usage.value, limit.value - usage.value],
          backgroundColor: ["#FEF7C3", "#FFFFFF17"],
          hoverOffset: 0,
          borderWidth: 0,
        },
      ],
    };
  }
};

watch(
  () => 11,
  async () => {
    await setupBaseData();
  }
);

// Fetching usage data on component mount
onMounted(async () => {
  await setupBaseData();
});

const updateKey = async () => {
  keyData.value.name = name.value;
  keyData.value.limit = keyUsageLimit.value;
  await saveKeyDetails();
  back();
};

const updateLimit = async () => {
  keyData.value.limit = keyLimit.value;
  const isSuccess = await saveKeyDetails();
  if(isSuccess)
    keyUsageLimit.value = keyLimit.value;
  closeIncrementModal();
};

const saveKeyDetails = async () : Promise<boolean> => {
  
  const keyService = new ApiKeyManagementService();
  const isSuccess = await keyService.UpdateApiKey(keyData.value);
  if(!isSuccess)
    console.error("Update Failed");
  return isSuccess;
}
const setupBaseData = async () => {
  isLoading.value = true;
  allWorking.value = false;
  await hubService
    .getUsageById(11)
    .then(async (usageData) => {
      allWorking.value = true;

      startDate.value = usageData.startDate;
      endDate.value = usageData.endDate;
      usage.value = usageData.usage;
      limit.value = usageData.limit;
      usagePercentage.value = usageData.usagePercentage;
      listInvoices.value = await hubService.getAllInvoices();

      setChartData();
    })
    .catch(() => {
      allWorking.value = false;
    });

  const res = await hubService.getUsageChartData(11);

  const compressedData = res.map((item: any) => ({
    [item.day]: parseFloat(item.cost.toFixed(2)),
  }));
  highChartData.value = compressedData;

  isLoading.value = false;
};

const openIncrementModal = () => {
  isModalIncrement.value = true;
};

const closeIncrementModal = () => {
  isModalIncrement.value = false;
};

const emit = defineEmits(["back"]);

const back = () => {
  emit("back");
};
</script>
<style lang="scss" scoped></style>
