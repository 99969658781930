<template>
  <section
    class="h7 success-notification"
    :class="{ 'error-message': isError }"
    role="alert"
  >
    <img
      loading="lazy"
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/e1614fa0eb5b92e2b0107922afdf26f328b3e3312af86ce4e805f34110defaae?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51"
      class="success-icon"
      alt=""
    />
    <p class="success-message">
      {{ message }}
    </p>
    <button
      class="close-button"
      @click="closeNotification"
      aria-label="Close notification"
    >
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/10bddebf33f61d7975e8d97e7447f51f34690ba27ef50a7f3dff1b0b8ed91edf?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51"
        class="close-icon"
        alt=""
      />
    </button>
  </section>
</template>

<script setup lang="ts">
import { computed } from "vue";
const closeNotification = () => {
  // Implement close functionality
};
const props = defineProps({
  message: {
    default: "",
    required: false,
    type: String,
  },
});

const isError = computed(() => {
  return props.message
    ? props.message[0].toLowerCase() === "s"
      ? true
      : false
    : false;
});
</script>

<style scoped lang="scss">
.success-notification {
  align-items: center;
  border-radius: 10px;
  background-color: #099250;
  display: flex;
  gap: 10px;
  color: #fff;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 10px;
}

.success-icon {
  aspect-ratio: 1;
  object-fit: contain;
  width: 24px;
  align-self: stretch;
  margin: auto 0;
}

.success-message {
  align-self: stretch;
  width: 440px;
  margin: auto 0;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.close-icon {
  aspect-ratio: 1;
  object-fit: contain;
  width: 16px;
  align-self: stretch;
  margin: auto 0;
}
.error-message {
  background-color: #aa3a3a !important;
}

@media (max-width: 991px) {
  .success-message {
    max-width: 100%;
  }
}
</style>
