<template>
    <div class="icon-linear">
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_11450_18883)">
                <rect x="2" y="1" width="52" height="52" rx="8" fill="white" shape-rendering="crispEdges" />
                <rect x="2.5" y="1.5" width="51" height="51" rx="7.5" stroke="#EAECF0" shape-rendering="crispEdges" />
                <path d="M7 18.075C7 13.8038 7 11.6682 7.8444 10.0427C8.55597 8.67287 9.67287 7.55597 11.0427 6.8444C12.6682 6 14.8038 6 19.075 6H36.925C41.1962 6 43.3318 6 44.9573 6.8444C46.3271 7.55597 47.444 8.67287 48.1556 10.0427C49 11.6682 49 13.8038 49 18.075V35.925C49 40.1962 49 42.3318 48.1556 43.9573C47.444 45.3271 46.3271 46.444 44.9573 47.1556C43.3318 48 41.1962 48 36.925 48H19.075C14.8038 48 12.6682 48 11.0427 47.1556C9.67287 46.444 8.55597 45.3271 7.8444 43.9573C7 42.3318 7 40.1962 7 35.925V18.075Z" fill="url(#paint0_linear_11450_18883)" />
                <path d="M25.9323 39.2905L15.7109 29.0691C16.584 34.2937 20.7077 38.4174 25.9323 39.2905Z" fill="#F2F3F8" />
                <path d="M15.5586 26.3062L28.6949 39.4426C29.4742 39.3997 30.2344 39.2854 30.9691 39.1059L15.8953 24.032C15.7157 24.7668 15.6014 25.527 15.5586 26.3062Z" fill="#F2F3F8" />
                <path d="M16.5508 22.0762L32.9256 38.451C33.5212 38.1945 34.0928 37.893 34.6361 37.5506L17.4512 20.3657C17.1088 20.909 16.8073 21.4806 16.5508 22.0762Z" fill="#F2F3F8" />
                <path d="M18.5586 18.865C20.8437 16.2155 24.2254 14.5384 27.9988 14.5384C34.8811 14.5384 40.4604 20.1176 40.4604 26.9999C40.4604 30.7734 38.7833 34.155 36.1337 36.4402L18.5586 18.865Z" fill="#F2F3F8" />
            </g>
            <defs>
                <filter id="filter0_d_11450_18883" x="0" y="0" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11450_18883" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_11450_18883" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_11450_18883" x1="7" y1="48" x2="49" y2="6" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#29359B" />
                    <stop offset="1" stop-color="#6068CA" />
                </linearGradient>
            </defs>
        </svg>

    </div>
</template>

<script setup lang="ts">
</script>

<style>
    .icon-linear {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        color: rgb(177, 168, 168);
    }

</style>