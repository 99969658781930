<template>
    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.0//EN" "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
    <div class="icon-people">
        <svg width="100%" height="100%" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 15.5C11.3261 15.5 12.5979 14.9732 13.5355 14.0355C14.4732 13.0979 15 11.8261 15 10.5C15 9.17392 14.4732 7.90215 13.5355 6.96447C12.5979 6.02678 11.3261 5.5 10 5.5C8.67392 5.5 7.40215 6.02678 6.46447 6.96447C5.52678 7.90215 5 9.17392 5 10.5C5 11.8261 5.52678 13.0979 6.46447 14.0355C7.40215 14.9732 8.67392 15.5 10 15.5ZM21.25 15.5C22.2446 15.5 23.1984 15.1049 23.9017 14.4017C24.6049 13.6984 25 12.7446 25 11.75C25 10.7554 24.6049 9.80161 23.9017 9.09835C23.1984 8.39509 22.2446 8 21.25 8C20.2554 8 19.3016 8.39509 18.5983 9.09835C17.8951 9.80161 17.5 10.7554 17.5 11.75C17.5 12.7446 17.8951 13.6984 18.5983 14.4017C19.3016 15.1049 20.2554 15.5 21.25 15.5ZM5.3125 18C4.56658 18 3.85121 18.2963 3.32376 18.8238C2.79632 19.3512 2.5 20.0666 2.5 20.8125V21.125C2.5 21.125 2.5 26.75 10 26.75C17.5 26.75 17.5 21.125 17.5 21.125V20.8125C17.5 20.0666 17.2037 19.3512 16.6762 18.8238C16.1488 18.2963 15.4334 18 14.6875 18H5.3125ZM21.25 24.875C19.7862 24.875 18.665 24.6488 17.8062 24.3025C18.306 23.459 18.6197 22.5184 18.7262 21.5437C18.7384 21.4253 18.7463 21.3065 18.75 21.1875V20.8125C18.7516 19.7653 18.3475 18.7582 17.6225 18.0025C17.665 18.0007 17.7075 17.9999 17.75 18H24.75C25.4793 18 26.1788 18.2897 26.6945 18.8055C27.2103 19.3212 27.5 20.0207 27.5 20.75C27.5 20.75 27.5 24.875 21.25 24.875Z" fill="#CBD6E3" />
        </svg>

        <span class="icon-title">{{title}}</span>
    </div>
</template>

<script>
    export default {
        props: {
            width: {
                type: String,
                required: false
            },
            height: {
                type: String,
                required: false
            },
            title: {
                type: String,
                required: false,
                default: ''
            }
        }
    };
</script>

<style scoped>
    .icon-people {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        /*margin: 7.5px 15px 7.5px 7px;*/
    }

    .icon-title {
        font-size: 20px;
        font-family: serif;
    }
</style>