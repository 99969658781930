import AppConfigGlobal from "@/core/config/uiSettings";
import type { AxiosRequestConfig } from "axios";
import Axios from "axios";

export default abstract class Service {
  private defaultConfig: AxiosRequestConfig = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: this.GetBearerToken(),

    },
  };
  //private defaultConfig: AxiosRequestConfig = { withCredentials: true };

  protected PerformGet<T>(
    url: string,
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    let mergedConfig = { ...this.defaultConfig, ...config };
    return Axios.get(url, mergedConfig)
      .then((response) => response.data)
      .catch((err) => { throw err });
  }
  protected PerformDelete<T>(
    url: string,
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    let mergedConfig = { ...this.defaultConfig, ...config };
    return Axios.delete(url, mergedConfig).then((response) => response.data);
  }

  protected PerformPut<T>(
    url: string,
    data: object,
    config: AxiosRequestConfig = {
      headers: { "Content-Type": "application/json" },
    }
  ): Promise<T> {
    let mergedConfig = { ...this.defaultConfig };
    return Axios.put(url, data, mergedConfig).then((response) => response.data);
  }

  protected PerformPost<T>(
    url: string,
    data: object,
    config: AxiosRequestConfig = {
      headers: { "Content-Type": "application/json" },
    },
  ): Promise<T> {
    let mergedConfig = { ...this.defaultConfig, signal: config.signal };
    return Axios.post(url, JSON.stringify(data), mergedConfig).then(
      (response) => response.data
    );
    }

    protected PerformPostExportPdf<T>(
        url: string,
        data: object,
        config: AxiosRequestConfig = {
            headers: { "Content-Type": "application/json" }
        }
    ): Promise<T> {
        let mergedConfig = { ...this.defaultConfig, signal: config.signal };
        return Axios.post(url, JSON.stringify(data), { ...mergedConfig, responseType: "blob" })
            .then((response) => response.data);           ;
    }

  protected PerformPostFile<T>(
    url: string,
    data: object,
    config: AxiosRequestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    }
  ): Promise<T> {
    let mergedConfig = { ...this.defaultConfig, ...config };
    return Axios.post(url, data, mergedConfig).then(
      (response) => response.data
    );
  }

  protected PerformPostV2<T>(
    url: string,
    data: object,
    config: AxiosRequestConfig = {
      headers: { "Content-Type": "application/json" },
    }
  ): Promise<T> {
    let mergedConfig = { ...this.defaultConfig };
    return Axios.post(url, data, mergedConfig);
  }

  protected PerformGetSample<T>(
    url: string,
    id: string,
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    let mergedConfig = { ...this.defaultConfig, ...config };
    return Axios.get(`${url}/${id}`, mergedConfig);
  }

  public CreateCookie(name: string, value: any) {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    document.cookie = `${name}=${value}; path=/; secure; samesite=Lax; expires=${date.toUTCString()}; domain=${AppConfigGlobal.Domain
      }`;
  }

  public ClearCookie(name: string) {
    document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; secure; samesite=strict; domain=${AppConfigGlobal.Domain}`;
  }
  private GetBearerToken() {
    const token = this.GetCookieValue("freddyUserId");
    if (token == undefined || token == null) {
      return "";
    }
    return `Bearer ${token}`;
  }
  public GetCookieValue(name: string) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts && parts.length === 2) {
      return parts.pop()?.split(";").shift();
    }
  }
}
