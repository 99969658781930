<template>
  <div
    v-if="!isUploadingComplete"
    class="text-white p-5 rounded-2xl w-full max-w-full border border-[#333741]"
  >
    <!-- Upload Area -->
    <div
      class="text-center rounded-lg cursor-pointer text-[#cbd6e3] text-sm font-normal"
      @click="triggerFileInput"
    >
      <IconCloudUpload
        class="border border-[#333741] p-2.5 rounded-lg mb-3 w-10 h-10 mx-auto"
      />
      <input
        ref="fileInput"
        type="file"
        multiple
        @change="handleFileSelect"
        accept=".svg, .png, .jpg, .jpeg, .pdf"
        style="display: none"
      />
      <label class="block"
        ><span class="text-[#a7d0f8] font-semibold cursor-pointer"
          >Click to upload</span
        >
        or drag and drop</label
      >
      <p>SVG, PNG, JPG, or PDF</p>
    </div>

    <!-- File List -->
    <div
      v-if="files.length"
      class="max-h-[300px] overflow-y-auto flex flex-col gap-2.5 mt-6 pr-4"
    >
      <div
        v-for="(file, index) in files"
        :key="file.name"
        class="p-4 items-start rounded-[16px] border border-[#333741] pt-6"
        :class="{
          'border border-solid border-[#e53e3e]': file.status === 'failed',
        }"
      >
        <div class="flex justify-between">
          <div class="flex gap-[10px]">
            <IconFile
              class="w-5 h-5 self-start stroke-white"
              :class="{
                'text-[#e53e3e]': file.status === 'failed',
              }"
            />
            <div class="flex flex-col leading-[20px]">
              <span class="font-medium">{{ file.name }}</span>
              <span
                v-if="file.status !== 'failed'"
                class="text-[0.9rem] text-gray-400"
                >{{ formatSize(file.size) }}</span
              >

              <div
                v-if="file.status === 'failed'"
                class="flex text-[14px] font-normal leading-5 flex-col gap-1"
              >
                Upload failed, please try again
                <button
                  class="bg-none border-none font-semibold text-[#cbd6e3] cursor-pointer"
                  @click="retryUpload(index)"
                >
                  Try again
                </button>
              </div>
            </div>
          </div>

          <div
            v-if="file.status === 'completed'"
            class="text-[#38a169] text-xl ml-2.5"
          >
            <input type="checkbox" checked />
          </div>
          <button
            v-else
            class="bg-transparent border-0 text-[#e53e3e] cursor-pointer text-[1.2rem] p-0"
            @click="removeFile(index)"
          >
            <IconDelete class="w-4 h-4" />
          </button>
        </div>

        <!-- v-if="file.status === 'uploading'" -->
        <!-- Progress bar -->
        <div
          v-if="file.status !== 'failed'"
          class="flex gap-3 pl-7 mt-2.5 items-center"
        >
          <div
            class="bg-gray-600 h-2 rounded overflow-hidden my-1 mx-auto w-[calc(100%-32px)]"
          >
            <div
              class="bg-[#a7d0f8] h-full transition-width duration-300 ease-in-out"
              :style="{ width: file.progress + '%' }"
            ></div>
          </div>

          <span class="text-[14px] font-medium text-[#ffffff33]">{{
            `${file.progress}%`
          }}</span>
        </div>
      </div>
    </div>
  </div>

  <!-- Step 2: Edit File Information and Select Vector Store -->
  <div
    v-if="isUploadingComplete"
    class="flex flex-col gap-6 border border-[#333741] rounded-2xl p-6"
  >
    <div class="text-center flex flex-col items-center gap-3">
      <span
        class="w-10 h-10 flex items-center justify-center border border-[#333741] rounded-lg"
      >
        <IconFile class="w-[14px] h-[17px] stroke-slate-50" />
      </span>
      <p class="text-[#CBD6E3]">
        You must select one or more vector stores for each <br />
        file and optionally you can change the file name.
      </p>
    </div>

    <div>
      <label class="mb-2 text-[#CBD6E3]">Bulk Vector store selection </label>
      <div class="flex flex-row gap-[6px] mt-1 justify-between items-center">
        <div class="w-[378px]">
          <MultiSelectDropdown
            id="vectorStore"
            class="bg-[#071A2B]"
            :listOfItems="listOfVectorStores"
            placeholder="Please select multiple files to use this function"
            :searchIcon="false"
            :dropDownArrow="false"
            leftPadding="2px"
            @update:tags="handleTagsUpdate"
          />
        </div>
        <button class="text-[#A7D0F8] font-medium text-[14px]">
          Select all
        </button>
      </div>
    </div>

    <!-- List of SelectedItems -->
    <div class="overflow-y-auto max-h-[300px] pr-4">
      <div
        v-for="(file, index) in files"
        :key="index"
        class="flex flex-col gap-3 border border-[#FFFFFF33] p-4 rounded-xl mb-3"
      >
        <div class="flex flex-col gap-[6px]">
          <div class="flex flex-col">
            <CustomCheckbox
              @update:isChecked="(value: boolean) => files[index].isChecked = value"
              class="self-end border border-solid border-white"
            />
            <label for="fileName" class="text-[#CBD6E3] text-[14px]"
              >File name</label
            >
          </div>

          <input
            type="text"
            v-model="file.name"
            class="bg-[#071A2B] border border-[#FFFFFF33] p-2 px-3 text-base font-normal leading-6 font-roboto rounded-lg text-[#CBD6E3]"
            id="fileName"
          />
        </div>

        <div class="flex flex-col gap-[6px]">
          <label for="vectorStore" class="text-[#CBD6E3] text-[14px]"
            >Vector store</label
          >
          <MultiSelectDropdown
            id="vectorStore"
            class="bg-[#071A2B]"
            :listOfItems="listOfVectorStores"
            :fileIndex="index"
            placeholder="Search for a vector store"
            :searchIcon="true"
            :dropDownArrow="true"
            @update:tags="handleTagsUpdate"
            :marginLeft="'12px'"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- Footer with Buttons -->
  <div v-if="files.length" class="flex justify-end gap-4 mt-5">
    <button
      class="btn btn-outline w-[100px] h-[40px] pt-[2px] pb-[2px]"
      @click="closeModal"
    >
      Cancel
    </button>
    <button
      v-if="isUploadingComplete"
      class="btn btn-primary w-[100px] h-[40px] pt-[2px] pb-[2px] text-background"
      @click="startSend"
      :disabled="selectedTags.length === 0"
    >
      <!-- <button v-if="isUploadingComplete" class="btn btn-primary"
      :class="tags.length && files.length ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-buttonBlue text-white cursor-pointer'"
      :disabled="!files.length || !tags.length" @click="startSend"> -->
      Save
    </button>
    <button
      v-else
      class="btn btn-primary w-[100px] h-[40px] pt-[2px] pb-[2px]"
      :disabled="!files.length"
      @click="startUpload"
    >
      Next
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { IconCloudUpload, IconDelete, IconFile } from "@/components/icons";
import MultiSelectDropdown from "@/components/common/MultiSelectDropdown.vue";
import CustomCheckbox from "../common/CustomCheckbox.vue";
import type { IVectorStore, IUploadFile } from "@/Models";
import { KnowledgeService } from "@/Services/KnowledgeService";

interface FileData {
  name: string;
  size: number;
  isChecked: boolean;
  status: "ready" | "uploading" | "completed" | "failed";
  progress: number;
  rawFile: File;
  vectorStore: number[];
}
const knowledgeService = new KnowledgeService();
const selectedTags = ref<IVectorStore[]>([]);
const multiselectedTags = ref<IVectorStore[]>([]);
const emit = defineEmits(["cancel", "fileUploaded"]);
const files = ref<FileData[]>([]);
const fileInput = ref<HTMLInputElement | null>(null);
const listOfVectorStores = ref<IVectorStore[]>([]);
const uploadFiles = ref<IUploadFile[]>([]);
const tags = ref([]);

// Format file size to a readable format
const formatSize = (size: number) => (size / 1024 / 1024).toFixed(2) + " MB";

const handleTagsUpdate = (tags: IVectorStore[], Index: number) => {
  if (Index == -1) {
    multiselectedTags.value = tags;
    // files.value.forEach((file, index) => {
    //   if(file.isChecked){
    //     file.vectorStore = selectedTags.value.map(tag => tag.storeId);
    //   }
    // });
    return;
  }
  selectedTags.value = tags;
  if (files.value[Index] && selectedTags.value) {
    files.value[Index].vectorStore = selectedTags.value.map(
      (tag) => tag.storeId
    );
  }
};

// Trigger file input click
const triggerFileInput = () => {
  fileInput.value?.click();
};
const getVectorStores = async () => {
  listOfVectorStores.value = await knowledgeService.GetAllVectorStores();
};
// Handle file selection
const handleFileSelect = (event: Event) => {
  const selectedFiles = (event.target as HTMLInputElement).files;
  if (selectedFiles) {
    for (const file of selectedFiles) {
      files.value.push({
        name: file.name,
        size: file.size,
        status: "ready",
        progress: 0,
        rawFile: file,
        vectorStore: [],
        isChecked: false, // Initialize vector store as empty
      });
    }
  }
};

// Remove file from list
const removeFile = (index: number) => {
  files.value.splice(index, 1);
};

const startSend = async () => {
  console.log("startSend: ", startSend);

  uploadFiles.value = [];
  for (const item of files.value) {
    const base64 = await base64File(item.rawFile);
    uploadFiles.value.push({
      fileName: item.name,
      file: base64,
      vectorStoreId: item.isChecked
        ? [
            ...new Set(
              multiselectedTags.value
                .map((tag) => tag.storeId)
                .concat(item.vectorStore)
            ),
          ]
        : item.vectorStore,
    });
  }
  const isSuccess = await knowledgeService.UploadFiles(uploadFiles.value);
  console.log("Files:", files.value);
  console.log("Tags:", tags.value);
  if (isSuccess) {
    console.log("File upload success!");
    emit("fileUploaded", isSuccess);
  } else {
    console.log("File upload failed!");
  }
  closeModal();
};
// Simulate upload for each file
const startUpload = async () => {
  files.value.forEach((file, index) => {
    if (file.status === "ready" || file.status === "failed") {
      file.status = "uploading";
      file.progress = 0;
      simulateUpload(index);
    }
  });
};
const base64File = async (file: File) => {
  const base64String = await convertFileToBase64(file);
  return base64String; // This will return a base64 string for the single file
};

const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const blob: Blob = file as Blob;
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob); // FileReader works fine with File
  });
};
// Simulate upload progress and complete/fail state
// const simulateUpload = (index: number) => {
//   const interval = setInterval(() => {
//     const file = files.value[index];
//     if (file.progress >= 100) {
//       clearInterval(interval);
//       file.status = Math.random() < 0.2 ? "failed" : "completed"; // 20% chance to fail
//     } else {
//       file.progress += 10;
//     }
//   }, 200);
// };

const simulateUpload = (index: number) => {
  const interval = setInterval(() => {
    const file = files.value[index];
    if (file.progress >= 100) {
      clearInterval(interval);
      files.value[index] = {
        ...file,
        status: Math.random() < 0.2 ? "failed" : "completed",
      };
      checkIfUploadComplete();
    } else {
      files.value[index] = { ...file, progress: file.progress + 10 };
      // debugger;
    }
  }, 100);
};

// Check if all files have a selected vector store
const canSave = computed(() =>
  files.value.every((file) => file.vectorStore != null || file.isChecked)
);

// Check if all files are done uploading
const isUploadingComplete = ref(false);

// Retry upload for failed files
const retryUpload = (index: number) => {
  files.value[index].status = "uploading";
  files.value[index].progress = 0;
  simulateUpload(index);
};
// Check if all files have completed uploading
const checkIfUploadComplete = () => {
  isUploadingComplete.value = files.value.every(
    (file) => file.status === "completed"
  );
};

// Close modal (for demonstration purposes)
const closeModal = () => {
  emit("cancel");
  // Add your close modal logic here
  // alert("Close modal triggered");
};

onMounted(() => {
  getVectorStores();
});
</script>
