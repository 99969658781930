<template>
    <svg
      width="16"
      height="16"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M2.49988 2.49482C2.49988 2.00924 2.49988 1.76644 2.60112 1.63261C2.68933 1.51601 2.82414 1.44386 2.97008 1.43515C3.1376 1.42515 3.33961 1.55982 3.74364 1.82918L9.00142 5.33436C9.33526 5.55693 9.50218 5.66821 9.56035 5.80847C9.6112 5.9311 9.6112 6.06891 9.56035 6.19154C9.50218 6.3318 9.33526 6.44308 9.00142 6.66564L3.74364 10.1708C3.33961 10.4402 3.1376 10.5749 2.97008 10.5649C2.82414 10.5561 2.68933 10.484 2.60112 10.3674C2.49988 10.2336 2.49988 9.99077 2.49988 9.50519V2.49482Z" stroke="#CBD6E3" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
</template>