import DeleteConfirmationModal from "@/components/common/DeleteConfirmationModal.vue";
import { createApp, h } from "vue";

export function openDeleteConfirmModal(
  title: string,
  description: string
): Promise<boolean> {
  return new Promise((resolve) => {
    // Step 1: Create a container element
    const container = document.createElement("div");
    document.body.appendChild(container);

    // Step 2: Create the app instance
    const app = createApp({
      render() {
        return h(DeleteConfirmationModal, {
          title,
          description,
          onClose: () => {
            resolve(false); // Resolve the promise with false
            cleanup();
          },
          onConfirmDeletion: () => {
            resolve(true); // Resolve as true when confirmed
            cleanup();
          },
        });
      },
    });

    const cleanup = () => {
      app.unmount();
      document.body.removeChild(container);
    };

    // Step 3: Mount the app
    app.mount(container);
  });
}
