<template>
    <section class="BlurModal__Backdrop bg-[#1D2E47] bg-opacity-90"
             role="dialog"
             aria-labelledby="modal-title"
             aria-describedby="modal-description">
        <article class="flex overflow-hidden bg-slate-900  p-8 border-opacity-10 rounded-[40px] flex-col self-stretch my-auto min-w-[240px] w-[307px]">
            <header class="flex flex-col w-full">
                <img loading="lazy"
                     src="https://cdn.builder.io/api/v1/image/assets/TEMP/52f32fdfb905d7f9f8f2290e851bbf329058dd454dfaa1760b9d3db3b5fde472?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51"
                     class="object-contain w-8 rounded-3xl aspect-square"
                     alt="" />
                <div class="flex flex-col mt-5 w-full">
                    <h2 id="modal-title"
                        class="gap-5 w-full text-xl font-bold text-white">
                        Delete User
                    </h2>
                    <p id="modal-description"
                       class="flex-1 shrink gap-5 mt-2 w-full text-xs font-medium text-slate-300">
                        Are you sure you want to delete the user '{{ userName }}'? This
                        action cannot be undone.
                    </p>
                </div>
            </header>
            <footer class="flex gap-5 items-center mt-5 w-full text-lg font-medium text-center whitespace-nowrap">
                <div class="flex flex-1 shrink gap-2.5 items-center self-stretch my-auto w-full basis-0 min-w-[240px]">
                    <button @click="onCancel"
                            class="overflow-hidden flex-1 shrink self-stretch py-0.5 my-auto text-blue-400 rounded-md border border-blue-400 border-solid min-h-[40px]">
                        Cancel
                    </button>
                    <button @click="onDelete"
                            class="overflow-hidden flex-1 shrink self-stretch p-0.5 my-auto text-white bg-red-500 rounded-md min-h-[40px]">
                        Delete
                    </button>
                </div>
            </footer>
        </article>
    </section>

</template>
<script setup lang="ts">
    import ModalOverlay from "../../common/ModalOverlay.vue";

    import { ref, defineProps, defineEmits } from 'vue';

    const props = defineProps({
        userName: {
            type: String,
            default: '',
            required: true
        }
    });

    const emits = defineEmits(['onCancel', 'onDelete']);

    const onCancel = () => {
        emits('onCancel');
    }

    const onDelete = () => {
        emits('onDelete');
    }
</script>
<style scoped lang="scss">
    .BlurModal__Backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        /*background: #1D2E47;*/
        //backdrop-filter: blur(4px); // Adjust the blur strength as desired
        z-index: 1000;
        overflow: auto;
    }
</style>