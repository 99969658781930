<template>
  <div
    class="flex w-full gap-6 border-b-2 border-[#FFFFFF17] pr-2.5 font-inter text-sm font-semibold nav-bar"
  >
    <button
      class="unset-all MenuContainer__Title hover:cursor-pointer !flex !flex-col !pt-2"
      v-for="(tab, index) in tabList"
      :key="index"
      @click="handleTabSwitch(tab)"
    >
      {{ tab.title }}
      <span :class="{ 'active-tab': activeTab === tab.id }"></span>
    </button>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import type { ITabList, IMenuListItem } from "@/Models";

// props and emits
const props = withDefaults(defineProps<ITabList>(), {
  currentTab: 0,
});
const emits = defineEmits(["tabSwitch"]);

// variables
const activeTab = ref<number>(props.currentTab);

// click event
const handleTabSwitch = (tab: IMenuListItem) => {
  if (activeTab.value === tab.id) return;
  activeTab.value = tab.id;
  emits("tabSwitch", tab.id);
};
</script>

<style lang="scss" scoped>
.active-tab {
  @apply w-full bg-white h-0.5 mt-2 rounded-t-md;
}
</style>
