<template>
    <section v-if="isModalVisible" class="absolute bottom-2 z-1000 flex flex-row items-center justify-center w-[100%] pt-4" role="status" aria-live="polite">
        <span class="text-red-400">{{props.message}}</span>
        <button class="flex overflow-hidden top-2 right-2 z-0 justify-center items-center p-2 w-9 h-9 rounded-lg min-h-[36px]"
                aria-label="Close notification"
                @click="closeNotification">
            <img loading="lazy"
                 src="https://cdn.builder.io/api/v1/image/assets/TEMP/f7d4a6c11c0013d58de7ad3259d9816ca8a3bf508b8502b5560118eeb189691f?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51"
                 class="object-contain self-stretch my-auto w-5 aspect-square"
                 alt="" />
        </button>
    </section>
</template>

<script setup lang="ts">
    import { ref, onMounted, defineProps, defineEmits} from 'vue'

    const isModalVisible = ref(false);

    const closeNotification = () => {
        isModalVisible.value = false;
        emits('onClose');
    }

    const emits = defineEmits(['onClose']);
    const props = defineProps({
        message: {
            type: String,
            required: true,
            default: ''
        },
        isVisible: {
            type: Boolean,
            default: false,
            required: true
        }
    })

    onMounted(() => {
        isModalVisible.value = props.isVisible;
        setTimeout(() => {
            closeNotification();
        }, 3000);
    })
</script>