<template>
    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.0//EN" "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
    <div class="icon-avatar">
        <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="37" height="37" rx="18.5" fill="#7BA8EF" />
            <text x="50%"
                  y="50%"
                  dominant-baseline="middle"
                  text-anchor="middle"
                  font-size="18"
                  font-weight="700"
                  fill="white"
                  font-family="Inter">
                {{title}}
            </text>
        </svg>
        <!--<span class="icon-title">{{title}}</span>-->
    </div>
</template>

<script>
    export default {
        props: {
            width: {
                type: String,
                required: false
            },
            height: {
                type: String,
                required: false
            },
            title: {
                type: String,
                required: false,
                default: ''
            }
        }
    };
</script>

<style scoped  lang="scss">
    .icon-avatar {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: 5px;

        &:hover {
            cursor: pointer;
        }
    }

    .icon-title {
        font-size: 15px;
    }
</style>