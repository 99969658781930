<template>
    <div class="icon-figma">
        <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_11450_13201)">
                <rect x="2.66797" y="1" width="52" height="52" rx="8" fill="white" shape-rendering="crispEdges" />
                <rect x="3.16797" y="1.5" width="51" height="51" rx="7.5" stroke="#EAECF0" shape-rendering="crispEdges" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M28.668 27C28.668 23.2721 31.69 20.25 35.418 20.25C39.1459 20.25 42.168 23.2721 42.168 27C42.168 30.7279 39.1459 33.75 35.418 33.75C31.69 33.75 28.668 30.7279 28.668 27Z" fill="#1ABCFE" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.168 40.5C15.168 36.7721 18.19 33.75 21.918 33.75H28.668V40.5C28.668 44.2279 25.6459 47.25 21.918 47.25C18.19 47.25 15.168 44.2279 15.168 40.5Z" fill="#0ACF83" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M28.668 6.75V20.25H35.418C39.1459 20.25 42.168 17.2279 42.168 13.5C42.168 9.77208 39.1459 6.75 35.418 6.75H28.668Z" fill="#FF7262" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.168 13.5C15.168 17.2279 18.19 20.25 21.918 20.25H28.668V6.75H21.918C18.19 6.75 15.168 9.77208 15.168 13.5Z" fill="#F24E1E" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.168 27C15.168 30.7279 18.19 33.75 21.918 33.75H28.668V20.25H21.918C18.19 20.25 15.168 23.2721 15.168 27Z" fill="#A259FF" />
            </g>
            <defs>
                <filter id="filter0_d_11450_13201" x="0.667969" y="0" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11450_13201" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_11450_13201" result="shape" />
                </filter>
            </defs>
        </svg>
    </div>
</template>

<script setup lang="ts">
</script>

<!-- <style>
    /* .icon-figma {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        color: rgb(177, 168, 168);
    } */
</style> -->