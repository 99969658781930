<template>
  <section class="disconnect-alert">
    <div class="alert-container">
      <header class="alert-header">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/a09460fa4dd441a564783daaecb1c4511ff1278d69494cff747c2c2865ee009c?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51"
          alt=""
          class="alert-icon"
        />
        <div class="alert-content">
          <h2 class="alert-title">Disconnect connecter</h2>
          <p class="alert-description">
            Are you sure you want to Disconnect this connecter?
          </p>
        </div>
      </header>
      <footer class="alert-actions flex justify-between w-full mt-3">
        <button class="btn btn-outline px-6" @click="closeView">Cancel</button>
        <button class="btn btn-danger px-6" @click="disconnect">
          <span v-if="!isDisconnecting">Disconnect</span>
          <Loader v-else :isLoading="isDisconnecting" />
        </button>
      </footer>
    </div>
  </section>
</template>

<script setup lang="ts">
import { HubService } from "@/Services/HubService";
import { ref } from "vue";
import Loader from "@/components/Loader.vue";

const emits = defineEmits(["closeView"]);
const hubService = new HubService();
const props = defineProps({
  selectedAssistantId: {
    default: 0,
    required: true,
    type: Number,
  },
  selectedProviderId: {
    default: 0,
    required: true,
    type: Number,
  },
});

const isDisconnecting = ref<boolean>(false);
const disconnectionStatus = ref<boolean>(false);
const message = ref<string>("");

const closeView = () => {
  emits("closeView", message.value, disconnectionStatus.value);
};
const disconnect = async () => {
  try {
    isDisconnecting.value = true;
    disconnectionStatus.value = await hubService.DisconnectSource(
      props.selectedProviderId,
      props.selectedAssistantId
    );
    if (disconnectionStatus.value) {
      message.value = `Your knowledge has been disconnected successfully`;
    }
  } catch {
    console.error("disconnecting the provider has been failed");
    message.value = `Something went wrong. Please try again.`;
  }
  setTimeout(async () => {
    isDisconnecting.value = false;
    closeView();
  }, 3000);
};
</script>

<style scoped>
.disconnect-alert {
  display: flex;
  align-items: center;
  font-family: Inter, sans-serif;
  justify-content: flex-start;
}

.alert-container {
  border-radius: 40px;
  background-color: #031525;
  align-self: stretch;
  display: flex;
  width: 371px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: auto 0;
  padding: 32px;
  border: 1px solid rgba(255, 255, 255, 0.09);
}

.alert-header {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

.alert-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 32px;
  border-radius: 24px;
}

.alert-content {
  display: flex;
  margin-top: 20px;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

.alert-title {
  width: 100%;
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  margin: 0;
}

.alert-description {
  flex: 1;
  margin-top: 8px;
  width: 100%;
  font-size: 12px;
  color: #cbd6e3;
  font-weight: 500;
}

/* .alert-actions {
        display: flex;
        margin-top: 20px;
        width: 100%;
        align-items: center;
        gap: 10px;
        font-size: 17px;
        font-weight: 500;
        white-space: nowrap;
        text-align: center;
        justify-content: flex-end;
    } */

/* .btn {
        border-radius: 6px;
        min-height: 40px;
        overflow: hidden;
        flex: 1;
        padding: 10px;
        cursor: pointer;
        border: none;
        font-size: 17px;
        font-weight: 500;
    } */

/* .btn-secondary {
        color: #7ba8ef;
        background-color: transparent;
        border: 1px solid #7ba8ef;
    }

    .btn-danger {
        background-color: #f14d4d;
        color: #fff;
    } */
</style>
