<template>
    <div class="icon-zapier">
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_11450_14511)">
                <rect x="2" y="1" width="52" height="52" rx="8" fill="white" shape-rendering="crispEdges" />
                <rect x="2.5" y="1.5" width="51" height="51" rx="7.5" stroke="#EAECF0" shape-rendering="crispEdges" />
                <path d="M32.4327 31.4428C29.5832 32.5151 26.3902 32.517 23.5406 31.4435C22.467 28.5971 22.4668 25.4047 23.539 22.5579C26.3878 21.4838 29.584 21.4836 32.4328 22.5577C33.506 25.4037 33.5056 28.5968 32.4327 31.4428ZM48.708 23.5001H36.442L45.1149 14.8322C43.7538 12.9214 42.0734 11.2429 40.1614 9.88256L31.4879 18.5505V6.29238C29.173 5.90322 26.799 5.90381 24.4841 6.29238V18.5505L15.8106 9.88256C13.8991 11.242 12.2182 12.9228 10.8569 14.8322L19.5311 23.5001H7.26525C6.96247 25.8828 6.8641 28.1221 7.26525 30.5H19.5313L10.8571 39.1679C12.2216 41.0815 13.8957 42.7545 15.8106 44.1181L24.4841 35.4496V47.7083C26.7992 48.096 29.1726 48.0962 31.4877 47.7083V35.4496L40.1621 44.1181C42.0749 42.7565 43.7523 41.0794 45.1149 39.1679L36.4407 30.5H48.708C49.0973 28.1886 49.0973 25.8115 48.708 23.5001Z" fill="#FF4A00" />
            </g>
            <defs>
                <filter id="filter0_d_11450_14511" x="0" y="0" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11450_14511" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_11450_14511" result="shape" />
                </filter>
            </defs>
        </svg>
    </div>
</template>

<script setup lang="ts">
</script>

<style>
    .icon-zapier {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        color: rgb(177, 168, 168);
    }
</style>