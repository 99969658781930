<script setup lang="ts">
import SideMenu from "./SideMenu.vue";
import FreddyHub from "./freddycomponents/FreddyHub.vue";
import ScrewHub from "./screwcomponents/ScrewHub.vue";
import StreamLineHub from "./streamlinecomponents/StreamLineHub.vue";
import ScrewManagement from "./screwmanagementcomponents/ScrewManagementHub.vue";
import AppHive from "./apphivecomponents/AppHiveHub.vue";
import UsageLimitUpdate from "@/components/freddycomponents/usage/UsageLimitUpdate.vue";
import IconAvatar from "./icons/IconAvatar.vue";
import IconChevronLeft from "./icons/IconChevronLeft.vue";
import IconChevronRight from "./icons/IconChevronRight.vue";
import type { IComponentMap } from "../Models/IComponentMap";
import AppConfigGlobal from "../core/config/uiSettings";
import { HubService } from "@/Services/HubService";
import { ref, markRaw, onMounted, computed } from "vue";

const isSideMenuClosed = ref<boolean>(false);
const selectedSideMenuOptionIndex = ref<number>(0);
const isIncreaseLimitModalOpen = ref<boolean>(false);
const hubService = new HubService();
const sideMenuComponentList = ref<IComponentMap[]>([
  {
    title: "Freddy",
    component: markRaw(FreddyHub),
    show: true,
    mainComponent: {},
  },
  {
    title: "Streamline",
    component: markRaw(StreamLineHub),
    show: true,
    mainComponent: {},
  },
  {
    title: "Crew",
    component: markRaw(ScrewHub),
    show: true,
    mainComponent: {},
  },
  {
    title: "Crew Management",
    component: markRaw(ScrewManagement),
    show: true,
    mainComponent: {},
  },
  {
    title: "App Hive",
    component: markRaw(AppHive),
    show: false,
    mainComponent: {},
  },
]);

const toggleSideMenu = () => {
  isSideMenuClosed.value = !isSideMenuClosed.value;
};

const sideMenuOptionChanged = (index: number) => {
  selectedSideMenuOptionIndex.value = index;
};

const userShortName = computed(() => {
  const nameArray =
    AppConfigGlobal.UserName !== "" ? AppConfigGlobal.UserName.split(" ") : [];
  return nameArray.length > 0 ? `${nameArray[0][0]}${nameArray[1][0]}` : "";
});

onMounted(async () => {
  const userConfiguration = await hubService.GetAdminUserConfiguration();
  // Fetch user configuration
  const cookieCurrentOrgId = hubService.GetCookieValue("CurrentOrgId")
  console.log("cookieCurrentOrgId", cookieCurrentOrgId)
  /// Check if cookieCurrentOrgId is valid and greater than 0
  if (AppConfigGlobal.OrganizationId && AppConfigGlobal.OrganizationId > 0) {
    AppConfigGlobal.CurrentOrganizationId = AppConfigGlobal.OrganizationId;
    console.log("AppConfigGlobal.OrganizationId", AppConfigGlobal.CurrentOrganizationId);
  } 
  // Check if cookieCurrentOrgId is valid and greater than 0
  else if (cookieCurrentOrgId && parseInt(cookieCurrentOrgId) > 0) {
    AppConfigGlobal.CurrentOrganizationId = parseInt(cookieCurrentOrgId);
    console.log("Cookie CurrentOrgId", AppConfigGlobal.CurrentOrganizationId);
  } 
  // Fallback to userConfiguration.organizationId
  else {
    AppConfigGlobal.CurrentOrganizationId = userConfiguration.organizationId;
    console.log("UserConfiguration OrganizationId", AppConfigGlobal.CurrentOrganizationId);
  }
  AppConfigGlobal.UserName = userConfiguration.name;
  AppConfigGlobal.UserImage = userConfiguration.image;
});
</script>

<template>
  <div class="md:flex md:h-full md:w-full md:overflow-hidden md:min-w-full md:fixed">
    <div class="usageLimit" v-if="isIncreaseLimitModalOpen">
      <UsageLimitUpdate />
    </div>
    <div class="box left w-[17.188rem] bg-[--color-background-sidebar] p-3 hidden md:block"
      :class="{ collapsed: isSideMenuClosed }">
      <SideMenu :isSideMenuClosed="isSideMenuClosed" @onSideMenuOptionChange="sideMenuOptionChanged" />
      <div class="box left collapse">
        <component :is="isSideMenuClosed ? IconChevronRight : IconChevronLeft" :width="24" :height="24"
          @click="toggleSideMenu()"></component>
      </div>
    </div>
    <div
      class="box right flex-1 px-5 pt-[2.313rem] flex-col flex md:w-[calc(100%-17.188rem)]"
      :class="{ 'full-size': isSideMenuClosed }"
    >
      <div class="box right header flex justify-between mb-3">
        <div class="box right header select">
          <span class="heading6 text-xl font-bold">{{ sideMenuComponentList.filter(x =>
            x.show)[selectedSideMenuOptionIndex].title}}</span>
        </div>
        <div class="box right header actions">
          <!--<IconSun />-->
          <!--<IconDocumentation />-->
          <!--<component :is="isNotificationPresent ? IconNewNotification : IconNotification"></component>-->
          <IconAvatar :title="userShortName" />
        </div>
      </div>
      <!--Load this component only after successfully binding the user configuration data.-->
      <div class="box right body flex-grow relative" v-if="AppConfigGlobal.UserName !== ''">
        <!-- <KeepAlive> -->
        <component :is="sideMenuComponentList[selectedSideMenuOptionIndex].component"></component>
        <!-- </KeepAlive> -->
      </div>
    </div>
  </div>
</template>
<!--
<style scoped lang="scss">
    @import '@/assets/mainstyle';

    $light-border: rgba(255, 255, 255, 0.09);

    $smwidth: 275px;
    $collapsedsmwidth: 40px;
    $collapseIconHeight: 40px;

    .container {
        position: fixed;
        display: flex;
        width: 100vw;
        height: 100vh;
    }

    .box {
        color: white;
        height: 100%;
        width: 100%;
        /*padding: 10px;*/

        &.left {
            position: absolute;
            background-color: $bgleft;
            width: $smwidth;
            overflow: hidden;
            display: flex;
            z-index: 10;
            flex-direction: column;
            align-items: center;
            padding: 5px 12px;

            @media (max-width: 650px) {
                width: $collapsedsmwidth;
            }

            &.collapse {
                flex-direction: row-reverse;
                width: 100%;
                height: $collapseIconHeight;
                align-content: end;
                bottom: 0;
                border-top: 1px solid $light-border;
            }

            &.collapsed {
                width: $collapsedsmwidth;
            }
        }

        &.right {
            position: relative;
            background-color: $bgright;
            left: $smwidth;
            width: calc(100% - $smwidth);
            display: flex;
            flex-direction: column;
            gap: 10px;
            z-index: 6;
            padding: 14px 0px;

            &.header {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                height: 37px;
                left: 0;
                padding: 0px 20px;

                &.select {
                    width: 69px;
                    font-size: 14px;
                    color: rgb(232 226 224);
                    background-color: transparent;
                    border: none;
                    outline: none;
                    padding: 0;

                    :hover {
                        cursor: pointer;
                        color: white;
                    }
                }

                &.actions {
                    padding: 0;
                    width: 200px;
                    justify-content: flex-end;
                }
            }

            &.body {
                width: 100%;
                height: calc(100% - 47px);
                left: 0;
                padding: 0;
            }

            &.footer {
                position: fixed;
                height: 0;
                width: 100%;
                left: 0;
                bottom: 0;
            }

            &.full-size {
                left: $collapsedsmwidth;
                width: calc(100% - $collapsedsmwidth);
            }

            @media (max-width: 650px) {
                left: $collapsedsmwidth;
                width: calc(100% - $collapsedsmwidth);
            }
        }
    }

    @media (max-width: 768px) {
        .left {
            width: 208px !important;
        }

        .box.right {
            left: 208px;
            width: calc(100% - 84px);
        }
    }

    @media (max-width: 768px) {
        .box.right {
            left: 0px;
            width: calc(100% - 0px);
        }

        .box.left {
            display: none;
        }
    }
</style> -->
