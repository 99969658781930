<template>
<svg
  width="16"
  height="16"
  viewBox="0 0 12 12"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
<g clip-path="url(#clip0_26568_6192)">
<path d="M4.74988 7.5V4.5M7.24988 7.5V4.5M10.9999 6C10.9999 8.76142 8.7613 11 5.99988 11C3.23845 11 0.999878 8.76142 0.999878 6C0.999878 3.23858 3.23845 1 5.99988 1C8.7613 1 10.9999 3.23858 10.9999 6Z" stroke="#CBD6E3" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_26568_6192">
<rect width="12" height="12" fill="white"/>
</clipPath>
</defs>
</svg>
</template>


