<template>
  <div class="flex gap-8 w-[40%] flex-col overflow-auto pb-10 scroll-width">
    <div class="VectorDetailedView__Header">
      <div class="flex gap-3 mb-[30px]">
        <button
          class="border border-solid border-red-500 rounded-md p-2 text-red-600 h-3" @click="openDeleteModal"
        >
          <IconDelete
            class="w-3 h- text-[#E71D41] text-opacity-66 align-middle"
          />
        </button>
        <button
          class="border border-solid border-[#7ba8ef] rounded-md p-2 text-[#7ba8ef] text-[14px] font-normal leading-4 px-2.5 py-1 items-center gap-[10px] flex"
          @click="handleCreateNewVectorStore"
        >
          <IconAdd class="w-4 h-4 text-[#ffffff66] align-middle self-center" />
          Create new vector store
        </button>
      </div>

      <span class="font-sans font-semibold text-sm text-[#ffffff66]"
        >Vector Store</span
      >
      <div id="storeName" class="flex gap-3">
        <IconPencil
          class="text-[#ffffff66] align-middle self-center h-3 w-3 cursor-pointer"
          @click="isEditMode = !isEditMode"
        />
        <span class="text-2xl font-bold text-white" v-if="!isEditMode">
          {{ vectorNameInput }}
        </span>
        <input
          v-if="isEditMode"
          v-model="vectorNameInput"
          type="text"
          class="unset-all text-2xl font-bold border-b border-[#7ba8ef] pb-1"
          @keydown.enter.prevent="renameStore"
          @blur="renameStore"
        />
      </div>
    </div>

    <div class="flex flex-col gap-[30px]">
      <!-- For rename and header -->
      <div class="">
        <div
          class="grid grid-cols-[auto_1.5fr_5fr] gap-y-4 gap-x-4 items-center text-xs"
        >
          <!-- File ID -->
          <div>
            <IconInfoRounded
              class="w-4 h-4 text-[#ffffff66] align-middle self-center"
            />
          </div>
          <div class="text-[#FFFFFF66]">ID</div>
          <div class="">{{ chosenVector.vectorStoreId }}</div>

          <!-- Status -->
          <!-- <div>
            <IconStatistics
              class="w-4 h-4 text-[#ffffff66] align-middle self-center"
            />
          </div> -->
          <!-- <div class="text-[#FFFFFF66]">Usage</div>
          <div v-if="chosenVector.usage">
            {{ chosenVector.usage }} Neurons / This Month
          </div> -->

          <!-- Size -->
          <div v-if="chosenVector.size">
            <IconSize
              class="w-4 h-4 text-[#ffffff66] align-middle self-center"
            />
          </div>
          <div v-if="chosenVector.size" class="text-[#FFFFFF66]">Size</div>
          <div v-if="chosenVector.size">
            {{ chosenVector.size }}
          </div>

          <!-- Last active -->
          <div v-if="chosenVector.lastActive">
            <IconGlobExpand
              class="w-4 h-4 text-[#ffffff66] align-middle self-center"
            />
          </div>
          <div v-if="chosenVector.lastActive" class="text-[#FFFFFF66]">Last active</div>
          <div v-if="chosenVector.lastActive">
            {{ readableDateFormat(chosenVector.lastActive) }}
          </div>

          <!-- {{ chosenItem }} -->
          <!-- Created at -->
          <div v-if="chosenVector.createdOn">
            <IconClock
              class="w-4 h-4 text-[#ffffff66] align-middle self-center"
            />
          </div>
          <div v-if="chosenVector.createdOn" class="text-[#FFFFFF66]">Created at</div>
          <div v-if="chosenVector.createdOn">
            {{ readableDateFormat(chosenVector.createdOn) }}
          </div>
        </div>
      </div>

      <!-- Files List -->
      <div class="flex flex-col gap-1.5">
        <h2 class="font-bold text-[20px] font-inter">Files Attached</h2>
        <table class="w-full text-left FileListTable">
          <tr class="">
            <th>File</th>
            <th>Uploaded</th>
            <th>Status</th>
            <th></th>
          </tr>
          <tr
            class=""
            v-for="(file, index) in showFiles"
            :key="index"
          >
            <td>
              <span class="flex">
                <IconFile class="w-3 h-4" /> {{ file.name }} 
              </span>
            </td>
            <td class="text-[#ffffff66]">
              {{ formatDateToLocaleString(file.createdOn) }}
            </td>
            <td>
              <span
                class="place-content-center"
                :class="`StatusLabel--${file.status}`"
              >
                {{ file.status }}
              </span>
            </td>
            <td>
              <button @click="removeFile(file)">
                <IconDelete
                  class="w-4 h-4 text-[#ffff] align-middle self-center"
                />
              </button>
            </td>
          </tr>
        </table>
        <!-- Show more button -->
        <div
          v-if="filesAttached.length > 6"
          class="flex justify-center"
        >
          <button class="text-white flex items-center gap-2" @click="handleShowMore">
            <span
              ><IconChevronDown class="w-5 h-5" v-if="!isShowMore" /> <IconChevronUp class="w-4 h-4" v-else
            /></span>
            {{ !isShowMore ? "Show more" : "Show less" }}
          </button>
        </div>
      </div>

      <div class="VectorDetailedView__Info__Assistants flex flex-col gap-1.5">
        <h2 class="font-bold text-[20px] font-inter">Used by</h2>

        <table class="AssistantsTable">
          <tr>
            <th class="text-left w-2/4 font-Inter font-normal text-[12px]">
              Assistant
            </th>
            <th class="font-Inter text-left font-normal text-[12px]">ID</th>
          </tr>
          <tr v-for="(assistant, index) in assistantsList" :key="index">
            <td
              class="text-left w-2/4 font-Inter font-normal text-[12px] min-w-[120px]"
            >
              {{ assistant.assistantName }}
              <!-- <span> <IconFile class="FileIcon" /> {{ file.fileName }} </span> -->
            </td>
            <td class="font-Inter text-left font-normal text-[12px]">
              {{ assistant.assistantId }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <Modal v-if="chosenVector" :isVisible="isModalDelete" @close="closeDeleteModal" :largeModel="false">
        <template v-slot:body>
          <section class="flex items-center justify-start">
            <div
              class="flex flex-col items-center w-[371px] self-stretch mx-auto my-0 p-8 rounded-[40px] bg-[#031525] border-white/10">
              <header class="flex flex-col justify-start w-full">
                <img loading="lazy" src="../../assets/delete-orangebg.svg" alt=""
                  class="w-[32px] aspect-square rounded-full object-contain object-center" />
                <div class="flex flex-col justify-start w-full mt-5">
                  <h2 class="w-full text-[20px] font-bold text-white m-0">
                    Confirm deletion of vector Store
                  </h2>
                  <p class="flex-1 w-full mt-2 text-[12px] font-medium text-[#cbd6e3]">
                    Are you sure you would like to delete the vector Store {{ chosenVector.vectorStoreName }}
                  </p>
                </div>
              </header>
              <footer
                class="flex items-center justify-between w-full mt-5 gap-2.5 text-[17px] font-medium whitespace-nowrap text-center">
                <button
                  class="btn text-white w-6/12 h-10 !border-[#7BA8EF] !text-[#7BA8EF] focus-visible:outline-none items-center justify-center"
                  style="border:1px solid;" @click="closeDeleteModal">
                  Cancel
                </button>
                <button
                  class="btn btn-danger w-6/12 h-10 focus-visible:outline-none !text-white items-center justify-center"
                  @click="removeSelectedVectorStore">
                  <span>Delete</span>
                </button>
              </footer>
            </div>
          </section>
        </template>
      </Modal>
</template>

<script lang="ts" setup>
import { computed,onMounted, ref ,Suspense,watch,onBeforeUnmount} from "vue";
import {
  IconPencil,
  IconDelete,
  IconAdd,
  IconInfoRounded,
  IconSize,
  IconClock,
  IconFile,
  IconChevronDown,
  IconChevronUp,
  IconGlobExpand,
} from "../icons";
import type { IFiles, IVector,IVectorAssistant } from "@/Models";
import { formatDateToLocaleString, readableDateFormat } from "@/helpers";
import { KnowledgeService } from "@/Services/KnowledgeService";
import Modal from '../common/CustomModelBox.vue';
const props = defineProps<{ chosenVector: IVector }>();
const emit = defineEmits(["vectorCreation","vectorName","vectorDeletion"]);
const isRenaming = ref(false);
const isEditMode = ref(false);
const isShowMore = ref(false);
const vectorNameInput = ref(props.chosenVector.vectorStoreName);
const filesAttached = ref<IFiles[]>([]);
const showFiles = ref<IFiles[]>([]);
const assistantsList = ref<IVectorAssistant[]>([]);
const knowledgeService = new KnowledgeService();
const isDeleted = ref<boolean>();
const isModalDelete = ref<boolean>(false);


const getFilesAttached =async (vectorId:number) => {
  filesAttached.value = await knowledgeService.GetFilesAttachedtoVectorStore(vectorId);
  showFiles.value = trimmedFilesAttached.value;
}
const getAssistants =async (vectorId:number) => {
  assistantsList.value = await knowledgeService.GetAssitantsofVectorStore(vectorId);
}
const removeFile = async (file:IFiles) => {
  const isDeleted =await knowledgeService.RemoveFileAttached(file.id,props.chosenVector.vectorStoreId);
  if(isDeleted){
  filesAttached.value.splice(filesAttached.value.findIndex((f)=> f.id === file.id ),1);
  }
}
const removeSelectedVectorStore = async () => {
  isDeleted.value = await knowledgeService.RemoveVectorStore(props.chosenVector.vectorStoreId);
  if (isDeleted.value) {
    isModalDelete.value = false;
    emit("vectorDeletion");
  }
};

const openDeleteModal = () => {
  isModalDelete.value = true;
  console.log("OpenModal", isModalDelete.value)
};

const closeDeleteModal = () => {
  isModalDelete.value = false;
};


const handleShowMore = () => {
  isShowMore.value = !isShowMore.value;
  showFiles.value = trimmedFilesAttached.value;
};
const trimmedFilesAttached = computed(() => { 
  return isShowMore.value ? filesAttached.value : filesAttached.value.slice(0,6);
});
const renameStore =async () => {
  if(isRenaming.value){ return;}
  isRenaming.value = true;
  if(vectorNameInput.value != props.chosenVector.vectorStoreName)
  {
    const success = await knowledgeService.UpdateStore(vectorNameInput.value,props.chosenVector.vectorStoreId);
    if(!success){console.log("Failed to Rename VectorStore");}
    vectorNameInput.value = success ? vectorNameInput.value : props.chosenVector.vectorStoreName ;
    emit("vectorName",vectorNameInput);
  }
  isEditMode.value = false;
  isRenaming.value = false;
};
const handleCreateNewVectorStore = async() => {
  const storeId = await knowledgeService.CreateStore();
  const newVector = await knowledgeService.GetStoreDetailById(storeId);
  emit("vectorCreation", newVector);

};
watch(() => props.chosenVector, () => {
  vectorNameInput.value = props.chosenVector.vectorStoreName;
  getFilesAttached(props.chosenVector.vectorStoreId);
  getAssistants(props.chosenVector.vectorStoreId);
});

onMounted(() => {
    getFilesAttached(props.chosenVector.vectorStoreId);
    getAssistants(props.chosenVector.vectorStoreId);
});
const handleClickOutside = (event : any) => {
  const container = document.getElementById("storeName");
  if (isEditMode.value && container && !container.contains(event.target)) {
    renameStore();
  }
};

// Watch for changes in isEditMode to add/remove event listener
watch(isEditMode, (newVal) => {
  if (newVal) {
    document.addEventListener("click", handleClickOutside);
  } else {
    document.removeEventListener("click", handleClickOutside);
  }
});

// Ensure listener is removed on component unmount
onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});
</script>

<style lang="scss">
table.FileListTable {
  tr {
    @apply shadow-[0_1px_0_rgba(255,255,255,0.094)];
  }

  th,
  td {
    @apply text-xs py-1; /* font-size: 12px; padding: 4px 0; */
  }

  th:first-child,
  td:first-child {
    @apply min-w-[120px]; /* min-width: 120px; */
  }

  th,
  td {
    font-size: 12px;
    padding: 4px 0;

    &:first-child {
      min-width: 120px;
    }

    span {
      display: flex;
      gap: 6px;
    }

    &.CreatedDate {
      color: #ffffff66;
    }

    .StatusInfo {
      place-content: center;
    }

    .ActionButton {
      stroke: #ffffff;
    }
  }
}

table.AssistantsTable {
  width: 100%;
  text-align: left;

  tr {
    box-shadow: 0 1px 0 #ffffff17;
  }

  th,
  td {
    @apply text-xs py-1 w-1/2;
  }
  td:nth-child(2) {
    color: #ffffff66;
  }
}
</style>
