<template>
  <div>
    <div class="flex items-center justify-center flex-col gap-4">
      <div
        class="border border-dashed border-spacing-1 border-[#A7D0F8] flex w-fit px-4 py-3"
      >
        <IconStreamLineHub class="h-[75px] w-[75px]" />
      </div>

      <!-- Booking section -->
      <div v-if="!fillReqForm">
        <p class="text-[17px] font-medium mb-3 text-center">
          Schedule a call to discuss your
          <br />
          automation or send a automation
          <br />
          request by filling a form.
        </p>

        <div class="w-full flex flex-col gap-4 text-center">
          <button class="btn btn-primary w-auto flex justify-center gap-2.5">
            <IconCalendarWithTickInside class="h-4 w-4 self-center" /> Book now
          </button>

          <p class="text-[#94969C]">Or</p>

          <button class="btn text-[#7BA8EF]" @click="handleFillReqForm">
            Fill Request Form
          </button>
        </div>
      </div>

      <!-- Request form -->
      <div v-if="fillReqForm">
        <div class="w-full flex flex-col gap-5">
          <input
            type="text"
            placeholder="Name"
            class="bg-[#071A2B] text-white w-full px-3 py-2 rounded-lg border border-solid border-[#FFFFFF33]"
            v-model="automationServiceName"
          />

          <div
            class="text-center min-w-[400px] rounded-lg border border-solid bg-[#FFFFFF17] border-[#A7D0F8] px-6 py-4 cursor-pointer text-[#cbd6e3] text-sm font-normal"
            @click="triggerFileInput"
          >
            <IconCloudUpload
              class="border border-white p-2.5 rounded-lg mb-3 w-10 h-10 mx-auto"
            />

            <div v-if="!files.length">
              <input
                ref="fileInput"
                type="file"
                @change="handleFileSelect"
                accept=".zip, .py, .java"
                style="display: none"
              />
              <label class="block"
                ><span class="text-[#a7d0f8] font-semibold cursor-pointer"
                  >Click to upload</span
                >
                or drag and drop</label
              >
              <span class="text-gray-500 text-xs"
                >Zip, Py, Java (max. 10 MB)</span
              >
            </div>

            <div v-else>
              <div
                v-if="files.length"
                class="max-h-[300px] overflow-y-auto flex flex-col gap-2.5 mt-3"
              >
                <div
                  v-for="(file, index) in files"
                  :key="index"
                  class="p-4 items-start rounded-[16px] border border-[#333741] pt-6"
                  :class="{
                    'border border-solid border-[#e53e3e]':
                      file.status === 'failed',
                  }"
                >
                  <div class="flex justify-between">
                    <div class="flex gap-[10px]">
                      <IconFile
                        class="w-5 h-5 self-start stroke-white"
                        :class="{
                          'text-[#e53e3e]': file.status === 'failed',
                        }"
                      />
                      <div class="flex flex-col leading-[20px]">
                        <span class="font-medium">{{ file.name }}</span>
                        <span
                          v-if="file.status !== 'failed'"
                          class="text-[0.9rem] text-gray-400 text-left"
                          >{{ formatSize(file.size) }}</span
                        >
                      </div>
                    </div>

                    <div class="text-xl ml-2.5">
                      <CustomCheckbox
                        :isChecked="true"
                        class="border border-solid border-white"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Send button -->
          <button
            class="btn btn-primary"
            @click="sendAutomationRequest"
            :class="{ 'btn-disabled': !canUpload() }"
          >
            Send
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import {
  IconStreamLineHub,
  IconCalendarWithTickInside,
  IconCloudUpload,
  IconFile,
} from "@/components/icons";

import type { IStreamlineUploadFilesRequest } from "@/Models/IStreamlineUploadFilesRequest ";
import AppConfigGlobal from "@/core/config/uiSettings";
import CustomCheckbox from "../common/CustomCheckbox.vue";
import { streamlineService } from "@/Services/StreamlineService";

const fillReqForm = ref(false);
const files = ref<{ name: string; size: number; status: string }[]>([]);
const fileInput = ref<HTMLInputElement | null>(null);
const fileErrors = ref<{ success?: string; error?: string }[]>([]);
const fileToUpload = ref<File>();
const automationServiceName = ref<string>();
const isNameValid = ref<boolean>(true);
const nameError = ref<string>();
const isUploading = ref(false);
const service = new streamlineService();
// Trigger file input click
const triggerFileInput = () => {
  fileInput.value?.click();
};
const handleFillReqForm = () => {
  fillReqForm.value = true;
};

// Handle file selection
const handleFileSelect = (event: Event) => {
  const selectedFiles = (event.target as HTMLInputElement).files;
  if (selectedFiles) {
    for (const file of selectedFiles) {
      fileToUpload.value = file;
      files.value.push({
        name: file.name,
        size: file.size,
        status: "ready",
      });
    }
  }
};
const canUpload = () => {
  if (automationServiceName.value) {
    if (automationServiceName?.value?.trim().length <= 0) {
      isNameValid.value = false;
      console.log(isNameValid);
      nameError.value = "Name is Required";
    } else {
      isNameValid.value = true;
      console.log(isNameValid);
    }
  }

  return isNameValid.value && isFileValid();
};
const convertToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve((reader.result as string).split(",")[1]); // Get base64 part
    reader.onerror = (error) => reject(error);
  });
};
const sendAutomationRequest = async () => {
  if (!canUpload()) return;
  isUploading.value = true;
  if (fileToUpload.value) {
    const base64File = await convertToBase64(fileToUpload.value);
    const requestData: IStreamlineUploadFilesRequest = {
      zipFile: base64File,
      name: automationServiceName.value?.trim() || "",
      organizationId: AppConfigGlobal.CurrentOrganizationId,
      fileSize: fileToUpload.value.size,
      originalFileName: fileToUpload.value.name,
    };
    var response = await service
      .requestAutomation(requestData)
      .catch((error) => {
        console.log(error);
        if (error?.status == 409) {
          nameError.value = error?.response?.data;
        } else {
          fileErrors.value.push({ error: error?.response?.data });
        }
      });
  }
};
const formatSize = (size: number) => {
  if (size < 1024) {
    return size + " B";
  } else if (size < 1024 * 1024) {
    return (size / 1024).toFixed(2) + " KB";
  } else {
    return (size / 1024 / 1024).toFixed(2) + " MB";
  }
};
const isFileValid = () => {
  if (!fileToUpload.value) return false;
  if (fileToUpload.value.size > 10 * 1024 * 1024) {
    // 10 MB size limit
    fileErrors.value.push({ error: "File exceeds 10 MB size limit" });
    return false;
  }
  if (
    !fileToUpload.value.name.endsWith(".py") &&
    !fileToUpload.value.name.endsWith(".zip") &&
    !fileToUpload.value.name.endsWith(".java")
  ) {
    fileErrors.value.push({
      error: "Invalid file type. Accepted: .zip, .py, .java",
    });
    return false;
  }
  return true;
};
</script>
