<template>
    <nav v-if="isVisible" class="fixed inset-0 z-50 flex overflow-hidden flex-col text-lg font-medium text-red-500 rounded-xl bg-slate-900 h-fit w-[250px] max-h[300px] max-w-[400px] shadow-box-shadow-white" role="menu" aria-label="User actions menu"
         :style="{ top: `${top}px`, left: `${left - 250}px` }">
        <section v-if="showEditButton" class="flex flex-col w-full text-white">
            <button class="flex gap-3 items-center px-4 w-full min-h-[44px] hover:bg-slate-800" role="menuitem" tabindex="0" @click="handleEdit">
                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/04c0744345348814b43fab90286ba1900b27db50514d495a3575c841c2e9e805?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51" class="object-contain shrink-0 my-auto w-5 aspect-square" alt="" />
                <span class="flex-1 shrink basis-0 text-ellipsis">Edit</span>
            </button>
        </section>

        <button v-if="showAcceptRequestButton" class="flex gap-3 items-center px-4 w-full text-green-400 whitespace-nowrap min-h-[44px] hover:bg-slate-800" role="menuitem" tabindex="0" @click="handleAcceptRequest">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/b7cf78a654a4168416949e59c60e1c934e748a119ef242eb11f6165da850a431?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51" class="object-contain shrink-0 my-auto w-5 aspect-square" alt="" />
            <span class="flex-1 shrink basis-0 text-ellipsis text-[#86efac] border-[#86efac]">Accept request</span>
        </button>

        <button v-if="showResendInvitationButton" class="flex gap-3 items-center px-4 w-full text-white min-h-[44px] hover:bg-slate-800" role="menuitem" tabindex="0" @click="handleResendInvitation">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/10142c521de1093eca50a68ac48d01d89e7b887e25d57225889e69424c8647e1?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51" alt="" class="object-contain shrink-0 my-auto w-5 aspect-square" />
            <span class="flex-1 shrink basis-0 text-ellipsis">Resend Invitation</span>
        </button>

        <button v-if="showDeclineRequestButton" class="flex gap-3 items-center px-4 w-full whitespace-nowrap min-h-[44px] hover:bg-slate-800" role="menuitem" tabindex="0" @click="handleDeclineRequest">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/4ceb2d262ab7f7945b95d85c3441e97ce7be9acfde151a4d1599b2671b084770?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51" class="object-contain shrink-0 my-auto w-5 aspect-square" alt="" />
            <span class="flex-1 shrink basis-0 text-ellipsis">Decline request</span>
        </button>

        <button v-if="showRemoveUserButton" class="flex gap-3 items-center px-4 w-full whitespace-nowrap min-h-[45px] hover:bg-slate-800" role="menuitem" tabindex="0" @click="handleRemoveUser">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/9dcdd81669fd2f0d96fe7c6b8a9542ccdd4cd9d28d593cf86ecc3464bfc8672f?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51" class="object-contain shrink-0 my-auto w-5 aspect-square" alt="" />
            <span class="flex-1 shrink basis-0 text-ellipsis">Remove User</span>
        </button>
    </nav>
</template>

<script setup lang="ts">
    import { ref, defineProps, computed, defineEmits } from 'vue';
    const emits = defineEmits(['onEditUser', 'onAcceptRequest', 'onDeclineRequest', 'onResendInvitation', 'onRemoveUser'])

    const props = defineProps({
        isVisible: { type: Boolean, default: false },
        top: { type: Number, default: 0 },
        left: { type: Number, default: 0 },
        statusId: { type: Number, default: 0}
    })

    const showEditButton = computed(() => {
        return true;
    });

    const showAcceptRequestButton = computed(() => {
        return props.statusId === 3;
    })

    const showResendInvitationButton = computed(() => {
        return props.statusId === 4 || props.statusId === 5;
    })

    const showDeclineRequestButton = computed(() => {
        return props.statusId === 3;
    })

    const showRemoveUserButton = computed(() => {
        return true;
    });

    const handleEdit = () => {
        emits('onEditUser');
    }

    const handleAcceptRequest = () => {
        emits('onAcceptRequest');
    }

    const handleDeclineRequest = () => {
        emits('onDeclineRequest');
    }

    const handleResendInvitation = () => {
        emits('onResendInvitation');
    }

    const handleRemoveUser = () => {
        emits('onRemoveUser');
    }
</script>