<template>
    <div class="icon-cross-circle-fill">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="8" fill="#F14D4D" />
            <path d="M11 5L5 11M5 5L11 11" stroke="white" stroke-width="1.6666" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span class="bodySmall title">{{ props.title }}</span>
    </div>
</template>

<script setup lang="ts">
    const props = defineProps({
        title: {type: String, default: '', required: false}
    })
</script>

<style scoped lang="scss">
    @import '@/assets/mainstyle.scss';

    .icon-cross-circle-fill {
        display: flex;
        flex-direction: row;
        font-size: 16px;
        padding: 10px;
        color: green;
        gap: 5px;
        align-items: center;
    }

    .title {
        @extend .text-body2;
    }
</style>