<template>
    <div
        class="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-modalBackgroundBlur backdrop-blur-[2px] z-[1000] overflow-auto scroll-width">
        <div
            class="bg-[#031525] rounded-[40px]  w-auto min-w-[888px] max-w-[888px] p-6 relative m-auto  sm:min-w-[500px] sm:m-[40px] xs:min-w-[300px] md:width-[500px]">
            <div class="frame-2286">
                <div class="frame-3467296">
                    <div class="frame-3467213">
                        <div class="monthly-limit">Monthly Limit</div>
                        <!-- <img class="icon-x" src="" /> -->
                        <img data-v-8f6acbc5="" loading="lazy" @click="closeView"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/601a5267783be676e315b70bb1f816c6b149d6dc4ee63f4ce4c0f4e1ef49ef22?placeholderIfAbsent=true&amp;apiKey=fd41f46e3770495cb83a3d2783a50c51"
                            class="request-icon cursor-pointer" alt="Request connector icon">
                    </div>
                    <div class="frame-3467214">
                        <div
                            class="manage-your-api-spend-by-configuring-monthly-spend-limits-notification-emails-will-be-sent-to-members-of-your-organization-with-the-owner-role-note-that-there-may-be-a-delay-in-enforcing-limits-and-you-are-still-responsible-for-any-overage-incurred">
                            Manage your API spend by configuring monthly spend limits.
                            Notification emails will be sent to members of your organization with
                            the &quot;Owner&quot; role. Note that there may be a delay in
                            enforcing limits, and you are still responsible for any overage
                            incurred.
                        </div>
                    </div>
                </div>
                <div class="frame-2284">
                    <div class="input-with-label">
                        <div class="label">Set a monthly budget</div>
                        <div class="frame-3467304">
                            <div class="hint-text">
                                If your organisation exceeds this budget in a given calendar month,
                                subsequent API requests will be rejected.
                            </div>
                            <div class="input-field">
                                <div class="input-with-label2">
                                    <div class="input">
                                        <div class="text-input">
                                            <input class="text" v-model="newLimit" />
                                        </div>
                                        <div class="buttons-button">
                                            <div class="text-padding">
                                                <div class="text2">CHF</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="input-with-label">
                        <div class="label">Set an email notification threshold</div>
                        <div class="frame-3467305">
                            <div class="hint-text">
                                If your organisation exceeds this threshold in a given calendar
                                month, an email notification will be sent.
                            </div>
                            <div class="input-field">
                                <div class="input-with-label2">
                                    <div class="input">
                                        <div class="text-input">
                                            <input class="text" v-model="emailLimit" />
                                        </div>
                                        <div class="buttons-button">
                                            <div class="text-padding">
                                                <div class="text2">CHF</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="frame-3467215">
                    <div class="">
                        <div class="btn btn-outline w-[6.25rem] h-[2.5rem] p-2" @click="closeView">Cancel</div>
                    </div>
                    <div class="">
                        <div class="btn btn-primary w-[6.25rem] h-[2.5rem] p-2 hover:bg-" @click="Save">Save</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup lang="ts">
import { HubService } from "@/Services/HubService";
import { ref, watch, defineProps } from "vue";

// Define props to accept data from the parent
const props = defineProps<{
  initialLimit?: number;
}>();

// Reactive variables
const newLimit = ref<string>(""); // Prefilled with the parent's limit
const emailLimit = ref<string>("");

// Emit event back to parent
const emits = defineEmits(["closeLimitIncreaseModal" , "closeIncreaseLimit"]);

// Watch for changes in the `initialLimit` prop and update `newLimit`
watch(
  () => props.initialLimit,
  (newVal) => {
    newLimit.value = newVal?.toString() || "";
    console.log("newLimit.value",newLimit.value)
  },
  { immediate: true } // Trigger watcher on mount
);

// Close modal
const closeView = () => {
  emits("closeIncreaseLimit");
};

// Save the new limit
const Save = async () => {
  const hubService = new HubService();
  try {
    const res = await hubService.OrganisationUsageLimitUpdate(newLimit.value, emailLimit.value);
    if (res) {
      emits("closeLimitIncreaseModal");
    }
  } catch (error) {
    emits("closeLimitIncreaseModal");
  }
};
</script>

<style scoped>
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 888px;
    max-height: 527px;
    background: rgba(3, 21, 37, 0.77);
    border-radius: 40px;
    padding: 32px;
}

.bg-overlay {
    backdrop-filter: blur(4px);
    z-index: 1000;
    background-color: #031525c4;
}

.pop-up-create-key,
.pop-up-create-key * {
    box-sizing: border-box;
}

.pop-up-create-key {
    /* background: var(--background-primary, #031525);
        border-radius: var(--var-sds-size-icon-large, 40px);
        padding: var(--spacing-4xl, 32px);
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;
        overflow: hidden; */
    /* width: 888px;
    height: 483px;
    top: 208.13px;
    left: 276px;
    background: var(--background-primary, #031525);
     border-radius: 40px;
    padding: 32px;
    gap: 10px; */
    width: 888px;
    height: 483px;

    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: var(--background-primary, #031525);
    border-radius: 40px;
    border: 1px solid red;
}

.frame-2286 {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
}

.frame-3467296 {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm, 6px);
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}

.frame-3467213 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}

.monthly-limit {
    color: #ffffff;
    text-align: left;
    font-family: var(--heading-4-font-family, "Inter-SemiBold", sans-serif);
    font-size: var(--heading-4-font-size, 29px);
    font-weight: var(--heading-4-font-weight, 600);
    position: relative;
}

.icon-x {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    position: relative;
    overflow: visible;
}

.frame-3467214 {
    display: flex;
    flex-direction: row;
    gap: 412px;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}

.manage-your-api-spend-by-configuring-monthly-spend-limits-notification-emails-will-be-sent-to-members-of-your-organization-with-the-owner-role-note-that-there-may-be-a-delay-in-enforcing-limits-and-you-are-still-responsible-for-any-overage-incurred {
    color: var(--text-tetriary, #cbd6e3);
    text-align: left;
    font-family: var(--m3-body-large-font-family, "Roboto-Regular", sans-serif);
    font-size: var(--m3-body-large-font-size, 16px);
    line-height: var(--m3-body-large-line-height, 24px);
    letter-spacing: var(--m3-body-large-letter-spacing, 0.5px);
    font-weight: var(--m3-body-large-font-weight, 400);
    position: relative;
    flex: 1;
}

.frame-2284 {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}

.input-with-label {
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}

.label {
    color: var(--white, #ffffff);
    text-align: left;
    font-family: var(--heading-6-font-family, "Inter-Bold", sans-serif);
    font-size: var(--heading-6-font-size, 20px);
    font-weight: var(--heading-6-font-weight, 700);
    position: relative;
}

.frame-3467304 {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}

.hint-text {
    color: var(--text-tetriary, #cbd6e3);
    text-align: left;
    font-family: var(--body-font-family, "Inter-Medium", sans-serif);
    font-size: var(--body-font-size, 14px);
    font-weight: var(--body-font-weight, 500);
    position: relative;
    align-self: stretch;
}

.input-field {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm, 6px);
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}

.input-with-label2 {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm, 6px);
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}

.input {
    background: var(--background-secondary, #071a2b);
    border-radius: var(--radius-md, 8px);
    border-style: solid;
    border-color: var(--white-semi-transparent-20, rgba(255, 255, 255, 0.2));
    border-width: 1px;
    display: flex;
    flex-direction: row;
    gap: 0px;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    box-shadow: var(--shadows-shadow-xs-box-shadow, 0px 1px 2px 0px rgba(16, 24, 40, 0.05));
}

.text-input {
    background: var(--background-secondary, #071a2b);
    border-radius: var(--spacing-md, 8px) var(--spacing-none, 0px) var(--spacing-none, 0px) var(--spacing-md, 8px);
    border-style: solid;
    border-color: var(--white-semi-transparent-20, rgba(255, 255, 255, 0.2));
    border-width: 1px 0px 1px 1px;
    padding: var(--spacing-md, 9px) var(--spacing-lg, 12px) var(--spacing-md, 9px) var(--spacing-lg, 12px);
    display: flex;
    flex-direction: row;
    gap: var(--spacing-md, 8px);
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    position: relative;
    overflow: hidden;
}

.text {
    color: var(--white, #ffffff);
    text-align: left;
    font-family: var(--text-md-regular-font-family, "Inter-Regular", sans-serif);
    font-size: var(--text-md-regular-font-size, 16px);
    line-height: var(--text-md-regular-line-height, 24px);
    font-weight: var(--text-md-regular-font-weight, 400);
    position: relative;
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    background-color: transparent;
    border: none;
    outline: none;
}

.buttons-button {
    background: var(--background-secondary, #071a2b);
    border-radius: var(--spacing-none, 0px) var(--radius-md, 8px) var(--radius-md, 8px) var(--spacing-none, 0px);
    border-style: solid;
    border-color: var(--white-semi-transparent-20, rgba(255, 255, 255, 0.2));
    border-width: 1px;
    padding: 12px 14px 10px 14px;
    display: flex;
    flex-direction: row;
    gap: var(--spacing-xs, 4px);
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
}

.text-padding {
    padding: 0px var(--spacing-xxs, 2px) 0px var(--spacing-xxs, 2px);
    display: flex;
    flex-direction: row;
    gap: 0px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
}

.text2 {
    color: var(--text-tetriary, #cbd6e3);
    text-align: left;
    font-family: var(--text-sm-semibold-font-family, "Inter-SemiBold", sans-serif);
    font-size: var(--text-sm-semibold-font-size, 14px);
    line-height: var(--text-sm-semibold-line-height, 20px);
    font-weight: var(--text-sm-semibold-font-weight, 600);
    position: relative;
}

.frame-3467305 {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}

.frame-3467215 {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: flex-start;
    justify-content: flex-end;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}

.small-button {
    border-radius: var(--radius-sm, 6px);
    border-style: solid;
    border-color: var(--button, #7ba8ef);
    border-width: 1px;
    padding: 2px 0px 2px 0px;
    display: flex;
    flex-direction: row;
    gap: 0px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 100px;
    height: 40px;
    position: relative;
    overflow: hidden;
}

.button {
    color: var(--button, #7ba8ef);
    text-align: center;
    font-family: var(--heading-7-font-family, "Inter-Medium", sans-serif);
    font-size: var(--heading-7-font-size, 17px);
    font-weight: var(--heading-7-font-weight, 500);
    position: relative;
    align-self: stretch;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button:hover {
    cursor: pointer;
}

.small-button2 {
    background: var(--button, #7ba8ef);
    border-radius: var(--radius-sm, 6px);
    padding: 2px 0px 2px 0px;
    display: flex;
    flex-direction: row;
    gap: 0px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 100px;
    height: 40px;
    position: relative;
    overflow: hidden;
}

.button2 {
    color: var(--background-primary, #031525);
    text-align: center;
    font-family: var(--heading-7-font-family, "Inter-Medium", sans-serif);
    font-size: var(--heading-7-font-size, 17px);
    font-weight: var(--heading-7-font-weight, 500);
    position: relative;
    align-self: stretch;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button2:hover {
    cursor: pointer;
}
</style>