<template>
    <div class="icon-slack">
        <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_11450_20730)">
                <rect x="2.66797" y="1" width="52" height="52" rx="8" fill="white" shape-rendering="crispEdges" />
                <rect x="3.16797" y="1.5" width="51" height="51" rx="7.5" stroke="#EAECF0" shape-rendering="crispEdges" />
                <path d="M44.4183 25.4997C46.4892 25.4997 48.168 23.8209 48.168 21.75C48.168 19.6791 46.489 18.0003 44.4181 18.0003C42.3471 18.0003 40.668 19.6793 40.668 21.7503V25.4997H44.4183ZM33.918 25.4997C35.989 25.4997 37.668 23.8207 37.668 21.7497V11.2503C37.668 9.17926 35.989 7.50032 33.918 7.50032C31.8469 7.50032 30.168 9.17925 30.168 11.2503V21.7497C30.168 23.8207 31.8469 25.4997 33.918 25.4997Z" fill="#2EB67D" />
                <path d="M12.9176 28.5003C10.8468 28.5003 9.16797 30.1791 9.16797 32.25C9.16797 34.3209 10.8469 35.9997 12.9178 35.9997C14.9889 35.9997 16.668 34.3207 16.668 32.2497V28.5003H12.9176ZM23.418 28.5003C21.3469 28.5003 19.668 30.1793 19.668 32.2503V42.7497C19.668 44.8207 21.3469 46.4997 23.418 46.4997C25.489 46.4997 27.168 44.8207 27.168 42.7497V32.2503C27.168 30.1793 25.489 28.5003 23.418 28.5003Z" fill="#E01E5A" />
                <path d="M30.1686 42.7503C30.1686 44.8212 31.8474 46.5 33.9183 46.5C35.9892 46.5 37.668 44.8211 37.668 42.7502C37.668 40.6791 35.989 39 33.918 39L30.1686 39L30.1686 42.7503ZM30.1686 32.25C30.1686 34.3211 31.8475 36 33.9186 36L44.418 36C46.489 36 48.168 34.3211 48.168 32.25C48.168 30.1789 46.489 28.5 44.418 28.5L33.9186 28.5C31.8475 28.5 30.1686 30.1789 30.1686 32.25Z" fill="#ECB22E" />
                <path d="M27.1673 11.2497C27.1673 9.17879 25.4885 7.5 23.4177 7.5C21.3468 7.5 19.668 9.17895 19.668 11.2498C19.668 13.3209 21.3469 15 23.418 15L27.1673 15L27.1673 11.2497ZM27.1673 21.75C27.1673 19.6789 25.4884 18 23.4173 18L12.918 18C10.8469 18 9.16797 19.6789 9.16797 21.75C9.16797 23.8211 10.8469 25.5 12.918 25.5L23.4173 25.5C25.4884 25.5 27.1673 23.8211 27.1673 21.75Z" fill="#36C5F0" />
            </g>
            <defs>
                <filter id="filter0_d_11450_20730" x="0.667969" y="0" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11450_20730" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_11450_20730" result="shape" />
                </filter>
            </defs>
        </svg>
    </div>
</template>

<script setup lang="ts">
</script>

<style>
    .icon-slack {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        color: rgb(177, 168, 168);
    }
</style>