<template>
  <div class="flex flex-col h-full pt-5 pb-5" id="feature-request">
    <section
      class="flex flex-col font-medium rounded-t-2xl border border-solid bg-slate-900 border-zinc-700 border-opacity-40"
    >
      <header
        class="flex gap-4 items-start p-4 w-full max-md:px-5 max-md:max-w-full"
      >
        <div
          class="flex flex-col flex-1 shrink justify-center w-full basis-0 min-h-[40px] min-w-[240px] max-md:max-w-full"
        >
          <div
            class="flex flex-wrap gap-10 justify-between items-center w-full max-md:max-w-full"
          >
            <h1 class="gap-2 self-stretch my-auto text-lg text-white">
              Feature Request
            </h1>
            <form
              class="flex overflow-hidden flex-col self-stretch my-auto max-w-screen-sm text-sm rounded-lg shadow-xl min-h-[40px] min-w-[240px] text-white text-opacity-40 w-[566px] max-md:max-w-full"
            >
              <div class="flex flex-col max-w-full min-h-[40px] w-[566px]">
                <div class="flex flex-col w-full max-md:max-w-full">
                  <div
                    class="flex overflow-hidden gap-2 items-center px-3.5 w-full rounded-lg border border-solid bg-slate-900 border-zinc-700 border-opacity-40 min-h-[40px] max-md:max-w-full"
                  >
                    <div
                      class="flex flex-1 shrink gap-2 items-center self-stretch my-auto w-full basis-0 min-w-[240px] max-md:max-w-full"
                    >
                      <div
                        class="flex flex-wrap flex-1 shrink gap-2 items-center self-stretch my-auto w-full basis-0 min-w-[240px] max-md:max-w-full"
                      >
                        <img
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/86db1d54c8ef985c77d675a00e7df42b5745ea2a6c1185c4c52f1eff8b78a400?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51"
                          class="object-contain shrink-0 self-stretch my-auto w-5 aspect-square"
                          alt=""
                        />
                        <label for="searchInput" class="sr-only"
                          >Search for logs</label
                        >
                        <input
                          id="searchInput"
                          type="text"
                          placeholder="Search for Feature Request"
                          class="flex-1 shrink self-stretch my-auto basis-0 max-md:max-w-full bg-transparent border-none text-white text-opacity-40 focus:outline-none"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </header>
      <div
        class="flex w-full border-zinc-700 bg-opacity-40 min-h-[1px] max-md:max-w-full"
      ></div>
    </section>
    <main class="flex-1 bg-slate-900">
      <div
        class="max-h-[62vh] overflow-y-auto border border-white border-opacity-10"
      >
        <FeatureRequestTable />
      </div>
    </main>
    <footer
      class="h-fit border-zinc-700 text-white border border-solid bg-slate-900 border-zinc-700 border-opacity-40 text-slate-300 rounded-b-2xl p-3"
    >
      <Pagination
        :currentPage="currentPage"
        :itemsPerPage="10"
        :totalItems="totalItems"
        @update:current-page="handlePageChange"
      />
    </footer>
  </div>
</template>

<script setup lang="ts">
import FeatureRequestTable from "./FeatureRequestTable.vue";
import Pagination from "../../common/Pagination.vue";
import { ref } from "vue";

const totalItems = ref<number>(70);
const currentPage = ref<number>(2);

const handlePageChange = (page: number) => {
  currentPage.value = page;
};
</script>

<style scoped></style>
