<template>
  <DoughnutChart
    class="w-[10.66rem] h-[10.66rem] xs:w-[6.66rem]"
    :chartData="chartData"
    :options="chartOptions"
  />
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted, onBeforeMount } from "vue";
import { DoughnutChart } from "vue-chart-3";
import { calculatePercentage } from "@/helpers";
import type { IChartData } from "@/Models/charts";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  DoughnutController,
} from "chart.js";

// Props and emits
const props = defineProps<{
  chartData: IChartData;
  chosenMonth: number;
  showCenterText: boolean;
  innerTextFont?: string;
}>();
const totalVal = ref(0);
const percentage = ref("");
const ringDataNumbers = props.chartData.datasets[0].data;

// Chart options
const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    centerText: {
      //* Plugin for text in the center
      id: "centerText",
    },
    tooltip: {
      enabled: true,
      // To disable tooltip for 2nd index ring
      filter: (tooltipItem: any) =>
        tooltipItem.dataIndex !== ringDataNumbers.length - 1,
    },
  },
  cutout: "80%",
};

onBeforeMount(() => {
  // Register required components for Doughnut chart
  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale,
    DoughnutController
  );
});

onMounted(() => {
  if (props.showCenterText) {
    ChartJS.unregister(centerTextPlugin);
    ChartJS.register(centerTextPlugin);
  }
  // Total of all ring data
  totalVal.value = ringDataNumbers.reduce(
    (total: number, value: number) => total + value,
    0
  );

  // Get all items except the last
  const exceptLast = ringDataNumbers.slice(0, -1);
  const totalExceptRemaining = exceptLast.reduce(
    (total: number, value: number) => total + value,
    0
  );
  percentage.value = calculatePercentage(totalExceptRemaining, totalVal.value);
});

onUnmounted(() => {
  if (props.showCenterText) {
    ChartJS.unregister(centerTextPlugin);
  }
});

// Chart plugin
const centerTextPlugin = {
  id: "centerText",
  beforeDraw(chart: any) {
    const {
      ctx,
      chartArea: { left, right, top, bottom },
    } = chart;
    ctx.restore();
    ctx.font = `${props.innerTextFont || "29px"} Inter, sans-serif`;
    ctx.textBaseline = "middle";
    ctx.fillStyle = "#fff";
    const text = `${percentage.value}%`; // Text to display inside the doughnut
    const textX =
      Math.round((left + right) / 2) - getTextWidth(`${percentage.value}%`) / 2;
    const textY = Math.round((top + bottom) / 2);

    ctx.pointColor = "#fff";
    ctx.fillText(text, textX, textY);
    ctx.save();
  },
};
// ChartJS.register(centerTextPlugin);

// To get the width of the text
const getTextWidth = (text: string) => {
  const el = document.createElement("div");
  el.innerText = text;

  // Apply any styles that affect the width
  el.style.fontWeight = "600"; // Add the font weight
  el.style.fontSize = props.innerTextFont || "29px"; // Add the font size
  el.style.position = "absolute";
  el.style.visibility = "hidden"; // Hide it from view

  // Append the element to the body (it's hidden)
  document.body.appendChild(el);
  // Get the width of the element
  const width = el.offsetWidth;

  // Remove the element after measuring
  document.body.removeChild(el);
  return width;
};
</script>
