<template>
    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.0//EN" "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
    <div class="icon-home">
        <!-- <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5365 20V14H14.7511V20H20.0193V12H23.1802L12.6438 3L2.10742 12H5.26834V20H10.5365Z" fill="#CBD6E3" />
        </svg> -->
        <span class="icon-title">{{title}}</span>
    </div>
</template>

<script>
    export default {
        props: {
            width: {
                type: String,
                required: false
            },
            height: {
                type: String,
                required: false
            },
            title: {
                type: String,
                required: false,
                default: ''
            }
        }
    };
</script>

<style scoped>
    .icon-home {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: 5px;
    }

    .icon-title {
        color: var(--Text-Tetriary, #CBD6E3);
        font-family: Inter;
        font-size: 15px;
        font-weight: 600;
    }
</style>