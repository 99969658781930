<template>
  <section class="auth-frame" v-if="!isNotionConnector">
    <header class="auth-header">
      <div class="icon-container">
        <component :is="sourceLogoComponent"></component>
      </div>
      <h1 class="h7 heading">{{ sourceTitle }}</h1>
    </header>
    <main class="auth-content">
      <h2 class="h4 auth-title">Authentication</h2>
      <form class="auth-form" @submit.prevent="onLogin">
        <fieldset class="form-group">
          <legend class="bodySmall form-legend">
            Enter your credentials for {{ sourceTitle }}
          </legend>
          <div class="input-group">
            <label for="username" class="visually-hidden"
              >User name or email</label
            >
            <input
              type="text"
              id="username"
              class="paragraph form-input"
              placeholder="User name or email"
              autocomplete="off"
              v-model="username"
            />
          </div>
          <div class="input-group">
            <label for="password" class="visually-hidden">Password</label>
            <div class="password-input">
              <input
                :type="showPassword ? 'text' : 'password'"
                id="password"
                class="paragraph form-input"
                placeholder="Password"
                autocomplete="off"
                v-model="password"
              />
              <button
                type="button"
                class="toggle-password"
                @click="togglePasswordVisibility"
                :aria-label="showPassword ? 'Hide password' : 'Show password'"
              >
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/c31d541ab1554de0d867ac1ed7b3e7c52b49f6003786910c9cc0c33cbc4e15d5?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51"
                  alt=""
                  class="eye-icon"
                />
              </button>
            </div>
          </div>
        </fieldset>
        <button type="submit" class="h7 submit-button">Login</button>
      </form>
    </main>
  </section>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";

const emits = defineEmits(["onLogin"]);

const props = defineProps({
  sourceTitle: {
    type: String,
    default: "",
    required: true,
  },
  sourceLogoComponent: {
    type: Object,
    required: true,
  },
  selectedConnector: {
    type: String,
    required: false,
  },
});

const username = ref("");
const password = ref("");
const showPassword = ref(false);
const isNotionConnector = computed(() => props.selectedConnector == "Notion");
const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value;
};

const onLogin = () => {
  emits("onLogin", username.value, password.value);
};

onMounted(() => {
  username.value = "";
  password.value = "";
});
</script>

<style scoped>
.auth-frame {
  border-radius: 30px;
  background-color: #071a2b;
  display: flex;
  max-width: 462px;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  border: 1px solid #11385b;
  min-width: 25%;
}

.auth-header {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 12px;
}

.icon-container {
  align-items: center;
  border-radius: 8px;
  border: 1px solid #eaecf0;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  display: flex;
  width: 52px;
  height: 52px;
  padding: 2px;
}

.icon {
  aspect-ratio: 1;
  object-fit: contain;
  width: 48px;
}

.heading {
  color: #fff;
  flex: 1;
  margin: 0;
}

.auth-content {
  display: flex;
  margin-top: 46px;
  width: 100%;
  flex-direction: column;
}

.auth-title {
  color: #fff;
  margin: 0 0 16px;
}

.auth-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-group {
  border: none;
  padding: 0;
  margin: 0;
}

.form-legend {
  color: #fff;
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 20px;
}

.form-input {
  width: 100%;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #071a2b;
  color: #667085;
}

.password-input {
  position: relative;
}

.toggle-password {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}

.eye-icon {
  width: 16px;
  height: 16px;
}

.submit-button {
  align-self: stretch;
  border-radius: 6px;
  background-color: #7ba8ef;
  color: #031525;
  text-align: center;
  padding: 10px;
  border: none;
  cursor: pointer;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
</style>
