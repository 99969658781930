import AppConfigGlobal from "../core/config/uiSettings";
import type { IChangeLog } from "../Models/Users/IChangeLog";
import type { IUser } from "../Models/Users/IUser";
import type { IUserDetail } from "../Models/Users/IUserDetail";
import type { IUserRole } from "../Models/Users/IUserRole";
import type { IUserStatus } from "../Models/Users/IUserStatus";
import type { IUserStatusSummary } from "../Models/Users/IUserStatusSummary";
import type { IUserInvitationRequest } from "../Models/Users/Request/IUserInvitationRequest";
import type { ITableListRequest, ITableFilter } from "../Models/Users/Request/ITableListRequest";
import type { IUserListResponse } from "../Models/Users/Response/IUserListResponse";
import Core from "./Core";
import Axios from "axios";
import type { IUsersChangeLogsList } from "../Models/Users/Response/IUsersChangeLogsList";
import type { IUserUpdateRequest } from "../Models/Users/Request/IUserUpdateRequest";
import type { IUsersStatusChangeRequest } from "../Models/Users/Request/IUsersStatusChangeRequest";

export class UserService extends Core {
    public async GetAllOrganizationUsers(userListRequest: ITableListRequest): Promise<IUserListResponse> {
        try {
            return await this.PerformPost<IUserListResponse>(`${AppConfigGlobal.FreddyAssistantApiUrl}/v1/organization/${AppConfigGlobal.CurrentOrganizationId}/users`, userListRequest);
        } catch (e) {
            return {} as IUserListResponse;
        }
    }

    public async GetAllOrganizationUsersIds(userListRequest: ITableFilter): Promise<number[]> {
        try {
            return await this.PerformPost<number[]>(`${AppConfigGlobal.FreddyAssistantApiUrl}/v1/organization/${AppConfigGlobal.CurrentOrganizationId}/users/ids`, userListRequest);
        } catch (e) {
            return [];
        }
    }

    public async GetUserDetailById(userId: number): Promise<IUserDetail> {
        try {
            return await this.PerformGet<IUserDetail>(`${AppConfigGlobal.FreddyAssistantApiUrl}/v1/organization/${AppConfigGlobal.CurrentOrganizationId}/users/${userId}`);
        } catch (e) {
            return {} as IUserDetail;
        }
    }

    public async DeleteUserById(userId: number): Promise<boolean> {
        try {
            return await this.PerformDelete<boolean>(`${AppConfigGlobal.FreddyAssistantApiUrl}/v1/organization/${AppConfigGlobal.CurrentOrganizationId}/users/${userId}/delete`);
        } catch (e) {
            return false;
        }
    }

    public async DeleteUsers(userIds: number[]): Promise<boolean> {
        try {
            return await this.PerformPost<boolean>(`${AppConfigGlobal.FreddyAssistantApiUrl}/v1/organization/${AppConfigGlobal.CurrentOrganizationId}/users/delete-bulk`, userIds);
        } catch (e) {
            throw e;
        }
    }

    public async ResendUserInvitation(userId: number): Promise<boolean> {
        try {
            //return await this.PerformPost<boolean>('/DummyData/UsersData/UsersDetails.json');
            return true;
        } catch (e) {
            return false;
        }
    }

    public async GetUserDetails(userId: number): Promise<IUserDetail> {
        const response = await this.PerformGet<IUserDetail[]>('/DummyData/UsersData/UsersDetails.json');
        return response.find(x => x.id === userId) as IUserDetail;
    }

    public async UsersExportAsCSV(usersExportRequest: ITableListRequest): Promise<Blob> {
        try {
            return await this.PerformPost<Blob>(`${AppConfigGlobal.FreddyAssistantApiUrl}/v1/organization/${AppConfigGlobal.CurrentOrganizationId}/users/export-csv`, usersExportRequest);
        } catch (e) {
            throw e;
        }
    }

    public async UsersExportAsPDF(usersExportRequest: ITableListRequest): Promise<Blob> {
        try {
            return await this.PerformPostExportPdf<Blob>(`${AppConfigGlobal.FreddyAssistantApiUrl}/v1/organization/${AppConfigGlobal.CurrentOrganizationId}/users/export-pdf`, usersExportRequest,
            );
        } catch (e) {
            throw e;
        }
    }

    public async GetUserRoles(): Promise<IUserRole[]> {
        return await this.PerformGet<IUserRole[]>(`${AppConfigGlobal.FreddyAssistantApiUrl}/v1/organization/${AppConfigGlobal.CurrentOrganizationId}/get-roles`);
    }

    public async GetUserStatuses(): Promise<IUserStatus[]> {
        return await this.PerformGet<IUserRole[]>(`${AppConfigGlobal.FreddyAssistantApiUrl}/v1/organization/${AppConfigGlobal.CurrentOrganizationId}/user-statuses`);
    }

    public async GetUserStatusSummary(): Promise<IUserStatusSummary[]> {
        try {
            return await this.PerformGet<IUserStatusSummary[]>(`${AppConfigGlobal.FreddyAssistantApiUrl}/v1/organization/${AppConfigGlobal.CurrentOrganizationId}/users/status-summary`);
        } catch (e) {
            throw e;
        }
    }

    public async InviteUser(userRequest: IUserInvitationRequest): Promise<Number> {
        try {
            return await this.PerformPost<Number>(`${AppConfigGlobal.FreddyAssistantApiUrl}/v1/organization/${AppConfigGlobal.CurrentOrganizationId}/invite-user`, userRequest);
        }
        catch {
            return 0;
        }
    }

    public async UpdateUserBasicData(userId: number, userUpdateRequest: IUserUpdateRequest): Promise<Number> {
        try {
            return await this.PerformPut<Number>(`${AppConfigGlobal.FreddyAssistantApiUrl}/v1/organization/${AppConfigGlobal.CurrentOrganizationId}/users/${userId}`, userUpdateRequest);
        }
        catch {
            return 0;
        }
    }

    public async GetAllOrganizationChangeLog(changeLogListRequest: ITableListRequest): Promise<IUsersChangeLogsList> {
        try {
            return await this.PerformPost<IUsersChangeLogsList>(`${AppConfigGlobal.FreddyAssistantApiUrl}/v1/organization/${AppConfigGlobal.CurrentOrganizationId}/dashboard/change-logs`, changeLogListRequest);
        }
        catch {
            return {} as IUsersChangeLogsList;
        }
        
    }

    public async RevokeChangeLogById(changeLogId: number): Promise<boolean> {
        try {
            return await this.PerformPost<boolean>(`${AppConfigGlobal.FreddyAssistantApiUrl}/v1/organization/${AppConfigGlobal.CurrentOrganizationId}/dashboard/change-logs/${changeLogId}/revoke`, {});
        }
        catch {
            return false;
        }

    }

    public async ChangeUsersStatus(usersStatusChangeRequest: IUsersStatusChangeRequest): Promise<Number> {
        try {
            return await this.PerformPost<Number>(`${AppConfigGlobal.FreddyAssistantApiUrl}/v1/organization/${AppConfigGlobal.CurrentOrganizationId}/users/change-status-bulk`, usersStatusChangeRequest);
        }
        catch(e) {
            throw e;
        }
    }

    public async GetOrganizationUserChangeLog(userId: number, changeLogListRequest: ITableListRequest): Promise<IUsersChangeLogsList> {
        try {
            return await this.PerformPost<IUsersChangeLogsList>(`${AppConfigGlobal.FreddyAssistantApiUrl}/v1/organization/${AppConfigGlobal.CurrentOrganizationId}/users/${userId}/change-logs`, changeLogListRequest);
        }
        catch {
            return {} as IUsersChangeLogsList;
        }

    }
}