// To get the short month name
export const getShortMonthName = (monthNumber: number) =>
  [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ][monthNumber - 1];

// To fill the missing day and value as '0'
export const fillMissingDays = (
  month: number,
  dayData: Array<{ [key: number]: number }>
) => {
  const daysInMonth = new Date(new Date().getFullYear(), month, 0).getDate();

  const filledData = Object.fromEntries(
    Array.from({ length: daysInMonth }, (_, i) => {
      const day = i + 1;
      const value = dayData.find((data) => data[day])?.[day] || 0;
      return [day, value];
    })
  );

  return filledData;
};

// To return value as 1 month_name to until end of the month
export const getDatesForMonth = (monthNumber: number) => {
  const daysInMonth = new Date(
    new Date().getFullYear(),
    monthNumber,
    0
  ).getDate();
  return Array.from(
    { length: daysInMonth },
    (_, i) => `${i + 1} ${getShortMonthName(monthNumber)}`
  );
};
