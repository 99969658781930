<template>
  <div
    class="fixed z-[9998] top-0 left-0 w-full h-full bg-modalBackgroundBlur flex justify-center items-center"
  >
    <section class="flex items-center justify-start">
      <div
        class="flex flex-col items-center w-[371px] self-stretch mx-auto my-0 p-8 rounded-[40px] bg-[#031525] border-white/10"
      >
        <header class="flex flex-col justify-start w-full">
          <IconDelete
            class="h-8 w-8 border-4 stroke-2 rounded-full p-1 border-solid text-white border-[#E71D41] bg-[#F14D4D]"
          />

          <div class="flex flex-col justify-start w-full mt-5">
            <h2 class="w-full text-[20px] font-bold text-white m-0">
              {{ title }}
            </h2>
            <p
              class="flex-1 w-full mt-2 text-[12px] font-medium text-[#cbd6e3]"
            >
              {{ description }}
            </p>
          </div>
        </header>
        <!-- Footer -->
        <footer
          class="flex items-center justify-between w-full mt-5 gap-2.5 text-[17px] font-medium whitespace-nowrap text-center"
        >
          <button class="btn text-white" @click="closeDeleteModal">
            Cancel
          </button>
          <button class="btn btn-danger" @click="confirmDeletion">
            <span>Delete</span>
          </button>
        </footer>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import IconDelete from "../icons/IconDelete.vue";

const emit = defineEmits(["close", "confirmDeletion"]);
defineProps({
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    default: "",
  },
});

const closeDeleteModal = () => {
  emit("close", false);
};
const confirmDeletion = () => {
  emit("confirmDeletion", true);
};
</script>
