<template>
  <div class="icon-text">
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
      <path d="M14.2 6.00312H4.84C3.49587 6.00312 2.82381 6.00312 2.31042 6.26471C1.85883 6.4948 1.49168 6.86196 1.26158 7.31355C1 7.82694 1 8.499 1 9.84312V14.1631C1 15.5072 1 16.1793 1.26158 16.6927C1.49168 17.1443 1.85883 17.5114 2.31042 17.7415C2.82381 18.0031 3.49587 18.0031 4.84 18.0031H14.2M19 6.00312H21.16C22.5041 6.00312 23.1762 6.00312 23.6896 6.26471C24.1412 6.4948 24.5083 6.86196 24.7384 7.31355C25 7.82694 25 8.499 25 9.84312V14.1631C25 15.5072 25 16.1793 24.7384 16.6927C24.5083 17.1443 24.1412 17.5114 23.6896 17.7415C23.1762 18.0031 22.5041 18.0031 21.16 18.0031H19M19 22.8031L19 1.20312M22 1.20314L16 1.20312M22 22.8031L16 22.8031" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  </div>
</template>

<script setup lang="ts">

</script>

<style>
.icon-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  color: rgb(177, 168, 168);
}
</style>