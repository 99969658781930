<template>
  <highcharts
    class="h-[11.625rem] mt-[0.625rem] xs:w-auto"
    ref="chartRef"
    :modules="['accessibility']"
    :options="chartOptions"
  />
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { fillMissingDays, getDatesForMonth } from "@/helpers";
import HighchartsVue from "highcharts-vue";

// Props & Emits
const props = defineProps({
  chosenMonth: Number,
  chartData: {
    type: Object,
    required: true,
  },
});

const categories = ref(getDatesForMonth(props.chosenMonth));
const chartDetailedArr = ref(
  fillMissingDays(props.chosenMonth, props.chartData)
);

const totalSum = props.chartData.reduce((sum, item) => {
  const value = Object.values(item)[0]; // Get the value of the object
  return sum + value;
}, 0);

// Chart options for the Area Spline chart
const chartOptions = {
  chart: {
    type: "areaspline",
    backgroundColor: "#071a2b"
  },
  legend: {
    enabled: false,
  },
  title: {
    text: "",
    style: {
      color: "#fff",
    },
  },
  tooltip: {
    useHTML: true, // Enable HTML in the tooltip
    backgroundColor: "#333", // Tooltip background color
    borderColor: "#528BFF", // Tooltip border color
    style: {
      color: "#fff", // Tooltip text color
      fontSize: "12px", // Tooltip font size
    },
    formatter: function () {
      return `<div style="font-weight: bold;">Synapsis</div>
              <div>Cost: <span style="color:#A6EF67;">${this.y}</span> on <span style="color:#A6EF67;">${this.x}</span>,</div>`;
    },
  },
  height: "100%", // Use height percentage to fill container
  width: "100%", // Use width percentage to fill container
  xAxis: {
    categories: categories.value,
    tickPositions: [0, categories.value.length - 1],
    labels: {
      // formatter: function () {
      //   return this.value === "Dec 1" || this.value === "Dec 31"
      //     ? this.value
      //     : "";
      // },
      style: {
        color: "#A3A3A3",
        font: "10px Inter, sans-serif",
      },
    },
    startOnTick: true, // Ensure the first category starts right at the axis
    endOnTick: true, // Ensure the last category ends right at the axis
    tickmarkPlacement: "on", // Places tick marks exactly on categories
  },
  yAxis: {
    tickInterval: Math.round(totalSum / 2), //* Gap between the number in the y-axis, eg: 1Dec, 31DEc
    title: {
      text: "",
      style: {
        color: "#fff",
      },
    },
    // Label in the y-axis eg: 300, 100,500
    labels: {
      style: {
        color: "#A3A3A3",
        font: "10px Inter, sans-serif",
      },
    },
    min: 0,
    max: !totalSum ? 1 : undefined,
    gridLineColor: "#FFFFFF17",
  },
  series: [
    {
      name: "",
      data: Object.values(chartDetailedArr.value),
      color: "#528BFF",
      fillColor: {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 }, // Vertical gradient
        stops: [
          [0, "rgba(255, 255, 255, 0.5)"], // Top color (transparent white)
          [1, "rgba(0, 167, 255, 0)"], // Bottom color (transparent blue)
        ],
      },
      lineWidth: 1,
    },
    // { //*Second value
    //   name: "",
    //   // data: [0, 50, 100, 150, 0, 210, 100, 50, 0],
    //   data: Object.values(chartDetailedArr.value),
    //   color: "#A6EF67",
    //   // fillColor: "rgba(153, 102, 255, 0.2)",
    //   fillColor: {
    //     linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 }, // Vertical gradient
    //     stops: [
    //       [0, "#A6EF67"], // Top solid color
    //       [1, "rgba(166, 239, 103, 0)"], // Transparent at the bottom
    //     ],
    //   },
    //   lineWidth: 1,
    // },
  ],
  plotOptions: {
    areaspline: {
      fillOpacity: 0.5,
      marker: {
        enabled: false,
      },
    },
  },
};

const chartRef = ref(null);

const updateChart = () => {
  // Modify the chart options or data here
  // chartOptions.value.series[0].data = [200, 100, 350, 250, 400, 300, 500]; // Example update

  // Force re-render
  if (chartRef.value) {
    chartRef.value.chart.reflow(); // Ensure to use .chart to access the Highcharts instance
  }
};

// single ref
// watch(
//   props.chosenMonth,
//   () => {
//     updateChart();
//   },
//   { immediate: true }
// );

// Ensure chart is mounted only after the component is ready
onMounted(() => {
  // isMounted.value = true;
  window.addEventListener("resize", resizeChart);
});

const resizeChart = () => {
  if (chartRef.value) {
    const chart = chartRef.value.chart; // Access the chart instance
    if (chart) {
      chart.reflow();
    }
  }
};

// onMounted(() => {
//   isMounted.value = true;
//   window.addEventListener('resize', resizeChart);
// });

onBeforeUnmount(() => {
  window.removeEventListener("resize", resizeChart);
});

// Ensuring HighchartsVue is registered (this is typically done in the main.js)
HighchartsVue; // Keep this line to ensure HighchartsVue is recognized
</script>

<style>
/* DON'T REMOVE THIS */
/* .highcharts-background {
    fill: #2a2b38 !important;
  } */

.highcharts-root {
  .highcharts-credits {
    display: none;
  }
}
</style>
