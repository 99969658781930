<template>
    <section class="flex overflow-hidden flex-col p-5 bg-slate-900 w-full h-[60%] rounded-[40px]">
        <header class="flex gap-2.5 items-start self-start text-xl font-bold text-white whitespace-nowrap">
            <h2 class="gap-2.5 self-stretch">Users</h2>
        </header>
        <ul class="flex flex-col mt-5 w-full font-medium list-none p-0">
            <li v-for="(userStatusSummary, index) in listOfUserStatusSammary" :key="index" class="flex gap-10 justify-between items-center w-full min-h-[20px] mt-2 first:mt-0">
                <span class="gap-1.5 self-stretch my-auto text-sm text-white">
                    {{ userStatusSummary.userCount }} User{{ userStatusSummary.userCount > 2 ? 's' : '' }}
                </span>
                <div class="flex items-center self-stretch my-auto text-xs">
                    <div class="flex overflow-hidden gap-2.5 justify-center self-stretch px-1.5 py-0.5 my-auto rounded-md border border-solid min-h-[20px]" :class="GetUserStatusClass(userStatusSummary.statusId)">
                        <span class="gap-2.5 self-stretch h-full">{{ userStatusSummary.statusName }}</span>
                    </div>
                </div>
            </li>
        </ul>
    </section>
</template>

<script setup lang="ts">
    import { ref, onMounted } from 'vue';
    import type { IUserStatusSummary } from '@/Models/Users/IUserStatusSummary';
    import { UserService } from '@/Services/UserService';

    import { GetUserStatusClass } from '@/utility/GetUserStatusClass';

    const userService = new UserService();

    const listOfUserStatusSammary = ref<IUserStatusSummary[]>([]);

    onMounted(async () => {
        try {
            listOfUserStatusSammary.value = await userService.GetUserStatusSummary();
        } catch (e) {

        }
    })
</script>