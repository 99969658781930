import type {
  IUploadFile,
  IVectorStore,
  IVectorAssistant,
  IFiles,
  IFileDetails,
  IOraganizationFilesInfo,
  IVector,
  IRequestParams,
  IPaginationResponse,
  IVectorStoreUsageModel,
} from "@/Models/knowledge";
import Core from "./Core";
import AppConfigGlobal from "@/core/config/uiSettings";

export class KnowledgeService extends Core {
  public async GetAllFilesByOrganizationId(
    params: IRequestParams
  ): Promise<IPaginationResponse<IOraganizationFilesInfo>> {
    try {
      const { skip, take, sort, order, search } = params;
      const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl
        }/v1/Organizations/${AppConfigGlobal.CurrentOrganizationId}/files?skip=${skip}&take=${take}&sort=${encodeURIComponent(
          sort
        )}&order=${encodeURIComponent(order)}&search=${encodeURIComponent(
          search
        )}`;

      return await this.PerformGet<IPaginationResponse<IOraganizationFilesInfo>>(requestUrl);
    } catch (err: any) {
      console.log("An unexpected error occured", err);
      throw err;
    }
  }

  public async GetAllVectorStoresByOrganizationId(
    params: IRequestParams
  ): Promise<IPaginationResponse<IVector>> {
    try {
      const { skip, take, sort, order, search } = params;
      const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl
        }/v1/Organizations/${AppConfigGlobal.CurrentOrganizationId}/vectors/filter?skip=${skip}&take=${take}&sort=${encodeURIComponent(
          sort
        )}&order=${encodeURIComponent(order)}&search=${encodeURIComponent(
          search
        )}`;

      return await this.PerformGet<IPaginationResponse<IVector>>(requestUrl);
    } catch (err: any) {
      console.log("An unexpected error occured", err);
      throw err;
    }
  }

  public async GetFileDetailById(fileId: number | null): Promise<IFileDetails> {
    try {
      const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/Organizations/${AppConfigGlobal.CurrentOrganizationId}/files/details/${fileId}`;
      return await this.PerformGet<IFileDetails>(requestUrl);
    } catch (err: any) {
      console.log("An unexpected error occured", err);
      return err;
    }
  }

  public async RemoveFile(fileId: number | null): Promise<boolean> {
    const data = { fileId: fileId };
    const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/Organizations/${AppConfigGlobal.CurrentOrganizationId}/files/remove/${fileId}`;
    return await this.PerformPost<boolean>(requestUrl, data);
  }

  public async RemoveVectorStore(
    vectorStoreId: number | null
  ): Promise<boolean> {
    const data = { vectorStoreId: vectorStoreId };
    const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/Organizations/${AppConfigGlobal.CurrentOrganizationId}/vectors/remove/${vectorStoreId}`;
    return await this.PerformPost<boolean>(requestUrl, data);
  }

  public async RemoveFileAttached(
    fileId: number,
    storeId: number
  ): Promise<boolean> {
    const data = { fileId: fileId, vectorId: storeId };
    const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/Organizations/${AppConfigGlobal.CurrentOrganizationId}/vectors/files/remove`;
    return await this.PerformPost<boolean>(requestUrl, data);
  }

  public async GetFilesAttachedtoVectorStore(
    vectorId: number | null
  ): Promise<IFiles[]> {
    try {
      const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/Organizations/${AppConfigGlobal.CurrentOrganizationId}/vectors/files?vectorStoreId=${vectorId}`;
      return await this.PerformGet<IFiles[]>(requestUrl);
    } catch (err: any) {
      console.log("An unexpected error occured", err);
      return [];
    }
  }

  public async GetAssitantsofVectorStore(
    vectorId: number | null
  ): Promise<IVectorAssistant[]> {
    try {
      const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/Organizations/${AppConfigGlobal.CurrentOrganizationId}/vectors/assistants?vectorStoreId=${vectorId}`;
      return await this.PerformGet<IVectorAssistant[]>(requestUrl);
    } catch (err: any) {
      console.log("An unexpected error occured", err);
      return [];
    }
  }

  public async GetAllVectorStores(): Promise<IVectorStore[]> {
    try {
      const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/Organizations/${AppConfigGlobal.CurrentOrganizationId}/vector-stores`;
      return await this.PerformGet<IVectorStore[]>(requestUrl);
    } catch (err: any) {
      console.log("An unexpected error occured", err);
      return [];
    }
  }

  public async UploadFiles(uploadFiles: IUploadFile[]): Promise<boolean> {
    const data = { files: uploadFiles };
    const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/Organizations/${AppConfigGlobal.CurrentOrganizationId}/vectors/files/new`;
    return await this.PerformPost<boolean>(requestUrl, data);
  }

  public async UpdateStore(
    storeName: string,
    storeId: number
  ): Promise<boolean> {
    const data = { name: storeName };
    const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/Organizations/${AppConfigGlobal.CurrentOrganizationId}/vectors/${storeId}`;
    return await this.PerformPost<boolean>(requestUrl, data);
  }

  public async GetStoreDetailById(vectorId: number): Promise<IVector> {
    try {
      const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/Organizations/${AppConfigGlobal.CurrentOrganizationId}/vectors/details?vectorStoreId=${vectorId}`;
      return await this.PerformGet<IVector>(requestUrl);
    } catch (err: any) {
      console.log("An unexpected error occured", err);
      return err;
    }
  }

  public async CreateStore(): Promise<number> {
    const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/Organizations/${AppConfigGlobal.CurrentOrganizationId}/vectors/new`;
    return await this.PerformPost<number>(requestUrl, {});
  }

  public async GetVectorStoreUsage(): Promise<IVectorStoreUsageModel> {
    const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/Organizations/${AppConfigGlobal.CurrentOrganizationId}/vectors/store-usage`;
    return await this.PerformGet<IVectorStoreUsageModel>(requestUrl);
  }
}
