<template>
    <section class="flex overflow-hidden flex-col p-5 bg-slate-900 h-[40%] w-full rounded-[40px]">
      <h2 class="flex gap-2.5 items-start self-start text-xl font-bold text-white">
        Total Usage
      </h2>
      <div class="flex flex-col mt-5 w-full">
        <div class="flex gap-3 items-start w-full justify-between self-start font-medium">
          <div class="flex flex-col justify-center">
            <h3 class="gap-1.5 self-stretch text-sm text-white">
                <span class="content-[''] bg-lime-300 inline-block w-2 h-2 lime rounded-full mr-2"></span>
                <span>Neurons (30%)</span>
            </h3>
            <p class="gap-2.5 self-start mt-1 text-xs text-slate-300 pl-4">
                <span>CHF 36.00</span>
            </p>
          </div>
          <div class="flex flex-col justify-center">
              <h3 class="gap-1.5 self-stretch text-sm text-white">
                  <span class="content-[''] bg-blue-500 inline-block w-2 h-2 lime rounded-full mr-2"></span>
                  <span>Synapses (70%)</span>
              </h3>
            <p class="gap-2.5 self-start mt-1 text-xs text-slate-300 pl-4">
              CHF 84.00
            </p>
          </div>
        </div>
        <div class="flex gap-2.5 items-start mt-3 w-full">
          <div class="flex overflow-hidden flex-1 shrink justify-center w-full rounded-2xl basis-0 min-h-[15px] min-w-[200px]">
            <div class="flex flex-1 shrink bg-lime-300 basis-0 h-[15px] w-[106px]" role="progressbar" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100" aria-label="Neurons usage"></div>
            <div class="flex shrink-0 bg-blue-500 h-[15px] w-[100px]" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="50" aria-label="Synapses usage"></div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script setup lang="ts">
  </script>

  <style scoped lang="scss">

  </style>