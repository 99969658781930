<template>
  <div
    class="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-[#031525c4] backdrop-blur-[4px] z-[1000] overflow-auto scroll-width"
    :style="{  left: props.left || '',  width: props.outerWidth || '' , height: props.height}"
    @click="clickOutside"
  >
    <div
      class="bg-[#031525] rounded-[40px] shadow-[0_0_10px_2px_#ffffff40] w-auto min-w-[300px] max-w-[90%] p-6 relative m-auto"
      :class="innerWidth ? '' : 'w-auto'"
      :style="{ width: innerWidth || 'auto' }"
      @click.stop
    >
      <header class="flex justify-between items-center mb-6">
        <h2
          class="text-[29px] leading-[35px] font-semibold m-0"
          :class="headerClass"
        >
          {{ modalTitle }}
        </h2>
        <button
          class="bg-none border-none text-[29px] font-normal cursor-pointer text-white p-0"
          @click="closeModal"
        >
          ×
        </button>
      </header>
      <div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  modalTitle: string;
  headerClass: string;
  disableClickOutside?: boolean;
  outerWidth?: string; 
  innerWidth?: string;  
  left?: string;
  height?: string;
}>();
const emits = defineEmits(["close"]);

const closeModal = () => {
  emits("close");
};

const clickOutside = () => {
  if (!props.disableClickOutside) {
    closeModal();
  }
};
</script>
