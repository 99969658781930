<template>
    <div class="flex pt-5">
        <span class="m-auto text-gray-500 text-xl font-semibold">
            Coming soon
        </span>
    </div>
</template>

<script setup lang="ts">

</script>

<style lang="scss">

</style>