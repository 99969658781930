<template>
  <section class="request-connector" v-if="!isRequestSend">
    <header class="request-header">
      <h1 class="h4">Request New Connector</h1>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/601a5267783be676e315b70bb1f816c6b149d6dc4ee63f4ce4c0f4e1ef49ef22?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51"
        class="request-icon cursor-pointer"
        alt="Request connector icon"
        @click="closeView"
      />
    </header>
    <main class="request-form-container">
      <form @submit.prevent="handleSubmit" class="request-form">
        <div class="form-group">
          <label for="connectorName" class="bodySmall form-label"
            >Enter connector name*</label
          >
          <input
            id="connectorName"
            v-model="connectorName"
            type="text"
            class="form-input"
            required
            aria-required="true"
          />
        </div>
        <div class="form-group">
          <label for="description" class="bodySmall form-label"
            >Description</label
          >
          <textarea
            id="description"
            v-model="description"
            class="paragraph form-textarea"
            placeholder="Type here"
          ></textarea>
        </div>
        <div class="form-actions">
          <button type="button" class="btn btn-outline" @click="closeView">
            Cancel
          </button>
          <button type="submit" class="btn btn-primary">Send</button>
        </div>
      </form>
    </main>
  </section>
  <NewConnectorRequestSubmit v-else @closeView="closeView" />
</template>

<script setup lang="ts">
import { ref } from "vue";
import NewConnectorRequestSubmit from "./NewConnectorRequestSubmit.vue";
import { HubService } from "@/Services/HubService";
import AppConfigGlobal from "@/core/config/uiSettings";

const emits = defineEmits(["closeView"]);

const connectorName = ref("");
const description = ref("");
const isRequestSend = ref(false);

const handleSubmit = async () => {
  const hubService = new HubService();
  const modifiedDescription = `${description.value}\n\nSubmitted by ${AppConfigGlobal.UserName}`;
  console.log("saagdgd", modifiedDescription);
  try {
    const res = await hubService.CreateclickUpTask(
      connectorName.value,
      modifiedDescription
    );

    if (!res) {
      isRequestSend.value = false;
    }
    if (res) {
      isRequestSend.value = true;
    }
  } catch (error) {
    console.log();
  }
  // Handle form submission
  //   console.log("Form submitted", {
  //     connectorName: connectorName.value,
  //     description: description.value,
  //   });
};

const handleCancel = () => {
  // Handle cancel action
  console.log("Form cancelled");
};

const closeView = () => {
  emits("closeView");
};
</script>

<style scoped>
.request-connector {
  display: flex;
  height: auto;
  /* max-height: 90vh; */
  /* max-width: 95vw; */
  width: 888px;
  flex-direction: column;
  overflow: hidden;
  padding: 16px;
  background-color: rgba(3, 21, 37, 1);
  border-radius: 15px;
}

.request-header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;
  color: #fff;
  font: 600 29px Inter, sans-serif;
}

.request-icon {
  aspect-ratio: 1;
  object-fit: contain;
  width: 32px;
}

.request-icon:hover {
  cursor: pointer;
}

.request-form-container {
  margin-top: 24px;
  width: 100%;
}

.request-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-label {
  color: var(--Text-Tetriary, #cbd6e3);
  margin-bottom: 6px;
}

.form-input,
.form-textarea {
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: var(--Background-secondary, #071a2b);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  padding: 8px 12px;
  color: #fff;
}

.form-textarea {
  min-height: 94px;
  resize: vertical;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  flex-wrap: wrap;
}

.btn-cancel {
  color: var(--Button, #7ba8ef);
  border: 1px solid #7ba8ef;
  background-color: transparent;
  width: 100px;
  height: 40px;
}

.btn-send {
  background-color: #7ba8ef;
  color: var(--Background-primary, #031525);
  border: none;
  width: 100px;
  height: 40px;
}

.btn:hover {
  opacity: 0.9;
}

@media (max-width: 991px) {
  .request-header,
  .request-form-container,
  .form-group,
  .form-input,
  .form-textarea {
    max-width: 100%;
  }

  .form-actions {
    justify-content: center;
  }
}
</style>
