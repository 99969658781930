// src/components/freddycomponents/eventBus.ts
import { reactive } from 'vue';

interface Events {
  [eventName: string]: Array<(data: unknown) => void>;
}

export const eventBus = reactive({
  events: {} as Events, // Object to hold event listeners
  
  // Method to emit events
  emit(eventName: string, data: unknown): void {
    if (!this.events[eventName]) return;
    this.events[eventName].forEach(callback => callback(data));
  },

  // Method to listen for events
  on(eventName: string, callback: (data: unknown) => void): void {
    if (!this.events[eventName]) {
      this.events[eventName] = [];
    }
    this.events[eventName].push(callback);
  },

  // Method to remove event listeners
  off(eventName: string, callback: (data: unknown) => void): void {
    if (!this.events[eventName]) return;

    this.events[eventName] = this.events[eventName].filter(
      (cb) => cb !== callback
    );

    // If no listeners left, remove the event
    if (this.events[eventName].length === 0) {
      delete this.events[eventName];
    }
  },
});
