<template>
    <div class="icon-git-hub">
        <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_11450_17068)">
                <rect x="2.33203" y="1" width="52" height="52" rx="8" fill="white" shape-rendering="crispEdges" />
                <rect x="2.83203" y="1.5" width="51" height="51" rx="7.5" stroke="#EAECF0" shape-rendering="crispEdges" />
                <path d="M28.332 6C16.7295 6 7.33203 15.3975 7.33203 27C7.33203 36.2925 13.3433 44.1413 21.6908 46.9238C22.7408 47.1075 23.1345 46.4775 23.1345 45.9263C23.1345 45.4275 23.1083 43.7738 23.1083 42.015C17.832 42.9863 16.467 40.7287 16.047 39.5475C15.8108 38.9437 14.787 37.08 13.8945 36.5812C13.1595 36.1875 12.1095 35.2162 13.8683 35.19C15.522 35.1637 16.7033 36.7125 17.097 37.3425C18.987 40.5187 22.0058 39.6263 23.2133 39.075C23.397 37.71 23.9483 36.7913 24.552 36.2662C19.8795 35.7412 14.997 33.93 14.997 25.8975C14.997 23.6138 15.8108 21.7237 17.1495 20.2537C16.9395 19.7287 16.2045 17.5763 17.3595 14.6888C17.3595 14.6888 19.1183 14.1375 23.1345 16.8413C24.8145 16.3688 26.5995 16.1325 28.3845 16.1325C30.1695 16.1325 31.9545 16.3688 33.6345 16.8413C37.6508 14.1112 39.4095 14.6888 39.4095 14.6888C40.5645 17.5763 39.8295 19.7287 39.6195 20.2537C40.9583 21.7237 41.772 23.5875 41.772 25.8975C41.772 33.9562 36.8633 35.7412 32.1908 36.2662C32.952 36.9225 33.6083 38.1825 33.6083 40.1512C33.6083 42.96 33.582 45.2175 33.582 45.9263C33.582 46.4775 33.9758 47.1338 35.0258 46.9238C39.1947 45.5164 42.8172 42.8371 45.3836 39.263C47.95 35.6889 49.3309 31.4 49.332 27C49.332 15.3975 39.9345 6 28.332 6Z" fill="black" />
            </g>
            <defs>
                <filter id="filter0_d_11450_17068" x="0.332031" y="0" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11450_17068" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_11450_17068" result="shape" />
                </filter>
            </defs>
        </svg>
    </div>
</template>

<script setup lang="ts">
</script>

<style>
    .icon-git-hub {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        color: rgb(177, 168, 168);
    }
</style>