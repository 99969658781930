<template>
  <section class="vector-store-input">
    <div class="input-container">
      <label for="vectorStore" class="bodySmall input-label"
        >Vector Stores</label
      >
      <div class="input-wrapper" @click="openModel">
        <div class="input-list">
          <article class="tag" v-for="(option, index) in selectedOptions">
            <p class="h7 tag-content">{{ option.vectorStoreName }}</p>
            <button class="tag-close" aria-label="Remove tag">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/4dd548027470f369bf58a0a5ab4a6e656d032767e0826fb5b54b673e56acf4bb?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51"
                alt=""
                class="tag-close-icon"
                @click.once="removeOption(index)"
              />
            </button>
          </article>
        </div>
        <!--<div class="input-content">{{selectedOptions.vectorStoreName}}</div>-->
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/bcc2c5b1dff47c53168106f455b43967e33467ca4f3f3d07a598249747a8ea1c?placeholderIfAbsent=true&apiKey=fd41f46e3770495cb83a3d2783a50c51"
          alt=""
          class="input-iconinput-icon"
          @click="openModel"
        />
      </div>
    </div>
    <p class="bodySmall input-hint">
      Select the vector store to which you want to add the knowledge.
    </p>
  </section>
  <section v-if="isModelOpen" class="dropdown-menu">
    <ul class="h7 menu-items">
      <li
        class="menu-item"
        v-for="(option, index) in options"
        :key="index"
        @click="onOptionSelection(option)"
      >
        <a tabindex="0" class="menu-link">{{ option.vectorStoreName }}</a>
      </li>
    </ul>
    <!--<nav class="scroll-bar">
            <div class="scroll-area">
                <div class="scroll-thumb"></div>
            </div>
        </nav>-->
  </section>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import type { IVectorStoreAssistant } from "@/Models/IConnector";

const emits = defineEmits(["onOptionChange"]);

const props = defineProps({
  options: {
    type: Array<IVectorStoreAssistant>,
    default: [],
    required: true,
  },
});

const selectedOptions = ref<IVectorStoreAssistant[]>([]);
const lastChangedOption = ref<IVectorStoreAssistant>(
  {} as IVectorStoreAssistant
);
const isModelOpen = ref(false);

const openModel = (event: MouseEvent) => {
  if (event.target === event.currentTarget) {
    isModelOpen.value = !isModelOpen.value;
  }
};

const onOptionSelection = (option: IVectorStoreAssistant) => {
  isModelOpen.value = false;
  if (selectedOptions.value.includes(option)) {
    return;
  }
  selectedOptions.value.push(option);
  lastChangedOption.value = option;
  emits("onOptionChange", lastChangedOption.value);
};

const removeOption = (index: number) => {
  lastChangedOption.value = selectedOptions.value.splice(index, 1)[0];
  emits("onOptionChange", lastChangedOption.value);
};

onMounted(() => {
  console.log(props.options);
});
</script>

<style scoped lang="scss">
@import "@/assets/mainstyle";

.vector-store-input {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 100%;
  height: 87px;
}

.input-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
}

.input-label {
  color: var(--Text-Tetriary, #cbd6e3);
}

.input-wrapper {
  align-items: center;
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--White-semi-transparent-20, rgba(255, 255, 255, 0.2));
  background: var(--Background-secondary, #071a2b);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  display: flex;
  margin-top: 6px;
  width: 100%;
  padding: var(--spacing-md, 8px) var(--spacing-lg, 12px);
  gap: var(--spacing-md, 8px);
  justify-content: space-between;
  flex-wrap: wrap;
  cursor: pointer;
}

.input-list {
  display: flex;
  flex-direction: row;
  gap: 5px;
  max-width: 95%;
  overflow-x: hidden;
}

.input-content {
  align-self: stretch;
  display: flex;
  min-width: 240px;
  width: 1053px;
  height: 24px;
  gap: 8px;
  flex: 1;
  flex-basis: 0%;
  margin: auto 0;
}

.input-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  align-self: stretch;
  margin: auto 0;
  cursor: pointer;
  align-self: flex-end;
}

.input-hint {
  color: rgba(203, 214, 227, 1);
  /*margin-top: 6px;*/
}

@media (max-width: 991px) {
  .input-container,
  .input-wrapper,
  .input-hint {
    max-width: 100%;
  }
}

.dropdown-menu {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background-color: #071a2b;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  position: absolute;
  overflow: hidden;
  width: calc(100% - 40px);
  top: 120px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 10;
}

.menu-items {
  display: flex;
  background-color: #071a2b;
  flex-direction: column;
  width: 100%;
  color: #cbd6e3;
  padding: 4px 0;
}

.menu-item {
  min-height: 46px;
  display: flex;
  align-items: center;
  padding: 3px 6px;
}

.menu-link {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 8px;
  border-radius: 6px;
  text-decoration: none;
  color: inherit;
}

.scroll-bar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 16px;
  display: flex;
  align-items: flex-start;
  padding: 4px;
}

.scroll-area {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 156px;
}

.scroll-thumb {
  height: 156px;
  border-radius: 9999px;
  background-color: #cbd6e3;
}

@media (max-width: 991px) {
  .menu-items,
  .menu-link,
  .scroll-bar {
    max-width: 100%;
    white-space: initial;
  }

  .scroll-bar {
    display: none;
  }
}

.tag {
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 2px 0;
  border: 1px solid rgba(17, 56, 91, 1);
  border-radius: 6px;
  background-color: rgba(17, 56, 91, 1);
}

.tag-content {
  margin: auto 0;
  color: #fff;
  text-align: center;
  white-space: nowrap;
}

.tag-close {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  margin: auto 0;
  padding: 2px;
  border: none;
  border-radius: 3px;
  background: none;
  cursor: pointer;
}

.tag-close-icon {
  width: 12px;
  height: 12px;
  object-fit: contain;
}
</style>
