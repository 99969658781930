<template>
  <aside v-if="fileDetails">
    <div class="FileDetailsContainer">
      <span class="FileDetailsContainer__Label">File</span>
      <h2 class="truncate-text">{{ fileDetails.fileName }}</h2>
    </div>

    <div class="FileActionContainer">
      <button @click="openDeleteModal">
        <IconDelete class="FileActionContainer__DeleteIcon" />
      </button>
    </div>

    <div class="FileDetailsContainer__Details">
      <div class="info-grid">
        <!-- File ID -->
        <div>
          <IconInfoRounded class="InfoIcons" />
        </div>
        <div class="Title">File ID</div>
        <div class="Value">{{ fileDetails.fileId }}</div>

        <!-- Status -->
        <!-- <div>
          <IconHollowCircle class="InfoIcons" />
        </div>
        <div class="Title">Status</div>
        <div class="Value" :class="`StatusLabel--${fileDetails.status}`">
          {{ fileDetails.status }}
        </div> -->

        <!-- Purpose -->
        <div>
          <IconPurpose class="InfoIcons" />
        </div>
        <div class="Title">Purpose</div>
        <div class="Value">{{ fileDetails.purpose }}</div>

        <!-- Size -->
        <div>
          <IconSize class="InfoIcons" />
        </div>
        <div class="Title">Size</div>
        <div class="Value">{{ fileDetails.size }}</div>

        <!-- Created at -->
        <div>
          <IconClock class="InfoIcons" />
        </div>
        <div class="Title">Created at</div>
        <div class="Value">
          {{ readableDateFormat(fileDetails.createdOn) }}
        </div>

        <!-- Save in -->
        <div>
          <IconLocationWithCircleIn class="InfoIcons" />
        </div>
        <div v-if="fileDetails.vectorStoreName" class="Title">Save in</div>
        <div class="Value">{{ fileDetails.vectorStoreName }}</div>
      </div>
    </div>
  </aside>
  <Modal
    v-if="fileDetails"
    :isVisible="isModalDelete"
    @close="closeDeleteModal"
    :largeModel="false"
  >
    <template v-slot:body>
      <section class="flex items-center justify-start">
        <div
          class="flex flex-col items-center w-[371px] self-stretch mx-auto my-0 p-8 rounded-[40px] bg-[#031525] border-white/10"
        >
          <header class="flex flex-col justify-start w-full">
            <img
              loading="lazy"
              src="../../assets/delete-orangebg.svg"
              alt=""
              class="w-[32px] aspect-square rounded-full object-contain object-center"
            />
            <div class="flex flex-col justify-start w-full mt-5">
              <h2 class="w-full text-[20px] font-bold text-white m-0">
                Confirm deletion of file
              </h2>
              <p
                class="flex-1 w-full mt-2 text-[12px] font-medium text-[#cbd6e3]"
              >
                Are you sure you would like to delete the file
                {{ fileDetails.fileName }}
              </p>
            </div>
          </header>
          <footer
            class="flex items-center justify-between w-full mt-5 gap-2.5 text-[17px] font-medium whitespace-nowrap text-center"
          >
            <button
              class="btn text-white w-6/12 h-10 !border-[#7BA8EF] !text-[#7BA8EF] focus-visible:outline-none items-center justify-center"
              style="border: 1px solid"
              @click="closeDeleteModal"
            >
              Cancel
            </button>
            <button
              class="btn btn-danger w-6/12 h-10 focus-visible:outline-none !text-white items-center justify-center"
              @click="removeSelectedFile"
            >
              <span>Delete</span>
            </button>
          </footer>
        </div>
      </section>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from "vue";
import { readableDateFormat } from "@/helpers";
import Modal from "../common/CustomModelBox.vue";
import {
  IconHollowCircle,
  IconInfoRounded,
  IconDelete,
  IconPurpose,
  IconSize,
  IconClock,
  IconLocationWithCircleIn,
} from "../icons";
import type { IFileDetails } from "@/Models";
import { KnowledgeService } from "@/Services/KnowledgeService";

const emit = defineEmits(["fileDeletion"]);
const knowledgeService = new KnowledgeService();
const fileDetails = ref<IFileDetails>();
const isDeleted = ref<boolean>();
const isModalDelete = ref<boolean>(false);

const props = defineProps<{
  chosenFileId: number | null;
}>();

onMounted(async () => {
  await getFileDetails();
  console.log(fileDetails.value);
});

const getFileDetails = async () => {
  fileDetails.value = await knowledgeService.GetFileDetailById(
    props.chosenFileId
  );
};

const removeSelectedFile = async () => {
  isDeleted.value = await knowledgeService.RemoveFile(props.chosenFileId);
  if (isDeleted.value) {
    isModalDelete.value = false;
    emit("fileDeletion");
  }
};

const openDeleteModal = () => {
  isModalDelete.value = true;
  console.log("OpenModal", isModalDelete.value);
};

const closeDeleteModal = () => {
  isModalDelete.value = false;
};

watch(
  () => props.chosenFileId,
  async () => {
    console.log("debugger hit");
    await getFileDetails();
  }
);
</script>

<style lang="scss">
.FileActionContainer__DeleteIcon {
  stroke: #f14d4d;
}

.FileDetailsContainer {
  border-bottom: 1px solid #ffffff17;
  padding-bottom: 20px;

  h2 {
    width: 60%;
    font-size: 20px;
    font-weight: 700;
  }

  &__Label {
    font-size: 14px;
    font-weight: 600;
    color: #ffffff66;
  }

  &__Details {
    margin-top: 14px;
    font-size: 12px;
    font-weight: 500;

    .InfoIcons {
      width: 15px;
      height: 15px;
      color: #ffffff66;
      vertical-align: middle;
    }

    .Title {
      color: #ffffff66;
    }

    .info-grid {
      display: grid;
      grid-template-columns: auto 1fr 5fr;
      /* Icon, Title, Value */
      row-gap: 12px;
      /* Space between rows */
      column-gap: 16px;
      /* Space between columns (first and second column) */
      align-items: center;
      /* Vertically align items in each row */
    }
  }
}
</style>
