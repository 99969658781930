export enum DatalistTypeEnum {
  data = "data",
  vectorStorage = "vector-storage",
}

export enum FileListSortButtonEnum {
  date = "date",
  size = "size",
  name = "name",
}

export enum ListAscOrDescEnum {
  asc = "asc",
  desc = "desc",
}

export enum FilterEnum {
  skip = 0,
  take = 10,
}