<template>
    <div class="icon-robot-screen">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.2554 3.59422H7.74542C6.64447 3.59422 5.5886 4.03151 4.81004 4.80992C4.03148 5.58833 3.59397 6.6441 3.59375 7.74505V12.2551C3.59375 13.3561 4.03116 14.4121 4.80975 15.1907C5.58833 15.9693 6.64433 16.4067 7.74542 16.4067H12.2554C13.3565 16.4067 14.4125 15.9693 15.1911 15.1907C15.9697 14.4121 16.4071 13.3561 16.4071 12.2551V7.74505C16.4071 6.64396 15.9697 5.58797 15.1911 4.80938C14.4125 4.03079 13.3565 3.59338 12.2554 3.59338V3.59422Z" stroke="white" stroke-width="1.5" />
            <path d="M16.339 12.99H17.6881C17.9147 12.99 18.1319 12.9 18.2921 12.7399C18.4523 12.5797 18.5423 12.3624 18.5423 12.1359V7.86503C18.5423 7.63849 18.4523 7.42123 18.2921 7.26104C18.1319 7.10086 17.9147 7.01086 17.6881 7.01086H16.3381M3.66315 12.99H2.31315C2.20098 12.99 2.08991 12.9679 1.98628 12.925C1.88264 12.8821 1.78848 12.8192 1.70916 12.7399C1.62985 12.6605 1.56693 12.5664 1.524 12.4627C1.48108 12.3591 1.45898 12.248 1.45898 12.1359V7.86503C1.45898 7.63849 1.54898 7.42123 1.70916 7.26104C1.86935 7.10086 2.08661 7.01086 2.31315 7.01086H3.66315" stroke="white" stroke-width="1.5" />
            <path d="M2.30469 7.0109V3.59424M17.688 7.0109L17.6797 3.59424" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.2558 6.42004H8.74409C8.1276 6.42026 7.53645 6.66532 7.1006 7.10132C6.66476 7.53731 6.41992 8.12856 6.41992 8.74504V11.2559C6.42014 11.8724 6.66519 12.4635 7.10119 12.8994C7.53719 13.3352 8.12844 13.58 8.74492 13.58H11.2558C11.8722 13.5798 12.4634 13.3348 12.8992 12.8988C13.3351 12.4628 13.5799 11.8715 13.5799 11.255V8.74421C13.5797 8.12773 13.3346 7.53657 12.8987 7.10073C12.4627 6.66488 11.8714 6.42004 11.2549 6.42004H11.2558Z" stroke="white" stroke-width="1.5" />
        </svg>
    </div>
</template>

<script setup lang="ts">
</script>

<style>
    .icon-robot-screen {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        color: rgb(177, 168, 168);
    }

        .icon-robot-screen:hover {
            color: white;
            cursor: pointer;
        }
</style>