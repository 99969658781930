<template>
  <label
    class="relative inline-flex items-center cursor-pointer w-4 h-4 rounded-[4px] transition-colors duration-200"
    :class="isChecked ? 'bg-white border-transparent' : 'bg-transparent'"
  >
    <!-- Bind the checkbox to `isChecked` for reactivity -->
    <input
      type="checkbox"
      v-model="isChecked"
      @change="handleCheckboxClick"
      class="sr-only peer"
    />
    <div>
      <svg
        v-if="isChecked"
        class="w-4 h-4"
        viewBox="0 0 12 12"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 3L4.5 8.5L2 6"
          stroke="#031525"
          stroke-width="1.6666"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </label>
</template>

<script setup>
import { ref, watch } from "vue";
const props = defineProps({
  isChecked: {
    type: Boolean,
    default: false,
  },
});
const isChecked = ref(props.isChecked);

watch(
  () => props.isChecked,
  (newValue) => {
    isChecked.value = newValue;
  }
);

const emit = defineEmits(["update:isChecked"]);

const handleCheckboxClick = () => {
  emit("update:isChecked", isChecked.value);
};
</script>
