<template>
  <div class="freddy-container h-full w-full flex flex-col">
    <div class="freddy-container head">
      <!--<div class="freddy-container head title">
              <span class="freddy-container head title heading1">Freddy</span>
            </div>-->
<!--      <ul
        class="freddy-container head nav-bar flex gap-2 border-b text-sm font-semibold border-[&#45;&#45;grey-border-line]"
        v-show="showNavBar"
      >
        <li
          class="text-body-bold cursor-pointer"
          v-for="(tab, index) in tabList"
          :key="index"
          :class="{ 'active-tab': activeTab === tab }"
          v-show="tab['show']"
          @click="changeTab(tab)"
        >
          {{ tab.title }}
        </li>
      </ul>-->
      <div v-if="showNavBar" class="flex flex-row mt-[1.25rem] border-white border-b border-opacity-10 ">
        <div v-for="(tab, index) in tabList" :key="index" class="w-fit h-fit mr-6 cursor-pointer" v-show="tab['show']" @click="changeTab(tab)">
          <span>{{ tab.title }}</span>
          <div class="h-[3px] w-full" :class="{ 'bg-white rounded-tl-full rounded-tr-full': activeTab === tab }"/>
        </div>
      </div>
      <!-- <div class="border-white border-b -mt-5 opacity-10"></div> -->
    </div>
    <div class="freddy-container h-[calc(100vh-130px)] main overflow-y-auto">
      <div>
        <component :is="activeTab['component']"></component>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted, markRaw, onBeforeUnmount } from "vue";

import HubOverView from "./HubOverView.vue";
import HubUsageTab from "./usage/HubUsageTab.vue";
import HubKnowledge from "./HubKnowledge.vue";
import HubApi from "./HubApi.vue";
import HubConnectorsTab from "./connectors/HubConnectorsTab.vue";
import HubSettings from "./HubSettings.vue";
import HubAssistantsTab from "./HubAssistantsTab.vue";
import { eventBus } from "../common/eventBus";
import type { IComponentMap } from "@/Models/IComponentMap";

const tabList = ref<IComponentMap[]>([
  {
    title: "Overview",
    component: markRaw(HubOverView),
    show: false,
    mainComponent: {},
  },
  {
    title: "Usage",
    component: markRaw(HubUsageTab),
    show: true,
    mainComponent: {},
  },
  {
    title: "Assistants",
    component: markRaw(HubAssistantsTab),
    show: true,
    mainComponent: {},
  },
  {
    title: "Knowledge",
    component: markRaw(HubKnowledge),
    show: true,
    mainComponent: {},
  },
  {
    title: "Api",
    component: markRaw(HubApi),
    show: true,
    mainComponent: {},
  },
  {
    title: "Connectors",
    component: markRaw(HubConnectorsTab),
    show: true,
    mainComponent: {},
  },
  {
    title: "Settings",
    component: markRaw(HubSettings),
    show: false,
    mainComponent: {},
  },
]);

const activeTab = ref<IComponentMap>(tabList.value[1]);
const showNavBar = ref(true);

const changeTab = (selectedTab: IComponentMap) => {
  activeTab.value = selectedTab;
};

const handleShowNavBar = () => {
  showNavBar.value = true;
};

const handleHideNavBar = () => {
  showNavBar.value = false;
};

onMounted(() => {
  eventBus.on("showNavBar", handleShowNavBar);
  eventBus.on("hideNavBar", handleHideNavBar);
});

onBeforeUnmount(() => {
  eventBus.off("someEvent", handleShowNavBar);
  eventBus.off("someEvent", handleHideNavBar);
});
</script>
<!--
<style scoped lang="scss">
@import "@/assets/mainstyle";

    $nav-font: 14px;
    $head-font: 24px;
    $light-border: rgba(255, 255, 255, 0.09);
    $highlight-border: #7ba8ef;

    .freddy-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        gap: 12px;

  .text-body-bold {
    padding: 14px 4px;
    margin: 0;
  }

  &.head {
    width: 100%;
    height: 46px;
    flex-direction: column;
    gap: 10px;
    padding: 0 20px;
    /*margin-top: -34px;*/

            &.title {
                font-size: $head-font;
                margin-top: 0px;

                .heading1 {
                    color: #fff;
                    font-family: Inter;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }

            &.nav-bar {
                font-size: $nav-font;
                gap: 14px;
                flex-direction: row;
                list-style: none;
                padding-left: 0;
                border-bottom: 1px solid #142636;
                overflow: auto hidden;
                scrollbar-width: thin;
                height: 46px;

      li:hover {
        cursor: pointer;
      }
    }
  }

        .main {
            flex-direction: column;
            //height: calc(100% - 100px);
            height: calc(100% - 56px);
            padding: 0 20px;
            //margin-top: -30px;

            @media (max-width: 1064px) {
                //max-height: calc(100% - 130px);
            }
        }
    }

    @media (max-width: 1024px) {
        .title {
            margin-left: 8px;
        }
    }
</style> -->
