<!-- AssistantItem.vue -->
<template>
  <div
    :class="[
      'rounded-2xl mb-5 p-3 cursor-pointer',
      { 'bg-[var(--color-submenu-bg)]': isSelected },
    ]"
    @click="handleClick"
  >
    <div class="flex justify-between items-center">
      <p class="font-medium text-[1.063rem] text-white">{{ item.name }}</p>
      <p class="text-[0.625rem] font-medium text-lightBlue">
        <!--{{ item.actualDate }}-->
      </p>
    </div>
    <div class="flex justify-between items-center">
      <p class="text-[0.875rem] font-medium text-lightBlue mt-2">
        {{ item.id }}
      </p>
      <img
        v-if="item.isDefault"
        src="@/assets/lock.svg"
        alt=""
        class="cursor-pointer"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import type { PropType } from "vue";

import type { IAssistant } from "../../Models/IAssistant";

const props = defineProps({
  item: {
    type: Object as PropType<IAssistant>,
    required: true,
  },
  isSelected: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(["select"]);

const handleClick = () => {
  emits("select", props.item.id);
};

onMounted(() => {
  console.log(props.item);
});
</script>
