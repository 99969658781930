import type {
  IStreamlineAutomationListingResponse,
  IStreamlineAutomationsQuery,
  IStreamlineAutomationUpdateRequest,
} from "@/Models/IStreamline";
import Core from "./Core";
import AppConfigGlobal from "@/core/config/uiSettings";
import type { IStreamlineUploadFilesRequest } from "@/Models/IStreamlineUploadFilesRequest ";
import axios, { type AxiosRequestConfig } from "axios";
import { ref } from "vue";

export class streamlineService extends Core {
  public progress = ref(2);
  public async getAllAutomations(
    query: IStreamlineAutomationsQuery
  ): Promise<IStreamlineAutomationListingResponse[]> {
    const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/streamline/files`;
    return await this.PerformPost<IStreamlineAutomationListingResponse[]>(
      requestUrl,
      query
    );
  }

  // To Delete automation by ID
  public async deleteAutomationById(
    id: number,
    name: string
  ): Promise<boolean> {
    const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/streamline/files/${id}/${name}/delete`;
    return await this.PerformDelete<boolean>(requestUrl);
  }

  public async uploadFile(
    requestData: IStreamlineUploadFilesRequest
  ): Promise<Number> {
    const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/streamline/upload`;
    return await this.PerformPost<Number>(requestUrl, requestData);
  }

  public async updateAutomationById(
    updateAutomationRequest: IStreamlineAutomationUpdateRequest
  ) {
    const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/streamline/file/update`;
    return await this.PerformPost(requestUrl, updateAutomationRequest);
  }

  public async runStreamlineAutomation(
    id: number,
    signal?: AbortSignal
  ): Promise<string> {
    const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/streamline/run`;
    const data = {
      id: id,
      userId: AppConfigGlobal.UserId,
    };
    const config: AxiosRequestConfig = {
      headers: { "Content-Type": "application/json" },
      signal,
    };

    try {
      const res = await axios.post(requestUrl, data, {
        ...config,
        onUploadProgress: (event) => {
          if (event.total) {
            console.log(event, "up");
            this.progress.value = Math.round(
              (event.loaded / event.total) * 100
            );
          }
        },
        onDownloadProgress: (event) => {
          if (event.total) {
            console.log(event, "down");
            this.progress.value = Math.round(
              (event.loaded / event.total) * 100
            );
          }
        },
      });
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }

    return await this.PerformPost<string>(requestUrl, data, config);
  }

  public async getCount(): Promise<number> {
    const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/streamline/count/${AppConfigGlobal.CurrentOrganizationId}`;
    return await this.PerformGet<number>(requestUrl);
  }

  public async requestAutomation(
    requestData: IStreamlineUploadFilesRequest
  ): Promise<Number> {
    const requestUrl = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/streamline/request-automation`;
    return await this.PerformPost<Number>(requestUrl, requestData);
  }

  // File uploader with progress
  public async fileUploaderWithProgress(
    data: Record<string, any>
  ): Promise<number> {
    const url = `${AppConfigGlobal.FreddyAssistantApiUrl}/v1/streamline/upload`;

    try {
      const response = await axios.post(url, data, {
        onUploadProgress: (event) => {
          if (event.total) {
            this.progress.value = Math.round(
              (event.loaded / event.total) * 100
            );
          }
        },
        // For get operation
        // onDownloadProgress: (event) => {
        //   if (event.total) {
        //     this.progress.value = Math.round(
        //       (event.loaded / event.total) * 100
        //     );
        //   }
        // },
      });
      return response.data; // Return the response data
    } catch (error) {
      console.error("File upload failed:", error);
      throw error;
    }
  }
}
