<template>
  <article
    class="flex overflow-hidden flex-col mt-0 p-5 my-auto font-medium bg-slate-900 rounded-[40px] w-[244px] h-full"
  >
    <h2
      class="flex gap-2.5 items-start self-start text-xl font-bold text-white"
    >
      Feature Requests
    </h2>
    <div class="mt-5 w-[150px] h-[150px]">
      <svg viewBox="0 0 100 100" class="w-full h-full">
        <circle
          v-for="(segment, index) in pieSlices"
          :key="index"
          class="circle"
          cx="50"
          cy="50"
          r="40"
          fill="none"
          :stroke="segment.color"
          stroke-width="12"
          :stroke-dasharray="`${segment.dashArray}, 251.328`"
          :stroke-dashoffset="calculateOffset(index)"
          stroke-linecap="square"
          :style="{
            animationDelay: `${index * 0.2}s`,
            strokeDashoffset: calculateOffset(index),
          }"
        />
      </svg>
    </div>
    <ul class="flex flex-col mt-5 mb-5 w-full max-h-[150px] overflow-y-auto">
      <li
        v-for="(request, index) in featureRequests"
        :key="index"
        class="flex flex-col justify-center items-start mt-2 w-full"
      >
        <div class="flex items-center">
          <span
            class="content-[''] inline-block w-1.5 h-1.5 lime rounded-full mr-2"
            :class="featureRequests[index].class"
          ></span>
          <h3 class="gap-1.5 self-stretch text-sm text-white">
            {{ request.topic }}
          </h3>
        </div>
        <p class="gap-2.5 self-stretch pl-4 mt-1 text-xs text-slate-300">
          {{ request.count }} request{{ request.count !== 1 ? "s" : "" }}
        </p>
      </li>
    </ul>
    <button
      @click="toggleDetails"
      class="gap-1 self-stretch pt-2.5 mt-5 w-full text-xs text-center text-white border border-t-white"
    >
      Show details
    </button>
  </article>
</template>
<script lang="ts" setup>
import { ref, computed } from "vue";
import { scrollToElement } from "@/helpers";

interface FeatureRequest {
  topic: string;
  count: number;
  color: string;
  class: string;
}

interface PieSlice {
  color: string;
  dashArray: number;
  rotation: number;
}

// Reactive Variables
const total = ref(0);
const featureRequests = ref<FeatureRequest[]>([
  {
    topic: "Request topic 1",
    count: 9,
    color: "#33d4b9",
    class: "bg-[#7b5ad6]",
  },
  {
    topic: "Request topic 2",
    count: 5,
    color: "#18a38c",
    class: "bg-[#18a38c]",
  },
  {
    topic: "Request topic 3",
    count: 2,
    color: "#23665b",
    class: "bg-[#23665b]",
  },
  {
    topic: "Request topic 4",
    count: 1,
    color: "#7bd1c3",
    class: "bg-[#7bd1c3]",
  },
  {
    topic: "Request topic 4",
    count: 5,
    color: "#33665b",
    class: "bg-[#33665b]",
  },
  {
    topic: "Request topic 4",
    count: 6,
    color: "#13665b",
    class: "bg-[#13665b]",
  },
]);

// Toggle Details Function
const toggleDetails = () => {
  scrollToElement("feature-request");
};

// Compute Pie Slices
const pieSlices = computed<PieSlice[]>(() => {
  total.value = featureRequests.value.reduce(
    (sum, request) => sum + request.count,
    0
  );
  let currentAngle = 0;

  return featureRequests.value.map((request) => {
    const percentage = (request.count / total.value) * 100;
    const dashArray = (percentage * 251.328) / 100;
    const rotation = currentAngle; // Rotation for each slice, based on its starting angle
    currentAngle += percentage;

    return {
      color: request.color,
      dashArray,
      rotation,
    };
  });
});

// Calculate Offset
const calculateOffset = (index: number) => {
  const offset = pieSlices.value
    .slice(0, index)
    .reduce((sum, segment) => sum + segment.dashArray, 0);
  return -offset; // Negative offset to shift each slice properly
};
</script>

<style scoped>
@keyframes draw {
  from {
    stroke-dashoffset: 0; /* Starting from a full circle */
  }

  to {
    stroke-dashoffset: 251.328; /* Fully drawn */
  }
}

.circle {
  animation: draw 1s ease-out reverse;
}
</style>
