<template>
 

  <div class="MainContent__Container">
    <Loader v-if="isLoading" :isLoading="isLoading" />
    <div v-if="vectorOrFile === DatalistTypeEnum.data">
      <div class="MainContent__Container__Contents"
        :class="{ selectedItem: chosenFile && chosenFile.fileId === item.fileId }" v-for="(item, index) in listOfFiles"
        :key="index" @click="handleFileClick(item)">
        <span class="MainContent__Container__Contents--Title">{{
          item.fileName
        }}</span>
        <span v-if="item?.createdOn" class="MainContent__Container__Contents--Date">
          {{ formatDateToLocaleString(item.createdOn) }}
        </span>
        <span class="MainContent__Container__Contents--Size">
          {{ item.size }}

          <IconChevronRight />
        </span>
      </div>
    </div>

    <div class="MainContent__VectorListing" v-if="vectorOrFile === DatalistTypeEnum.vectorStorage">
      <div class="MainContent__VectorListing__Items" v-for="(item, index) in listOfVectorStores" :key="index"
        :class="{ selectedItem: chosenVector && chosenVector.vectorStoreId === item.vectorStoreId }"
        @click="handleVectorSelection(item)">
        <div class="MainContent__VectorListing__Items__VectorDetails">
          <h2>{{ item.vectorStoreName }}</h2>
          <span>
            {{ chosenVector && chosenVector.vectorStoreId === item.vectorStoreId ? item.size : null }}
          </span>
        </div>
        <span v-if="item?.createdOn" class="MainContent__VectorListing__Items--Date">
          {{ formatDateToLocaleString(item.createdOn) }}
        </span>
        <!-- <div>
        <span class="MainContent__Container__Contents--Title">{{
          item.fileName
        }}</span>

        <span class="MainContent__Container__Contents--Date">
          {{ formatDateToLocaleString(item.createdDate) }}
        </span>
      </div> -->

      </div>
    </div>

    <Pagination v-if="listOfFiles.length || listOfVectorStores.length"
      class="pt-[14px] pb-[18px] px-6 bottom-0 relative" :current-page="currentPage" :items-per-page="FilterEnum.take"
      :total-items="total" @update:current-page="handlePageChange" />
  </div>

  <Modal :isVisible="isModalSave" @close="closeSaveModal" :largeModel="false">
    <template v-slot:body>
      <section class="flex items-center justify-start">
        <div
          class="flex flex-col items-center w-[371px] self-stretch mx-auto my-0 p-8 rounded-[40px] bg-[#031525] border-white/10"
        >
          <header class="flex flex-col justify-start w-full">
            <img
              loading="lazy"
              src="../../assets/save-geen.svg"
              alt=""
              class="w-[32px] aspect-square rounded-full object-contain object-center"
            />
            <div class="flex flex-col justify-start w-full mt-5">
              <h2 class="w-full text-[20px] font-bold text-white m-0">
                File Upload
              </h2>
              <p
                class="flex-1 w-full mt-2 text-[12px] font-medium text-[#cbd6e3]"
              >
                File has been successfully uploaded in Vector Store.
              </p>
            </div>
          </header>
          <footer
            class="flex items-center justify-end w-full mt-5 gap-2.5 text-[17px] font-medium whitespace-nowrap text-center"
          >
            <button
              class="flex-1 w-full min-h-[40px] rounded-md overflow-hidden cursor-pointer text-[17px] font-medium text-buttonBlue bg-transparent border border-solid border-buttonBlue text-center"
              @click="closeSaveModal"
            >
              Close
            </button>
          </footer>
        </div>
      </section>
    </template>
  </Modal>

</template>

<script lang="ts" setup>
import { ref, watch, computed } from "vue";
import {
  DatalistTypeEnum,
  FileListSortButtonEnum,
  FilterEnum,
  ListAscOrDescEnum,
} from "@/enums";
import { formatDateToLocaleString } from "@/helpers";
import type {
  IVectorAndFileListingProps,
  IFileDetails,
  IVector,
  IRequestParams,
  IOraganizationFilesInfo
} from "@/Models";
import Pagination from "@/components/common/Pagination.vue";
import { KnowledgeService } from "@/Services/KnowledgeService";
import IconChevronRight from "@/components/icons/IconChevronRight.vue";
import Loader from "@/components/Loader.vue";
import { filter } from "lodash";
import Modal from '@/components/common/CustomModelBox.vue'
const props =  defineProps<IVectorAndFileListingProps & {
  fileUploadSuccess: boolean;
}>();

const isModalSave = ref(false);
const emit = defineEmits(["handleFileSelection", "handleVectorSelection"]);

const listOfFiles = ref<IOraganizationFilesInfo[]>([]);
const SearchFilter = ref<IRequestParams>();
const knowledgeService = new KnowledgeService();
const listOfVectorStores = ref<IVector[]>([]);
const currentPage = ref(1);
const total = ref(10);
const isLoading = ref<boolean>(false);


//const chosenFile = ref<IOraganizationFilesInfo | null>(null);

const handleFileClick = async (item: IOraganizationFilesInfo) => {
  //chosenFile.value = item;
  emit("handleFileSelection", item);
};
const handlePageChange = (page: number) => {
  currentPage.value = page;
  getAllFilesAndVectorStores();
};


const handleVectorSelection = (item: IVector): void => {
  // chosenFile.value = item;
  isLoading.value = false;
  emit("handleVectorSelection", item);
};

const getAllFilesAndVectorStores = async (): Promise<void> => {
  console.log(currentPage);
  listOfFiles.value = [];
  listOfVectorStores.value = [];
  total.value = 0;
  SearchFilter.value = {
    sort: props.filter?.sort ?? FileListSortButtonEnum.date,
    order: props.filter?.order ?? ListAscOrDescEnum.desc,
    skip: (currentPage.value - 1) * 10,
    take: FilterEnum.take,
    search: props.filter?.search ?? ""
  };
  if (props.vectorOrFile === DatalistTypeEnum.vectorStorage) {
    isLoading.value = true;
    try {
      const response = await knowledgeService.GetAllVectorStoresByOrganizationId(SearchFilter.value);
      listOfVectorStores.value = response.data;
      isLoading.value = false;
      total.value = response.totalCount;
    }
    catch (err) {
      listOfVectorStores.value = [];
      total.value = 0;
    }
  }
  else {
    isLoading.value = true;
    try {
      const response = await knowledgeService.GetAllFilesByOrganizationId(SearchFilter.value);
      isLoading.value = false;
      listOfFiles.value = response.data;
      total.value = response.totalCount;
    }
    catch (err) {
      listOfFiles.value = [];
      total.value = 0;
    }
  }
};

const closeSaveModal = () => {
  isModalSave.value = false;
};

const openSaveModal = () => {
  isModalSave.value = true;
}

watch([() => props.vectorOrFile, () => props.filter], () => {
  currentPage.value = 1;
  getAllFilesAndVectorStores();
});

watch([() => props.fileUploadSuccess], () => {
  openSaveModal();
  currentPage.value = 1;
  getAllFilesAndVectorStores();
});

</script>

<style lang="scss">
@import "@/assets/mainstyle";

.selectedItem {
  background: #11385b;
  border-radius: 4px;
}

// .MainContent__Container {
.MainContent {
  &__Container {
    position: relative;
    padding: 6px 32px 10px 0;
    border-top: 1px solid #ffffff12;
    border-right: 1px solid #ffffff12;
    width: 60%;

    &__Contents {
      display: flex;
      justify-content: space-between;
      // width: calc(56vw - 48px);
      font-size: 12px;
      padding: 12px 2px 6px 2px;
      border-bottom: 1px solid #ffffff12;
      cursor: pointer;

      &:hover {
        @extend .selectedItem;
        // Hover scale and wiggle
        // transform: scaleX(1.015);
        padding: 12px 16px 6px 16px;
      }

      &--Title {
        flex: 1;
        /* Allow the title to take up the remaining space */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        /* Truncate long file names with ellipsis */
        padding-right: 10px;
      }

      &--Date {
        flex: 1;
        color: #ffffff66;
        text-align: right;
      }

      &--Size {
        flex: 1;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        gap: 16px;
      }
    }
  }

  &__VectorListing {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__Items {
      display: flex;
      justify-content: space-between;
      padding: 12px;

      &__VectorDetails {
        h2 {
          font-size: 17px;
          font-weight: 500;
        }

        span {
          font-size: 14px;
          font-weight: 500;
          color: #cbd6e3;
        }
      }

      &--Date {
        font-size: 12px;
        color: #cbd6e3;
      }

      &:hover {
        background: #1d2e47;
        border-radius: 16px;
        cursor: pointer;
      }

      &.selectedItem {
        background: #1d2e47;
        border-radius: 16px;
      }
    }
  }

  &__pagination {
    bottom: 0;
    position: absolute;
  }
}
</style>
