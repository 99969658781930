<template>
  <div class="relative flex flex-row w-fit h-full">
    <div class="w-fit h-full bg-dropdownBackground rounded-[15px] overflow-hidden inline-flex flex-col justify-between items-center p-2.5">
      <div class="flex flex-row">
        <div class="flex flex-row gap-2.5 justify-start">
          <component :is="selectedItem.component"></component>
          <div class="h6Bold heading6">{{selectedItem.title}}</div>
        </div>
        <IconChevronDown @click="openOptions" />
      </div>
    </div>
    <!--<Transition name="slide-fade">-->
    <ul v-if="isOptionVisible" class="modal | flex flex-col bg-dropdownBackground rounded-[15px] w-[25ch] h-auto p-2.5 top-[52px] gap-2.5 list-none absolute">
      <li class="modal option | flex flex-row h-auto items-center justify-between sticky w-full"
          v-for="(item, index) in dropDownItems"
          :key="index"
          @click="selectItem(item)"
      >
        <div class="option-item | flex flex-row items-center flex-nowrap gap-3 hover:cursor-pointer hover:bg-[#13385a] hover:rounded-[10px]">
          <component :is="item.component"></component>
          <div class="flex flex-col justify-start">
            <span class="bodyXSmall text-body-small">{{item.title}}</span>
            <span class="bodyXSmall text-body-small-light">{{item.subTitle}}</span>
          </div>
          </div>
        <div class="select-btn">
          <IconRadioFill v-if="selectedItem.title === item.title" />
          <IconRadio v-else />
        </div>
      </li>
    </ul>
    <!--</Transition>-->
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, markRaw } from 'vue';
import type { IActionDropdownComponentMap } from '@/Models/IActionDropdownComponentMap';

import IconText from '@/components/icons/IconText.vue';
import IconRadioFill from '@/components/icons/IconRadioFill.vue';
import IconRadio from '@/components/icons/IconRadio.vue';
import IconChevronDown from '@/components/icons/IconChevronDown.vue';

const isOptionVisible = ref<boolean>(false);
const selectedItem = ref<IActionDropdownComponentMap>({} as IActionDropdownComponentMap);
const dropDownItems = ref<IActionDropdownComponentMap[]>([
  { title: 'Text', subTitle: 'Interact with Freddy through text-based inputs', component: markRaw(IconText), isVisible: true },
  /*{ title: 'Audio & Video', subTitle: 'Interact with Freddy through speech based inputs.', component: markRaw(IconVoice), isVisble: true }*/
]);

const emits = defineEmits(['onItemSelected']);

const props = defineProps({
  isTextSelected: {
    type: Boolean,
    required: false,
    default: true
  }
})

const openOptions = () => {
  isOptionVisible.value = !isOptionVisible.value;
};


const selectItem = (item: IActionDropdownComponentMap) => {
  isOptionVisible.value = false;
  selectedItem.value = item;
  emits('onItemSelected', item);
}

onMounted(() => {
  selectedItem.value = props.isTextSelected ? dropDownItems.value[0] : dropDownItems.value[1];
})
</script>

<style scoped lang="scss">
$drpdown-back-clr: #11385B;
$drpdown-item-width: fit-content;

.heading6 {
  color: white;
  word-wrap: break-word
}

.active {
  background: #13385a;
  border-radius: 10px;
  background-image: linear-gradient(to left, #2d567d, #2d567d);
}

.text-body-small {
  color: var(--White, #FFF);
}

.text-body-small-light {
  @extend .text-body-small;
  color: rgba(255, 255, 255, 0.40);
}

@media (max-width:600px) {
  .modal {
    gap: 46px;
    padding: 16px 6px 6px 4px;
    width: 240px;
    height: 170px;
  }
}
</style>