<template>
    <div v-if="!isAssistantPlayground">
        <div class="flex justify-between mt-9 pb-2 border-b border-solid mb-1">
            <div class="text-3xl font-semibold text-left">Assistants</div>
            <button @click="CreateAssistant"
                    class="flex justify-center items-center btn btn-primary text-white">
                <svg class="mr-1" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.9987 2.08594V7.91927M2.08203 5.0026H7.91536" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                Create
            </button>
        </div>
        <div class="xl:flex lg:flex md:flex-none sm:flex-none pt-2">
            <div class="xl:w-[30%] lg:w-1/2 md:w-full sm:w-full mr-2">
                <AssistantItem v-for="item in listOfAssistant"
                               :key="item.id"
                               :item="item"
                               :isSelected="isIdSelected(item.id)"
                               @select="selectedAssistantTab"
                               @openPlayground="handlePlayground" />
            </div>
            <AssistantCreate :selectedItemData="assistantDetail"
                             :isDefaultAssistant="(!isCreation && listOfAssistant.filter((a) => a.id === selectedItemId)[0] ?.isDefault) || false"
                             :toolOptions="toolOptions"
                             :modelOptions="modelOptions"
                             :vectorStoreOptions="vectorStoreOptions"
                             :outputModeOptions="outputModeOptions"
                             :isCreation="isCreation.valueOf()"
                             @openPlayground="handlePlayground"
                             @newAssistantCreated="handleNewAssistant"
                             @assistantUpdated="handleUpdateAssistant"
                             @deleteAssistant="deleteAssistant" />
        </div>
    </div>
    <div v-if="isAssistantPlayground">
        <AssistantPlayground :assistant="assistantDetail"
                             :isDefaultAssistant="(!isCreation && listOfAssistant.filter((a) => a.id === selectedItemId)[0]?.isDefault) || false"
                             :toolOptions="toolOptions"
                             :modelOptions="modelOptions"
                             :vectorStoreOptions="vectorStoreOptions"
                             :outputModeOptions="outputModeOptions"
                             :presetPrompts="assistantDetail.presetPrompts"
                             @newAssistantCreated="handleNewAssistant"
                             @back="handleBackButtonClick"
                             @openPlayground="handlePlayground"
                             @assistantUpdated="handleUpdateAssistant"
                             @deleteAssistant="deleteAssistant">
        </AssistantPlayground>
    </div>
</template>

<script setup lang="ts">
    import { ref, onMounted, watch } from "vue";

    import AssistantItem from "./HubAssistantsLeftCard.vue";
    import AssistantCreate from "./HubAssistantsCreate.vue";
    import AssistantPlayground from "@/components/freddycomponents/AssistantPlayground.vue";

    import { HubService } from "../../Services/HubService";
    import type { IAssistant } from "../../Models/IAssistant";
    import type { IAssistantDetail, IAssistantModel } from "../../Models/IAssistantDetail";
    import type { ITool } from "../../Models/ITool";
    import type { IModel } from "../../Models/IModel";
    import type { IVectorStore } from "../../Models/IVectoreStore";
    import type { IOutputMode } from "../../Models/IOutputMode";
    import AppConfigGlobal from "../../core/config/uiSettings";

    const hubService = new HubService();

    const getDefaultAssistantDetail = (): IAssistantDetail => {
        return {
            id: 0,
            key: "",
            name: "",
            description: "",
            instructions: "",
            additionalInstructions: "",
            model: {} as IAssistantModel,
            modelId: 1,
            createdBy: "",
            outputMode: "text",
            logitBias: 0,
            frequencyPenalty: 0,
            presencePenalty: 0,
            temperature: 0,
            topP: 0,
            isActive: true,
            isDefault: false,
            logoImage: "",
            vectorStores: [],
            tools: [],
            presetPrompts: [],
        };
    };
    const listOfAssistant = ref<IAssistant[]>([]);
    const assistantDetail = ref<IAssistantDetail>(getDefaultAssistantDetail());

    const toolOptions = ref<ITool[]>([]);
    const modelOptions = ref<IModel[]>([]);
    const vectorStoreOptions = ref<IVectorStore[]>([]);
    const outputModeOptions = ref<IOutputMode[]>([]);
    const isCreation = ref<Boolean>(false);
    const selectedItemId = ref(0);

    const isAssistantPlayground = ref<boolean>(false);

    const selectedAssistantTab = async (id: number) => {
        selectedItemId.value = id;
        isCreation.value = false;
        assistantDetail.value = await hubService.GetAssistantDetailById(
            selectedItemId.value
        );
    };
    const CreateAssistant = () => {
        isCreation.value = true;
        assistantDetail.value = getDefaultAssistantDetail();
    };

    const handleNewAssistant = async () => {
        listOfAssistant.value = await hubService.GetAllAssistants();

        if (listOfAssistant.value.length > 0) {
            selectedItemId.value =
                listOfAssistant.value[listOfAssistant.value.length - 1]?.id;
            assistantDetail.value = await hubService.GetAssistantDetailById(
                selectedItemId.value
            );
            isCreation.value = false;
        }
    };

    const handleUpdateAssistant = async () => {
        listOfAssistant.value = await hubService.GetAllAssistants();
        assistantDetail.value = await hubService.GetAssistantDetailById(
            selectedItemId.value
        );
    };

    const isIdSelected = (id: number) => {
        if (isCreation.value) return false;
        return (
            selectedItemId.value === id || (selectedItemId.value === null && id === 1)
        );
    };

    onMounted(async () => {
        try {
            listOfAssistant.value = await hubService.GetAllAssistants();
            toolOptions.value = await hubService.GetAllTools();
            modelOptions.value = await hubService.GetAllModels();
            vectorStoreOptions.value = await hubService.GetAllVectorStores();
            outputModeOptions.value = await hubService.GetAllOutputModes();

            if (listOfAssistant.value.length > 0) {
                selectedItemId.value = listOfAssistant.value[0].id;
                assistantDetail.value = await hubService.GetAssistantDetailById(
                    selectedItemId.value
                );
            }
        } catch (e: any) { }
    });

    const handlePlayground = () => {
        isAssistantPlayground.value = !isAssistantPlayground.value;
    };
    const deleteAssistant = async () => {
        await hubService.DeleteAssistantById(assistantDetail.value.id);
        await handleUpdateAssistant();
        if (listOfAssistant.value.length > 0) {
            selectedItemId.value = listOfAssistant.value[0].id;
            assistantDetail.value = await hubService.GetAssistantDetailById(
                selectedItemId.value
            );
        }
    };

    const handleBackButtonClick = () => {
        isAssistantPlayground.value = false;
    };

    watch(() => selectedItemId.value,
        (newData) => {
            AppConfigGlobal.CurrentAssistantId = newData;
        }
    );
</script>
