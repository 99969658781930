//import type { IUserOrganization } from "@/Models/IUserOrganization";
import { reactive } from "vue";

const AppConfigGlobalDev = reactive({
  OrganizationId: 3, //-->>PITS
  CurrentOrganizationId: 3, //-->>PITS
  CurrentAssistantId: 1, //-->>asst_E5Frmie8wHOabavLONOwcFna
  CurrentThreadId: 0,
  //Organizations: ref<IUserOrganization[]>([]),
  UserId: 40,
  UserName: "",
  UserImage: "",
  FreddyAssistantApiUrl: "https://freddy-api.aitronos.ch",
  // FreddyAssistantApiUrl: "https://localhost:7053",
  FreddyAssistantUrl: "http://localhost:5173",
  Domain: "localhost",
  //UserAuthUrl: "https://freddy-api.aitronos.ch/v1"
});

export type TGlobalConfig = typeof AppConfigGlobalDev;

if ((window as any)["AppConfigGlobal"]) {
  AppConfigGlobalDev.FreddyAssistantApiUrl = (window as any)["AppConfigGlobal"][
    "FreddyAssistantApiUrl"
  ];
  AppConfigGlobalDev.FreddyAssistantUrl = (window as any)["AppConfigGlobal"][
    "FreddyAssistantUrl"
  ];
  AppConfigGlobalDev.Domain = (window as any)["AppConfigGlobal"]["Domain"];
  AppConfigGlobalDev.OrganizationId = (window as any)["AppConfigGlobal"][
    "OrganizationId"
  ];
  //AppConfigGlobalDev.UserAuthUrl = (window as any)['AppConfigGlobal']['UserAuthUrl'];
}

const AppConfigGlobal = AppConfigGlobalDev;
export default AppConfigGlobal;
