<template>
  <section class="frame grid place-items-center h-full">
    <section
      class="authentication-section min-w-[265px] connector-card-space bg-[var(--color-background-sidebar)] border border-[var(--grey-border-line)] rounded-xl p-4 w-2/5"
    >
      <header class="header flex gap-3 items-center mb-5">
        <div tabindex="0" class="icon-wrap">
          <component :is="sourceLogoComponent"></component>
        </div>
        <h1 class="heading">{{ sourceTitle }}</h1>
      </header>
      <main class="main-content">
        <h2 class="authentication-title text-3xl font-semibold mb-5">
          Authentication
        </h2>
        <form class="authentication-form" @submit.prevent="onContinue">
          <label
            v-if="isNotionConnector"
            for="bearer-token"
            class="label text-sm mb-3 block"
            >Enter your Bearer Token</label
          >
          <label v-else for="org-link" class="label text-sm mb-3 block"
            >Enter your organizational link</label
          >
          <div
            class="input-wrap connectors-container head actions action border rounded-lg border-[var(--grey-border-line)] bg-[var(--color-background-sidebar)] mb-5"
          >
            <input
              v-if="isNotionConnector"
              v-model="bearerToken"
              id="bearer-token"
              type="text"
              class="input-field connectors-container head actions action search input w-full h-full bg-[var(--color-background-sidebar)] focus:outline-none text-sm p-3 my-1"
              placeholder="Bearer Token"
              required
            />
            <input
              v-else
              v-model="organizationLink"
              id="org-link"
              type="text"
              class="input-field connectors-container head actions action search input w-full h-full bg-[var(--color-background-sidebar)] focus:outline-none text-sm p-3"
              :placeholder="`https://example.com/${sourceTitle}`"
              required
            />
          </div>
          <button type="submit" class="btn btn-primary inline-block w-full">
            Continue
          </button>
        </form>
      </main>
    </section>
  </section>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";

const emits = defineEmits(["onContinueLink"]);

const props = defineProps({
  sourceTitle: {
    type: String,
    default: "",
    required: true,
  },
  sourceLogoComponent: {
    type: Object,
    required: true,
  },
  selectedConnector: {
    type: String,
    required: false,
  },
});

const organizationLink = ref("");
const bearerToken = ref("");
const isNotionConnector = computed(() => props.selectedConnector === "Notion");

const onContinue = () => {
  if (isNotionConnector.value) {
    emits("onContinueLink", bearerToken.value); // Emit the bearer token for Notion
  } else {
    emits("onContinueLink", organizationLink.value); // Emit the organizational link for other connectors
  }
};

onMounted(() => {
  organizationLink.value = "";
});
</script>
<!-- 
<style scoped>
    .frame {
        border-radius: 30px;
        background-color: rgba(7, 26, 43, 1);
        display: flex;
        max-width: 462px;
        flex-direction: column;
        justify-content: center;
        padding: 30px;
        border: 1px solid rgba(17, 56, 91, 1);
        min-width: 30%;
    }

    .header {
        display: flex;
        align-items: center;
        gap: 12px;
        flex: 1;
    }

    .icon-wrap {
        border-radius: 8px;
        border: 1px solid #eaecf0;
        background: #fff;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        display: flex;
        justify-content: center;
        padding: 2px;
    }

    .icon {
        width: 48px;
        aspect-ratio: 1;
        object-fit: contain;
    }

    .heading {
        color: #fff;
        font: 500 17px Inter, sans-serif;
        flex: 1;
    }

    .main-content {
        margin-top: 46px;
        display: flex;
        flex-direction: column;
    }

    .authentication-title {
        font-size: 29px;
        color: #fff;
        font-weight: 600;
    }

    .authentication-form {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
    }

    .label {
        color: #fff;
        font-size: 14px;
        font-weight: 500;
    }

    .input-wrap {
        display: flex;
        margin-top: 20px;
        align-items: center;
        gap: -1px;
    }

    .input {
        background-color: rgba(7, 26, 43, 1);
        display: flex;
        align-items: center;
        border: 1px solid rgba(123, 168, 239, 1);
        padding: 8px 12px;
    }

    .input-prefix {
        border-radius: 8px 0 0 8px;
    }

    .input-suffix {
        border-radius: 0 8px 8px 0;
    }

    .input-field {
        flex: 1;
        padding: 12px;
        border: 1px solid rgba(123, 168, 239, 1);
        color: #fff;
        background-color: rgba(7, 26, 43, 1);
        border-radius: 8px;
    }

    .submit-button {
        margin-top: 30px;
        border-radius: 6px;
        background-color: rgba(123, 168, 239, 1);
        min-height: 40px;
        width: 100%;
        padding: 10px;
        font-size: 17px;
        font-weight: 500;
        color: #031525;
        text-align: center;
        cursor: pointer;
    }
</style> -->
