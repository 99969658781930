<template>
    <article class="flex overflow-hidden flex-col p-5 mt-0  my-auto font-medium bg-slate-900 rounded-[40px] w-[244px] h-full">
        <h2 class="flex gap-2.5 items-start self-start text-xl font-bold text-white">
            Used Neurons
        </h2>
        <div class="mt-5 w-[150px] h-[150px]">
            <svg viewBox="0 0 100 100" class="w-full h-full">
                <circle v-for="(segment, index) in pieSlices"
                        :key="index"
                        class="circle"
                        cx="50"
                        cy="50"
                        r="40"
                        fill="none"
                        :stroke="segment.color"
                        stroke-width="12"
                        :stroke-dasharray="`${segment.dashArray}, 251.328`"
                        :stroke-dashoffset="calculateOffset(index)"
                        stroke-linecap="square"
                        :style="{ animationDelay: `${index * 0.2}s`, strokeDashoffset: calculateOffset(index)}" />
            </svg>
        </div>
        <ul class="flex flex-col mt-5 mb-5 w-full">
            <li v-for="(request, index) in featureRequests"
                :key="index"
                class="flex flex-col justify-center items-start mt-2 w-full">
                <div class="flex items-center">
                    <span class="content-[''] inline-block w-1.5 h-1.5 lime rounded-full mr-2" :class="featureRequests[index].class"></span>
                    <h3 class="gap-1.5 self-stretch text-sm text-white">{{ request.topic }}</h3>
                </div>              
                <p class="gap-2.5 self-stretch pl-4 mt-1 text-xs text-slate-300">
                    CHF {{ request.count }}
                </p>
            </li>
        </ul>
    </article>
</template>

<script lang="ts">
    import { defineComponent, ref, computed } from "vue";

    interface FeatureRequest {
        topic: string;
        count: number;
        color: string;
        class: string;
    }

    interface PieSlice {
        color: string;
        dashArray: number;
        rotation: number;
    }

    export default defineComponent({
        name: "FeatureRequests",
        setup() {
            const showDetails = ref(false);
            const total = ref(0);
            const featureRequests = ref<FeatureRequest[]>([
                { topic: 'API 1 (60%)', count: 60.00, color: '#2d7a3a', class: 'bg-[#2d7a3a]' },
                { topic: 'API 2 (20%)', count: 20.00, color: '#1a4721', class: 'bg-[#1a4721]' },
                { topic: 'API 3 (20%)', count: 20.00, color: '#0c6e1b', class: 'bg-[#0c6e1b]' },
                { topic: 'API 4 (10%)', count: 10.25, color: '#a8e3b1', class: 'bg-[#a8e3b1]' },
            ]);

            const toggleDetails = () => {
                showDetails.value = !showDetails.value;
            };

            const pieSlices = computed<PieSlice[]>(() => {
                total.value = featureRequests.value.reduce(
                    (sum, request) => sum + request.count,
                    0
                );
                let currentAngle = 0;

                return featureRequests.value.map((request) => {
                    const percentage = (request.count / total.value) * 100;
                    const dashArray = (percentage * 251.328) / 100;
                    const rotation = currentAngle; // Rotation for each slice, based on its starting angle
                    currentAngle += percentage;

                    return {
                        color: request.color,
                        dashArray,
                        rotation,
                    };
                });
            });

            const calculateOffset = (index: number) => {
                const offset = pieSlices.value
                    .slice(0, index)
                    .reduce((sum, segment) => sum + segment.dashArray, 0);
                return -offset; // Negative offset to shift each slice properly
            };

            return {
                showDetails,
                featureRequests,
                toggleDetails,
                pieSlices,
                calculateOffset,
                total,
            };
        },
    });
</script>

<style scoped lang="scss">
    @keyframes draw {
        from {
            stroke-dashoffset: 0; /* Starting from a full circle */
        }

        to {
            stroke-dashoffset: 251.328; /* Fully drawn */
        }
    }

    .circle {
        animation: draw 1s ease-out reverse;
    }
</style>
