import { ref } from 'vue';
import { UserService } from '@/Services/UserService';
import type { IUserStatus } from '../Models/Users/IUserStatus';
import type { IUserRole } from '../Models/Users/IUserRole';

const userService = new UserService();

const userStatuses = ref<IUserStatus[]>([]);
const userRoles = ref<IUserRole[]>([]);
let isDataFetched = false;

export const GetMasterData = async () => {
    if (!isDataFetched) {
        userRoles.value = await userService.GetUserRoles();
        userStatuses.value = await userService.GetUserStatuses();      
        isDataFetched = true;
    }
};

export const getUserStatuses = () => userStatuses;
export const getUserRoles = () => userRoles;
