<template>
    <main class="flex overflow-hidden flex-col p-5">
        <section class="flex overflow-hidden flex-col max-w-full bg-none h-full w-full">
            <header class="flex flex-col pt-2.5 w-full text-3xl font-semibold max-md:max-w-full">
                <h1 class="flex flex-wrap gap-5 items-start w-full max-md:max-w-full">
                    <span class="flex-1 shrink w-full min-w-[320px] max-md:max-w-full">{{userToEdit ? 'Edit User' : 'New User'}}</span>
                </h1>
                <div class="flex mt-2.5 w-full bg-gray-200 fill-gray-200 min-h-[1px] max-md:max-w-full"></div>
            </header>
            
            <form @submit.prevent="handleSave" class="flex relative overflow-hidden flex-col mt-5 min-h-[200px] w-full bg-none max-md:max-w-full">
                <div v-if="isLoadingResults"
                     class="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-[#031525c4] backdrop-blur-[4px] z-[1000] overflow-auto scroll-width">
                    <Spinner />
                </div>
                <div class="flex flex-1 gap-5 items-start size-full max-md:max-w-full">
                    <div class="flex flex-col flex-1 shrink w-full basis-0 min-h-[329px] min-w-[240px] max-md:max-w-full">
                        <div class="flex flex-wrap gap-5 items-start w-full whitespace-nowrap max-md:max-w-full">
                            <div class="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                                <div class="flex flex-col w-full max-md:max-w-full">
                                    <label for="fullname" class="text-sm font-medium">Fullname *</label>
                                    <div class="relative flex items-center rounded-lg border border-zinc-700 p-2">
                                        <input type="text"
                                               id="fullname"
                                               v-model="fullName"
                                               autocomplete="off"
                                               class="flex-1 grow shrink basis-0 gap-2 max-w-[90%] self-stretch my-auto text-ellipsis bg-transparent focus:outline-none" />
                                        <span class="absolute top-[0.75rem] right-3 group">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_21821_8660)">
                                                    <path d="M6.0587 6.0026C6.21543 5.55705 6.5248 5.18134 6.932 4.94203C7.3392 4.70271 7.81796 4.61523 8.28348 4.69508C8.749 4.77493 9.17124 5.01695 9.47542 5.37829C9.77959 5.73963 9.94607 6.19695 9.94536 6.66927C9.94536 8.0026 7.94536 8.66927 7.94536 8.66927M7.9987 11.3359H8.00536M14.6654 8.0026C14.6654 11.6845 11.6806 14.6693 7.9987 14.6693C4.3168 14.6693 1.33203 11.6845 1.33203 8.0026C1.33203 4.32071 4.3168 1.33594 7.9987 1.33594C11.6806 1.33594 14.6654 4.32071 14.6654 8.0026Z"
                                                          stroke="#85888E"
                                                          stroke-width="1.33333"
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_21821_8660">
                                                        <rect width="16" height="16" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <Tooltip title="Full Name" content="" />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                                <div class="flex flex-col w-full max-md:max-w-full">
                                    <label for="username" class="text-sm font-medium">Username *</label>
                                    <div class="relative flex items-center rounded-lg border border-zinc-700 p-2">
                                        <input type="text"
                                               id="username"
                                               autocomplete="off"
                                               v-model="userName"
                                               class="flex-1 grow shrink basis-0 gap-2 max-w-[90%] self-stretch my-auto text-ellipsis bg-transparent focus:outline-none" />
                                        <span class="absolute top-[0.75rem] right-3 group">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_21821_8660)">
                                                    <path d="M6.0587 6.0026C6.21543 5.55705 6.5248 5.18134 6.932 4.94203C7.3392 4.70271 7.81796 4.61523 8.28348 4.69508C8.749 4.77493 9.17124 5.01695 9.47542 5.37829C9.77959 5.73963 9.94607 6.19695 9.94536 6.66927C9.94536 8.0026 7.94536 8.66927 7.94536 8.66927M7.9987 11.3359H8.00536M14.6654 8.0026C14.6654 11.6845 11.6806 14.6693 7.9987 14.6693C4.3168 14.6693 1.33203 11.6845 1.33203 8.0026C1.33203 4.32071 4.3168 1.33594 7.9987 1.33594C11.6806 1.33594 14.6654 4.32071 14.6654 8.0026Z"
                                                          stroke="#85888E"
                                                          stroke-width="1.33333"
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_21821_8660">
                                                        <rect width="16" height="16" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <Tooltip title="User Name" content="" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-wrap gap-5 items-start mt-5 w-full whitespace-nowrap max-md:max-w-full">
                            <div class="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                                <div class="flex flex-col w-full max-md:max-w-full gap-2">
                                    <label for="email" class="text-sm font-medium">Email *</label>
                                    <div class="relative flex items-center rounded-lg border border-zinc-700 p-2">
                                        <input type="text"
                                               id="email"
                                               autocomplete="off"
                                               v-model="email"
                                               class="flex-1 grow shrink basis-0 gap-2 max-w-[90%] self-stretch my-auto text-ellipsis bg-transparent focus:outline-none" />
                                        <span class="absolute top-[0.75rem] right-3 group">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_21821_8660)">
                                                    <path d="M6.0587 6.0026C6.21543 5.55705 6.5248 5.18134 6.932 4.94203C7.3392 4.70271 7.81796 4.61523 8.28348 4.69508C8.749 4.77493 9.17124 5.01695 9.47542 5.37829C9.77959 5.73963 9.94607 6.19695 9.94536 6.66927C9.94536 8.0026 7.94536 8.66927 7.94536 8.66927M7.9987 11.3359H8.00536M14.6654 8.0026C14.6654 11.6845 11.6806 14.6693 7.9987 14.6693C4.3168 14.6693 1.33203 11.6845 1.33203 8.0026C1.33203 4.32071 4.3168 1.33594 7.9987 1.33594C11.6806 1.33594 14.6654 4.32071 14.6654 8.0026Z"
                                                          stroke="#85888E"
                                                          stroke-width="1.33333"
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_21821_8660">
                                                        <rect width="16" height="16" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <Tooltip title="Email" content="" />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="flex flex-col flex-1 shrink basis-0 min-w-[300px] max-md:max-w-full">
                                <div class="w-full 2xl:w-full xl:w-full lg:w-full sm:w-full md:w-full xl:mb-0 lg:mb-0 sm:mb-5 md:mb-5 mb-5 gap-2">
                                    <customLabel text="Role" tooltipText="Role"></customLabel>
                                    <select id="role"
                                            v-model="roleId"
                                            class="flex overflow-hidden flex-wrap gap-2 items-center px-3.5 py-2 mt-1.5 w-full text-base rounded-lg border border-solid bg-slate-900 border-zinc-700 max-md:max-w-full focus:outline-none">
                                        <option v-for="role in roles" :key="role.id" :value="role.id">{{ role.name }}</option>
                                    </select>
                                </div>
                            </div>

                            <!--<div class="flex flex-col flex-1 shrink basis-0 min-w-[300px] max-md:max-w-full">
                    <div class="w-full 2xl:w-full xl:w-full lg:w-full sm:w-full md:w-full xl:mb-0 lg:mb-0 sm:mb-5 md:mb-5 mb-5">
                        <customLabel text="Status" tooltipText="Status"></customLabel>
                        <select id="status"
                                v-model="formData.status"
                                class="flex overflow-hidden flex-wrap gap-2 items-center px-3.5 py-2 mt-1.5 w-full text-base rounded-lg border border-solid bg-slate-900 border-zinc-700 max-md:max-w-full focus:outline-none"
                                required>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                        </select>
                    </div>
                </div>-->
                        </div>

                        <!--<div class="flex flex-wrap gap-5 items-start mt-5 w-full max-md:max-w-full">
                <div class="flex flex-col flex-1 shrink basis-0 min-w-[300px] max-md:max-w-full">
                    <div class="w-full 2xl:w-full xl:w-full lg:w-full sm:w-full md:w-full xl:mb-0 lg:mb-0 sm:mb-5 md:mb-5 mb-5">
                        <customLabel text="Department" tooltipText="Department"></customLabel>
                        <select id="department"
                                v-model="formData.department"
                                class="flex overflow-hidden flex-wrap gap-2 items-center px-3.5 py-2 mt-1.5 w-full text-base rounded-lg border border-solid bg-slate-900 border-zinc-700 max-md:max-w-full focus:outline-none" >
                            <option value="Choose a department">Active</option>
                            <option v-for="dept in departments" :key="dept" :value="dept">{{ dept }}</option>
                        </select>
                    </div>
                </div>

                <div class="flex flex-col flex-1 shrink basis-0 min-w-[300px] max-md:max-w-full">
                    <div class="w-full 2xl:w-full xl:w-full lg:w-full sm:w-full md:w-full xl:mb-0 lg:mb-0 sm:mb-5 md:mb-5 mb-5">
                        <customLabel text="Role" tooltipText="Role"></customLabel>
                        <select id="role"
                                v-model="formData.role"
                                class="flex overflow-hidden flex-wrap gap-2 items-center px-3.5 py-2 mt-1.5 w-full text-base rounded-lg border border-solid bg-slate-900 border-zinc-700 max-md:max-w-full focus:outline-none" >
                            <option value="Choose a role">Active</option>
                            <option v-for="role in roles" :key="role" :value="role">{{ role }}</option>
                        </select>
                    </div>
                </div>
            </div>-->

                        <div class="flex flex-col pt-10 mt-5 w-full text-lg font-medium text-center whitespace-nowrap max-md:max-w-full">
                            <div class="flex items-center w-full max-md:max-w-full">
                                <div v-if="userToEdit" class="flex flex-row justify-between gap-2.5 w-full max-md:max-w-full self-stretch my-auto">
                                    <button type="button"
                                            @click="handleDelete"
                                            class="overflow-hidden self-stretch py-0.5 my-auto w-20 text-red-400 rounded-md border border-red-400 border-solid min-w-[80px] max-w-[80px] min-h-[40px]">
                                        Delete
                                    </button>
                                </div>
                                <div class="flex flex-row justify-end gap-2.5 w-full max-md:max-w-full self-stretch my-auto">
                                    <button type="button"
                                            @click="handleCancel"
                                            class="overflow-hidden self-stretch py-0.5 my-auto w-20 rounded-md border border-blue-400 border-solid min-w-[80px] max-w-[80px] min-h-[40px]">
                                        Cancel
                                    </button>
                                    <button type="submit"
                                            class="overflow-hidden min-w-[80px] max-w-[80px] self-stretch py-0.5 my-auto w-20 bg-blue-400 rounded-md min-h-[40px]">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </section>
    </main>
    <div>
        <DeleteUserModal v-if="isDeleteModalVisible" :userName="userToEdit?.fullName || ''" @onCancel="onDeleteCancel" @onDelete="onDelete" />
        <UserErrorModal v-if="isErrorModalVisible" :isVisible="isErrorModalVisible" :message="errorMessage" @onClose="closeErrorModal" />
    </div>
</template>

<script setup lang="ts">
    import { ref, defineEmits, defineProps, onMounted } from 'vue';
    import type {PropType} from 'vue';
    import Tooltip from "../../common/ToolTip.vue";
    import selectbox from "../../common/CustomSelect.vue";
    import CustomLabel from "../../common/labelCustom.vue";
    import Spinner from '@/components/common/Spinner.vue';

    import UserErrorModal from "../CrewManagementUsers/UserErrorModal.vue";

    import { UserService } from '@/Services/UserService';
    import { OrganizationService } from '@/Services/OrganizationService';

    import type { IUserRole } from '@/Models/Users/IUserRole';
    import type { IUser } from '@/Models/Users/IUser';
    import type { IUserInvitationRequest } from '@/Models/Users/Request/IUserInvitationRequest';
    import type { IUserUpdateRequest } from '@/Models/Users/Request/IUserUpdateRequest';

    import DeleteUserModal from "./DeleteUserModal.vue";

    const fullName = ref<string>('');
    const userName = ref<string>('');
    const email = ref<string>('');
    const roleId = ref<number>(0);

    const userService = new UserService();
    const organizationService = new OrganizationService();

    const emits = defineEmits(['cancel', 'saved', 'deleted', 'onSuccessAction', 'onError']);
    const props = defineProps({
        userToEdit: {
            type: Object as PropType<IUser>,
            default: undefined
        }
    })

    const errorMessage = ref<string>('');
    const roles = ref<IUserRole[]>([]);
    const organizationDomains = ref<string[]>([]);

    const isDeleteModalVisible = ref(false);
    const isErrorModalVisible = ref(false);

    const isLoadingResults = ref<boolean>(false);

    const handleCancel = () => {
        clearForm();
        emits('cancel');
    }

    const closeErrorModal = () => {
        isErrorModalVisible.value = false;
    }

    const validateUserInput = (): string => {
        if (!fullName.value.trim() || !userName.value.trim() || !email.value.trim() || !roleId.value) return 'Please fill all the required fields.';

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email.value.trim())) return 'Incorrect email format.';

        const emailParts = email.value.trim().split('@');
        if (!organizationDomains.value.map(x => x).includes(`@${emailParts[1]}`)) return 'This email is not valid in the current organization.';

        return '';
    }

    const handleSave = async () => {
        closeErrorModal();
        errorMessage.value = '';
        errorMessage.value = validateUserInput();     
        if (errorMessage.value) {
            isErrorModalVisible.value = true;
            return;
        }

        let savedUserId = props.userToEdit
            ? await updateUser()
            : await inviteUser();

        if (!savedUserId) {
            errorMessage.value = 'User is not created yet. Something went wrong!!';
            isErrorModalVisible.value = true;
            return;
        }
        emits('saved', savedUserId);
    }

    const updateUser = async () => {
        //---> Update only the changed property.
        const userUpdateRequest = {
            fullName: props.userToEdit?.fullName.trim() !== fullName.value.trim()
                ? fullName.value.trim() : '',
            userName: props.userToEdit?.userName.trim() !== userName.value.trim()
                ? userName.value.trim() : '',
            email: props.userToEdit?.email.trim() !== email.value.trim()
                ? email.value.trim() : '',
            roleId: props.userToEdit?.role.id !== roleId.value
                ? roleId.value : 0,
            previousRoleId: props.userToEdit?.role.id !== roleId.value
                ? props.userToEdit?.role.id : 0
        } as IUserUpdateRequest;
        const userIdToUpdate = props.userToEdit?.id as number;
        return await userService.UpdateUserBasicData(userIdToUpdate, userUpdateRequest);
    }

    const inviteUser = async () => {
        const userRequest = {
            fullName: fullName.value.trim(),
            userName: userName.value.trim(),
            email: email.value.trim(),
            roleId: roleId.value
        } as IUserInvitationRequest;

        return await userService.InviteUser(userRequest);
    }

    const handleDelete = () => {
        isDeleteModalVisible.value = true;
    }

    const onStatusSelect = () => {
    }

    const onDeleteCancel = () => {
        isDeleteModalVisible.value = false;
    }

    const onDelete = async () => {
        isDeleteModalVisible.value = false;
        const result = await userService.DeleteUserById(props.userToEdit?.id as number);
        if (result) {
            emits('deleted');
            emits('onSuccessAction', 'User removed', 'The user has been successfully removed.');
        }
        else {
            emits('onError');
        }
    }

    const clearForm = () => {
        fullName.value = '';
        userName.value = '';
        email.value = '';
        roleId.value = 0;
    }

    onMounted(async () => {
        isLoadingResults.value = true;
        clearForm();
        roles.value = await userService.GetUserRoles();
        organizationDomains.value = await organizationService.GetOrganizationDomains();

        if (props.userToEdit) {
            fullName.value = props.userToEdit.fullName;
            userName.value = props.userToEdit.userName;
            email.value = props.userToEdit.email;
            roleId.value = props.userToEdit.role.id;
        }

        isLoadingResults.value = false;
    })

</script>